import axios from 'axios';

export default axios.create({
  // use baseurl from .ENV file
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'http://localhost:3000/api', // Descomentar para não deixar o IP fixo
  // baseURL: 'http://10.145.5.74:3000/api', // FindesLab Descomentar para testar no celular - Alterar o IP para o IP da máquina (se necessário)
  // baseURL: 'http://192.168.100.5:3000/api', // Casa Breno Descomentar para testar no celular - Alterar o IP para o IP da máquina (se necessário)
  headers: {
    'Content-type': 'application/json',
  },
});
