import { useContext } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

import logo from '../../assets/img/logo-horizontal.svg';

import { Context } from '../../Context/AuthContext';
import {
  Container, Content, FormGroup, TextLink, CodeInput,
} from './styles';
import PersoraButton from '../../components/Button';
import { Clock } from '../../components/DuotoneIcon';

export default function AuthUser() {
  const { authenticated, handleAuth, btnLoading } = useContext(Context);
  const location = useLocation();
  console.debug('authenticated', authenticated);
  /** Se usuário está autenticado, redireciona ele para /userSpace */
  if (authenticated) {
    return <Redirect to="/userSpace" />;
  }

  // get email on url params
  const urlParams = new URLSearchParams(location.search);
  const email = urlParams.get('email');

  const handleSubmit = (event) => {
    event.preventDefault();
    // get email on url params
    const code = event.target.code.value.replace(/-|\s/g, '');
    // remove spaces and hyphens from code
    handleAuth(email, code);
  };

  return (
    <Container>
      <Content>
        <img src={logo} alt="Logo" width="140px" />
        <h2 className="mb-1">Veja seu email 📨</h2>
        <p className="sourcesans mb-3">
          Acabamos de te enviar um código de acesso para
          {' '}
          <b>{email}</b>
        </p>
        <form id="authUserForm" onSubmit={handleSubmit}>
          <FormGroup>
            <div htmlFor="login-authCode">Insira o código</div>
            <CodeInput type="text" className="form-control" id="login-authCode" name="code" placeholder="XXXX-XXXX" />
          </FormGroup>
          <PersoraButton className="mt-4" type="submit" form="authUserForm" disabled={btnLoading}>
            {btnLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Acessar Persora'}
          </PersoraButton>
        </form>
        <TextLink className="small">
          Voltar para
          <Link to="/login" style={{ marginLeft: '3px' }}>🚪 tela de Login!</Link>
        </TextLink>
      </Content>
    </Container>
  );
}
