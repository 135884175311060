import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import defaultTheme from '../../../assets/styles/themes/default';
import profileContents from '../../../utils/profileContents';

export default function UserProfileComunicationCards({ lastProfile, ...props }) {
  const firstLetterProfile = lastProfile.displayedProfileLetters[0].toLowerCase();
  const profilebackgroundcolor = defaultTheme.colors.disc[firstLetterProfile].light;

  return (
    <div {...props}>
      <h4 className="sourcesans mb-2 semibold">Comunicação</h4>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <span className="icon" style={{ background: profilebackgroundcolor }}>💬</span>
            Conversas
          </Accordion.Header>
          <Accordion.Body>
            <hr className="pt-0 mt-0" />
            <div
              dangerouslySetInnerHTML={
              { __html: profileContents[lastProfile.profileLetters].comunicacao.conversas.neutral }
            }
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <span className="icon" style={{ background: profilebackgroundcolor }}>💼</span>
            Reuniões
          </Accordion.Header>
          <Accordion.Body>
            <hr className="pt-0 mt-0" />
            <div
              dangerouslySetInnerHTML={
              { __html: profileContents[lastProfile.profileLetters].comunicacao.reunioes.neutral }
            }
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <span className="icon" style={{ background: profilebackgroundcolor }}>📨</span>
            E-mails
          </Accordion.Header>
          <Accordion.Body>
            <hr className="pt-0 mt-0" />
            <div
              dangerouslySetInnerHTML={
              { __html: profileContents[lastProfile.profileLetters].comunicacao.emails.neutral }
            }
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <span className="icon" style={{ background: profilebackgroundcolor }}>🤝</span>
            Feedback
          </Accordion.Header>
          <Accordion.Body>
            <hr className="pt-0 mt-0" />
            <div
              dangerouslySetInnerHTML={
              { __html: profileContents[lastProfile.profileLetters].comunicacao.feedback.neutral }
            }
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <span className="icon" style={{ background: profilebackgroundcolor }}>💣️</span>
            Conflitos
          </Accordion.Header>
          <Accordion.Body>
            <hr className="pt-0 mt-0" />
            <div
              dangerouslySetInnerHTML={
              { __html: profileContents[lastProfile.profileLetters].comunicacao.conflitos.neutral }
            }
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
UserProfileComunicationCards.propTypes = {
  lastProfile: PropTypes.shape({
    profileLetters: PropTypes.string,
    displayedProfileLetters: PropTypes.string,
  }).isRequired,
};
