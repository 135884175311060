import PropTypes from 'prop-types';
import { useState } from 'react';
import { Sheet } from 'react-modal-sheet';
// import ReactGA from 'react-ga4';
// import mixpanel from 'mixpanel-browser';
import {
  CloseButton,
  ComoSeraButton,
  InsideOutlineButton, SoraContainer, StyledButton, TextContainer,
} from './styles';
import soraPremium1 from '../../assets/img/Sora/Paz.svg';
import soraPremium2 from '../../assets/img/premium-sora-2.svg';
import { Cross, QuestionCircle } from '../DuotoneIcon';
import api from '../../services/backend';
import PremiumAbout from '../../pages/Sheets/PremiumAbout';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import PremiumPrices from '../../pages/Sheets/PremiumPrices';
// import { UserContext } from '../../Context/UserContext';

export default function PremiumDisclaimer({ modelo, children, ...props }) {
  const [premiumAboutSheetIsOpen, setPremiumAboutSheetOpen] = useState(false);
  const [premiumPricesSheetIsOpen, setPremiumPricesSheetOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  // const { currentUserContextData } = useContext(UserContext);
  const handleDismissDisclaimer = async (dismissAttribute) => {
    console.log('clicou');
    try {
      setIsVisible(false);
      const body = {
        [dismissAttribute]: true,
      };
      const { data } = await api.patch('/user/disclaimer', body);
      console.log(data);
    } catch (error) {
      setIsVisible(true);
      console.log(error);
    }
  };

  return (
    <>
      {modelo === 'botaoComoSera' && (
        <ComoSeraButton type="button" className="mt-3" onClick={() => setPremiumAboutSheetOpen(true)}>
          <QuestionCircle tamanho={24} cor="#FFFFFF" />
          <span className="semibold dmsans">
            Como será o premium?
          </span>
        </ComoSeraButton>
      )}
      {modelo === 'condensado' && isVisible
      && (
        <>
          <CloseButton onClick={() => handleDismissDisclaimer('premiumDisclaimerAtTeamTab')}>
            <Cross cor="#FFF" />
          </CloseButton>
          <StyledButton
            {...props}
            className={`${props.className}`}
            style={{ position: 'relative' }}
            onClick={() => {
              // if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
              //   ReactGA.event({
              //     category: 'Premium',
              //     action: 'abrir_upgrade_premium',
              //   });
              //   mixpanel.track('abrir_upgrade_premium', {
              //     category: 'Premium',
              //     company_id: currentUserContextData._idWorkspace[0],
              //   });
              // }
              setPremiumAboutSheetOpen(true);
            }}
          >
            <TextContainer style={{ width: '70%', paddingRight: '1rem' }}>
              <h5>Upgrade Premium!</h5>
              <p>
                Adicione todo o seu time no app e acelere o seu crescimento como líder.
              </p>
              {/* <InsideOutlineButton estilo="outline">
            Quero saber mais
          </InsideOutlineButton> */}
            </TextContainer>
            <SoraContainer style={{ width: '25%', maxWidth: '100px' }}>
              <img src={soraPremium2} alt="Sora Premium" style={{ width: '135%', maxWidth: '150px', marginBottom: '-25px' }} />
            </SoraContainer>
            {children}
          </StyledButton>
        </>
      )}
      {modelo === 'expandido'
      && (
      <StyledButton
        {...props}
        className={`${props.className}`}
        style={{ position: 'relative' }}
        onClick={() => {
          // if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
          //   ReactGA.event({
          //     category: 'Premium',
          //     action: 'abrir_upgrade_premium',
          //   });
          //   mixpanel.track('abrir_upgrade_premium', {
          //     category: 'Premium',
          //     company_id: currentUserContextData._idWorkspace[0],
          //   });
          // }
          setPremiumAboutSheetOpen(true);
        }}
      >
        <TextContainer style={{ width: '70%', paddingRight: '1rem' }}>
          <h5>Upgrade Premium!</h5>
          <p>
            Adicione todo o seu time no app e acelere o seu crescimento como líder.
          </p>
          <InsideOutlineButton estilo="outline">
            Quero saber mais
          </InsideOutlineButton>
        </TextContainer>
        <SoraContainer style={{ width: '25%', maxWidth: '100px' }}>
          <img src={soraPremium1} alt="Sora Premium" style={{ width: '135%', maxWidth: '150px' }} />
        </SoraContainer>
        {children}
      </StyledButton>
      )}
      <Sheet
        isOpen={premiumAboutSheetIsOpen}
        onClose={() => setPremiumAboutSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
      >
        <PremiumAbout
          setPremiumAboutSheetOpen={setPremiumAboutSheetOpen}
          setPremiumPricesSheetOpen={setPremiumPricesSheetOpen}
        />
        <Sheet.Backdrop onTap={() => setPremiumAboutSheetOpen(false)} />
      </Sheet>
      <Sheet
        isOpen={premiumPricesSheetIsOpen}
        onClose={() => setPremiumPricesSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableScrollLocking
      >
        <PremiumPrices
          setPremiumAboutSheetOpen={setPremiumAboutSheetOpen}
          setPremiumPricesSheetOpen={setPremiumPricesSheetOpen}
        />
        <Sheet.Backdrop onTap={() => setPremiumPricesSheetOpen(false)} />
      </Sheet>
    </>
  );
}

PremiumDisclaimer.propTypes = {
  children: PropTypes.node,
  modelo: PropTypes.oneOf(['condensado', 'expandido', 'botaoComoSera']),
  className: PropTypes.string,
};
PremiumDisclaimer.defaultProps = {
  children: '',
  modelo: 'condensado',
  className: '',
};
