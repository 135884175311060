import PropTypes from 'prop-types';
import {
  Container, LeftBar, RightBar, StepDivider,
} from './styles';

export default function PipBarGraph({
  scaleValues,
  leftValue,
  rightValue,
  containerHeight,
  worseDimension,
  ...props
}) {
  const leftHeight = (
    (leftValue * containerHeight) / scaleValues[scaleValues.length - 1])
    - (containerHeight / scaleValues.length)
    + 2;
  const rightHeight = (
    (rightValue * containerHeight) / scaleValues[scaleValues.length - 1])
    - (containerHeight / scaleValues.length)
    + 2;

  const hasTwoValues = leftValue && rightValue;

  return (
    <Container style={{ height: `${containerHeight}px` }} className={`${props.className}`}>
      {scaleValues.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <StepDivider $contentbefore={value} key={`pipBarGraphStep-${index}`} style={{ bottom: `${index * (containerHeight / scaleValues.length)}px` }} />
      ))}
      {leftValue && leftValue >= scaleValues[0]
        ? (
          <LeftBar
            style={{ height: `${leftHeight}px` }}
            className={`${!hasTwoValues && worseDimension ? 'worseDimension' : ''}`}
            $contentbefore={`${leftValue}${!hasTwoValues && worseDimension ? ' 🚨' : ''}`}
          />
        )
        : null}
      {rightValue && rightValue >= scaleValues[0]
        ? (
          <RightBar
            style={{ height: `${rightHeight}px` }}
            className={`${hasTwoValues && worseDimension ? 'worseDimension' : ''}`}
            $contentbefore={`${rightValue}${hasTwoValues && worseDimension ? ' 🚨' : ''}`}
          />
        )
        : null}
    </Container>
  );
}

PipBarGraph.propTypes = {
  scaleValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  leftValue: PropTypes.number,
  rightValue: PropTypes.number,
  containerHeight: PropTypes.number,
  className: PropTypes.string,
  worseDimension: PropTypes.bool,
};
PipBarGraph.defaultProps = {
  leftValue: 0,
  rightValue: 0,
  containerHeight: 140,
  className: '',
  worseDimension: false,
};
