import styled from 'styled-components';

export const AudioContainer = styled.div`

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 28px;
  background-color: ${({ theme }) => theme.colors.black10};
  gap: 12px;
`;

export const PlayPauseButton = styled.button`
  width:48px !important;
  min-width: 48px !important;
  height:48px !important;
  border-radius:48px;
  background-color: ${({ theme }) => theme.colors.secondary.main};
  border: none;
  color: ${({ theme }) => theme.colors.black60};
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TranscriptButton = styled.button`
  width:40px;
  height:48px;
  margin-left: -5px;
  margin-right: 4px;
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RangeInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  min-width: 100px;
  flex-grow: 1;
  cursor: pointer;
  border-radius: 15px;
  height: 6px;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.black40} 0%,
    ${({ theme }) => theme.colors.black40} 100%
  );
  transition: ease-in-out 0.1s;

  &::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 18px;
    width: 18px;
    background-color: ${({ theme }) => theme.colors.secondary.main} !important;
    border: none !important;
    border-radius: 50%;
    border: none;
    transition: .2s ease-in-out;
    margin-top: -6px;
  }
  &::-moz-range-thumb {
    height: 18px;
    width: 18px;
    background-color: ${({ theme }) => theme.colors.secondary.main} !important;
    border: none !important;
    border-radius: 50%;
    border: none;
    transition: .2s ease-in-out;
  }

  &::-webkit-slider-runnable-track {
    height: 6px;
    border-radius: 50px;
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.black40} 0%,
      ${({ theme }) => theme.colors.primary.main} 0%,
      ${({ theme }) => theme.colors.primary.main} 100%
    );
    background-size: ${({ value, max }) => `${(value / max) * 100}%`} 6px;
    background-position: center left; /* <--- added centering */
    background-repeat: no-repeat;
  }
  &::-moz-range-track {
    height: 6px;
    border-radius: 50px;
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.black40} 0%,
      ${({ theme }) => theme.colors.primary.main} 0%,
      ${({ theme }) => theme.colors.primary.main} 100%
    );
    background-size: ${({ value, max }) => `${(value / max) * 100}%`} 6px;
    background-position: center left; /* <--- added centering */
    background-repeat: no-repeat;
  }
`;

export const SpeedButton = styled.button`
  width: 100%;
  height: 100%;
  border-radius:48px;
  background-color: rgba(34, 59, 89, 0.6);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  color: #FFF;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  transition: ease-in-out 0.3s;
  transition-delay: 600ms;
  font-weight: 600;

  &:disabled {
    opacity: 0;
  }
`;

export const ProgressTime = styled.span`
  color: ${({ theme }) => theme.colors.secondary.dark};
  font-weight: 600;
`;

export const Photo = styled.div`
  width:48px !important;
  min-width: 48px !important;
  height:48px !important;
  border-radius: 54px;
  background-position: center center;
  background-size: cover;
  /* border: 1px solid #fff; */
  overflow: hidden;
`;
