// função para remover trecho específico de uma string
export const removeSubstring = (string, substring) => string.replace(substring, '');

// função para checar se parte de uma string existe
export const checkSubstring = (string, substring) => string.includes(substring);

// função para limitar o tamanho de uma string e adicionar '...' no final
export const limitString = (string, limit) => {
  if (string.length > limit) {
    return `${string.substring(0, limit)}...`;
  }
  return string;
};
