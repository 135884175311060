import styled from 'styled-components';

export const Container = styled.button`
  background: #FFF;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='13' ry='13' stroke='%23979FB8FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
  border: 2px solid #FFF;
  border-radius: 16px;
  box-shadow: 0px 2px 12px 0px rgba(6, 16, 42, 0.10);
  overflow: hidden;
  position: relative;
  opacity: .9;
  text-align: left;
  padding: 18px 18px;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary.light};
  font-weight: 400;
  font-size: 1.1rem;
  margin-bottom: 20px;
`;
