import PropTypes from 'prop-types';
import { StyledButton } from './styles';

// import margin and padding (as mt-4, mb-2, px-4) bootstrap here

/**
 * Componente de botão personalizado para Persora.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {React.ReactNode} props.children - Conteúdo do botão.
 * @param {string} [props.estilo='primary'] - ('primary', 'secondary', 'outline', 'ghost').
 * @param {string} [props.tamanho='default'] - ('default', 'condensed', 'mini', 'navtab').
 * @param {boolean} [props.fitContent=false] - Se o botão deve ter tamanho de acordo com o conteúdo.
 * @param {boolean} [props.justIcon=false] - Se o botão deve ter paddings para exibir de Icon.
 * @param {string} [props.ativo=false] - tapBarActive se o botão/tap deve ter estado ativo.
 * @param {boolean} [props.isPremiumButton] - Se o botão é do tipo premium.
 * @param {string} [props.type='submit'] - Tipo do botão.
 * @param {string} [props.form] - ID do formulário associado ao botão.
 * @returns {JSX.Element} Elemento JSX representando o botão.
*/

export default function PersoraButton({
  estilo, tamanho, fitContent, justIcon, ativo, children, isPremiumButton, ...props
}) {
  let fitContentClass = '';
  let justIconClass = '';
  let isPremiumButtonClass = '';
  if (fitContent) {
    fitContentClass = 'fit-content';
  }
  if (justIcon) {
    justIconClass = 'just-icon';
  }
  if (isPremiumButton) {
    isPremiumButtonClass = 'is-premium-button';
  }
  return (
    // <StyleButton with className received from props and {estilo} and {tamanho} as props>
    <StyledButton {...props} className={`${props.className} ${estilo} ${tamanho} ${fitContentClass} ${justIconClass} ${ativo} ${isPremiumButtonClass}`}>
      {children}
    </StyledButton>
  );
}

PersoraButton.propTypes = {
  children: PropTypes.node.isRequired,
  estilo: PropTypes.oneOf(['primary', 'secondary', 'outline', 'ghost']),
  tamanho: PropTypes.oneOf(['default', 'condensed', 'mini', 'navtab']),
  fitContent: PropTypes.bool,
  justIcon: PropTypes.bool,
  ativo: PropTypes.string,
  className: PropTypes.string,
  isPremiumButton: PropTypes.bool,
};
PersoraButton.defaultProps = {
  estilo: 'primary',
  tamanho: 'default',
  fitContent: false,
  justIcon: false,
  ativo: '',
  className: '',
  isPremiumButton: false,
};
