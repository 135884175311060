import { mirage } from 'ldrs';
import PropTypes from 'prop-types';

import {
  Container,
} from './styles';

import theme from '../../assets/styles/themes/default';

mirage.register();

function PersoraLoader({ text, color }) {
  return (
    <Container style={{ width: '100%' }}>
      <l-mirage size={45} color={color} />
      <span style={{ marginTop: '20px', fontWeight: 500 }}>{text}</span>
    </Container>
  );
}
PersoraLoader.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};
PersoraLoader.defaultProps = {
  text: 'Carregando...',
  color: theme.colors.secondary.main,
};

export default PersoraLoader;
