import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.surfaces.lightBlue};
  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px rgba(6, 16, 42, 0.10);
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.surfaces.lightBlue};
  position: relative;

  &.firstAuthFalse {
    opacity: .95;
    /* filter: grayscale(100%); */
    background: ${({ theme }) => theme.colors.black10};
    border-color: ${({ theme }) => theme.colors.black20};
    /* border: 1px dashed ${({ theme }) => theme.colors.black40}; */
  }
`;

export const CardHeader = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black20};
  padding: ${({ theme }) => theme.spaces.screenEdge};
  display: flex;
`;

export const UserIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const CardButtons = styled.div`
  background: ${({ theme }) => theme.colors.surfaces.lightBlue};
  padding: 12px ${({ theme }) => theme.spaces.screenEdge};
  display: flex;
  justify-content: space-between;
    &.firstAuthFalse {
      background: ${({ theme }) => theme.colors.black10};
    }
`;

export const CardSora = styled.div`
  background: ${({ theme }) => theme.colors.secondary.light};
  padding: ${({ theme }) => theme.spaces.screenEdge} calc(${({ theme }) => theme.spaces.screenEdge} * 1.2);
  padding-left: 14px;
  color: ${({ theme }) => theme.colors.primary.main};
  margin-bottom: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  overflow: hidden !important;
`;

export const SoraImage = styled.img`
  align-self: flex-start;
  width: 70px;
`;
