import PropTypes from 'prop-types';

import theme from '../../assets/styles/themes/default';

export function Cross({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 5L5 19M5.00001 5L19 19" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Cross.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Cross.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Copy({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M3 12C3 10.8954 3.89543 10 5 10H12C13.1046 10 14 10.8954 14 12V19C14 20.1046 13.1046 21 12 21H5C3.89543 21 3 20.1046 3 19V12Z" fill={cor} />
      <path d="M17.5 14H19C20.1046 14 21 13.1046 21 12V5C21 3.89543 20.1046 3 19 3H12C10.8954 3 10 3.89543 10 5V6.5M5 10H12C13.1046 10 14 10.8954 14 12V19C14 20.1046 13.1046 21 12 21H5C3.89543 21 3 20.1046 3 19V12C3 10.8954 3.89543 10 5 10Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Copy.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Copy.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Edit1({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M4 20H8L18 10L14 6L4 16V20Z" fill={cor} />
      <path d="M18 10L21 7L17 3L14 6M18 10L8 20H4V16L14 6M18 10L14 6" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}
Edit1.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Edit1.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Edit2({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M4 20H8L18 10L14 6L4 16V20Z" fill={cor} />
      <path d="M12 20H20.5M18 10L21 7L17 3L14 6M18 10L8 20H4V16L14 6M18 10L14 6" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Edit2.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Edit2.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Trash({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M18 18V6H6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18Z" fill={cor} />
      <path d="M10 10V16M14 10V16M18 6V18C18 19.1046 17.1046 20 16 20H8C6.89543 20 6 19.1046 6 18V6M4 6H20M15 6V5C15 3.89543 14.1046 3 13 3H11C9.89543 3 9 3.89543 9 5V6" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Trash.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Trash.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Bell({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 3C8.68629 3 6 5.68629 6 9C6 13 4 17 4 17H20C20 17 18 13 18 9C18 5.68629 15.3137 3 12 3Z" fill={cor} />
      <path d="M9 17V18C9 18.394 9.0776 18.7841 9.22836 19.1481C9.37913 19.512 9.6001 19.8427 9.87868 20.1213C10.1573 20.3999 10.488 20.6209 10.8519 20.7716C11.2159 20.9224 11.606 21 12 21C12.394 21 12.7841 20.9224 13.1481 20.7716C13.512 20.6209 13.8427 20.3999 14.1213 20.1213C14.3999 19.8427 14.6209 19.512 14.7716 19.1481C14.9224 18.7841 15 18.394 15 18V17M18 9C18 12 20 17 20 17H4C4 17 6 13 6 9C6 5.732 8.732 3 12 3C15.268 3 18 5.732 18 9Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Bell.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Bell.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function BellDisabled({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.68332 4C9.65926 3.35265 10.8114 3 12 3C13.5913 3 15.1174 3.63214 16.2426 4.75736C17.3678 5.88258 18 7.4087 18 9C18 10.7834 18.7068 13.2736 19.28 15M3 3L21 21M5.96047 10C5.67984 13.6403 4 17 4 17H12.5M14.7716 19.1481C14.6209 19.512 14.3999 19.8427 14.1213 20.1213C13.8427 20.3999 13.512 20.6209 13.1481 20.7716C12.7841 20.9224 12.394 21 12 21C11.606 21 11.2159 20.9224 10.8519 20.7716C10.488 20.6209 10.1573 20.3999 9.87868 20.1213C9.6001 19.8427 9.37913 19.512 9.22836 19.1481C9.0776 18.7841 9 18.394 9 18V17" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
BellDisabled.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
BellDisabled.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function EyeOpen({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" fillRule="evenodd" clipRule="evenodd" d="M12 5C5.63636 5 2 12 2 12C2 12 5.63636 19 12 19C18.3636 19 22 12 22 12C22 12 18.3636 5 12 5ZM15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" fill={cor} />
      <path d="M12 5C5.63636 5 2 12 2 12C2 12 5.63636 19 12 19C18.3636 19 22 12 22 12C22 12 18.3636 5 12 5Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
EyeOpen.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
EyeOpen.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function EyeClosed({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 14.8335C21.3082 13.3317 22 12 22 12C22 12 18.3636 5 12 5C11.6588 5 11.3254 5.02013 11 5.05822C10.6578 5.09828 10.3244 5.15822 10 5.23552M12 9C12.3506 9 12.6872 9.06015 13 9.17071C13.8524 9.47199 14.528 10.1476 14.8293 11C14.9398 11.3128 15 11.6494 15 12M3 3L21 21M12 15C11.6494 15 11.3128 14.9398 11 14.8293C10.1476 14.528 9.47198 13.8524 9.1707 13C9.11386 12.8392 9.07034 12.6721 9.04147 12.5M4.14701 9C3.83877 9.34451 3.56234 9.68241 3.31864 10C2.45286 11.1282 2 12 2 12C2 12 5.63636 19 12 19C12.3412 19 12.6746 18.9799 13 18.9418" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
EyeClosed.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
EyeClosed.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Settings({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M11 3C10.4477 3 10 3.44772 10 4V4.56876C10 4.99658 9.71288 5.36825 9.31776 5.53229C8.9225 5.6964 8.46228 5.63386 8.15966 5.33123L7.75734 4.92891C7.36681 4.53839 6.73365 4.53839 6.34312 4.92891L4.92891 6.34313C4.53838 6.73365 4.53838 7.36681 4.92891 7.75734L5.33123 8.15966C5.63386 8.46229 5.6964 8.9225 5.53229 9.31776C5.36825 9.71288 4.99658 10 4.56877 10L4 10C3.44772 10 3 10.4477 3 11V13C3 13.5523 3.44772 14 4 14H4.56879C4.99659 14 5.36825 14.2871 5.53228 14.6822C5.69638 15.0775 5.63384 15.5377 5.33123 15.8403L4.92889 16.2426C4.53837 16.6331 4.53837 17.2663 4.92889 17.6568L6.34311 19.071C6.73363 19.4616 7.36679 19.4616 7.75732 19.071L8.1596 18.6688C8.46223 18.3661 8.92247 18.3036 9.31774 18.4677C9.71287 18.6317 10 19.0034 10 19.4313V20C10 20.5523 10.4477 21 11 21H13C13.5523 21 14 20.5523 14 20V19.4312C14 19.0034 14.2871 18.6318 14.6822 18.4677C15.0775 18.3036 15.5377 18.3661 15.8403 18.6688L16.2426 19.071C16.6331 19.4616 17.2663 19.4616 17.6568 19.071L19.071 17.6568C19.4616 17.2663 19.4616 16.6331 19.071 16.2426L18.6688 15.8403C18.3661 15.5377 18.3036 15.0775 18.4677 14.6822C18.6318 14.2871 19.0034 14 19.4312 14H20C20.5523 14 21 13.5523 21 13V11C21 10.4477 20.5523 10 20 10L19.4313 10C19.0034 10 18.6317 9.71287 18.4677 9.31774C18.3036 8.92247 18.3661 8.46223 18.6688 8.1596L19.071 7.75734C19.4615 7.36681 19.4616 6.73365 19.071 6.34312L17.6568 4.92891C17.2663 4.53838 16.6331 4.53838 16.2426 4.92891L15.8403 5.33123C15.5377 5.63384 15.0775 5.69638 14.6822 5.53228C14.2871 5.36825 14 4.99659 14 4.56879V4C14 3.44772 13.5523 3 13 3H11ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill={cor} />
      <path d="M11 3H13C13.5523 3 14 3.44772 14 4V4.56879C14 4.99659 14.2871 5.36825 14.6822 5.53228C15.0775 5.69638 15.5377 5.63384 15.8403 5.33123L16.2426 4.92891C16.6331 4.53838 17.2663 4.53838 17.6568 4.92891L19.071 6.34312C19.4616 6.73365 19.4615 7.36681 19.071 7.75734L18.6688 8.1596C18.3661 8.46223 18.3036 8.92247 18.4677 9.31774C18.6317 9.71287 19.0034 10 19.4313 10L20 10C20.5523 10 21 10.4477 21 11V13C21 13.5523 20.5523 14 20 14H19.4312C19.0034 14 18.6318 14.2871 18.4677 14.6822C18.3036 15.0775 18.3661 15.5377 18.6688 15.8403L19.071 16.2426C19.4616 16.6331 19.4616 17.2663 19.071 17.6568L17.6568 19.071C17.2663 19.4616 16.6331 19.4616 16.2426 19.071L15.8403 18.6688C15.5377 18.3661 15.0775 18.3036 14.6822 18.4677C14.2871 18.6318 14 19.0034 14 19.4312V20C14 20.5523 13.5523 21 13 21H11C10.4477 21 10 20.5523 10 20V19.4313C10 19.0034 9.71287 18.6317 9.31774 18.4677C8.92247 18.3036 8.46223 18.3661 8.1596 18.6688L7.75732 19.071C7.36679 19.4616 6.73363 19.4616 6.34311 19.071L4.92889 17.6568C4.53837 17.2663 4.53837 16.6331 4.92889 16.2426L5.33123 15.8403C5.63384 15.5377 5.69638 15.0775 5.53228 14.6822C5.36825 14.2871 4.99659 14 4.56879 14H4C3.44772 14 3 13.5523 3 13V11C3 10.4477 3.44772 10 4 10L4.56877 10C4.99658 10 5.36825 9.71288 5.53229 9.31776C5.6964 8.9225 5.63386 8.46229 5.33123 8.15966L4.92891 7.75734C4.53838 7.36681 4.53838 6.73365 4.92891 6.34313L6.34312 4.92891C6.73365 4.53839 7.36681 4.53839 7.75734 4.92891L8.15966 5.33123C8.46228 5.63386 8.9225 5.6964 9.31776 5.53229C9.71288 5.36825 10 4.99658 10 4.56876V4C10 3.44772 10.4477 3 11 3Z" stroke={cor} strokeWidth="1.5" />
      <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" stroke={cor} strokeWidth="1.5" />
    </svg>
  );
}
Settings.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Settings.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Filters({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.15">
        <path d="M21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12Z" fill={cor} />
        <path d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18Z" fill={cor} />
        <path d="M7 6C7 7.10457 6.10457 8 5 8C3.89543 8 3 7.10457 3 6C3 4.89543 3.89543 4 5 4C6.10457 4 7 4.89543 7 6Z" fill={cor} />
      </g>
      <path d="M19 4V10M19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14M19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14M19 14V20M12 4V16M12 16C10.8954 16 10 16.8954 10 18C10 19.1046 10.8954 20 12 20C13.1046 20 14 19.1046 14 18C14 16.8954 13.1046 16 12 16ZM5 8V20M5 8C6.10457 8 7 7.10457 7 6C7 4.89543 6.10457 4 5 4C3.89543 4 3 4.89543 3 6C3 7.10457 3.89543 8 5 8Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}
Filters.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Filters.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};
export function Hamburger({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 17H20M4 12H20M4 7H20" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Hamburger.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Hamburger.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Link({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 16H17C19.2091 16 21 14.2091 21 12C21 9.79086 19.2091 8 17 8H15M8 12H16M9 8H7C4.79086 8 3 9.79086 3 12C3 14.2091 4.79086 16 7 16H9" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Link.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Link.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Login({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H15M11 16L15 12M15 12L11 8M15 12H3" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Login.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Login.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Logout({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H15M8 8L4 12M4 12L8 16M4 12L16 12" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Logout.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Logout.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ArrowUp({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 4V20M12 4L18 10M12 4L6 10" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ArrowUp.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};
ArrowUp.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};

export function ArrowDown({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 20L12 4M12 20L6 14M12 20L18 14" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ArrowDown.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ArrowDown.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ArrowLeft({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 12L20 12M4 12L10 6M4 12L10 18" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ArrowLeft.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ArrowLeft.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ArrowRight({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 12L4 12M20 12L14 18M20 12L14 6" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ArrowRight.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ArrowRight.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ChevronUp({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 15L12 7L4 15" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ChevronUp.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ChevronUp.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ChevronDown({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 9L12 17L20 9" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ChevronDown.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ChevronDown.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ChevronLeft({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4L7 12L15 20" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ChevronLeft.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ChevronLeft.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ChevronRight({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 20L17 12L9 4" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}
ChevronRight.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ChevronRight.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ArrowSmallUp({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 7V17M12 7L16 11M12 7L8 11" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}
ArrowSmallUp.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ArrowSmallUp.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ArrowSmallDown({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 17L12 7M12 17L8 13M12 17L16 13" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}
ArrowSmallDown.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ArrowSmallDown.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ArrowSmallLeft({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 12L17 12M7 12L11 8M7 12L11 16" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ArrowSmallLeft.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ArrowSmallLeft.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ArrowSmallRight({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 12L7 12M17 12L13 16M17 12L13 8" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ArrowSmallRight.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ArrowSmallRight.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ChevronSmallUp({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 14L12 9L7 14" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}
ChevronSmallUp.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ChevronSmallUp.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ChevronSmallDown({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 10L12 15L17 10" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ChevronSmallDown.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ChevronSmallDown.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ChevronSmallLeft({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 7L9 12L14 17" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ChevronSmallLeft.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ChevronSmallLeft.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ChevronSmallRight({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 17L15 12L10 7" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ChevronSmallRight.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ChevronSmallRight.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function User1({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" fill={cor} />
      <path d="M16 15H8C5.79086 15 4 16.7909 4 19V21H20V19C20 16.7909 18.2091 15 16 15Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
User1.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
User1.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function User2({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.15">
        <path d="M12.0504 20.9999C12.0336 21 12.0168 21 12 21C11.9832 21 11.9664 21 11.9496 20.9999C11.9666 20.9832 11.9834 20.9664 12 20.9495C12.0166 20.9664 12.0334 20.9832 12.0504 20.9999Z" fill={cor} />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.9936 18.7141C17.9978 18.6433 18 18.5719 18 18.5C18 16.567 16.433 15 14.5 15H9.5C7.567 15 6 16.567 6 18.5C6 18.5719 6.00217 18.6433 6.00644 18.7141C4.16145 17.0659 3 14.6686 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 14.6686 19.8385 17.0659 17.9936 18.7141ZM12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" fill={cor} />
      </g>
      <path d="M18 18.7083C18 17.0886 16.8283 15 15 15H9C7.17172 15 6 17.0886 6 18.7083M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9Z" stroke={cor} strokeWidth="1.5" />
    </svg>
  );
}
User2.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
User2.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Users({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" fill={cor} />
      <path d="M19 15C21.2091 15 23 16.7909 23 19V21H21M16 10.874C17.7252 10.4299 19 8.86384 19 7C19 5.13617 17.7252 3.57007 16 3.12602M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7ZM5 15H13C15.2091 15 17 16.7909 17 19V21H1V19C1 16.7909 2.79086 15 5 15Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Users.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Users.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function UserHeart({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" fill={cor} />
      <path d="M16 15H8C5.79086 15 4 16.7909 4 19V21H20V19C20 16.7909 18.2091 15 16 15Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.3658 9.08574L20.2171 8.94124C19.9736 8.70444 19.6432 8.57141 19.2988 8.57141C18.5714 8.57141 18 9.10713 18 10.102C18 11.7857 20.5714 12.8571 20.5714 12.8571C20.5714 12.8571 23.1429 11.7857 23.1429 10.102C23.1429 9.10713 22.5614 8.57141 21.8441 8.57141C21.4996 8.57141 21.1693 8.70444 20.9257 8.94124L20.7771 9.08574C20.6647 9.19498 20.4781 9.19498 20.3658 9.08574Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
UserHeart.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
UserHeart.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function UserPlus({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" fill={cor} />
      <path d="M20 8.5V13.5M17.5 11H22.5M8 15H16C18.2091 15 20 16.7909 20 19V21H4V19C4 16.7909 5.79086 15 8 15ZM16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
UserPlus.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
UserPlus.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function UserMinus({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" fill={cor} />
      <path d="M17 11H23M8 15H16C18.2091 15 20 16.7909 20 19V21H4V19C4 16.7909 5.79086 15 8 15ZM16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
UserMinus.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
UserMinus.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function UserCross({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" fill={cor} />
      <path d="M22 9L18 13M18 9L22 13M8 15H16C18.2091 15 20 16.7909 20 19V21H4V19C4 16.7909 5.79086 15 8 15ZM16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
UserCross.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
UserCross.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};
export function PlussSmall({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 7V17M7 12H17" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
PlussSmall.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
PlussSmall.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function UserWarning({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" fill={cor} />
      <path d="M20 7V10.5M20 13V13.01M8 15H16C18.2091 15 20 16.7909 20 19V21H4V19C4 16.7909 5.79086 15 8 15ZM16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
UserWarning.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
UserWarning.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function UserQuestion({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" fill={cor} />
      <path d="M20.9531 13V12.995M19 7.4C19.2608 6.58858 20.0366 6 20.9531 6C22.0836 6 23 6.89543 23 8C23 9.60675 21.2825 8.81678 21 10.5M8 15H16C18.2091 15 20 16.7909 20 19V21H4V19C4 16.7909 5.79086 15 8 15ZM16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
UserQuestion.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
UserQuestion.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function QuestionCircle({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill={cor} />
      <path d="M12 17V16.9929M12 14.8571C12 11.6429 15 12.3571 15 9.85714C15 8.27919 13.6568 7 12 7C10.6567 7 9.51961 7.84083 9.13733 9M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
QuestionCircle.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
QuestionCircle.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function InfoCircle({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill={cor} />
      <path d="M12 7.01001V7.00002M12 17L12 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
InfoCircle.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
InfoCircle.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function UsersMore({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z" fill={cor} />
      <path d="M3 19H1V18C1 16.1362 2.27477 14.5701 4 14.126M6 10.8293C4.83481 10.4175 4 9.30621 4 7.99999C4 6.69378 4.83481 5.58254 6 5.1707M21 19H23V18C23 16.1362 21.7252 14.5701 20 14.126M18 5.1707C19.1652 5.58254 20 6.69378 20 7.99999C20 9.30621 19.1652 10.4175 18 10.8293M10 14H14C16.2091 14 18 15.7909 18 18V19H6V18C6 15.7909 7.79086 14 10 14ZM15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
UsersMore.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
UsersMore.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function UserFaceid({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 11V12.5L11.5 13M15 8V10M9 8V10M9 20H5C4.44772 20 4 19.5523 4 19V15M20 15V19C20 19.5523 19.5523 20 19 20H15M20 9V5C20 4.44772 19.5523 4 19 4H15M4 9V5C4 4.44772 4.44772 4 5 4H9M9 16C9 16 10 17 12 17C14 17 15 16 15 16" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
UserFaceid.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
UserFaceid.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};
export function LockOn({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M5 11H19V21H5V11Z" fill={cor} />
      <path d="M8 11V7C8 5.66667 8.8 3 12 3C15.2 3 16 5.66667 16 7V11M8 11H5V21H19V11H16M8 11H16" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
LockOn.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
LockOn.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Brain({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 13.874C4.27477 13.4299 3 11.8638 3 10C3 7.79086 4.79086 6 7 6C7.16933 6 7.3362 6.01052 7.5 6.03095C8.11775 4.28316 9.54072 3 11.5 3C13.2124 3 14.7898 3.96516 15.5503 5.37295C15.98 5.13528 16.4743 5 17.0001 5C18.6569 5 20.0001 6.34315 20.0001 8C20.0001 9.04349 19.4673 9.96254 18.659 10.5C19.7344 10.8437 20.5338 11.8513 20.5338 13.0408C20.5338 14.5137 19.3398 15.7077 17.8669 15.7077C16.394 15.7077 15.4446 14.8538 15 14C14.0155 14.5095 12.8043 14.1244 12.2948 13.1399M6 13.874C6 15.3469 7.19401 16.6669 8.6669 16.6669C10.267 16.6669 14.0876 16.5075 14.0876 18.641V20.7745M6 13.874C6 12.4011 7.19401 11.3331 8.6669 11.3331M9.86981 8.00393C10.4241 7.04391 11.6517 6.71498 12.6117 7.26925C13.5717 7.82352 13.9006 9.0511 13.3464 10.0111" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Brain.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Brain.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Map({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.15">
        <path d="M3 3L9 5V21L3 19V3Z" fill={cor} />
        <path d="M21 5L15 3V19L21 21V5Z" fill={cor} />
      </g>
      <path d="M15 3V19M15 3L9 5M15 3L21 5V21L15 19M15 19L9 21M9 5V21M9 5L3 3V19L9 21" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}
Map.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Map.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Mail({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M21 18.0001V6.00008C21 5.72393 20.8881 5.47393 20.7071 5.29297L20.5 5.50008L13.4142 12.5859C12.6332 13.3669 11.3668 13.3669 10.5858 12.5859L3.5 5.50008L3.29289 5.29297C3.11193 5.47393 3 5.72393 3 6.00008V18.0001C3 18.5524 3.44772 19.0001 4 19.0001H20C20.5523 19.0001 21 18.5524 21 18.0001Z" fill={cor} />
      <path d="M3.29289 5.29289C3.47386 5.11193 3.72386 5 4 5H20C20.2761 5 20.5261 5.11193 20.7071 5.29289M3.29289 5.29289C3.11193 5.47386 3 5.72386 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.72386 20.8881 5.47386 20.7071 5.29289M3.29289 5.29289L10.5858 12.5858C11.3668 13.3668 12.6332 13.3668 13.4142 12.5858L20.7071 5.29289" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Mail.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Mail.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Smartphone({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M6 5C6 3.89543 6.89543 3 8 3H16C17.1046 3 18 3.89543 18 5V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V5Z" fill={cor} />
      <path d="M12 18.01V18M8 3H16C17.1046 3 18 3.89543 18 5V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V5C6 3.89543 6.89543 3 8 3Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Smartphone.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Smartphone.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Crown({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M5 20L3 8L8.5 12.5L12 7L15.5 12.5L21 8L19 20H5Z" fill={cor} />
      <path d="M21 8L19 20H5L3 8M21 8L15.5 12.5L12 7M21 8C21.8284 8 22.5 7.32843 22.5 6.5C22.5 5.67157 21.8284 5 21 5C20.1716 5 19.5 5.67157 19.5 6.5C19.5 7.32843 20.1716 8 21 8ZM12 7L8.5 12.5L3 8M12 7C12.8284 7 13.5 6.32843 13.5 5.5C13.5 4.67157 12.8284 4 12 4C11.1716 4 10.5 4.67157 10.5 5.5C10.5 6.32843 11.1716 7 12 7ZM3 8C3.82843 8 4.5 7.32843 4.5 6.5C4.5 5.67157 3.82843 5 3 5C2.17157 5 1.5 5.67157 1.5 6.5C1.5 7.32843 2.17157 8 3 8Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Crown.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Crown.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Star1({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 17L6 20L7.5 14L3 9L9.5 8.5L12 3L14.5 8.5L21 9L16.5 14L18 20L12 17Z" fill={cor} />
      <path d="M12 17L6 20L7.5 14L3 9L9.5 8.5L12 3L14.5 8.5L21 9L16.5 14L18 20L12 17Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Star1.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Star1.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Star2({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 3C12 7.97056 16.0294 12 21 12C16.0294 12 12 16.0294 12 21C12 16.0294 7.97056 12 3 12C7.97056 12 12 7.97056 12 3Z" fill={cor} />
      <path d="M12 3C12 7.97056 16.0294 12 21 12C16.0294 12 12 16.0294 12 21C12 16.0294 7.97056 12 3 12C7.97056 12 12 7.97056 12 3Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Star2.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Star2.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Sun({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" fill={cor} />
      <path d="M3 12H5M5.00006 19L7.00006 17M12 19V21M17 17L19 19M5 5L7 7M19 12H21M16.9999 7L18.9999 5M12 3V5M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Sun.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Sun.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Bolt({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M16 4H9L6 13H10L8 21L19 10H13.6L16 4Z" fill={cor} />
      <path d="M16 4H9L6 13H10L8 21L19 10H13.6L16 4Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Bolt.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Bolt.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Cardiology({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M4.3314 12.0474L12 20L19.6686 12.0474C20.5211 11.1633 21 9.96429 21 8.71405C21 6.11055 18.9648 4 16.4543 4C15.2487 4 14.0925 4.49666 13.24 5.38071L12 6.66667L10.76 5.38071C9.90749 4.49666 8.75128 4 7.54569 4C5.03517 4 3 6.11055 3 8.71405C3 9.96429 3.47892 11.1633 4.3314 12.0474Z" fill={cor} />
      <path d="M4.76786 12.5L12 20L19.2321 12.5M4.76786 12.5L4.3314 12.0474C3.47892 11.1633 3 9.96429 3 8.71405C3 6.11055 5.03517 4 7.54569 4C8.75128 4 9.90749 4.49666 10.76 5.38071L12 6.66667L13.24 5.38071C14.0925 4.49666 15.2487 4 16.4543 4C18.9648 4 21 6.11055 21 8.71405C21 9.96429 20.5211 11.1633 19.6686 12.0474L19.2321 12.5M4.76786 12.5H7.99997L8.99997 10.5L11 14.5L14 8L16 12.5H19.2321" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Cardiology.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Cardiology.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Clock({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill={cor} />
      <path d="M12 8V12L14 14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Clock.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Clock.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function MessageCircleLines({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M3.00003 11.5C2.99659 12.8199 3.30496 14.1219 3.90003 15.3C4.6056 16.7118 5.69028 17.8992 7.03258 18.7293C8.37488 19.5594 9.92179 19.9994 11.5 20C12.8199 20.0035 14.1219 19.6951 15.3 19.1L21 21L19.1 15.3C19.6951 14.1219 20.0035 12.8199 20 11.5C19.9994 9.92179 19.5594 8.37488 18.7293 7.03258C17.8992 5.69028 16.7118 4.6056 15.3 3.90003C14.1219 3.30496 12.8199 2.99659 11.5 3.00003H11C8.91568 3.11502 6.94699 3.99479 5.47089 5.47089C3.99479 6.94699 3.11502 8.91568 3.00003 11V11.5Z" fill={cor} />
      <path d="M8 9.5H15M8 13.5H13M15.3 19.1L21 21L19.1 15.3C19.1 15.3 20 14 20 11.5C20 6.80558 16.1944 3 11.5 3C6.80558 3 3 6.80558 3 11.5C3 16.1944 6.80558 20 11.5 20C14.0847 20 15.3 19.1 15.3 19.1Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
MessageCircleLines.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
MessageCircleLines.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Wand({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 12H17.3333M18.1618 5.83822L15.3333 8.66664M12 6.66667V4M8.66672 8.66668L5.8383 5.83826M6.66667 12H4M8.66672 15.3334L5.8383 18.1618M12 20V17.3333M26.6667 26.6666L16 16" stroke={cor} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Wand.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Wand.defaultProps = {
  tamanho: 32,
  cor: theme.colors.iconDefault,
};

export function MessageSquareLines({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M20 4H4V16H7V21L12 16H20V4Z" fill={cor} />
      <path d="M8 8H16M8 12H13M7 16V21L12 16H20V4H4V16H7Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
MessageSquareLines.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
MessageSquareLines.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ListRight({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M20 4H4V20H20V4Z" fill={cor} />
      <path d="M7 8H17M7 12H17M7 16H13M4 4H20V20H4V4Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ListRight.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ListRight.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ChartVertical({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M20 4H4V20H20V4Z" fill={cor} />
      <path d="M16 11V17M12 7L12 17M8 14L8 17M4 4H20V20H4V4Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ChartVertical.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ChartVertical.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Calendar({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V11M4 19V11H20M4 19V7C4 5.89543 4.89543 5 6 5H18C19.1046 5 20 5.89543 20 7V11M15 3V7M9 3V7" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Calendar.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Calendar.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function CalendarCheck({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.8572 15L13.6573 21.1999L11.0001 18.5428M9 11H4M20 9V7C20 5.89543 19.1046 5 18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H8M15 3V7M9 3V7" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
CalendarCheck.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
CalendarCheck.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function DocumentFilled({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M5 21H19V9H15C13.8954 9 13 8.10457 13 7V3H5V21Z" fill={cor} />
      <path d="M8 13H14M8 17H16M13 3H5V21H19V9M13 3H14L19 8V9M13 3V7C13 8 14 9 15 9H19" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
DocumentFilled.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
DocumentFilled.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Send1({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M20 4L3 11L10 14L13 21L20 4Z" fill={cor} />
      <path d="M20 4L3 11L10 14L13 21L20 4Z" stroke={cor} strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  );
}
Send1.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Send1.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Send2({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M16.6667 3.33301L2.5 9.16634L8.33333 11.6663L10.8333 17.4997L16.6667 3.33301Z" fill={cor} />
      <path d="M16.6667 3.33301L2.5 9.16634L8.33333 11.6663M16.6667 3.33301L10.8333 17.4997L8.33333 11.6663M16.6667 3.33301L8.33333 11.6663" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Send2.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Send2.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Home1({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M4 10L12 3L20 10L20 20H15V16C15 15.2044 14.6839 14.4413 14.1213 13.8787C13.5587 13.3161 12.7957 13 12 13C11.2044 13 10.4413 13.3161 9.87868 13.8787C9.31607 14.4413 9 15.2043 9 16V20H4L4 10Z" fill={cor} />
      <path d="M4 10L12 3L20 10L20 20H15V16C15 15.2044 14.6839 14.4413 14.1213 13.8787C13.5587 13.3161 12.7957 13 12 13C11.2044 13 10.4413 13.3161 9.87868 13.8787C9.31607 14.4413 9 15.2043 9 16V20H4L4 10Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Home1.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Home1.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Inbox({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M21 18V12H16L14 15H10L8.5 12H3V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18Z" fill={cor} />
      <path d="M3 12H8.5L10 15H14L16 12H21M3 12V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18V12M3 12L5.75667 4.64888C5.90304 4.25857 6.27616 4 6.693 4H17.307C17.7238 4 18.097 4.25857 18.2433 4.64888L21 12" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Inbox.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Inbox.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Screen({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M4 6C4 5.44771 4.44772 5 5 5H19C19.5523 5 20 5.44772 20 6V15C20 15.5523 19.5523 16 19 16H5C4.44772 16 4 15.5523 4 15V6Z" fill={cor} />
      <path d="M12 20H16M12 20H8M12 20V16M12 16H5C4.44772 16 4 15.5523 4 15V6C4 5.44771 4.44772 5 5 5H19C19.5523 5 20 5.44772 20 6V15C20 15.5523 19.5523 16 19 16H12Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Screen.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Screen.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function ScreenShare({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M4 6C4 5.44771 4.44772 5 5 5H19C19.5523 5 20 5.44772 20 6V15C20 15.5523 19.5523 16 19 16H5C4.44772 16 4 15.5523 4 15V6Z" fill={cor} />
      <path d="M16 10.5H8M16 10.5L13 13.5M16 10.5L13 7.5M12 20H16M12 20H8M12 20V16M12 16H5C4.44772 16 4 15.5523 4 15V6C4 5.44771 4.44772 5 5 5H19C19.5523 5 20 5.44772 20 6V15C20 15.5523 19.5523 16 19 16H12Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ScreenShare.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
ScreenShare.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Suitcase({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M4 8C4 6.89543 4.89543 6 6 6H18C19.1046 6 20 6.89543 20 8V12C20 13.1046 19.1046 14 18 14H6C4.89543 14 4 13.1046 4 12V8Z" fill={cor} />
      <path d="M12 14V12M12 14V16M12 14H18C19.1046 14 20 13.1046 20 12M12 14H6C4.89543 14 4 13.1046 4 12M20 12V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V12M20 12V8C20 6.89543 19.1046 6 18 6H6C4.89543 6 4 6.89543 4 8V12M15 6V5C15 3.89543 14.1046 3 13 3H11C9.89543 3 9 3.89543 9 5V6" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Suitcase.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Suitcase.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Gift({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.15">
        <path d="M5 10H19V20H5V10Z" fill={cor} />
        <path d="M4 7H20V10H4V7Z" fill={cor} />
      </g>
      <path d="M19 10.0802V20.0802H5V10.0802M19 10.0802H5M19 10.0802H20V7.0802H4V10.0802H5M12 7.0802C12.8333 5.24687 14.9999 1.5802 16.9999 3.5802C18.9999 5.5802 14.5 6.91353 12 7.0802ZM12 7.0802C11.1667 5.24687 8.99999 1.5802 6.99999 3.5802C4.99999 5.5802 9.5 6.91353 12 7.0802Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Gift.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Gift.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Windows({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M3 10C3 9.44771 3.44772 9 4 9H16C16.5523 9 17 9.44772 17 10V19C17 19.5523 16.5523 20 16 20H4C3.44772 20 3 19.5523 3 19V10Z" fill={cor} />
      <path d="M3 12H20C20.5523 12 21 11.5523 21 11V4C21 3.44772 20.5523 3 20 3H11C10.4477 3 10 3.44772 10 4V9M21 6H10M4 9H16C16.5523 9 17 9.44772 17 10V19C17 19.5523 16.5523 20 16 20H4C3.44772 20 3 19.5523 3 19V10C3 9.44771 3.44772 9 4 9Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Windows.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Windows.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Book({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" fillRule="evenodd" clipRule="evenodd" d="M4 14.6667V5.33333C4 4.59695 4.79594 4 5.77778 4H20V13.3333V16H5.77778C4.79594 16 4 15.403 4 14.6667Z" fill={cor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M4 18.2222V5.77778C4 4.79594 4.79594 4 5.77778 4H20V16.4444V20H5.77778C4.79594 20 4 19.2041 4 18.2222Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 8H15" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 11.5H14" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 16.5H20" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Book.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Book.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function BookOpen({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M15 3H22V17.5H14.5L12 19L8.5 17.5H2V3H9L12 5L15 3Z" fill={cor} />
      <path d="M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 3H16C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V21C12 20.2044 12.3161 19.4413 12.8787 18.8787C13.4413 18.3161 14.2044 18 15 18H22V3Z" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
BookOpen.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
BookOpen.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Circle({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Circle.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Circle.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function CircleFilled({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.6" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill={cor} />
      <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke={cor} strokeWidth="1.5" />
    </svg>
  );
}
CircleFilled.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
CircleFilled.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function CircleChecked({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 11.0801V12.0001C21.9988 14.1565 21.3005 16.2548 20.0093 17.9819C18.7182 19.7091 16.9033 20.9726 14.8354 21.584C12.7674 22.1954 10.5573 22.122 8.53446 21.3747C6.51168 20.6274 4.78465 19.2462 3.61095 17.4372C2.43726 15.6281 1.87979 13.4882 2.02167 11.3364C2.16356 9.18467 2.9972 7.13643 4.39827 5.49718C5.79935 3.85793 7.69278 2.71549 9.79618 2.24025C11.8996 1.76502 14.1003 1.98245 16.07 2.86011" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 4L12 14.01L9 11.01" stroke={cor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
CircleChecked.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
CircleChecked.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};
export function Check({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0001 7L9.0001 18L4 13" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Check.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Check.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function CheckSmall({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.0001 9L10 16L7 13" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
CheckSmall.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
CheckSmall.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function PdePersora({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.7505 10.2333C15.7477 10.5609 15.6746 10.8844 15.5358 11.1828C15.397 11.4813 15.1956 11.7483 14.9445 11.9666C14.6442 12.2216 14.2986 12.4211 13.9246 12.5553C13.4597 12.7174 12.9682 12.7948 12.4744 12.7837H10.5104V7.56367H12.4744C12.9665 7.55886 13.4551 7.64498 13.9142 7.81744C14.2939 7.95801 14.6433 8.16629 14.9445 8.43156C15.2092 8.66868 15.4168 8.95986 15.5522 9.28422C15.6816 9.58932 15.749 9.91596 15.7505 10.246V10.2333ZM19.9994 10.3323C20.012 9.44442 19.8344 8.56373 19.4777 7.74638C19.1381 6.98568 18.6336 6.3053 17.9988 5.75176C17.3288 5.17678 16.5475 4.73764 15.7009 4.46006C14.7421 4.1432 13.7347 3.98786 12.7222 4.00074H4.347C4.30115 3.9997 4.25556 4.00772 4.21299 4.02431C4.17042 4.04091 4.13174 4.06573 4.09931 4.09728C4.06688 4.12883 4.04137 4.16645 4.02432 4.20787C4.00726 4.24929 3.99902 4.29365 4.00009 4.33826V7.23123C3.99902 7.27584 4.00726 7.3202 4.02432 7.36162C4.04137 7.40304 4.06688 7.44065 4.09931 7.47221C4.13174 7.50376 4.17042 7.52858 4.21299 7.54517C4.25556 7.56177 4.30115 7.56978 4.347 7.56874H6.34495V20.511C6.33492 20.5767 6.34079 20.6437 6.3621 20.7068C6.38342 20.7699 6.41958 20.8273 6.46772 20.8743C6.51585 20.9214 6.57462 20.9569 6.63935 20.978C6.70407 20.999 6.77296 21.0051 6.84052 20.9957H10.02C10.0876 21.0051 10.1565 20.999 10.2212 20.978C10.2859 20.9569 10.3447 20.9214 10.3928 20.8743C10.441 20.8273 10.4771 20.7699 10.4985 20.7068C10.5198 20.6437 10.5256 20.5767 10.5156 20.511V16.4507L12.5266 16.4253C13.5679 16.4375 14.6045 16.2867 15.5966 15.9787C16.4527 15.7109 17.2492 15.2881 17.944 14.7327C18.5884 14.2138 19.106 13.5619 19.4594 12.8243C19.8232 12.0436 20.0058 11.1945 19.9941 10.3374" fill={cor} />
    </svg>
  );
}
PdePersora.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
PdePersora.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function PinpaperCheck({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M19 21V4.5H15V6H9V4.5H5V21H19Z" fill={cor} />
      <path d="M15 12L10.7999 16.2L9 14.4M9 4.5H5V21H19V4.5H15M9 4.5V6H15V4.5M9 4.5V3H15V4.5" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}
PinpaperCheck.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
PinpaperCheck.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function PinpaperFilled({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M19 21V4.5H15V6H9V4.5H5V21H19Z" fill={cor} />
      <path d="M8 17H16M8 13H14M9 4.5H5V21H19V4.5H15M9 4.5V6H15V4.5M9 4.5V3H15V4.5" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
PinpaperFilled.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
PinpaperFilled.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Trophy({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M7 4H17V12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12V4Z" fill={cor} />
      <path d="M8 21H16M12 21V17M12 17C9.23858 17 7 14.7614 7 12V4H17V12C17 14.7614 14.7614 17 12 17ZM17 6H18.5C19.8807 6 21 7.11929 21 8.5C21 9.88071 19.8807 11 18.5 11H17M7 11H5.5C4.11929 11 3 9.88071 3 8.5C3 7.11929 4.11929 6 5.5 6H7" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Trophy.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Trophy.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function TrendingDown({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 11.3332L8.66667 5.99984L6 8.6665L2 4.6665M14 11.3332H10M14 11.3332V7.33317" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
TrendingDown.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
TrendingDown.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function TrendingUp({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5977 4.6665L9.26432 9.99984L6.59766 7.33317L2.59766 11.3332M14.5977 4.6665H10.5977M14.5977 4.6665V8.6665" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
TrendingUp.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
TrendingUp.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Play({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M8 6V18L18 12L8 6Z" fill={cor} />
      <path d="M8 6V18L18 12L8 6Z" stroke={cor} strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  );
}
Play.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Play.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function Pause({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 6V18M9 6V18" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Pause.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Pause.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};

export function EmoteSad({ tamanho, cor, strokeWidth }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill={cor} />
      <path d="M16 16C16 16 14.5 14 12 14C9.5 14 8 16 8 16M15 9H15.01M9 9H9.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke={cor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
EmoteSad.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
  strokeWidth: PropTypes.number,
};
EmoteSad.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
  strokeWidth: 1.5,
};

export function EmoteSmile({ tamanho, cor, strokeWidth }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill={cor} />
      <path d="M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14M15 9H15.01M9 9H9.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke={cor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
EmoteSmile.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
  strokeWidth: PropTypes.number,
};
EmoteSmile.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
  strokeWidth: 1.5,
};

export function EmoteNormal({ tamanho, cor, strokeWidth }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill={cor} />
      <path d="M8 15H16M15 9H15.01M9 9H9.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke={cor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
EmoteNormal.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
  strokeWidth: PropTypes.number,
};
EmoteNormal.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
  strokeWidth: 1.5,
};

export function Basketball({ tamanho, cor }) {
  return (
    <svg width={tamanho} height={tamanho} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M19.9996 6.66602C18.0693 7.66328 16.7498 9.67755 16.7498 12C16.7498 14.3225 18.0693 16.3367 19.9996 17.334C18.3609 19.5575 15.7238 21 12.7498 21C9.77577 21 7.13868 19.5575 5.5 17.334C7.43025 16.3367 8.74978 14.3225 8.74978 12C8.74978 9.67754 7.43025 7.66328 5.5 6.66602C7.13868 4.44251 9.77577 3 12.7498 3C15.7238 3 18.3609 4.44251 19.9996 6.66602Z" fill={cor} />
      <path d="M3.75 12C3.75 10.0017 4.40127 8.15549 5.50313 6.66208M3.75 12C3.75 13.9931 4.39785 15.8348 5.49444 17.3261M3.75 12H21.75M5.50313 6.66208C7.14201 4.4408 9.77775 3 12.75 3M5.50313 6.66208C6.72869 7.42812 7.7295 8.34295 8.29322 9.7039C8.59475 10.4319 8.74994 11.2121 8.74994 12C8.74994 12.7879 8.59475 13.5681 8.29322 14.2961C7.7181 15.6846 6.71869 16.5246 5.49444 17.3261M12.75 3C15.7286 3 18.3692 4.44695 20.0073 6.6763M12.75 3V21M12.75 21C9.77249 21 7.13269 19.5541 5.49444 17.3261M12.75 21C15.7165 21 18.3477 19.5648 19.9873 17.3509M20.0073 6.6763C21.1029 8.1672 21.75 10.008 21.75 12M20.0073 6.6763C18.7806 7.47481 17.7819 8.31524 17.2067 9.7039C16.9052 10.4319 16.75 11.2121 16.75 12C16.75 12.7879 16.9052 13.5682 17.2067 14.2961C17.7856 15.6937 18.7951 16.4972 19.9873 17.3509M21.75 12C21.75 14.0041 21.095 15.8552 19.9873 17.3509" stroke={cor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
Basketball.propTypes = {
  tamanho: PropTypes.number,
  cor: PropTypes.string,
};
Basketball.defaultProps = {
  tamanho: 24,
  cor: theme.colors.iconDefault,
};
