import styled from 'styled-components';

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  /* justify-content: center; */
  width: 100vw;
  height: 100dvh;
  background-color: #FFF;
`;

export const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  max-width: 340px;
  padding-left: 16px;
  padding-right: 16px;

  h3{
    margin-top: 20px;
  }
`;
