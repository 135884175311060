import styled from 'styled-components';

// welcomeContainer with 100dvh height, with fixed header with 50px and fixed footer with 50px

export const OnboardingContainer = styled.div`
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
`;
export const OnboardingHeader = styled.div`
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  text-align: center;
  padding-top: 12px;
  align-items: center;
  display: flex;
  padding: 0 ${({ theme }) => theme.spaces.screenEdge};
  justify-content: space-between;
`;
export const HeaderProgressBar = styled.div`
  position: relative;
  width: calc(100% - 40px);
  height: 6px;
  background: ${({ theme }) => theme.colors.black20};
  border-radius: 6px;

  &::after {
    content: '';
    display: block;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ percentual }) => percentual || '0%'};
    height: 100%;
    background: ${({ theme }) => theme.colors.primary.main};
    border-radius: 6px;
    transition: ease-in-out 0.2s;
  }
`;

export const OnboardingBody = styled.div`
  padding: 0 ${({ theme }) => theme.spaces.screenEdge};
  height: calc(100dvh - 90px - 50px);
  width: 100%;
  /* margin-top: 50px; */
  margin-top: 50px;
`;
export const OnboardingBodyFullHeight = styled.div`
  padding: 0 ${({ theme }) => theme.spaces.screenEdge};
  width: 100%;
  margin-top: 50px;
  background: #fff !important;
`;

export const OnboardingFooter = styled.div`
  padding: 0 ${({ theme }) => theme.spaces.screenEdge};
  height: 85px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  transition: ease-in-out 0.3s;
  opacity: 1;
  &.hiddenFooter {
    opacity: 0;
    padding-top: 185px;
  }
`;

export const GoalButton = styled.button`
  background: none;
  width: calc(100% - 2 * ( ${({ theme }) => theme.spaces.screenEdge} ));
  margin-left: ${({ theme }) => theme.spaces.screenEdge};
  border-radius: 12px;
  border: none;
  outline: none;
  transition: ease-in-out 0.2s;
  cursor: pointer;

    &.not-selected {
      /* inner black border with 2px solid black */
      opacity: 0.5;
      transform: scale(0.95);
    }
`;

export const GoalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.alerts.generic.background};
  color: ${({ theme }) => theme.colors.alerts.generic.textColor};
  border-radius: 12px;
  box-shadow: 0px 2px 12px 0px rgba(6, 16, 42, 0.10);
`;

export const GoalHeader = styled.div`
  padding: ${({ theme }) => theme.spaces.screenEdge};
  padding-bottom: 6px;
  color: ${({ theme }) => theme.colors.alerts.generic.titleColor};
  display: flex;
  align-items: start;
  position: relative;
  justify-content: space-between;
`;

export const GoalHeaderLeft = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  text-align: left;
`;

export const GoalHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const GoalContent = styled.div`
  padding: 0 ${({ theme }) => theme.spaces.screenEdge};
  text-align: left;
`;
export const DiscContainer = styled.div`
  padding: ${({ theme }) => theme.spaces.screenEdge};
  min-height: 100dvh;
`;

export const DiscContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.alerts.generic.background};
  color: ${({ theme }) => theme.colors.alerts.generic.textColor};
  border-radius: 12px;
  box-shadow: 0px 2px 12px 0px rgba(6, 16, 42, 0.10);
  padding: ${({ theme }) => theme.spaces.screenEdge};
  min-height: calc(100dvh - 2 * ${({ theme }) => theme.spaces.screenEdge});
  justify-content: space-between;

    &.autoconhecimento {
      background: ${({ theme }) => theme.colors.disc.c.lighter};
    }
    &.comunicacao {
      background: ${({ theme }) => theme.colors.disc.i.light};
    }
`;

export const DiscWordsContainer = styled.div`
  /* buttons inside discwordscontainer must be align side by side, left-to-right, top-to-bottom */
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
`;

export const FitOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  /* delay 2 secs to start transition*/
  transition: ease-in-out 0.8s;

    &.inactive {
      height: 0;
      opacity: 0.5;
      transform: scale(0.75);
    }
`;
export const BetweenFitButtons = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;
export const FitButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: ${({ theme }) => theme.colors.alerts.generic.background}; */
  background: rgba(255,255,255, .3);
  box-shadow: 0px 2px 12px 0px rgba(6, 16, 42, 0.10);
  padding: 8px 12px;
  /* width: calc(50% - 25px); */
  width: calc(100% - 0px);
  border-radius: 12px;
  border: none;
  outline: none;
  transition: ease-in-out 0.2s;
  color: ${({ theme }) => theme.colors.textDefault};
  cursor: pointer;
    p {
      padding: 0 16px;
    }
    img {
      height: 50px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &.selected {
      background: rgba(255,255,255, .8);
    }
    &.not-selected {
      /* inner black border with 2px solid black */
      opacity: 0.5;
      transform: scale(0.95);
      p {
        height: 0;
        overflow: hidden;
        margin-top: -16px;
      }
    }
`;

export const FormGroup = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  div {
    margin-bottom: 5px;
    font-family: 'DM Sans', sans-serif;
    /* text-transform: uppercase; */
    font-size: 12px;
    font-weight: 600;
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.primary.main};

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.primary.light};
    }
  }
`;
