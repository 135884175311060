import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Pagination } from 'swiper';
import { v4 as uuidv4 } from 'uuid';

// import { Accordion } from 'react-bootstrap';
import {
  Container, Content, EmojiSwiper,
} from './styles';

import defaultTheme from '../../../assets/styles/themes/default';

// modules styles
import 'swiper/components/pagination/pagination.min.css';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { replaceVoce } from '../../../utils/profileUtils';

SwiperCore.use([Pagination]);

export default function UserProfileCharsCards({
  lastProfile, chars, firstName, aboutAnotherUser, ...props
}) {
  const firstLetterProfile = lastProfile.displayedProfileLetters[0].toLowerCase();
  const profilebackgroundcolor = defaultTheme.colors.disc[firstLetterProfile].lighter;

  return (
    <div {...props}>
      <h4 className="sourcesans mb-0 semibold">Características</h4>
      <Swiper
        spaceBetween={-10}
        slidesPerView={1.15}
        autoHeight
        style={{
          zIndex: 0, marginLeft: '-16px', marginBottom: '20px', width: 'calc(100% + 32px)',
        }}
        // centeredSlides
        modules={[EffectCoverflow]}
        pagination={{
          clickable: true,
          el: '.swiper-user-profile-cards-pagination',
        }}
        className="swiper-user-profile-cards"
      >
        {chars.map((char) => (
          <SwiperSlide key={uuidv4()}>
            <Container className="mt-2" profilebackgroundcolor={profilebackgroundcolor} style={{ minHeight: '120px' }}>
              <Content>
                {aboutAnotherUser
                  ? (
                    <>
                      {replaceVoce({ text: char, name: firstName })}
                    </>
                  )
                  : char }
              </Content>
            </Container>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-user-profile-cards-pagination" style={{ marginTop: '-15px' }} />
      <EmojiSwiper>
        👆
        <br />
        <span>arraste...</span>
      </EmojiSwiper>
    </div>
  );
}
UserProfileCharsCards.propTypes = {
  lastProfile: PropTypes.shape({
    displayedProfileLetters: PropTypes.string,
  }).isRequired,
  chars: PropTypes.arrayOf(PropTypes.string).isRequired,
  firstName: PropTypes.string.isRequired,
  aboutAnotherUser: PropTypes.bool.isRequired,
};
