import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../services/backend';

// userId = subscriberId 66e85c6dd9c2afe15eeae6f1 Tula Luana
// transactionId 66e85c6dd9c2afe15eeae6f1-1726776433189
// notificationId 66e85dee2b75ce7e1d13b40c <===== não quero esse que vem de /v1/notifications
// messageId 66ec84763bb676a0fc567ca0 <===== quero esse quero esse que vem de /v1/messages
// http://localhost:8000/userSpace?activeTapBar=user&novuTransactionId=66e85c6dd9c2afe15eeae6f1-1726776433189
// http://localhost:8000/userSpace?activeTapBar=user&novuTransactionId=66e85c6dd9c2afe15eeae6f1-1726776433189

export function MarkInAppNotificationAsReadByUrl() {
  const location = useLocation(); // Obter a localização atual

  async function getMessageIdFromTransactionId(transactionId) {
    try {
      // Faz a primeira requisição para obter o messageId
      const { data } = await api.get(`/novu/v1/notifications?transactionId=${transactionId}`);
      // Verifica se há dados retornados
      if (data.data.length === 0) {
        // console.log('Nenhuma notificação encontrada para esse transactionId'); // DEBUG
        return null;
      }
      const { subscriberId } = data.data[0].subscriber;
      // console.log('subscriberId from TransactionId:', subscriberId); // DEBUG

      // Verifica se o subscriberId existe
      // e Faz a segunda requisição para obter as mensagens do subscriberId
      if (subscriberId) {
        const queryParams = {
          limit: 25,
          page: 0,
          subscriberId: `${subscriberId}`,
          channel: 'in_app',
          transactionId,
        };
        const secondData = await api.get('/novu/v1/messages', { params: queryParams });
        const { messageId } = secondData.data;
        // console.log('messageId from subscriberId:', messageId); // DEBUG

        // Verifica se o messageId existe e
        // Faz a terceira requisição para marcar a mensagem como vista e lida
        if (messageId) {
          const body = {
            messageId,
            mark: {
              seen: true,
              read: true,
            },
          };
          const thirdData = await api.post(`/novu/v1/subscribers/${subscriberId}/messages/markAs`, body);
          // console.log('MENSAGEM MARCADA:', body); // DEBUG
          // remove novuTransactionId da URL
          window.history.replaceState({}, document.title, location.pathname);
          return thirdData.data.message;
        }
      }
    } catch (error) {
      console.error('Erro ao tentar obter as notificações do usuário', error);
    }
    return null;
  }

  useEffect(() => {
    const markNotificationAsRead = async () => {
      // console.log('Tentando marcar a notificação como lida');
      const searchParams = new URLSearchParams(location.search); // Obter os parâmetros da URL
      const transactionId = searchParams.get('novuTransactionId'); // Obter o valor do parâmetro 'novuTransactionId'
      if (!transactionId) return; // Se não houver 'novuTransactionId', não fazer nada
      const messageId = await getMessageIdFromTransactionId(transactionId); // Obter o messageId
      if (messageId) {
        // console.log('Notificação lida com o messageId:', messageId);
      }
    };

    markNotificationAsRead(); // Chamar a função assíncrona dentro do useEffect
  }, []);

  return null; // Este componente não precisa renderizar nada
}
