// import PropTypes from 'prop-types';
import { Sheet } from 'react-modal-sheet';
import { useState, useContext, useEffect } from 'react';
import {
  CardButtons,
  CardHeader,
  Container,
  IconStatus,
  ProgressContainer,
  ProgressBar,
  ListItem,
  SingleItem,
  Title,
  Description,
} from './styles';
import PersoraButton from '../Button';
import defaultTheme from '../../assets/styles/themes/default';
import { UserContext } from '../../Context/UserContext';

// modules styles
import 'swiper/components/pagination/pagination.min.css';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import PersoraTextButton from '../TextButton';
import {
  Cardiology,
  Check, ChevronSmallRight, Circle, CircleChecked, PdePersora, Star2, UserFaceid, UserPlus,
} from '../DuotoneIcon';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import { Tag } from '../InstrumentComponent/styles';
// import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';

// tasksGoals
// -> basicData (BE define quando faz o primeiro acesso)
// -> initialProfile (BE define quando fizer o primeiro mapeaento)
// -> aboutYou (FE define quando interagir com o perfil sobre ele)
// -> teamCommunication (FE define quando interagir com o estilo de comunicação dele)
// -> inviteTeam (BE define quando convidar pelo menos 2 colaboradores para o time)
// -> meetSora (FE define quando ele clicar no botão de conhecer a Sora)

export default function NewGoalsTracker() {
  // console.log(userTasksGoals);
  // if (!localStorage.getItem('goalsTrackerIsOpen')) {
  //   localStorage.setItem('goalsTrackerIsOpen', true);
  // }
  // const [goalsTrackerIsOpen, setGoalsTrackerIsOpen] = useState(localStorage.getItem(
  // 'goalsTrackerIsOpen'
  // ) === 'true');
  // const handleChangeGoalsTrackerIsOpen = () => {
  //   const newIsOpenValue = !goalsTrackerIsOpen;
  //   setGoalsTrackerIsOpen(newIsOpenValue);
  //   localStorage.setItem('goalsTrackerIsOpen', newIsOpenValue);
  //   setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
  // };
  const [goalsTrackerSheetIsOpen, setGoalsTrackerSheetOpen] = useState(false);
  const { currentUserContextData } = useContext(UserContext);
  const { totalInvitedUsers, invitedUsersMapped } = currentUserContextData.workspaceContext;
  const {
    meetSora,
    interactionSora,
    interactionTeam,
    // eslint-disable-next-line
    pipSendAnswer,
    // eslint-disable-next-line
    pipSeeResult,
    // eslint-disable-next-line
    pipSeeActionPlan,
  } = currentUserContextData.tasksGoals;

  // eslint-disable-next-line
  const isPipEnabledAtWorkspace = currentUserContextData
    ?.workspaceContext?.toolsSettings.pip?.enabled;
  // eslint-disable-next-line
  const isPipAvaliableAtWorkspace = currentUserContextData.pipContext.length > 0;
  const isLedAtTeamWithPipEnabled = currentUserContextData?.workspaceContext?.teams
    .filter((team) => currentUserContextData.isLedAt.includes(team._id))
    .some((team) => team.toolsSettings.pipEnabled);
  const isLeaderAtTeamWithPipEnabled = currentUserContextData?.workspaceContext?.teams
    .filter((team) => currentUserContextData.isLeaderAt.includes(team._id))
    .some((team) => team.toolsSettings.pipEnabled);
  const [showPipCard, setShowPipCard] = useState(false);
  const leaderAtThisTeams = currentUserContextData?.workspaceContext?.teams
    .filter((team) => currentUserContextData.isLeaderAt.includes(team._id)
    && team.toolsSettings.pipEnabled);
  const ledAtThisTeams = currentUserContextData?.workspaceContext?.teams
    .filter((team) => currentUserContextData.isLedAt.includes(team._id)
    && team.toolsSettings.pipEnabled);

  const userTeams = (currentUserContextData?.type === 'Líder' ? leaderAtThisTeams : ledAtThisTeams);

  // console.log('leaderAtThisTeams com pip ativa', leaderAtThisTeams); // DEBUG
  // console.log('ledAtThisTeams com pip ativa', ledAtThisTeams); // DEBUG

  // eslint-disable-next-line
  const [totalTasks, setTotalTasks] = useState(currentUserContextData.type === 'Líder' ? 6 : 3);
  // eslint-disable-next-line
  const [totalTasksDone, setTotalTasksDone] = useState(0);

  useEffect(() => {
    if (currentUserContextData.type === 'Líder') {
      setTotalTasksDone(
        1 + (totalInvitedUsers >= 4 ? 1 : 0)
          + (invitedUsersMapped >= 4 ? 1 : 0)
          + (meetSora === 1 ? 1 : 0)
          + (interactionSora === 1 ? 1 : 0)
          + (interactionTeam === 1 ? 1 : 0),
      );
    } else if (currentUserContextData.type === 'Colaborador') {
      setTotalTasksDone(
        1 + (meetSora === 1 ? 1 : 0)
        + (interactionTeam === 1 ? 1 : 0),
      );
    }
    if (
      isPipEnabledAtWorkspace // está ativa no workspace
      && isPipAvaliableAtWorkspace // está disponível (foi criada pelo menos uma no workspace)
      && (isLedAtTeamWithPipEnabled || isLeaderAtTeamWithPipEnabled)) { // é líder ou liderado
      // eslint-disable-next-line
      // console.log('isPipAvaliableAtWorkspace', isPipEnabledAtWorkspace, isPipAvaliableAtWorkspace, pipQuantity, isLedAtTeamWithPipEnabled, isLeaderAtTeamWithPipEnabled);
      setShowPipCard(true);
      if (currentUserContextData.type === 'Líder') {
        // para cada ledAtThisTeams, se a pip estiver ativa, adicionar 2 tarefas
        setTotalTasks((prevTotalTasks) => prevTotalTasks + (leaderAtThisTeams.length * 1));
        userTeams.map((team) => (
          pipSeeResult?.includes(team._id)
            && pipSeeActionPlan?.includes(team._id)
            && setTotalTasksDone((prevTotalTasksDone) => prevTotalTasksDone + 1)
        ));
      } else if (currentUserContextData.type === 'Colaborador') {
        // para cada leaderAtThisTeams, se a pip estiver ativa, adicionar 2 tarefas
        setTotalTasks((prevTotalTasks) => prevTotalTasks + (ledAtThisTeams.length * 1));
        userTeams.map((team) => (
          pipSendAnswer?.includes(team._id)
            && pipSeeResult?.includes(team._id)
            && setTotalTasksDone((prevTotalTasksDone) => prevTotalTasksDone + 1)
        ));
      }
    }
  }, [currentUserContextData]);

  const statusOptions = {
    pendingTasks: {
      emoji: '👀',
      text: 'Você tem ações pendentes.',
    },
    goOn: {
      emoji: '💪',
      text: 'Continue! Seu time conta com você.',
    },
    allDone: {
      emoji: '🔥',
      text: 'Você está contribuindo com o time!',
    },
  };

  let currenteStatus = {};

  if (totalTasksDone >= totalTasks) {
    currenteStatus = statusOptions.allDone;
  } else if (totalTasksDone === (totalTasks - 1)) {
    currenteStatus = statusOptions.goOn;
  } else {
    currenteStatus = statusOptions.pendingTasks;
  }

  return (
    <Container>
      <CardHeader>
        <IconStatus className={totalTasksDone >= totalTasks && 'done'}>
          {currenteStatus.emoji}
        </IconStatus>
        <ProgressContainer className="ms-3">
          <Title className="sourcesans semibold mb-2 pb-1">{currenteStatus.text}</Title>
          <ProgressBar percentual={((100 / totalTasks) * totalTasksDone)} progresscontent={`${totalTasksDone}/${totalTasks}`} />
        </ProgressContainer>
      </CardHeader>
      <CardButtons>
        <div style={{ width: '100%' }}>
          <PersoraTextButton
            className="mb-0 small semibold"
            onClick={() => setGoalsTrackerSheetOpen(true)}
            style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
          >
            <div>
              Ver tudo
            </div>
            <div style={{ marginRight: '-6px' }}>
              <ChevronSmallRight tamanho={22} />
            </div>
          </PersoraTextButton>
        </div>
      </CardButtons>
      <Sheet
        isOpen={goalsTrackerSheetIsOpen}
        onClose={() => setGoalsTrackerSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        // detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
      >
        <Sheet.Container>
          <Sheet.Header>
            <div
              style={{
                padding: defaultTheme.spaces.screenEdge,
                paddingTop: defaultTheme.spaces.screenEdge,
                paddingBottom: 0,
                color: defaultTheme.colors.primary.main,
              }}
            >
              <Container className="mb-4">
                <CardHeader className="px-1">
                  <ProgressContainer className="ms-3 pe-2">
                    <Title className="sourcesans semibold mb-2 pb-2">
                      {currenteStatus.text}
                      {' '}
                      {currenteStatus.emoji}
                    </Title>
                    <ProgressBar percentual={((100 / totalTasks) * totalTasksDone)} progresscontent={`${totalTasksDone}/${totalTasks}`} />
                  </ProgressContainer>
                </CardHeader>
              </Container>
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  padding: `0 ${defaultTheme.spaces.screenEdge} 3rem ${defaultTheme.spaces.screenEdge}`,
                  color: defaultTheme.colors.primary.main,
                }}
              >
                <ListItem className="pt-3">
                  {/* eslint-disable-next-line */}
                  {/* TODO: NO FUTURO ESSE MAP TEM QUE CONSIDERAR QUE UM USUÁRIO PODE SER LÍDER E COLABORADOR SIMULTANEAMENTE */}
                  {showPipCard && currentUserContextData.type === 'Líder' && (
                    userTeams.map((team) => (
                      <SingleItem key={team._id} style={{ alignItems: 'start' }}>
                        <IconStatus
                          className={pipSeeResult?.includes(team._id)
                            && pipSeeActionPlan?.includes(team._id)
                            && 'done'}
                        >
                          {pipSeeResult?.includes(team._id) && pipSeeActionPlan?.includes(team._id)
                            ? <Check cor={defaultTheme.colors.primary.main} />
                            : <Cardiology cor={defaultTheme.colors.primary.main} />}
                        </IconStatus>
                        <ProgressContainer className="ms-3">
                          <Title className="sourcesans semibold mb-2 pt-3">
                            <Tag style={{ fontSize: '1rem' }}>
                              {team.emoji}
                              {' '}
                              {team.name}
                            </Tag>
                          </Title>
                          <Description>
                            {/* se pipSeeResult[] includes team._id */}
                            <div className="mb-2">
                              {pipSeeResult?.includes(team._id)
                                ? <CircleChecked tamanho={20} />
                                : <Circle tamanho={20} />}
                              {' '}
                              Ver resultado da PIP
                            </div>
                            <div className="mb-4">
                              {pipSeeActionPlan?.includes(team._id)
                                ? <CircleChecked tamanho={20} />
                                : <Circle tamanho={20} />}
                              {' '}
                              Acessar plano de ação da PIP
                            </div>
                          </Description>
                          <ProgressBar
                            percentual={
                              `${pipSeeResult?.includes(team._id) && pipSeeActionPlan?.includes(team._id)
                                ? 100
                                : `${pipSeeResult?.includes(team._id) || pipSeeActionPlan?.includes(team._id) ? 50 : 0}`}`
                            }
                            color="secondary"
                            progresscontent={
                              `${pipSeeResult?.includes(team._id) && pipSeeActionPlan?.includes(team._id)
                                ? '2'
                                : `${pipSeeResult?.includes(team._id) || pipSeeActionPlan?.includes(team._id) ? '1' : 0}`}/2`
                            }
                          />
                        </ProgressContainer>
                      </SingleItem>
                    ))
                  )}
                  {showPipCard && currentUserContextData.type === 'Colaborador' && (
                    userTeams.map((team) => (
                      <SingleItem key={team._id} style={{ alignItems: 'start' }}>
                        <IconStatus
                          className={pipSendAnswer?.includes(team._id)
                            && pipSeeResult?.includes(team._id)
                            && 'done'}
                        >
                          {pipSendAnswer?.includes(team._id) && pipSeeResult?.includes(team._id)
                            ? <Check cor={defaultTheme.colors.primary.main} />
                            : <Cardiology cor={defaultTheme.colors.primary.main} />}
                        </IconStatus>
                        <ProgressContainer className="ms-3">
                          <Title className="sourcesans semibold mb-2 pt-3">
                            <Tag style={{ fontSize: '1rem' }}>
                              {team.emoji}
                              {' '}
                              {team.name}
                            </Tag>
                          </Title>
                          <Description>
                            {/* se pipSeeResult[] includes team._id */}
                            <div className="mb-2">
                              {pipSendAnswer?.length > 0
                                ? <CircleChecked tamanho={20} />
                                : <Circle tamanho={20} />}
                              {' '}
                              Responder a PIP no prazo
                            </div>
                            <div className="mb-4">
                              {pipSeeResult?.includes(team._id)
                                ? <CircleChecked tamanho={20} />
                                : <Circle tamanho={20} />}
                              {' '}
                              Ver resultado da PIP
                            </div>
                          </Description>
                          <ProgressBar
                            percentual={
                              `${pipSendAnswer?.includes(team._id) && pipSeeResult?.includes(team._id)
                                ? 100
                                : `${pipSendAnswer?.includes(team._id) || pipSeeResult?.includes(team._id) ? 50 : 0}`}`
                            }
                            color="secondary"
                            progresscontent={
                              `${pipSendAnswer?.includes(team._id) && pipSeeResult?.includes(team._id)
                                ? '2'
                                : `${pipSendAnswer?.includes(team._id) || pipSeeResult?.includes(team._id) ? '1' : 0}`}/2`
                            }
                          />
                        </ProgressContainer>
                      </SingleItem>
                    ))
                  )}
                  <SingleItem>
                    <IconStatus className="done">
                      <Check cor={defaultTheme.colors.primary.dark} />
                    </IconStatus>
                    <ProgressContainer className="ms-3">
                      <Title className="sourcesans semibold mb-2">Conhecer meu estilo</Title>
                      <Description>
                        Mapear meu estilo para personalizar a comunicação com o time.
                      </Description>
                      <ProgressBar percentual="100" color="secondary" progresscontent="1/1" />
                    </ProgressContainer>
                  </SingleItem>
                  {currentUserContextData.type === 'Líder'
                  && (
                  <SingleItem>
                    <IconStatus className={totalInvitedUsers >= 4 && 'done'}>
                      {totalInvitedUsers >= 4
                        ? <Check cor={defaultTheme.colors.primary.main} />
                        : <UserPlus cor={defaultTheme.colors.primary.main} />}
                    </IconStatus>
                    <ProgressContainer className="ms-3">
                      <Title className="sourcesans semibold mb-2">Convidar o time</Title>
                      <Description>Enviar convites para 4 pessoas do meu time.</Description>
                      <ProgressBar
                        percentual={25 * totalInvitedUsers}
                        color="secondary"
                        progresscontent={
                          `${totalInvitedUsers === 4 ? '4' : totalInvitedUsers}/4`
                        }
                      />
                    </ProgressContainer>
                  </SingleItem>
                  )}
                  {currentUserContextData.type === 'Líder'
                  && (
                    <SingleItem>
                      <IconStatus className={invitedUsersMapped >= 4 && 'done'}>
                        {invitedUsersMapped >= 4
                          ? <Check cor={defaultTheme.colors.primary.main} />
                          : <UserFaceid cor={defaultTheme.colors.primary.main} />}
                      </IconStatus>
                      <ProgressContainer className="ms-3">
                        <Title className="sourcesans semibold mb-2">Estilos do time</Title>
                        <Description>
                          Ter as 4 pessoas do time mapeadas para a personalização da comunicação.
                        </Description>
                        <ProgressBar
                          percentual={25 * invitedUsersMapped}
                          color="secondary"
                          progresscontent={
                            `${invitedUsersMapped === 4 ? '4' : invitedUsersMapped}/4`
                          }
                        />
                      </ProgressContainer>
                    </SingleItem>
                  )}
                  <SingleItem>
                    <IconStatus className={meetSora === 1 && 'done'}>
                      {meetSora
                        ? <Check cor={defaultTheme.colors.primary.main} />
                        : <Star2 cor={defaultTheme.colors.primary.main} />}
                    </IconStatus>
                    <ProgressContainer className="ms-3">
                      <Title className="sourcesans semibold mb-2">Conhecer a Sora</Title>
                      <Description>
                        Acessar o estilo completo da Sora para conhecer minha copiloto.
                      </Description>
                      <ProgressBar
                        percentual={meetSora ? 100 : 0}
                        color="secondary"
                        progresscontent={
                          `${meetSora === 1 ? '1' : 0}/1`
                        }
                      />
                    </ProgressContainer>
                  </SingleItem>
                  {currentUserContextData.type === 'Líder'
                  && (
                    <SingleItem>
                      <IconStatus className={interactionSora === 1 && 'done'}>
                        {interactionSora
                          ? <Check cor={defaultTheme.colors.primary.main} />
                          : <PdePersora cor={defaultTheme.colors.primary.main} />}
                      </IconStatus>
                      <ProgressContainer className="ms-3">
                        <Title className="sourcesans semibold mb-2">Conhecer o botão copiloto</Title>
                        <Description>
                          Utilizar o botão copiloto simulando uma interação com a Sora.
                        </Description>
                        <ProgressBar
                          percentual={interactionSora ? 100 : 0}
                          color="secondary"
                          progresscontent={
                            `${interactionSora === 1 ? '1' : 0}/1`
                          }
                        />
                      </ProgressContainer>
                    </SingleItem>
                  )}
                  <SingleItem>
                    <IconStatus className={interactionTeam === 1 && 'done'}>
                      {interactionTeam
                        ? <Check cor={defaultTheme.colors.primary.main} />
                        : <PdePersora cor={defaultTheme.colors.primary.main} />}
                    </IconStatus>
                    <ProgressContainer className="ms-3">
                      <Title className="sourcesans semibold mb-2">Ver como interagir com o time</Title>
                      <Description>
                        Utilizar o botão copiloto para ver a melhor forma
                        {' '}
                        de interagir com pelo menos uma pessoa do time.
                      </Description>
                      <ProgressBar
                        percentual={interactionTeam ? 100 : 0}
                        color="secondary"
                        progresscontent={
                          `${interactionTeam === 1 ? '1' : 0}/1`
                        }
                      />
                    </ProgressContainer>
                  </SingleItem>
                </ListItem>
                <PersoraButton className="mt-4" onClick={() => setGoalsTrackerSheetOpen(false)}>Fechar</PersoraButton>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setGoalsTrackerSheetOpen(false)} />
      </Sheet>
    </Container>
  );
}
