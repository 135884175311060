import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.surfaces.lightBlue};
  color: ${({ theme }) => theme.bodyColor};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  width: 100vw;
`;

export const Content = styled.div`
  border-radius: 8px;
  min-width: 370px;
  max-width: 370px;
  color: ${({ theme }) => theme.bodyColor};
  padding: 20px 30px;
  display: flex;
  flex-direction: column;

  img {
    margin: 20px;
    margin-bottom: 60px;
    align-self: center;
  }

  h3 {
    margin-bottom: 4px;
  }

  p {
    line-height: 1.5rem;
  }

`;

export const FormGroup = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  div {
    margin-bottom: 5px;
    font-family: 'DM Sans', sans-serif;
    /* text-transform: uppercase; */
    font-size: 12px;
    font-weight: 600;
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.primary.main};

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.primary.light};
    }
  }
`;

export const CodeInput = styled.input`
  text-align: center;
  font-family: monospace;
  letter-spacing: 1px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary.light} !important;
  font-size: 1.2rem;

  &::placeholder {
    color: ${({ theme }) => theme.colors.primary.light} !important;
    opacity: 0.5;
  }
`;

export const TextLink = styled.p`
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary.light};
    font-weight: 600;
  }
`;
