import styled from 'styled-components';

import gradientBg1 from '../../assets/img/gradient-bg-1.svg';

export const StyledButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 12px;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.primary.main};
  background-image: url(${gradientBg1});
  /* background: rgb(120,200,228);
  background: linear-gradient(90deg, rgba(120,200,228,1) 33%, rgba(50,144,174,1) 100%); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: none;
  border: none;
  border-radius: 8px;
  border-color: ${({ theme }) => theme.colors.primary.main};
  font-size: 18px;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
  transition: all 0.2s ease-in-out;
  height: fit-content;
  text-align: left;
  padding: 24px;
  overflow: hidden;
`;

export const CloseButton = styled.button`
  outline: none;
  box-shadow: none;
  border: none;
  padding: 12px;
  background: none;
  position: absolute;
  right: ${({ theme }) => theme.spaces.screenEdge};
`;

export const TextContainer = styled.div`
  h5 {
    font-weight: 600;
    font-family: 'Source Sans 3', sans-serif;
    font-size: 1.2rem;
  }
  p {
    font-size: 1.05rem;
    margin-bottom: 0;
  }
`;

export const SoraContainer = styled.div`
  position: absolute;
  right: 0;
  margin-bottom: -24px;
  margin-right: 10%;
  pointer-events: none;
  img {
    width: 105%;
  }
`;

export const InsideOutlineButton = styled.div`
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 6px 12px;
  width: fit-content;
  margin-top: .6rem;
  font-weight: 600;
  transition: .3s;
  font-size: .85rem;

  &:hover {
    background: none !important;
    color: #fff;
  }
`;

export const ComoSeraButton = styled.button`
  width: fit-content;
  padding: .75rem 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.surfaces.white};
  background-color: ${({ theme }) => theme.colors.primary.main};
  box-shadow: none;
  /* border: none; */
  border: 1px solid transparent;
  border-radius: 8px;
  border: none;
  font-size: 15px;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
  transition: all 0.2s ease-in-out;
  height: fit-content;

    background: linear-gradient(135deg,  rgba(34,59,89,1) 38%,rgba(33,131,166,1) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    &:hover {
      background: linear-gradient(135deg,  rgba(34,59,89,1) 0%,rgba(33,131,166,1) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
`;
