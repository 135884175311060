export const defineRepository = (
  {
    targetPerfil,
    targetDisplayedProfile,
  },
) => {
  const totalLetrasPerfilOriginal = targetPerfil.length;
  if (totalLetrasPerfilOriginal <= 2) {
    switch (targetDisplayedProfile) {
      case 'D':
        return 0;
      case 'Di':
        return 1;
      case 'DI':
        return 2;
      case 'ID':
        return 3;
      case 'Id':
        return 4;
      case 'I':
        return 5;
      case 'Is':
        return 6;
      case 'IS':
        return 7;
      case 'SI':
        return 8;
      case 'Si':
        return 9;
      case 'S':
        return 10;
      case 'Sc':
        return 11;
      case 'SC':
        return 12;
      case 'CS':
        return 13;
      case 'Cs':
        return 14;
      case 'C':
        return 15;
      case 'Cd':
        return 16;
      case 'CD':
        return 17;
      case 'DC':
        return 18;
      case 'Dc':
        return 19;
      case 'DS':
        return 20;
      case 'SD':
        return 21;
      case 'IC':
        return 22;
      case 'CI':
        return 23;
      default:
        return 'Erro';
    }
  } else if (totalLetrasPerfilOriginal === 3) {
    switch (targetPerfil) {
      case 'ISC':
        switch (targetDisplayedProfile) {
          case 'IS':
          case 'IC':
            return 24;
          case 'SI':
          case 'SC':
            return 25;
          case 'CI':
          case 'CS':
            return 26;
          default:
            return 'Erro';
        }
      case 'DIC':
        switch (targetDisplayedProfile) {
          case 'DI':
          case 'DC':
            return 27;
          case 'ID':
          case 'IC':
            return 28;
          case 'CD':
          case 'CI':
            return 29;
          default:
            return 'Erro';
        }
      case 'DIS':
        switch (targetDisplayedProfile) {
          case 'DI':
          case 'DS':
            return 30;
          case 'ID':
          case 'IS':
            return 31;
          case 'SD':
          case 'SI':
            return 32;
          default:
            return 'Erro';
        }
      case 'DSC':
        switch (targetDisplayedProfile) {
          case 'DS':
          case 'DC':
            return 33;
          case 'SD':
          case 'SC':
            return 34;
          case 'CD':
          case 'CS':
            return 35;
          default:
            return 'Erro';
        }
      default:
        return 'Erro';
    }
  } else {
    return 'Erro';
  }
};
