// import logo from './logo.svg';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';

import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

import GlobalStyles from './assets/styles/global';
import defaultTheme from './assets/styles/themes/default';
import 'react-toastify/dist/ReactToastify.css';

import Routes from './Routes';
import { AuthProvider } from './Context/AuthContext';
import { ModalBumpers } from './components/ModalBumpers';
import { UserProvider } from './Context/UserContext';
import { MarkInAppNotificationAsReadByUrl } from './utils/markInAppNotAsReadByUrl';

// import Header from './components/Header';

if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
  ReactGA.initialize('G-TNF1HPSPYW');
  mixpanel.init('42774eae7662dd47ae31e2d43a705e8b', {
    track_pageview: true, persistence: 'localStorage',
    // debug: true, ignore_dnt: true, track_pageview: true, persistence: 'localStorage',
  });
}

function App() {
  useEffect(() => {
    const handleUrlChange = () => {
      console.log('Oops, URL changed');
    };

    // Listen for changes in the URL
    window.addEventListener('popstate', handleUrlChange);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        style={{ marginTop: '4px', zIndex: '99999999999' }}
        draggableDirection="y"
      />
      <BrowserRouter>
        <AuthProvider>
          <UserProvider>
            {/* <Header /> */}
            <MarkInAppNotificationAsReadByUrl />
            <ModalBumpers />
            <Routes />
          </UserProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

// signup-lider-
// welcome-lider-
// profile-lider-
