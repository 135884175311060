import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { NotFoundContainer, Content } from './styles';
// import soraConfusa from '../../assets/img/Sora/Confusa.svg';
import soraConfusao from '../../assets/img/SoraV2/Busto/Confusao.svg';
import { Bell } from '../../components/DuotoneIcon';
import defaultTheme from '../../assets/styles/themes/default';
import PersoraButton from '../../components/Button';

// import { removeSubstring } from '../../utils/formatters';

export default function NotFound() {
  const currentLocation = useLocation();
  const { host } = window.location;

  useEffect(() => {
    if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
      ReactGA.event({
        category: 'Erro',
        action: 'pagina_not_fount_404_visualizada',
        host,
        path: currentLocation.pathname,
        search: currentLocation.search,
      });
      mixpanel.track('pagina_not_fount_404_visualizada', {
        category: 'Erro',
        host,
        path: currentLocation.pathname,
        search: currentLocation.search,
      });
    }
    toast.error(`Página não encontrda • host:${host.replace('minha.persora.app', 'xxxxx.xxxxxxx.xxx')} • path:${currentLocation.pathname} • search:${currentLocation.search}`, {
      autoClose: 3000,
      position: 'bottom-center',
    });
  }, []);

  return (
    <NotFoundContainer>
      <Content style={{ color: defaultTheme.colors.primary.light }}>
        <img src={soraConfusao} alt="Sora 404 - Imagem da Sora, com as mãos na cabeça, vestindo uma camisa roxa escrito 404." width="200px" />
        <h3 className="mb-3">Ops, algo deu errado.</h3>
        <p className="mb-4">
          <b>Se você abriu uma notificação</b>
          {' '}
          e foi redirecionado para
          {' '}
          esta página, volte ao app e veja as mensagens através do
          {' '}
          <b>
            (
            <Bell tamanho={22} cor={defaultTheme.colors.primary.light} />
            )
          </b>
          .
        </p>
        <Link to="/userSpace?activeTapBar=user" style={{ color: '#fff' }}>
          <PersoraButton estilo="outline" tamanho="condensed" className="py-2 px-3">
            Voltar para o aplicativo
          </PersoraButton>
        </Link>
      </Content>
    </NotFoundContainer>
  );
}
