import PropTypes from 'prop-types';
import { Ratio } from 'react-bootstrap';
import { CardLabel, CardReadAbout, StyledButton } from './styles';
import { ArrowSmallRight } from '../DuotoneIcon';

// import margin and padding (as mt-4, mb-2, px-4) bootstrap here

/**
 * Componente de botão personalizado para Persora.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {React.ReactNode} props.children - Conteúdo do botão.
 * @param {string} props.imageCover - URL da imagem de fundo.
 * @param {string} props.imageProportion - Proporção da imagem de fundo ('1x1', '4x3', '16x9')
 * @param {string} props.cardLabel - Rótulo do cartão.
 * @returns {JSX.Element} Elemento JSX representando o botão.
*/

export default function PersoraCardButton({
  imageCoverUrl, imageProportion, cardLabel, children, ...props
}) {
  return (
    // <StyleButton with className received from props and {estilo} and {tamanho} as props>
    <StyledButton {...props} className={`${props.className}`}>
      <Ratio aspectRatio={imageProportion}>
        <div style={{
          backgroundImage: `url(${imageCoverUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          width: '100%',
          height: '100%',
        }}
        />
      </Ratio>
      <CardLabel dangerouslySetInnerHTML={{ __html: cardLabel }} />
      <CardReadAbout>
        Ler sobre
        <ArrowSmallRight />
      </CardReadAbout>
      {children}
    </StyledButton>
  );
}

PersoraCardButton.propTypes = {
  children: PropTypes.node,
  imageCoverUrl: PropTypes.string.isRequired,
  imageProportion: PropTypes.oneOf(['1x1', '4x3', '16x9']).isRequired,
  cardLabel: PropTypes.string,
  className: PropTypes.string,
};
PersoraCardButton.defaultProps = {
  children: null,
  cardLabel: null,
  className: '',
};
