import { createContext, useState } from 'react';
import { PropTypes } from 'prop-types';

const UserContext = createContext();

function UserProvider({ children }) {
  const [currentUserContextData, setCurrentUserContextData] = useState({});
  // const fullName = currentUserContextData.name;
  // const nameParts = fullName.split(' ');
  // const firstName = nameParts[0];
  // const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
  function handleCurrentUserData(data) {
    console.log('userData @ UserProvider', data);
    setCurrentUserContextData({
      name: data.user.name,
      // firstName,
      // lastName,
      email: data.user.email,
      type: data.user.type,
      owner: data.user.owner,
      createdAt: data.user.createdAt,
      invitedBy: data.user.invitedBy,
      isLeaderAt: data.user.isLeaderAt,
      isLedAt: data.user.isLedAt,
      displayedProfileLetters: data.user.displayedProfileLetters,
      profileLetters: data.user.profileLetters,
      disclaimers: data.user.disclaimers,
      tasksGoals: data.user.tasksGoals,
      settings: data.user.settings,
      notificationSettings: data.user.notificationSettings,
      firstAuth: data.user.firstAuth,
      adminPersora: data.user.adminPersora,
      // idsOneSignal: data.user.idsOneSignal,
      idsOneSignal: data.user.idsOneSignal,
      _id: data.user._id,
      _idWorkspace: data.user._idWorkspace,
      _idTeam: data.user._idTeam,
      workspaceContext: {
        profileSora: data.workspace.profileSora,
        premium: data.workspace.premium,
        totalUsers: data.workspace.totalUsers,
        totalUsersMapped: data.workspace.totalUsersMapped,
        totalInvitedUsers: data.workspace.totalInvitedUsers,
        invitedUsersMapped: data.workspace.invitedUsersMapped,
        totalAuthenticatedUsers: data.workspace.totalAuthenticatedUsers,
        toolsSettings: data.workspace.toolsSettings,
        segment: data.workspace.segment,
        teamDepartment: data.workspace.teamDepartment,
        teams: data.teams,
      },
      pipContext: data.lastQuestSurveys.pip,
    });
  }

  return (
    <UserContext.Provider value={{
      handleCurrentUserData,
      currentUserContextData,
      setCurrentUserContextData,
    }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserContext, UserProvider };
