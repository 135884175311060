import { Sheet } from 'react-modal-sheet';
import defaultTheme from '../../assets/styles/themes/default';

export default function LearnMoreMapearPerfil() {
  return (
    <Sheet.Container>
      <Sheet.Header />
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              minHeight: 360,
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: 0,
              color: defaultTheme.colors.primary.main,
            }}
          >
            <h3 className="medium">
              Como fazer?
            </h3>
            <p>
              É bem simples, basta selecionar as palavras que melhor te definem na sua percepção.
            </p>
            <h5 className="bold mt-4 mb-3" style={{ color: defaultTheme.colors.primary.main }}>Mas atenção:</h5>
            <div className="emoji-paragraph">
              <span>🚫</span>
              <p>Não vale pedir ajuda.</p>
            </div>
            <div className="emoji-paragraph">
              <span>🥰</span>
              <p>Não se trata de como você gostaria de ser, mas de como é.</p>
            </div>
            <div className="emoji-paragraph">
              <span>💯</span>
              <p>Seja sincero para aproveitar 100% da ferramenta.</p>
            </div>
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}
