// essencial
// c=o que te gera mais dinheiro
// o que te diminui custo
// o que melhora a performance

export default {
  D: {
    resumo: {
      female: '<p>Você tende a ser mais assertiva, intensa e ambiciosa. Geralmente, é pragmática, focada em resultados e age rapidamente, tomando decisões com firmeza e objetividade.</p><p>Prefere mais independência e pode se sentir desgastada quando os outros esperam que seja mais colaborativa. Também pode preferir ter controle sobre uma situação, em vez de ter que reagir às ações dos outros.</p>',
      male: '<p>Você tende a ser mais assertivo, intenso e ambicioso. Geralmente, é pragmático, focado em resultados e age rapidamente, tomando decisões com firmeza e objetividade.</p><p>Prefere mais independência e pode se sentir desgastado quando os outros esperam que seja mais colaborativo. Também pode preferir ter controle sobre uma situação, em vez de ter que reagir às ações dos outros.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você tende a ser uma pessoa criativa, determinada e gosta de alcançar objetivos por conta própria.',
        'Gosta de competições e está sempre desafiando você e os outros.',
        'Espera muito de si e das pessoas ao seu redor.',
        'Adora um debate animado e não se deixa influenciar facilmente pelos outros.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta de conversas diretas e objetivas.</p>',
      },
      reunioes: {
        neutral: '<p>Prefere reuniões curtas e marcadas somente quando necessárias.</p>',
      },
      emails: {
        neutral: '<p>Gosta de e-mails curtos, profissionais e breves.</p>',
      },
      feedback: {
        neutral: '<p>Valoriza feedbacks diretos, práticos e focados no essencial.</p>',
      },
      conflitos: {
        neutral: '<p>Aborda conflitos de forma objetiva, buscando resolvê-los rapidamente.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você se comunica de maneira direta, utilizando fatos e linguagem acessível.',
          'Sua atenção está sempre nos resultados e você define expectativas realistas.',
          'É muito firme e conclui decisões com clareza.',
          'Ao atribuir tarefas, segue uma abordagem voltada para objetivos, evitando detalhes desnecessários.',
          'Age com grande senso de urgência.',
          'Motiva os outros por meio de desafios competitivos.',
          'Quando dá direções, faz isso de maneira impessoal, com clareza e precisão.',
          'Expressa o desejo de manter controle e autonomia.',
        ],
        male: [
          'Você se comunica de maneira direta, utilizando fatos e linguagem acessível.',
          'Sua atenção está sempre nos resultados e você define expectativas realistas.',
          'É muito firme e conclui decisões com clareza.',
          'Ao atribuir tarefas, segue uma abordagem voltada para objetivos, evitando detalhes desnecessários.',
          'Age com grande senso de urgência.',
          'Motiva os outros por meio de desafios competitivos.',
          'Quando dá direções, faz isso de maneira impessoal, com clareza e precisão.',
          'Expressa o desejo de manter controle e autonomia.',
        ],
      },
      cegos: {
        female: [
          'Você às vezes resolve problemas sem envolver os outros, buscando uma solução imediata.',
          'Pode omitir detalhes importantes em busca de velocidade.',
          'Mostra impaciência ao fornecer instruções detalhadas.',
          'Às vezes, sente a necessidade de criticar aqueles que não demonstram senso de urgência.',
          'Delega responsabilidades, mas retém o controle, o que pode ser limitante para os outros.',
          'Pode direcionar os outros com tanta firmeza que eles não se sintam à vontade para fazer perguntas ou discutir problemas potenciais.',
          'Em situações em que sua autoridade ou autonomia é questionada, você pode reagir de forma agressiva.',
          'Seu senso de urgência intenso pode criar estresse desnecessário nas pessoas ao seu redor.',
        ],
        male: [
          'Você às vezes resolve problemas sem envolver os outros, buscando uma solução imediata.',
          'Pode omitir detalhes importantes em busca de velocidade.',
          'Mostra impaciência ao fornecer instruções detalhadas.',
          'Às vezes, sente a necessidade de criticar aqueles que não demonstram senso de urgência.',
          'Delega responsabilidades, mas retém o controle, o que pode ser limitante para os outros.',
          'Pode direcionar os outros com tanta firmeza que eles não se sintam à vontade para fazer perguntas ou discutir problemas potenciais.',
          'Em situações em que sua autoridade ou autonomia é questionada, você pode reagir de forma agressiva.',
          'Seu senso de urgência intenso pode criar estresse desnecessário nas pessoas ao seu redor.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Você se sente motivada e cheia de energia quando consegue concluir projetos ambiciosos dentro de prazos apertados.',
          'Assumir a principal responsabilidade e ter controle sobre grandes projetos.',
          'Tomar decisões rapidamente, mesmo com dados limitados.',
          'Tomar decisões em nome de outras pessoas.',
          'Organizar e participar de competições.',
          'Ver resultados tangíveis, marcos e conquistas mensuráveis.',
          'Fornecer direção focada em objetivos aos outros, sem a necessidade de dar instruções detalhadas.',
        ],
        male: [
          'Você se sente motivado e cheio de energia quando consegue concluir projetos ambiciosos dentro de prazos apertados.',
          'Assumir a principal responsabilidade e ter controle sobre grandes projetos.',
          'Tomar decisões rapidamente, mesmo com dados limitados.',
          'Tomar decisões em nome de outras pessoas.',
          'Organizar e participar de competições.',
          'Ver resultados tangíveis, marcos e conquistas mensuráveis.',
          'Fornecer direção focada em objetivos aos outros, sem a necessidade de dar instruções detalhadas.',
        ],
      },
      drena: {
        female: [
          'Muita atenção às necessidades e preocupações de outras pessoas pode deixá-la estressada.',
          'Desempenhar exclusivamente um papel de apoio em uma equipe.',
          'Responder a situações difíceis com empatia e compaixão.',
          'Promover trabalho em equipe e cooperação entre várias partes.',
          'Acompanhar e verificar como outras pessoas estão lidando com um desafio.',
          'Gastar muito tempo tentando entender como as pessoas se sentem em relação a uma mudança.',
          'Construir confiança e lealdade a longo prazo com comportamento consistente e previsível.',
        ],
        male: [
          'Muita atenção às necessidades e preocupações de outras pessoas pode deixá-lo estressado.',
          'Desempenhar exclusivamente um papel de apoio em uma equipe.',
          'Responder a situações difíceis com empatia e compaixão.',
          'Promover trabalho em equipe e cooperação entre várias partes.',
          'Acompanhar e verificar como outras pessoas estão lidando com um desafio.',
          'Gastar muito tempo tentando entender como as pessoas se sentem em relação a uma mudança.',
          'Construir confiança e lealdade a longo prazo com comportamento consistente e previsível.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você tende a ser criativa, determinada e gosta de alcançar objetivos por conta própria.',
          'Gosta de competições e está sempre desafiando a si mesma e os outros.',
          'Espera muito de si mesma e das pessoas ao seu redor.',
          'Adora um debate animado e não se deixa influenciar facilmente pelos outros.',
        ],
        male: [
          'Você tende a ser criativo, determinado e gosta de alcançar objetivos por conta própria.',
          'Gosta de competições e está sempre desafiando a si mesmo e os outros.',
          'Espera muito de si mesmo e das pessoas ao seu redor.',
          'Adora um debate animado e não se deixa influenciar facilmente pelos outros.',
        ],
      },
      pontosCegos: {
        female: [
          'Seu ambiente de trabalho pode se tornar excessivamente competitivo ou agressivo para as pessoas mais reservadas.',
          'Pode tumultuar o ambiente ao tentar resolver conflitos imediatamente com discussões verbais diretas.',
          'Seu ritmo de trabalho pode ser rápido demais para que os membros da equipe completem suas tarefas com a qualidade que consideram adequada.',
          'Pode não permitir tempo flexível suficiente para os membros da equipe se conhecerem bem e construírem confiança.',
          'Pode realizar mudanças repentinas, sem comunicar previamente à equipe ou oferecer tempo adequado para preparação.',
        ],
        male: [
          'Seu ambiente de trabalho pode se tornar excessivamente competitivo ou agressivo para as pessoas mais reservadas.',
          'Pode tumultuar o ambiente ao tentar resolver conflitos imediatamente com discussões verbais diretas.',
          'Seu ritmo de trabalho pode ser rápido demais para que os membros da equipe completem suas tarefas com a qualidade que consideram adequada.',
          'Pode não permitir tempo flexível suficiente para os membros da equipe se conhecerem bem e construírem confiança.',
          'Pode realizar mudanças repentinas, sem comunicar previamente à equipe ou oferecer tempo adequado para preparação.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Demonstração de coragem ao desafiar o status quo.',
          'Ousadia para explorar novas abordagens.',
          'Determinação em enfrentar o que está estabelecido.',
          'Objetividade ao comunicar suas ideias e pensamentos, o que agiliza a tomada de decisões.',
        ],
      },
      dificuldades: {
        neutral: [
          'Dificuldade em receber críticas, devido à convicção de que está sempre certo, o que pode limitar o processo de aprendizado.',
          'Tendência a gerar conflitos, já que sua abordagem pode ser vista como indelicada por outros.',
          'Excesso de competitividade, o que pode prejudicar a cooperação e o trabalho em equipe.',
          'Propensão ao autoritarismo, o que pode inibir a participação e criatividade dos outros membros da equipe.',
        ],
      },
    },
  },
  Di: {
    resumo: {
      female: '<p>Sua assertividade se destaca, com uma habilidade natural de se posicionar de forma ousada e resistir à influência alheia. Reconhecida por sua decisão, energia e capacidade persuasiva, você tem o dom de convencer os outros a se alinharem com seus objetivos.</p><p>Você naturalmente assume a liderança, estabelecendo o ritmo e direção para os demais. Como uma negociadora habilidosa, você consegue persuadir as pessoas a entenderem e aceitarem seu ponto de vista com eficácia.</p>',
      male: '<p>Sua assertividade se destaca, com uma habilidade natural de se posicionar de forma ousada e resistir à influência alheia. Reconhecido por sua decisão, energia e capacidade persuasiva, você tem o dom de convencer os outros a se alinharem com seus objetivos.</p><p>Você naturalmente assume a liderança, estabelecendo o ritmo e direção para os demais. Como um negociador habilidoso, você consegue persuadir as pessoas a entenderem e aceitarem seu ponto de vista com eficácia.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você deseja assumir o comando das coisas.',
        'Resiste à influência de outros.',
        'Expressa abertamente opiniões e ideias.',
        'Busca grandes e ambiciosos objetivos com rapidez e explosões de intensidade.',
        'Compete e debate com os outros.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Valoriza conversas confiantes, assertivas e direto ao ponto. Até porque tende a reter somente as partes mais importantes.</p>',
      },
      reunioes: {
        neutral: '<p>Devem ser espontâneas, objetivas e curtas.</p>',
      },
      emails: {
        neutral: '<p>Devem ser curtos, objetivos e com poucos detalhes.</p>',
      },
      feedback: {
        neutral: '<p>Gosta de feedbacks diretos, práticos e focados nos pontos mais importantes.</p>',
      },
      conflitos: {
        neutral: '<p>Os conflitos devem ser abordados abertamente e com uma mentalidade voltada para a resolução de problemas.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você é rápida, independente e firme ao tomar decisões.',
          'Consegue agir com informações limitadas.',
          'Busca responsabilidade, autonomia e controle sobre as decisões que impactam os resultados.',
          'Usa inspiração verbal para direcionar os outros.',
          'Delega efetivamente a responsabilidade por tarefas detalhadas.',
        ],
        male: [
          'Você é rápido, independente e firme ao tomar decisões.',
          'Consegue agir com informações limitadas.',
          'Busca responsabilidade, autonomia e controle sobre as decisões que impactam os resultados.',
          'Usa inspiração verbal para direcionar os outros.',
          'Delega efetivamente a responsabilidade por tarefas detalhadas.',
        ],
      },
      cegos: {
        female: [
          'Trabalha com um senso de urgência que pode causar estresse desnecessário aos outros.',
          'Pode delegar excessivamente a responsabilidade de acompanhar os detalhes.',
          'Tenta manter controle excessivo sobre os resultados.',
          'Fornece estrutura insuficiente para pessoas que precisam de uma abordagem de trabalho definida.',
          'Reage de forma agressiva quando outros tentam limitar sua autoridade ou autonomia.',
          'Persegue muitas ideias ou oportunidades novas ao mesmo tempo.',
        ],
        male: [
          'Trabalha com um senso de urgência que pode causar estresse desnecessário aos outros.',
          'Pode delegar excessivamente a responsabilidade de acompanhar os detalhes.',
          'Tenta manter controle excessivo sobre os resultados.',
          'Fornece estrutura insuficiente para pessoas que precisam de uma abordagem de trabalho definida.',
          'Reage de forma agressiva quando outros tentam limitar sua autoridade ou autonomia.',
          'Persegue muitas ideias ou oportunidades novas ao mesmo tempo.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Apresentar ideias e estratégias para grupos.',
          'Direcionar e motivar outros a melhorar seu desempenho.',
          'Buscar novas oportunidades sem muita orientação.',
          'Comunicar-se por meio de conversas rápidas e mensagens, apenas quando necessário.',
          'Organizar e participar de competições.',
          'Tomar decisões em nome de outras pessoas.',
          'Concluir projetos ambiciosos dentro de prazos apertados.',
          'Assumir a principal responsabilidade e liderança em grandes projetos.',
        ],
        male: [
          'Apresentar ideias e estratégias para grupos.',
          'Direcionar e motivar outros a melhorar seu desempenho.',
          'Buscar novas oportunidades sem muita orientação.',
          'Comunicar-se por meio de conversas rápidas e mensagens, apenas quando necessário.',
          'Organizar e participar de competições.',
          'Tomar decisões em nome de outras pessoas.',
          'Concluir projetos ambiciosos dentro de prazos apertados.',
          'Assumir a principal responsabilidade e liderança em grandes projetos.',
        ],
      },
      drena: {
        female: [
          'Manter-se consistente e previsível em um ambiente estruturado.',
          'Promover trabalho em equipe e cooperação entre os outros.',
          'Fornecer análises e relatórios detalhados.',
          'Analisar todos os aspectos de uma decisão importante.',
          'Dedicar tempo para entender como as pessoas se sentem sobre uma mudança recente.',
        ],
        male: [
          'Manter-se consistente e previsível em um ambiente estruturado.',
          'Promover trabalho em equipe e cooperação entre os outros.',
          'Fornecer análises e relatórios detalhados.',
          'Analisar todos os aspectos de uma decisão importante.',
          'Dedicar tempo para entender como as pessoas se sentem sobre uma mudança recente.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Ansiosa para assumir o comando e fornecer direção clara.',
          'Tende a desafiar os outros com tarefas exigentes e altas expectativas.',
          'Fornece instruções de alto nível focadas no resultado final.',
          'Cria um ambiente de trabalho competitivo e dinâmico.',
        ],
        male: [
          'Ansioso para assumir o comando e fornecer direção clara.',
          'Tende a desafiar os outros com tarefas exigentes e altas expectativas.',
          'Fornece instruções de alto nível focadas no resultado final.',
          'Cria um ambiente de trabalho competitivo e dinâmico.',
        ],
      },
      pontosCegos: {
        female: [
          'O seu ambiente de trabalho pode se tornar excessivamente competitivo ou agressivo para as pessoas mais reservadas.',
          'Pode tumultuar o ambiente ao tentar resolver conflitos imediatamente com discussões verbais diretas.',
          'Seu ritmo de trabalho pode ser rápido demais para que os membros da equipe completem suas tarefas com a qualidade que consideram adequada.',
          'Pode não permitir tempo flexível suficiente para os membros da equipe se conhecerem bem e construírem confiança.',
          'Pode fazer mudanças repentinas, sem informar a equipe ou fornecer tempo suficiente para se preparar.',
        ],
        male: [
          'O seu ambiente de trabalho pode se tornar excessivamente competitivo ou agressivo para as pessoas mais reservadas.',
          'Pode tumultuar o ambiente ao tentar resolver conflitos imediatamente com discussões verbais diretas.',
          'Seu ritmo de trabalho pode ser rápido demais para que os membros da equipe completem suas tarefas com a qualidade que consideram adequada.',
          'Pode não permitir tempo flexível suficiente para os membros da equipe se conhecerem bem e construírem confiança.',
          'Pode fazer mudanças repentinas, sem informar a equipe ou fornecer tempo suficiente para se preparar.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Demonstração de coragem ao desafiar o status quo.',
          'Determinação em enfrentar o que está estabelecido.',
          'Objetividade ao dizer o que pensa.',
          'Sente-se confortável em navegar no mundo dos sonhos e possibilidades.',
        ],
      },
      dificuldades: {
        neutral: [
          'Dificuldade em receber críticas, devido à crença de que está sempre certo, o que pode limitar o processo de aprendizado.',
          'Propensão ao autoritarismo, o que pode inibir a participação e criatividade dos outros membros da equipe.',
          'Problemas de relacionamento, podendo ser visto como indelicado.',
          'Pode fazer o grupo perder o foco.',
        ],
      },
    },
  },
  DI: {
    resumo: {
      female: '<p>Você aborda pessoas e situações com energia e entusiasmo. Gosta de desafios, tem interesse em conhecer novas pessoas e demonstra grande habilidade social, sendo capaz de persuadir os outros com facilidade.</p><p>É extrovertida, comunicando-se de maneira clara e envolvente, utilizando um estilo emocionalmente expressivo e participando ativamente nas conversas.</p>',
      male: '<p>Você aborda pessoas e situações com energia e entusiasmo. Gosta de desafios, tem interesse em conhecer novas pessoas e demonstra grande habilidade social, sendo capaz de persuadir os outros com facilidade.</p><p>É extrovertido, comunicando-se de maneira clara e envolvente, utilizando um estilo emocionalmente expressivo e participando ativamente nas conversas.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você aborda as pessoas com energia e entusiasmo.',
        'Utiliza carisma para reunir pessoas, construir  rapport (entrosamento) e compartilhar ideias.',
        'Assume com entusiasmo a liderança em situações sociais.',
        'Expressa suas opiniões e ideias de forma franca.',
        'Trabalha com intensidade e um espírito aventureiro.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta quando são objetivas e dão espaço para você usar suas habilidades de persuasão.</p>',
      },
      reunioes: {
        neutral: '<p>Devem ser curtas e espontâneas, sem uma agenda rígida.</p>',
      },
      emails: {
        neutral: '<p>Devem ser breves e incluir apenas as informações mais importantes.</p>',
      },
      feedback: {
        neutral: '<p>Gosta quando são mais específicos e focados nos pontos mais críticos.</p>',
      },
      conflitos: {
        neutral: '<p>Encara os conflitos como uma oportunidade de encontrar soluções aprimoradas; Prefere abordar problemas de frente e apresentar soluções diretas.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você assume responsabilidade e propriedade sobre os resultados.',
          'Usa inspiração verbal para direcionar os outros.',
          'Apresenta o panorama geral com entusiasmo.',
          'Identifica rapidamente novas oportunidades de avanço.',
          'Assume riscos necessários e toma decisões ousadas, mesmo quando não tem todas as informações.',
          'Cria soluções inovadoras para problemas desafiadores.',
        ],
        male: [
          'Você assume responsabilidade e propriedade sobre os resultados.',
          'Usa inspiração verbal para direcionar os outros.',
          'Apresenta o panorama geral com entusiasmo.',
          'Identifica rapidamente novas oportunidades de avanço.',
          'Assume riscos necessários e toma decisões ousadas, mesmo quando não tem todas as informações.',
          'Cria soluções inovadoras para problemas desafiadores.',
        ],
      },
      cegos: {
        female: [
          'Pode delegar demais e perder de vista os detalhes.',
          'Tende a ser excessivamente controladora em relação aos resultados.',
          'Oferece pouca estrutura para pessoas que apreciam um ambiente de trabalho definido.',
          'Salta entre muitas ideias ou oportunidades novas simultaneamente.',
          'Trabalha em um ritmo tão acelerado que pode causar estresse aos outros.',
          'Tem dificuldade em seguir rotinas consistentes e previsíveis.',
          'Pode se mostrar sarcástica com frequência, o que pode causar falhas de comunicação com pessoas mais literais.',
        ],
        male: [
          'Pode delegar demais e perder de vista os detalhes.',
          'Tende a ser excessivamente controlador em relação aos resultados.',
          'Oferece pouca estrutura para pessoas que apreciam um ambiente de trabalho definido.',
          'Salta entre muitas ideias ou oportunidades novas simultaneamente.',
          'Trabalha em um ritmo tão acelerado que pode causar estresse aos outros.',
          'Tem dificuldade em seguir rotinas consistentes e previsíveis.',
          'Pode se mostrar sarcástico com frequência, o que pode causar falhas de comunicação com pessoas mais literais.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Delegar trabalhos detalhados e analíticos para outras pessoas.',
          'Direcionar e motivar os outros como forma de aprimorar o desempenho.',
          'Criar novos relacionamentos e conquistar as pessoas.',
          'Apresentar novas ideias a uma audiência.',
          'Buscar novas oportunidades com direção mínima.',
          'Assumir riscos quando surge uma oportunidade.',
          'Tomar decisões rapidamente com poucos dados.',
          'Alternar e combinar múltiplas ideias de uma vez.',
          'Assumir a liderança em grandes iniciativas.',
        ],
        male: [
          'Delegar trabalhos detalhados e analíticos para outras pessoas.',
          'Direcionar e motivar os outros como forma de aprimorar o desempenho.',
          'Criar novos relacionamentos e conquistar as pessoas.',
          'Apresentar novas ideias a uma audiência.',
          'Buscar novas oportunidades com direção mínima.',
          'Assumir riscos quando surge uma oportunidade.',
          'Tomar decisões rapidamente com poucos dados.',
          'Alternar e combinar múltiplas ideias de uma vez.',
          'Assumir a liderança em grandes iniciativas.',
        ],
      },
      drena: {
        female: [
          'Seguir agendas diárias estruturadas e consistentes.',
          'Fornecer orientação individual e instruções passo a passo.',
          'Pesquisar e confiar nos métodos que levaram as pessoas a alcançarem seus objetivos.',
          'Minimizar riscos com análises aprofundadas.',
          'Auxiliar outras pessoas na elaboração de planos claramente definidos.',
          'Comunicar detalhes de uma decisão importante.',
        ],
        male: [
          'Seguir agendas diárias estruturadas e consistentes.',
          'Fornecer orientação individual e instruções passo a passo.',
          'Pesquisar e confiar nos métodos que levaram as pessoas a alcançarem seus objetivos.',
          'Minimizar riscos com análises aprofundadas.',
          'Auxiliar outras pessoas na elaboração de planos claramente definidos.',
          'Comunicar detalhes de uma decisão importante.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Ansiosa para assumir o comando e fornecer direção clara.',
          'Tende a desafiar os outros com tarefas exigentes e altas expectativas.',
          'Fornece instruções de alto nível focadas no resultado final.',
          'Cria um ambiente de trabalho competitivo e dinâmico.',
        ],
        male: [
          'Ansioso para assumir o comando e fornecer direção clara.',
          'Tende a desafiar os outros com tarefas exigentes e altas expectativas.',
          'Fornece instruções de alto nível focadas no resultado final.',
          'Cria um ambiente de trabalho competitivo e dinâmico.',
        ],
      },
      pontosCegos: {
        female: [
          'Seu ambiente de trabalho pode se tornar excessivamente competitivo ou agressivo para as pessoas mais reservadas.',
          'Pode tumultuar o ambiente ao tentar resolver conflitos imediatamente com discussões verbais diretas.',
          'Seu ritmo de trabalho pode ser rápido demais para que os membros da equipe completem suas tarefas com a qualidade que consideram adequada.',
          'Pode não permitir tempo flexível suficiente para os membros da equipe se conhecerem bem e construírem confiança.',
          'Pode realizar mudanças repentinas, sem comunicar previamente à equipe ou oferecer tempo adequado para preparação.',
        ],
        male: [
          'O seu ambiente de trabalho pode se tornar excessivamente competitivo ou agressivo para as pessoas mais reservadas.',
          'Pode tumultuar o ambiente ao tentar resolver conflitos imediatamente com discussões verbais diretas.',
          'Seu ritmo de trabalho pode ser rápido demais para que os membros da equipe completem suas tarefas com a qualidade que consideram adequada.',
          'Pode não permitir tempo flexível suficiente para os membros da equipe se conhecerem bem e construírem confiança.',
          'Pode realizar mudanças repentinas, sem comunicar previamente à equipe ou oferecer tempo adequado para preparação.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Demonstração de coragem ao desafiar o status quo.',
          'Determinação em enfrentar o que está estabelecido.',
          'Deixa as pessoas à vontade, trazendo alegria e descontração.',
          'Sente-se confortável em navegar no mundo dos sonhos e possibilidades.',
        ],
      },
      dificuldades: {
        neutral: [
          'Dificuldade em receber críticas, devido à convicção de que está sempre certo, o que pode limitar o processo de aprendizado.',
          'Propensão ao autoritarismo, o que pode inibir a participação e criatividade dos outros membros da equipe.',
          'Pode apresentar entusiasmo excessivo.',
          'Pode fazer o grupo perder o foco.',
        ],
      },
    },
  },
  Id: {
    resumo: {
      female: '<p>Sua energia e espírito aventureiro se destacam em cada interação. Com uma abordagem de comunicação casual e afirmações audaciosas, você possui um jeito descontraído e acessível de se relacionar com as pessoas, encarando o desafio de fazer novas amizades com entusiasmo. Reconhecida por sua habilidade social, criatividade e carisma inegáveis, você cativa a todos com seu modo expressivo e emocional, inspirando-os frequentemente a agir.</p>',
      male: '<p>Sua energia e espírito aventureiro se destacam em cada interação. Com uma abordagem de comunicação casual e afirmações audaciosas, você possui um jeito descontraído e acessível de se relacionar com as pessoas, encarando o desafio de fazer novas amizades com entusiasmo. Reconhecido por sua habilidade social, criatividade e carisma inegáveis, você cativa a todos com seu modo expressivo e emocional, inspirando-os frequentemente a agir.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você gosta do desafio de conhecer novas pessoas.',
        'Aborda pessoas e situações de maneira enérgica e animada.',
        'Envolve os outros com histórias.',
        'Usa carisma para unir as pessoas, construir rapport (entrosamento) e compartilhar ideias.',
        'Discute ideias amplas e possibilidades futuras.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Usa um tom casual e assertivo. Gosta de falas com entusiasmo e narrativas.</p>',
      },
      reunioes: {
        neutral: '<p>Devem ser presenciais, quando possível, sem uma agenda específica.</p>',
      },
      emails: {
        neutral: '<p>Devem ser casuais, breves e incluir as informações relevantes.</p>',
      },
      feedback: {
        neutral: '<p>Gosta quando se concentram na visão geral e são entregues com incentivo.</p>',
      },
      conflitos: {
        neutral: '<p>Enxerga os conflitos como oportunidades produtivas e valiosas, apreciando a chance de debater questões para descobrir soluções inovadoras.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você confia na intuição e na capacidade de improvisar.',
          'Usa linguagem positiva e entusiasmada ao motivar os outros.',
          'Identifica rapidamente novas oportunidades de avanço.',
          'Resolve problemas envolvendo outros em brainstorming e discussão aberta.',
          'Delega responsabilidade por tarefas detalhadas.',
          'Traz energia e um senso de aventura para a equipe.',
          'Prioriza as interações pessoais e os relacionamentos.',
          'Cria soluções inovadoras para problemas desafiadores.',
        ],
        male: [
          'Você confia na intuição e na capacidade de improvisar.',
          'Usa linguagem positiva e entusiasmada ao motivar os outros.',
          'Identifica rapidamente novas oportunidades de avanço.',
          'Resolve problemas envolvendo outros em brainstorming e discussão aberta.',
          'Delega responsabilidade por tarefas detalhadas.',
          'Traz energia e um senso de aventura para a equipe.',
          'Prioriza as interações pessoais e os relacionamentos.',
          'Cria soluções inovadoras para problemas desafiadores.',
        ],
      },
      cegos: {
        female: [
          'Dificuldade em seguir rotinas previsíveis.',
          'Falha por não avaliar problemas de forma realista devido a expectativas excessivamente otimistas.',
          'Persegue muitas ideias novas simultaneamente.',
          'Tenta controlar todos os resultados.',
          'Distancia-se demais dos detalhes de projetos importantes.',
          'Cria um ambiente muito flexível para pessoas que precisam de uma abordagem estruturada para trabalhar.',
          'Tem dificuldade de limitar o tempo gasto interagindo com as pessoas.',
        ],
        male: [
          'Dificuldade em seguir rotinas previsíveis.',
          'Falha por não avaliar problemas de forma realista devido a expectativas excessivamente otimistas.',
          'Persegue muitas ideias novas simultaneamente.',
          'Tenta controlar todos os resultados.',
          'Distancia-se demais dos detalhes de projetos importantes.',
          'Cria um ambiente muito flexível para pessoas que precisam de uma abordagem estruturada para trabalhar.',
          'Tem dificuldade de limitar o tempo gasto interagindo com as pessoas.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Encontrar novas oportunidades sem muita orientação.',
          'Interagir regularmente com um grupo grande e diversificado de pessoas.',
          'Cultivar novos relacionamentos e convencer os outros.',
          'Explorar várias ideias simultaneamente.',
          'Atribuir tarefas analíticas a outras pessoas.',
          'Pensar rapidamente e descobrir as coisas à medida que avançam.',
          'Participar de discussões em grupo e sessões de brainstorming.',
          'Dedicar tempo para entender como outra pessoa pensa.',
          'Fornecer encorajamento verbal e contar histórias.',
        ],
        male: [
          'Encontrar novas oportunidades sem muita orientação.',
          'Interagir regularmente com um grupo grande e diversificado de pessoas.',
          'Cultivar novos relacionamentos e convencer os outros.',
          'Explorar várias ideias simultaneamente.',
          'Atribuir tarefas analíticas a outras pessoas.',
          'Pensar rapidamente e descobrir as coisas à medida que avançam.',
          'Participar de discussões em grupo e sessões de brainstorming.',
          'Dedicar tempo para entender como outra pessoa pensa.',
          'Fornecer encorajamento verbal e contar histórias.',
        ],
      },
      drena: {
        female: [
          'Considerar todos os aspectos de uma decisão-chave.',
          'Seguir procedimentos e rotinas.',
          'Levar tempo para pensar em um problema antes de tomar uma decisão final.',
          'Vistoriar e manter resultados de alta qualidade.',
          'Gastar muito tempo pesquisando a causa raiz de um problema.',
          'Fazer frequentemente perguntas sobre os fatos e esclarecedoras.',
          'Fornecer instruções claras e passo a passo.',
          'Comunicar-se principalmente por escrito.',
          'Minimizar riscos com estrutura, redundância e análise.',
        ],
        male: [
          'Considerar todos os aspectos de uma decisão-chave.',
          'Seguir procedimentos e rotinas.',
          'Levar tempo para pensar em um problema antes de tomar uma decisão final.',
          'Vistoriar e manter resultados de alta qualidade.',
          'Gastar muito tempo pesquisando a causa raiz de um problema.',
          'Fazer frequentemente perguntas sobre os fatos e esclarecedoras.',
          'Fornecer instruções claras e passo a passo.',
          'Comunicar-se principalmente por escrito.',
          'Minimizar riscos com estrutura, redundância e análise.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Cria um ambiente de trabalho descontraído e extrovertido.',
          'Focada em inspirar os outros com uma visão ousada do futuro.',
          'Mais confortável em entregar mensagens importantes verbalmente, por meio de reuniões em grupo.',
          'Dá aos outros autonomia para encontrar suas próprias soluções para problemas.',
        ],
        male: [
          'Cria um ambiente de trabalho descontraído e extrovertido.',
          'Focado em inspirar os outros com uma visão ousada do futuro.',
          'Mais confortável em entregar mensagens importantes verbalmente, por meio de reuniões em grupo.',
          'Dá aos outros autonomia para encontrar suas próprias soluções para problemas.',
        ],
      },
      pontosCegos: {
        female: [
          'Pode não manter anotações e documentação detalhadas para os membros da equipe consultarem.',
          'Pode ter uma atitude relaxada em relação aos riscos, sem considerar cuidadosamente os custos e consequências de decisões importantes.',
          'Pode não dedicar tempo suficiente para a equipe analisar os detalhes de um problema antes de pular para soluções.',
          'Pode não detectar ou procurar problemas ocultos antes que se tornem óbvios.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
        ],
        male: [
          'Pode não manter anotações e documentação detalhadas para os membros da equipe consultarem.',
          'Pode ter uma atitude relaxada em relação aos riscos, sem considerar cuidadosamente os custos e consequências de decisões importantes.',
          'Pode não dedicar tempo suficiente para a equipe analisar os detalhes de um problema antes de pular para soluções.',
          'Pode não detectar ou procurar problemas ocultos antes que se tornem óbvios.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Estimula a troca de ideias do grupo.',
          'Deixa as pessoas à vontade, trazendo alegria e descontração.',
          'Sente-se confortável em navegar no mundo dos sonhos e possibilidades.',
          'Demonstração de coragem ao desafiar o status quo.',
        ],
      },
      dificuldades: {
        neutral: [
          'Pode fazer o grupo perder o foco.',
          'Pode ter dificuldade de ficar em silêncio para ouvir seus próprios pensamentos e refletir isoladamente.',
          'Pode apresentar entusiasmo excessivo.',
          'Dificuldade para ouvir críticas.',
        ],
      },
    },
  },
  I: {
    resumo: {
      female: '<p>Você é uma pessoa entusiasmada! Irradia alegria e extroversão. Sua interação é natural, descontraída e casual.</p><p>Sua abertura e facilidade de abordagem tornam você uma presença frequente em interações sociais. Comunicar-se é algo que aprecia, e suas preferências incluem atividades diárias que envolvem interações.</p>',
      male: '<p>Você é uma pessoa entusiasmada! Irradia alegria e extroversão. Sua interação é natural, descontraída e casual.</p><p>Sua abertura e facilidade de abordagem tornam você uma presença frequente em interações sociais. Comunicar-se é algo que aprecia, e suas preferências incluem atividades diárias que envolvem interações.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você valoriza a interação com os outros.',
        'Gosta do desafio de conhecer novas pessoas.',
        'Percebe rapidamente os outros e faz com que se sintam confortáveis em novos grupos.',
        'Constrói rapport (entrosamento) facilmente ao conhecer alguém novo.',
        'Valoriza os encontros e garante a alegria dos ambientes.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta do tom descontraído, com humor e compartilhamento de histórias pessoais, descrevendo situações passadas de forma engraçada.</p>',
      },
      reunioes: {
        neutral: '<p>Presenciais sempre que possível e nada de agendas muito engessadas.</p>',
      },
      emails: {
        neutral: '<p>Texto amigável e descontraído.</p>',
      },
      feedback: {
        neutral: '<p>Valoriza os feedbacks mais amplos (sem muita especificidade) e sempre com incentivo.</p>',
      },
      conflitos: {
        neutral: '<p>Gosta de abordar os conflitos de forma ponderada, evitando discussões desnecessárias ou que não levem a soluções.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você prioriza relacionamentos e interações pessoais.',
          'Facilita brainstorming em grupo para encontrar soluções para problemas.',
          'Oferece muitos incentivos verbais ao desenvolver os outros.',
          'Comunica-se de maneira espontânea e expressiva emocionalmente.',
          'Improvisa rapidamente com base na intuição.',
          'Usa horários flexíveis e abordagens abertas para gerenciamento de tempo.',
          'Entende como motivar outras pessoas a agir.',
          'Traz energia e um toque de diversão para a equipe.',
        ],
        male: [
          'Você prioriza relacionamentos e interações pessoais.',
          'Facilita brainstorming em grupo para encontrar soluções para problemas.',
          'Oferece muitos incentivos verbais ao desenvolver os outros.',
          'Comunica-se de maneira espontânea e expressiva emocionalmente.',
          'Improvisa rapidamente com base na intuição.',
          'Usa horários flexíveis e abordagens abertas para gerenciamento de tempo.',
          'Entende como motivar outras pessoas a agir.',
          'Traz energia e um toque de diversão para a equipe.',
        ],
      },
      cegos: {
        female: [
          'Pode ser excessivamente otimista em relação a pessoas ou situações.',
          'Tende a gastar mais tempo interagindo com pessoas do que concluindo tarefas.',
          'Tem dificuldade em seguir rotinas consistentes e previsíveis.',
          'Confia em intuições quando mais planejamento é necessário.',
          'Fornece estrutura insuficiente para pessoas que precisam de uma abordagem definida para o trabalho.',
          'Se distrai com as ideias novas e tem dificuldade em manter o foco.',
          'Evita decisões que possam resultar em reprovação ou criar uma má impressão.',
        ],
        male: [
          'Pode ser excessivamente otimista em relação a pessoas ou situações.',
          'Tende a gastar mais tempo interagindo com pessoas do que concluindo tarefas.',
          'Tem dificuldade em seguir rotinas consistentes e previsíveis.',
          'Confia em intuições quando mais planejamento é necessário.',
          'Fornece estrutura insuficiente para pessoas que precisam de uma abordagem definida para o trabalho.',
          'Se distrai com as ideias novas e tem dificuldade em manter o foco.',
          'Evita decisões que possam resultar em reprovação ou criar uma má impressão.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Interagir frequentemente com um grupo diversificado de pessoas.',
          'Participar de discussões em grupo e sessões de brainstorming.',
          'Incentivar os outros e compartilhar histórias.',
          'Partir para novas aventuras e buscar oportunidades abstratas.',
          'Considerar como as outras pessoas pensam.',
          'Alternar entre várias ideias ao mesmo tempo.',
          'Manter a agenda aberta e flexível para reuniões espontâneas ao longo do dia.',
          'Resolver problemas enquanto pensa rapidamente.',
          'Explicar as coisas com linguagem emocional e expressiva.',
        ],
        male: [
          'Interagir frequentemente com um grupo diversificado de pessoas.',
          'Participar de discussões em grupo e sessões de brainstorming.',
          'Incentivar os outros e compartilhar histórias.',
          'Partir para novas aventuras e buscar oportunidades abstratas.',
          'Considerar como as outras pessoas pensam.',
          'Alternar entre várias ideias ao mesmo tempo.',
          'Manter a agenda aberta e flexível para reuniões espontâneas ao longo do dia.',
          'Resolver problemas enquanto pensa rapidamente.',
          'Explicar as coisas com linguagem emocional e expressiva.',
        ],
      },
      drena: {
        female: [
          'Resolver problemas com análises detalhadas dos dados existentes.',
          'Passar muito tempo pesquisando a causa raiz de um problema.',
          'Criar procedimentos, regras e diretrizes para que outras pessoas sigam.',
          'Ajudar os outros a se tornarem mais metódicos e eficientes em seus processos.',
          'Esclarecer fatos fazendo perguntas específicas.',
          'Tirar um tempo para pensar em um problema antes de tomar uma decisão final.',
          'Usar a escrita como o principal meio de comunicação.',
          'Trabalhar sozinha em projetos que representem o grupo.',
          'Inspecionar e manter resultados de alta qualidade.',
        ],
        male: [
          'Resolver problemas com análises detalhadas dos dados existentes.',
          'Passar muito tempo pesquisando a causa raiz de um problema.',
          'Criar procedimentos, regras e diretrizes para que outras pessoas sigam.',
          'Ajudar os outros a se tornarem mais metódicos e eficientes em seus processos.',
          'Esclarecer fatos fazendo perguntas específicas.',
          'Tirar um tempo para pensar em um problema antes de tomar uma decisão final.',
          'Usar a escrita como o principal meio de comunicação.',
          'Trabalhar sozinho em projetos que representem o grupo.',
          'Inspecionar e manter resultados de alta qualidade.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você cria um ambiente de trabalho leve, descontraído e extrovertido.',
          'É focada em inspirar os outros com uma visão ousada do futuro.',
          'Você fica mais confortável em entregar mensagens importantes verbalmente, por meio de reuniões em grupo.',
          'Tem facilidade em dar autonomia para as pessoas buscarem suas próprias soluções para os desafios do time.',
        ],
        male: [
          'Você cria um ambiente de trabalho leve, descontraído e extrovertido.',
          'É focado em inspirar os outros com uma visão ousada do futuro.',
          'Você fica mais confortável em entregar mensagens importantes verbalmente, por meio de reuniões em grupo.',
          'Tem facilidade em dar autonomia para as pessoas buscarem suas próprias soluções para os desafios do time.',
        ],
      },
      pontosCegos: {
        female: [
          'Tem dificuldade em manter anotações e documentação detalhadas para a equipe consultar.',
          'Pode ter uma atitude relaxada em relação aos riscos, sem considerar cuidadosamente os custos e consequências de decisões importantes.',
          'Pode sentir-se ansiosa e não dar tempo suficiente para a equipe analisar os detalhes de um problema antes de pular para soluções.',
          'Pode não perceber problemas latentes antes que se tornem óbvios e complexos.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
        ],
        male: [
          'Tem dificuldade em manter anotações e documentação detalhadas para a equipe consultar.',
          'Pode ter uma atitude relaxada em relação aos riscos, sem considerar cuidadosamente os custos e consequências de decisões importantes.',
          'Pode sentir-se ansioso e não dar tempo suficiente para a equipe analisar os detalhes de um problema antes de pular para soluções.',
          'Pode não perceber problemas latentes antes que se tornem óbvios e complexos.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Contribui para um clima favorável aos bons relacionamentos.',
          'Estimula a troca de ideias do grupo.',
          'Deixa as pessoas à vontade, trazendo alegria e descontração.',
          'Sente-se confortável em navegar no mundo dos sonhos e possibilidades.',
        ],
      },
      dificuldades: {
        neutral: [
          'Pode ter dificuldade em ficar em silêncio, ouvir os próprios pensamentos e refletir isoladamente.',
          'Pode dar pouca importância ao planejamento e estudo de viabilidade das novas ideias.',
          'Pode apresentar entusiasmo excessivo.',
          'Pode fazer o grupo perder o foco.',
        ],
      },
    },
  },
  Is: {
    resumo: {
      female: '<p>Você é uma pessoa calorosa e descontraída. Costuma ser positiva e alegre, e valoriza estar sempre rodeada de muitos amigos.</p><p>É habilidosa em reconhecer os pontos fortes das pessoas e mantém uma atitude amigável que a torna rapidamente querida por todos ao seu redor. Por isso, prefere interações leves e descontraídas, onde se sente mais à vontade.</p>',
      male: '<p>Você é uma pessoa calorosa e descontraída. Costuma ser positivo e alegre, e valoriza estar sempre rodeado de muitos amigos.</p><p>É habilidoso em reconhecer os pontos fortes das pessoas e mantém uma atitude amigável que o torna rapidamente querido por todos ao seu redor. Por isso, prefere interações leves e descontraídas, onde se sente mais à vontade.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você proporciona aos outros um senso de pertencimento e aceitação.',
        'Gosta de interagir com as pessoas.',
        'Tem uma abordagem extrovertida e descontraída, vivendo a vida conforme ela se desenrola.',
        'Evita críticas e confrontos.',
        'Ajuda os outros a se sentirem rapidamente à vontade em novos grupos.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta de um tom amigável e conciliador, com expressão emocional e assuntos pessoais.</p>',
      },
      reunioes: {
        neutral: '<p>Gosta dos encontros presenciais ou das câmeras abertas, sem ficar preso em pautas específicas.</p>',
      },
      emails: {
        neutral: '<p>Devem ser leves, amigáveis e não muito sérios.</p>',
      },
      feedback: {
        neutral: '<p>Devem ser minuciosamente explicados e entregues de maneira positiva.</p>',
      },
      conflitos: {
        neutral: '<p>Devem se concentrar na busca por novas soluções, e abordados com cuidado para não prejudicar os relacionamentos.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você desenvolve as pessoas com incentivo verbal.',
          'Prioriza as interações e relacionamentos pessoais.',
          'Motiva as pessoas a agir, mesmo que estejam nervosas.',
          'Avalia as capacidades dos outros de forma otimista.',
          'Traz energia positiva e calor para uma equipe.',
          'Comunica-se frequentemente e de forma regular.',
          'Usa linguagem casual e amigável com os colegas.',
          'Busca a experiência e as ideias dos outros ao resolver problemas.',
        ],
        male: [
          'Você desenvolve as pessoas com incentivo verbal.',
          'Prioriza as interações e relacionamentos pessoais.',
          'Motiva as pessoas a agir, mesmo que estejam nervosas.',
          'Avalia as capacidades dos outros de forma otimista.',
          'Traz energia positiva e calor para uma equipe.',
          'Comunica-se frequentemente e de forma regular.',
          'Usa linguagem casual e amigável com os colegas.',
          'Busca a experiência e as ideias dos outros ao resolver problemas.',
        ],
      },
      cegos: {
        female: [
          'Confia demais em intuições quando é necessário um planejamento detalhado.',
          'Perde a objetividade ao se tornar amigável e próxima das pessoas.',
          'Não avalia os problemas de forma realista devido às expectativas otimistas em relação às pessoas ou situações.',
          'Está excessivamente focada em não perder a aprovação dos outros.',
          'Evita tarefas importantes para dedicar mais tempo às pessoas.',
          'Tenta agradar pessoas que estão resistindo ou discutindo.',
          'Tem dificuldade com rotinas consistentes e estáveis que podem não ser suficientemente estimulantes.',
          'Aborda problemas de maneira pessoal e emocional, o que pode atrapalhar a tomada de decisão racional.',
        ],
        male: [
          'Confia demais em intuições quando é necessário um planejamento detalhado.',
          'Perde a objetividade ao se tornar amigável e próximo das pessoas.',
          'Não avalia os problemas de forma realista devido às expectativas otimistas em relação às pessoas ou situações.',
          'Está excessivamente focado em não perder a aprovação dos outros.',
          'Evita tarefas importantes para dedicar mais tempo às pessoas.',
          'Tenta agradar pessoas que estão resistindo ou discutindo.',
          'Tem dificuldade com rotinas consistentes e estáveis que podem não ser suficientemente estimulantes.',
          'Aborda problemas de maneira pessoal e emocional, o que pode atrapalhar a tomada de decisão racional.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Colaborar regularmente ao lado de outras pessoas, em vez de trabalhar sozinho.',
          'Partir para novas aventuras e buscar oportunidades intangíveis.',
          'Compreender e explicar o impacto humano em decisões organizacionais.',
          'Usar narrativas expressivas e emocionais para tornar uma história interessante.',
          'Fornecer encorajamento verbal e contar histórias.',
          'Ensinar, orientar e aconselhar outras pessoas.',
          'Discutir questões em grupo e fazer brainstorming com os outros.',
          'Alternar entre várias ideias simultaneamente.',
        ],
        male: [
          'Colaborar regularmente ao lado de outras pessoas, em vez de trabalhar sozinho.',
          'Partir para novas aventuras e buscar oportunidades intangíveis.',
          'Compreender e explicar o impacto humano em decisões organizacionais.',
          'Usar narrativas expressivas e emocionais para tornar uma história interessante.',
          'Fornecer encorajamento verbal e contar histórias.',
          'Ensinar, orientar e aconselhar outras pessoas.',
          'Discutir questões em grupo e fazer brainstorming com os outros.',
          'Alternar entre várias ideias simultaneamente.',
        ],
      },
      drena: {
        female: [
          'Definir diretrizes e regras claras para os outros.',
          'Desenvolver processos mais eficientes.',
          'Assumir a responsabilidade principal pelos prazos.',
          'Gastar muito tempo pesquisando a causa raiz de um problema.',
          'Orientar os outros focando no que precisa ser feito e no prazo de entrega.',
          'Resolver problemas com uma análise detalhada dos dados existentes.',
          'Fazer trabalhos independentes e relatar os resultados.',
          'Navegar em sistemas grandes e complexos.',
        ],
        male: [
          'Definir diretrizes e regras claras para os outros.',
          'Desenvolver processos mais eficientes.',
          'Assumir a responsabilidade principal pelos prazos.',
          'Gastar muito tempo pesquisando a causa raiz de um problema.',
          'Orientar os outros focando no que precisa ser feito e no prazo de entrega.',
          'Resolver problemas com uma análise detalhada dos dados existentes.',
          'Fazer trabalhos independentes e relatar os resultados.',
          'Navegar em sistemas grandes e complexos.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você cria um ambiente de trabalho descontraído e extrovertido.',
          'Focada em inspirar os outros com uma visão ousada do futuro.',
          'Mais confortável em entregar mensagens importantes verbalmente, por meio de reuniões em grupo.',
          'Dá aos outros autonomia para encontrar suas próprias soluções para problemas.',
        ],
        male: [
          'Você cria um ambiente de trabalho descontraído e extrovertido.',
          'Focado em inspirar os outros com uma visão ousada do futuro.',
          'Mais confortável em entregar mensagens importantes verbalmente, por meio de reuniões em grupo.',
          'Dá aos outros autonomia para encontrar suas próprias soluções para problemas.',
        ],
      },
      pontosCegos: {
        female: [
          'Tem dificuldade em manter anotações e documentação detalhadas para a equipe consultar.',
          'Pode ter uma atitude relaxada em relação aos riscos, sem considerar cuidadosamente os custos e consequências de decisões importantes.',
          'Pode sentir-se ansiosa e não dar tempo suficiente para a equipe analisar os detalhes de um problema antes de pular para soluções.',
          'Pode não perceber problemas latentes antes que se tornem óbvios e complexos.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
        ],
        male: [
          'Tem dificuldade em manter anotações e documentação detalhadas para a equipe consultar.',
          'Pode ter uma atitude relaxada em relação aos riscos, sem considerar cuidadosamente os custos e consequências de decisões importantes.',
          'Pode sentir-se ansioso e não dar tempo suficiente para a equipe analisar os detalhes de um problema antes de pular para soluções.',
          'Pode não perceber problemas latentes antes que se tornem óbvios e complexos.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Deixa as pessoas à vontade, trazendo alegria e descontração.',
          'Sente-se confortável em navegar no mundo dos sonhos e possibilidades.',
          'Estimula a troca de ideias do grupo.',
          'Organização das ideias do grupo.',
        ],
      },
      dificuldades: {
        neutral: [
          'Pode apresentar entusiasmo excessivo.',
          'Pode fazer o grupo perder o foco.',
          'Pode ter dificuldade de ficar em silêncio para ouvir seus próprios pensamentos e refletir isoladamente.',
          'Pode seguir uma ideia só para evitar confronto.',
        ],
      },
    },
  },
  IS: {
    resumo: {
      female: '<p>Você irradia calor e hospitalidade, mantendo uma atitude positiva e alegre. Desfruta da companhia de muitos amigos.</p><p>Encara o mundo com uma perspectiva aberta, confiante e amigável. Embora evite conflitos quando as coisas ficam difíceis, você tem grande facilidade em fazer com que os outros se sintam compreendidos e aceitos como são.</p>',
      male: '<p>Você irradia calor e hospitalidade, mantendo uma atitude positiva e alegre. Desfruta da companhia de muitos amigos.</p><p>Encara o mundo com uma perspectiva aberta, confiante e amigável. Embora evite conflitos quando as coisas ficam difíceis, você tem grande facilidade em fazer com que os outros se sintam compreendidos e aceitos como são.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você mostra habilidade para se adaptar às situações à medida que se apresentam.',
        'Fomenta um ambiente inclusivo, onde todos se sintam integrados.',
        'Desfruta genuinamente do convívio com outras pessoas.',
        'Demonstra facilidade em se ajustar a indivíduos com características diferentes das suas.',
        'Prefere evitar conflitos.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta de mais expressão emocional e atenção sobre como as pessoas estão se sentindo.</p>',
      },
      reunioes: {
        neutral: '<p>Gosta dos encontros presenciais ou das câmeras abertas, sempre com ambientes leves e descontraídos.</p>',
      },
      emails: {
        neutral: '<p>Devem ser amigáveis, informais e pessoais.</p>',
      },
      feedback: {
        neutral: '<p>Com explicações minuciosas e entregues com encorajamento.</p>',
      },
      conflitos: {
        neutral: '<p>Devem ser abordados com paciência e cuidado para não prejudicar os relacionamentos.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você avalia as capacidades dos outros de forma otimista.',
          'Comunica de forma informal, misturando conversas pessoais com discussões de negócios.',
          'Mantém comunicação frequente para manter todos bem informados.',
          'Aborda a resolução de problemas de um ponto de vista pessoal ou emocional.',
          'É amigável e acolhedora com os colegas de trabalho.',
          'Inclui as pessoas em discussões sobre como as coisas serão realizadas.',
          'Considera o impacto sobre outras pessoas ao tomar decisões.',
          'Busca orientação de outros ao enfrentar desafios.',
        ],
        male: [
          'Você avalia as capacidades dos outros de forma otimista.',
          'Comunica de forma informal, misturando conversas pessoais com discussões de negócios.',
          'Mantém comunicação frequente para manter todos bem informados.',
          'Aborda a resolução de problemas de um ponto de vista pessoal ou emocional.',
          'É amigável e acolhedor com os colegas de trabalho.',
          'Inclui as pessoas em discussões sobre como as coisas serão realizadas.',
          'Considera o impacto sobre outras pessoas ao tomar decisões.',
          'Busca orientação de outros ao enfrentar desafios.',
        ],
      },
      cegos: {
        female: [
          'Tende a ceder facilmente a pessoas que são argumentativas.',
          'Adia decisões que podem impactar negativamente as pessoas.',
          'Tende a desenvolver muita proximidade com as pessoas, o que às vezes dificulta avaliá-las de forma objetiva.',
          'É otimista de forma irrealista ao considerar grandes problemas.',
          'Evita decisões que possam resultar em reprovação ou criar uma má impressão.',
          'Sente desconforto ao se comunicar com indivíduos hostis ou agressivos.',
          'Minimiza feedback negativo, deixando outros sem uma compreensão clara do problema.',
          'Opta por corrigir ou refazer o trabalho em vez de confrontar alguém que se torna hostil.',
        ],
        male: [
          'Tende a ceder facilmente a pessoas que são argumentativas.',
          'Adia decisões que podem impactar negativamente as pessoas.',
          'Tende a desenvolver muita proximidade com as pessoas, o que às vezes dificulta avaliá-las de forma objetiva.',
          'É otimista de forma irrealista ao considerar grandes problemas.',
          'Evita decisões que possam resultar em reprovação ou criar uma má impressão.',
          'Sente desconforto ao se comunicar com indivíduos hostis ou agressivos.',
          'Minimiza feedback negativo, deixando outros sem uma compreensão clara do problema.',
          'Opta por corrigir ou refazer o trabalho em vez de confrontar alguém que se torna hostil.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Colaborar com outras pessoas em vez de trabalhar sozinho.',
          'Orientar e aconselhar os outros em situações difíceis.',
          'Estar disponível para as necessidades pessoais e emocionais das outras pessoas.',
          'Usar a diplomacia e a abertura natural para resolver problemas.',
          'Participar de discussões em grupo e sessões de brainstorming.',
          'Enfatizar o impacto humano de uma decisão na organização.',
          'Fazer perguntas a colegas mais experientes para aprender como fazer algo.',
        ],
        male: [
          'Colaborar com outras pessoas em vez de trabalhar sozinho.',
          'Orientar e aconselhar os outros em situações difíceis.',
          'Estar disponível para as necessidades pessoais e emocionais das outras pessoas.',
          'Usar a diplomacia e a abertura natural para resolver problemas.',
          'Participar de discussões em grupo e sessões de brainstorming.',
          'Enfatizar o impacto humano de uma decisão na organização.',
          'Fazer perguntas a colegas mais experientes para aprender como fazer algo.',
        ],
      },
      drena: {
        female: [
          'Tentar fazer com que os outros sigam regras e procedimentos.',
          'Interagir de maneira mais reservada e centrada nos negócios.',
          'Monitorar e avaliar de perto os resultados obtidos.',
          'Tentar encontrar maneiras de tornar os processos mais eficientes.',
          'Dar feedback crítico aos outros sobre como melhorar o desempenho.',
          'Corrigir e mostrar aos outros como realizar as tarefas da maneira correta.',
          'Monitorar de perto o cronograma e garantir que um projeto siga um processo estruturado.',
          'Navegar em sistemas grandes e complexos com habilidade.',
        ],
        male: [
          'Tentar fazer com que os outros sigam regras e procedimentos.',
          'Interagir de maneira mais reservada e centrada nos negócios.',
          'Monitorar e avaliar de perto os resultados obtidos.',
          'Tentar encontrar maneiras de tornar os processos mais eficientes.',
          'Dar feedback crítico aos outros sobre como melhorar o desempenho.',
          'Corrigir e mostrar aos outros como realizar as tarefas da maneira correta.',
          'Monitorar de perto o cronograma e garantir que um projeto siga um processo estruturado.',
          'Navegar em sistemas grandes e complexos com habilidade.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você cria um ambiente de trabalho leve, descontraído e extrovertido.',
          'É focada em inspirar os outros com uma visão ousada do futuro.',
          'Você fica mais confortável em entregar mensagens importantes verbalmente, por meio de reuniões em grupo.',
          'Tem facilidade em dar autonomia para as pessoas buscarem suas próprias soluções para os desafios do time.',
        ],
        male: [
          'Você cria um ambiente de trabalho leve, descontraído e extrovertido.',
          'É focado em inspirar os outros com uma visão ousada do futuro.',
          'Você fica mais confortável em entregar mensagens importantes verbalmente, por meio de reuniões em grupo.',
          'Tem facilidade em dar autonomia para as pessoas buscarem suas próprias soluções para os desafios do time.',
        ],
      },
      pontosCegos: {
        female: [
          'Tem dificuldade em manter anotações e documentação detalhadas para a equipe consultar.',
          'Pode ter uma atitude relaxada em relação aos riscos, sem considerar cuidadosamente os custos e consequências de decisões importantes.',
          'Pode sentir-se ansiosa e não dar tempo suficiente para a equipe analisar os detalhes de um problema antes de pular para soluções.',
          'Pode não perceber problemas latentes antes que se tornem óbvios e complexos.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
        ],
        male: [
          'Tem dificuldade em manter anotações e documentação detalhadas para a equipe consultar.',
          'Pode ter uma atitude relaxada em relação aos riscos, sem considerar cuidadosamente os custos e consequências de decisões importantes.',
          'Pode sentir-se ansioso e não dar tempo suficiente para a equipe analisar os detalhes de um problema antes de pular para soluções.',
          'Pode não perceber problemas latentes antes que se tornem óbvios e complexos.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Paciente para ouvir as ideias dos outros.',
          'Organização das ideias do grupo.',
          'Deixa as pessoas à vontade, trazendo alegria e descontração.',
          'Sente-se confortável em navegar no mundo dos sonhos e possibilidades.',
        ],
      },
      dificuldades: {
        neutral: [
          'Pode levar muito tempo para se convencer da necessidade de uma inovação.',
          'Pode seguir uma ideia só para evitar confronto.',
          'Pode apresentar entusiasmo excessivo.',
          'Pode fazer o grupo perder o foco.',
        ],
      },
    },
  },
  Si: {
    resumo: {
      female: '<p>Você se destaca por sua empatia e solidariedade, sempre buscando ajudar os outros com sinceridade e frequência. A forma como você escuta com atenção e compreensão faz com que as pessoas se sintam cuidadas. Suas respostas, sempre atenciosas e reconfortantes, contribuem para um ambiente acolhedor, onde todos se sentem à vontade para compartilhar seus sentimentos sem receio de constrangimento ou rejeição.</p>',
      male: '<p>Você se destaca por sua empatia e solidariedade, sempre buscando ajudar os outros com sinceridade e frequência. A forma como você escuta com atenção e compreensão faz com que as pessoas se sintam cuidadas. Suas respostas, sempre atenciosas e reconfortantes, contribuem para um ambiente acolhedor, onde todos se sentem à vontade para compartilhar seus sentimentos sem receio de constrangimento ou rejeição.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você expressa cuidado e compreensão para com as pessoas.',
        'Ajuda e apoia as pessoas em seus objetivos.',
        'Adapta-se a situações difíceis.',
        'Reduz conflitos com os outros.',
        'Ajusta-se facilmente ao estilo das pessoas.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta de um tom amigável e caloroso. Prefere se relacionar pessoalmente e aborda opiniões divergentes apenas depois de construir confiança.</p>',
      },
      reunioes: {
        neutral: '<p>Prefere reuniões presenciais, com pauta específica e organizada previamente.</p>',
      },
      emails: {
        neutral: '<p>Gosta de e-mails calorosos, sinceros e expressivos.</p>',
      },
      feedback: {
        neutral: '<p>Devem ser com explicações cuidadosas e entregues com empatia.</p>',
      },
      conflitos: {
        neutral: '<p>Devem ser abordados diplomaticamente, permitindo que todos os lados expressem suas opiniões e ouçam os outros.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você promove os benefícios do trabalho em equipe e coopera motivando todos.',
          'Proporciona uma presença tranquilizadora e estabilizadora para as pessoas em situações difíceis.',
          'Constrói relacionamentos de confiança, proporcionando previsibilidade e lealdade.',
          'Inclui as pessoas compartilhando suas experiências ao resolver problemas.',
          'Resolve conflitos com diplomacia e sensibilidade.',
        ],
        male: [
          'Você promove os benefícios do trabalho em equipe e coopera motivando todos.',
          'Proporciona uma presença tranquilizadora e estabilizadora para as pessoas em situações difíceis.',
          'Constrói relacionamentos de confiança, proporcionando previsibilidade e lealdade.',
          'Inclui as pessoas compartilhando suas experiências ao resolver problemas.',
          'Resolve conflitos com diplomacia e sensibilidade.',
        ],
      },
      cegos: {
        female: [
          'Pode faltar com firmeza e assertividade quando necessário.',
          'Sente desconforto ao lidar com pessoas agressivas.',
          'Tem dificuldade em se desvincular e ser produtivo em situações carregadas emocionalmente.',
          'Mostra desconforto ao gerenciar pessoas que resistem ativamente a uma supervisão próxima.',
          'Reage emocionalmente em vez de objetivamente a feedbacks críticos.',
        ],
        male: [
          'Pode faltar com firmeza e assertividade quando necessário.',
          'Sente desconforto ao lidar com pessoas agressivas.',
          'Tem dificuldade em se desvincular e ser produtivo em situações carregadas emocionalmente.',
          'Mostra desconforto ao gerenciar pessoas que resistem ativamente a uma supervisão próxima.',
          'Reage emocionalmente em vez de objetivamente a feedbacks críticos.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Construir confiança e lealdade a longo prazo com um comportamento consistente e previsível.',
          'Perguntar a outras pessoas como se sentem sobre uma potencial mudança.',
          'Aconselhar pessoas enquanto lidam com um desafio.',
          'Prestar atenção às necessidades e preocupações de outras pessoas.',
          'Resolver problemas com diplomacia e abertura.',
        ],
        male: [
          'Construir confiança e lealdade a longo prazo com um comportamento consistente e previsível.',
          'Perguntar a outras pessoas como se sentem sobre uma potencial mudança.',
          'Aconselhar pessoas enquanto lidam com um desafio.',
          'Prestar atenção às necessidades e preocupações de outras pessoas.',
          'Resolver problemas com diplomacia e abertura.',
        ],
      },
      drena: {
        female: [
          'Comunicação muito direta ou franca.',
          'Considerar muitos fatores para tomar decisões.',
          'Tomar decisões rapidamente com dados limitados.',
          'Usar uma abordagem assertiva para dirigir e desenvolver outros.',
          'Questionar criticamente práticas e procedimentos existentes.',
          'Assumir a responsabilidade principal sobre processos e cronogramas.',
        ],
        male: [
          'Comunicação muito direta ou franca.',
          'Considerar muitos fatores para tomar decisões.',
          'Tomar decisões rapidamente com dados limitados.',
          'Usar uma abordagem assertiva para dirigir e desenvolver outros.',
          'Questionar criticamente práticas e procedimentos existentes.',
          'Assumir a responsabilidade principal sobre processos e cronogramas.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Lidera pelo exemplo.',
          'Cria um ambiente de trabalho tranquilo e calmo.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
          'Focada em desenvolver a equipe com orientação e instrução individualizada.',
        ],
        male: [
          'Lidera pelo exemplo.',
          'Cria um ambiente de trabalho tranquilo e calmo.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
          'Focado em desenvolver a equipe com orientação e instrução individualizada.',
        ],
      },
      pontosCegos: {
        female: [
          'Pode ser excessivamente permissiva em vez de responsabilizar os membros da equipe por prazos, qualidade e responsabilidades.',
          'Pode apenas ver o melhor nas pessoas quando mais ceticismo ou crítica é necessário.',
          'Sente dificuldade em tomar decisões quando muitas pessoas com diferentes interesses estão envolvidas.',
          'Pode permitir que conflitos interpessoais permaneçam ocultos sem trazê-los à tona.',
          'Pode perder boas oportunidades para o desenvolvimento e avanço da equipe devido à alta sensibilidade ao risco.',
        ],
        male: [
          'Pode ser excessivamente permissivo em vez de responsabilizar os membros da equipe por prazos, qualidade e responsabilidades.',
          'Pode apenas ver o melhor nas pessoas quando mais ceticismo ou crítica é necessário.',
          'Sente dificuldade em tomar decisões quando muitas pessoas com diferentes interesses estão envolvidas.',
          'Pode permitir que conflitos interpessoais permaneçam ocultos sem trazê-los à tona.',
          'Pode perder boas oportunidades para o desenvolvimento e avanço da equipe devido à alta sensibilidade ao risco.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Paciente para ouvir as ideias dos outros.',
          'Organização das ideias do grupo.',
          'Facilidade para se concentrar em uma atividade por um longo tempo.',
          'Sente-se confortável em navegar no mundo dos sonhos e possibilidades.',
        ],
      },
      dificuldades: {
        neutral: [
          'Pode levar muito tempo para se convencer da necessidade de uma inovação.',
          'Pode seguir uma ideia só para evitar confronto.',
          'Pode ter excesso de interdependência.',
          'Pode apresentar entusiasmo excessivo.',
        ],
      },
    },
  },
  S: {
    resumo: {
      female: '<p>Você tende a ser calma, paciente e respeitosa em suas interações. Raramente se irrita ou se empolga, e é provável que trabalhe para manter um ambiente pacífico e harmonioso.</p><p>Demonstra sua consideração e sinceridade ao ouvir e responder pacientemente às necessidades e pedidos dos outros. Em situações difíceis ou estressantes, consegue tratar as pessoas com empatia e respeito.</p>',
      male: '<p>Você tende a ser calmo, paciente e respeitoso em suas interações. Raramente se irrita ou se empolga, e é provável que trabalhe para manter um ambiente pacífico e harmonioso.</p><p>Demonstra sua consideração e sinceridade ao ouvir e responder pacientemente às necessidades e pedidos dos outros. Em situações difíceis ou estressantes, consegue tratar as pessoas com empatia e respeito.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você ouve pacientemente as necessidades e pedidos dos outros.',
        'Se compromete com o trabalho e colabora com todos.',
        'Sente profundo desconforto com pessoas agressivas ou hostis.',
        'Evita situações excessivamente competitivas.',
        'Segue as orientações de um líder de confiança.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Prefere um tom sereno, cordial e acolhedor. Valoriza quando prestam a atenção em seus sentimentos e fazem perguntas para compreender suas possíveis preocupações ou opiniões.</p>',
      },
      reunioes: {
        neutral: '<p>Devem ocorrer com agenda definida e serem presenciais, sempre que possível.</p>',
      },
      emails: {
        neutral: '<p>Devem transmitir calor, sinceridade e contexto. Gosta de mensagens mais amigáveis e serenas.</p>',
      },
      feedback: {
        neutral: '<p>Devem ser dados de forma bem pensada e com muita empatia.</p>',
      },
      conflitos: {
        neutral: '<p>Devem se tratados com cautela, pois os conflitos podem machucar as pessoas envolvidas.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você acompanha regularmente o time/projeto, sempre se mostrando disponível para ajudar.',
          'Responde a perguntas com paciência e compreensão.',
          'Solicita feedback em intervalos regulares.',
          'Está sempre atento às necessidades e preocupações das outras pessoas.',
        ],
        male: [
          'Você acompanha regularmente o time/projeto, sempre se mostrando disponível para ajudar.',
          'Responde a perguntas com paciência e compreensão.',
          'Solicita feedback em intervalos regulares.',
          'Está sempre atento às necessidades e preocupações das outras pessoas.',
        ],
      },
      cegos: {
        female: [
          'Não é direta ao comunicar informações negativas.',
          'Pode ser excessivamente passiva quando a assertividade é necessária.',
          'Evita confronto e falta com feedback quando outros precisam.',
          'Posterga decisões envolvendo conflitos interpessoais.',
        ],
        male: [
          'Não é direto ao comunicar informações negativas.',
          'Pode ser excessivamente passivo quando a assertividade é necessária.',
          'Evita confronto e falta com feedback quando outros precisam.',
          'Posterga decisões envolvendo conflitos interpessoais.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Pedir feedback regularmente.',
          'Desempenhar um papel de apoio na equipe e manter-se fora dos holofotes.',
          'Responder a situações difíceis com empatia e compaixão.',
          'Promover o trabalho em equipe e a cooperação entre as partes.',
          'Ouvir perguntas de outras pessoas e responder de maneira ponderada.',
        ],
        male: [
          'Pedir feedback regularmente.',
          'Desempenhar um papel de apoio na equipe e manter-se fora dos holofotes.',
          'Responder a situações difíceis com empatia e compaixão.',
          'Promover o trabalho em equipe e a cooperação entre as partes.',
          'Ouvir perguntas de outras pessoas e responder de maneira ponderada.',
        ],
      },
      drena: {
        female: [
          'Trabalhar frequentemente com prazos apertados.',
          'Tomar decisões em nome de outras pessoas sem muita participação do grupo.',
          'Delegar tarefas com grandes objetivos, em vez de instruções detalhadas.',
          'Engajar-se em competições com outros.',
          'Estar sob os holofotes ou no centro das atenções.',
        ],
        male: [
          'Trabalhar frequentemente com prazos apertados.',
          'Tomar decisões em nome de outras pessoas sem muita participação do grupo.',
          'Delegar tarefas com grandes objetivos, em vez de instruções detalhadas.',
          'Engajar-se em competições com outros.',
          'Estar sob os holofotes ou no centro das atenções.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você lidera pelo exemplo.',
          'Cria um ambiente de trabalho tranquilo e calmo.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
          'Focada em desenvolver a equipe com orientação e instrução individualizada.',
        ],
        male: [
          'Você lidera pelo exemplo.',
          'Cria um ambiente de trabalho tranquilo e calmo.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
          'Focado em desenvolver a equipe com orientação e instrução individualizada.',
        ],
      },
      pontosCegos: {
        female: [
          'Pode ser excessivamente permissiva em vez de responsabilizar os membros da equipe por prazos, qualidade e responsabilidades.',
          'Pode apenas ver o melhor nas pessoas quando mais ceticismo ou crítica é necessário.',
          'Decisões podem ser difíceis quando muitas pessoas com interesses conflitantes estão envolvidas.',
          'Pode permitir que conflitos interpessoais permaneçam ocultos sem trazê-los à tona.',
          'Pode perder boas oportunidades para o desenvolvimento e avanço da equipe devido à alta sensibilidade ao risco.',
        ],
        male: [
          'Pode ser excessivamente permissivo em vez de responsabilizar os membros da equipe por prazos, qualidade e responsabilidades.',
          'Pode apenas ver o melhor nas pessoas quando mais ceticismo ou crítica é necessário.',
          'Decisões podem ser difíceis quando muitas pessoas com interesses conflitantes estão envolvidas.',
          'Pode permitir que conflitos interpessoais permaneçam ocultos sem trazê-los à tona.',
          'Pode perder boas oportunidades para o desenvolvimento e avanço da equipe devido à alta sensibilidade ao risco.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Persistente para fazer quantas vezes forem necessárias para viabilizar sua ideia.',
          'Facilidade para se concentrar em uma atividade por um longo tempo.',
          'Gera união no time.',
          'Paciente para ouvir as ideias dos outros.',
        ],
      },
      dificuldades: {
        neutral: [
          'Pode ter excesso de interdependência.',
          'Pode seguir uma ideia para evitar confronto, mesmo quando discorda.',
          'Pode levar muito tempo para se convencer da necessidade de uma inovação.',
          'Medo do novo, em razão da imprevisibilidade de futuro que pode representar.',
        ],
      },
    },
  },
  Sc: {
    resumo: {
      female: '<p>Você costuma ser uma pessoa calma, trazendo uma sensação de previsibilidade e estabilidade nas suas relações. Você valoriza e espera a mesma consistência das pessoas ao seu redor. Com uma atenção aos detalhes superior à da maioria, você se sente confortável deixando que outros liderem as conversas. Frequentemente, opta por não expressar suas opiniões de maneira muito direta, com o objetivo de preservar um ambiente harmonioso e evitar conflitos.</p>',
      male: '<p>Você costuma ser uma pessoa calma, trazendo uma sensação de previsibilidade e estabilidade nas suas relações. Você valoriza e espera a mesma consistência das pessoas ao seu redor. Com uma atenção aos detalhes superior à da maioria, você se sente confortável deixando que outros liderem as conversas. Frequentemente, opta por não expressar suas opiniões de maneira muito direta, com o objetivo de preservar um ambiente harmonioso e evitar conflitos.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você trabalha de forma cooperativa com os outros.',
        'Segue líderes de confiança.',
        'Se posiciona com humildade e gosta de privacidade.',
        'Sempre evita falar sobre suas realizações.',
        'Você prefere atender aos pedidos alheios a correr o risco de provocar conflitos.',
        'Busca previsibilidade e consistência.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta de discutir questões importantes pessoalmente. Valoriza as perguntas e reserva bastante tempo para entender o que a outra pessoa está sentindo.</p>',
      },
      reunioes: {
        neutral: '<p>Prefere reuniões presenciais, com pauta específica e organizada previamente.</p>',
      },
      emails: {
        neutral: '<p>Gosta de e-mails calorosos, sinceros e bem formatados.</p>',
      },
      feedback: {
        neutral: '<p>Valoriza feedbacks minuciosamente detalhados e entregues com recomendações.</p>',
      },
      conflitos: {
        neutral: '<p>Os conflitos devem ser tratados com bastante cautela. Você prefere abordar conversas difíceis com calma, em vez de apressá-las.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você aborda a tomada de decisão com cautela.',
          'É altamente organizada e atenta aos detalhes.',
          'Utiliza soluções de baixo risco que se mostraram eficazes no passado.',
          'Usa uma abordagem estruturada para desenvolver os outros sempre que possível.',
        ],
        male: [
          'Você aborda a tomada de decisões com cautela.',
          'É altamente organizado e atento aos detalhes.',
          'Utiliza soluções de baixo risco que se mostraram eficazes no passado.',
          'Usa uma abordagem estruturada para desenvolver os outros sempre que possível.',
        ],
      },
      cegos: {
        female: [
          'Evita abordar conflitos mesmo quando se faz necessário.',
          'Adia decisões que considera de alto risco.',
          'Hesita em tentar soluções que não foram testadas.',
          'Gasta tempo demais analisando informações antes de tomar uma decisão.',
        ],
        male: [
          'Evita abordar conflitos mesmo quando se faz necessário.',
          'Adia decisões que considera de alto risco.',
          'Hesita em tentar soluções que não foram testadas.',
          'Gasta tempo demais analisando informações antes de tomar uma decisão.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Receber feedback constante.',
          'Estabelecer rotinas diárias.',
          'Organizar e esclarecer informações para outras pessoas.',
          'Apresentar e analisar todos os aspectos de uma decisão importante.',
        ],
        male: [
          'Receber feedback constante.',
          'Estabelecer rotinas diárias.',
          'Organizar e esclarecer informações para outras pessoas.',
          'Apresentar e analisar todos os aspectos de uma decisão importante.',
        ],
      },
      drena: {
        female: [
          'Apresentar ideias e estratégias para grupos.',
          'Direcionar e pressionar outros para melhorar seu desempenho.',
          'Buscar novas oportunidades sem qualquer orientação.',
          'Saltar entre múltiplas ideias simultaneamente.',
          'Pensar rapidamente e descobrir as coisas à medida que avançam.',
        ],
        male: [
          'Apresentar ideias e estratégias para grupos.',
          'Direcionar e pressionar outros para melhorar seu desempenho.',
          'Buscar novas oportunidades sem qualquer orientação.',
          'Saltar entre múltiplas ideias simultaneamente.',
          'Pensar rapidamente e descobrir as coisas à medida que avançam.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você lidera pelo exemplo.',
          'Cria um ambiente de trabalho tranquilo e calmo.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
          'Focada em desenvolver a equipe com orientação e instrução individualizada.',

        ],
        male: [
          'Você lidera pelo exemplo.',
          'Cria um ambiente de trabalho tranquilo e calmo.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
          'Focado em desenvolver a equipe com orientação e instrução individualizada.',

        ],
      },
      pontosCegos: {
        female: [
          'ode adotar uma abordagem excessivamente permissiva em vez de cobrar dos membros da equipe prazos, qualidade e responsabilidades.',
          'Pode apenas ver o melhor nas pessoas quando mais ceticismo ou crítica é necessário.',
          'Sente dificuldade em tomar decisões quando muitas pessoas com diferentes interesses estão envolvidas.',
          'Pode permitir que conflitos interpessoais permaneçam ocultos sem trazê-los à tona.',
          'Pode perder boas oportunidades para o desenvolvimento e avanço da equipe devido à alta sensibilidade ao risco.',
        ],
        male: [
          'Pode adotar uma abordagem excessivamente permissivo em vez de cobrar dos membros da equipe prazos, qualidade e responsabilidades.',
          'Pode apenas ver o melhor nas pessoas quando mais ceticismo ou crítica é necessário.',
          'Sente dificuldade em tomar decisões quando muitas pessoas com diferentes interesses estão envolvidas.',
          'Pode permitir que conflitos interpessoais permaneçam ocultos sem trazê-los à tona.',
          'Pode perder boas oportunidades para o desenvolvimento e avanço da equipe devido à alta sensibilidade ao risco.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Organização das ideias do grupo.',
          'Persistente para fazer quantas vezes forem necessárias para viabilizar sua ideia.',
          'Facilidade para se concentrar em uma atividade por um longo tempo.',
          'Pesquisas e estudos de viabilidades.',
        ],
      },
      dificuldades: {
        neutral: [
          'Medo do novo, em razão da imprevisibilidade de futuro que pode representar.',
          'Pode seguir uma ideia para evitar confronto, mesmo quando discorda.',
          'Pode levar muito tempo para se convencer da necessidade de uma inovação.',
          'Inflexibilidade com normas e procedimentos já estabelecidos.',
        ],
      },
    },
  },
  SC: {
    resumo: {
      female: '<p>Você tende a ser bastante reservada e cautelosa ao tomar decisões. Geralmente, oferece previsibilidade e consistência em suas interações e espera o mesmo dos outros.</p><p>Concorda rapidamente com os outros, acomodando seus pontos de vista em vez de arriscar conflitos. Pode ser extremamente perceptiva, detalhista e meticulosa em seu trabalho. É habilidosa em se adaptar a diferentes pessoas e contextos sociais, promovendo a harmonia em qualquer situação.</p>',
      male: '<p>Você tende a ser bastante reservado e cauteloso ao tomar decisões. Geralmente, oferece previsibilidade e consistência em suas interações e espera o mesmo dos outros.</p><p>Concorda rapidamente com os outros, acomodando seus pontos de vista em vez de arriscar conflitos. Pode ser extremamente perceptivo, detalhista e meticuloso em seu trabalho. É habilidoso em se adaptar a diferentes pessoas e contextos sociais, promovendo a harmonia em qualquer situação.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você tem uma incrível capacidade de oferecer suporte e orientação aos outros.',
        'Valoriza consistência e previsibilidade, criando um ambiente em que as pessoas se sintam à vontade ao seu redor.',
        'Além disso, possui a habilidade de auxiliar os outros em situações difíceis, evitando conflitos e fomentando a colaboração no grupo.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta de abordar as coisas com calma e faz perguntas para obter mais informações.</p>',
      },
      reunioes: {
        neutral: '<p>Prefere reuniões agendadas formalmente e com pauta preparada.</p>',
      },
      emails: {
        neutral: '<p>Gosta de e-mails contextualizados e bem formatados.</p>',
      },
      feedback: {
        neutral: '<p>Valoriza feedbacks minuciosamente detalhados e entregues com recomendações.</p>',
      },
      conflitos: {
        neutral: '<p>Utiliza conflitos como meio para solucionar problemas cruciais, abordando-os com cautela.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você instrui as pessoas passo a passo, sendo clara em suas orientações sobre como realizar as tarefas.',
          'Segue uma rotina previsível e mantém cronogramas estabelecidos para cumprir seus compromissos.',
          'Trabalha com um plano específico e organizado sobre como as coisas serão feitas.',
          'Quando precisa delegar tarefas, você fornece informações específicas e detalhadas para garantir que tudo seja bem executado.',
        ],
        male: [
          'Você instrui as pessoas passo a passo, sendo claro em suas orientações sobre como realizar as tarefas.',
          'Segue uma rotina previsível e mantém cronogramas estabelecidos para cumprir seus compromissos.',
          'Trabalha com um plano específico e organizado sobre como as coisas serão feitas.',
          'Quando precisa delegar tarefas, você fornece informações específicas e detalhadas para garantir que tudo seja bem executado.',
        ],
      },
      cegos: {
        female: [
          'Às vezes, você adia decisões de alto risco por tempo demais, o que pode impactar negativamente o progresso.',
          'Pode hesitar em experimentar soluções não testadas, mesmo que sejam potencialmente eficazes.',
          'Pode hesitar em experimentar soluções não testadas, mesmo que sejam potencialmente eficazes.',
          'Pode haver momentos em que você não é firme ou assertiva quando necessário, e é importante estar atenta a isso.',
        ],
        male: [
          'Às vezes, você adia decisões de alto risco por tempo demais, o que pode impactar negativamente o progresso.',
          'Pode hesitar em experimentar soluções não testadas, mesmo que sejam potencialmente eficazes.',
          'Em algumas situações, você delega decisões de alto impacto para níveis superiores de autoridade ou exige aprovação, o que pode atrasar processos.',
          'Pode haver momentos em que você não é firme ou assertivo quando necessário, e é importante estar atento a isso.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Oferecer assistência personalizada, fornecendo instruções detalhadas para garantir uma compreensão completa das tarefas.',
          'Preferir interações principalmente por escrito, promovendo uma comunicação clara e documentada.',
          'Explorar métodos que levaram as pessoas a alcançarem seus objetivos, visando aprimorar continuamente o desempenho.',
          'Abordar situações com estrutura e análise, buscando minimizar riscos.',
          'Auxiliar outras pessoas na criação de planos bem definidos.',
        ],
        male: [
          'Oferecer assistência personalizada, fornecendo instruções detalhadas para garantir uma compreensão completa das tarefas.',
          'Preferir interações principalmente por escrito, promovendo uma comunicação clara e documentada.',
          'Explorar métodos que levaram as pessoas a alcançarem seus objetivos, visando aprimorar continuamente o desempenho.',
          'Abordar situações com estrutura e análise, buscando minimizar riscos.',
          'Auxiliar outras pessoas na criação de planos bem definidos.',
        ],
      },
      drena: {
        female: [
          'Delegar tarefas complexas e analíticas para outros membros da equipe.',
          'Aceitar riscos significativos em cenários imprevisíveis.',
          'Tomar decisões rápidas, mesmo quando há limitação de dados disponíveis.',
          'Lidar frequentemente com feedback crítico.',
          'Assumir a responsabilidade principal em projetos extensos.',
        ],
        male: [
          'Delegar tarefas complexas e analíticas para outros membros da equipe.',
          'Aceitar riscos significativos em cenários imprevisíveis.',
          'Tomar decisões rápidas, mesmo quando há limitação de dados disponíveis.',
          'Lidar frequentemente com feedback crítico.',
          'Assumir a responsabilidade principal em projetos extensos.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você tende a ser conselheira e apoiadora.',
          'Geralmente lidera pelo exemplo.',
          'Cria um ambiente de trabalho tranquilo e calmo.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
          'Focada em desenvolver a equipe com orientação e instrução individualizada.',
        ],
        male: [
          'Você tende a ser conselheiro e apoiador.',
          'Geralmente lidera pelo exemplo.',
          'Cria um ambiente de trabalho tranquilo e calmo.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
          'Focado em desenvolver a equipe com orientação e instrução individualizada.',
        ],
      },
      pontosCegos: {
        female: [
          'Pode adotar uma abordagem excessivamente permissiva em vez de cobrar dos membros da equipe prazos, qualidade e responsabilidades.',
          'Pode apenas ver o melhor nas pessoas quando mais ceticismo ou crítica é necessário.',
          'Sente dificuldade em tomar decisões quando muitas pessoas com diferentes interesses estão envolvidas.',
          'Pode permitir que conflitos interpessoais permaneçam ocultos sem trazê-los à tona.',
          'Pode perder boas oportunidades para o desenvolvimento e avanço da equipe devido à alta sensibilidade ao risco.',
        ],
        male: [
          'Pode adotar uma abordagem excessivamente permissiva em vez de cobrar dos membros da equipe prazos, qualidade e responsabilidades.',
          'Pode apenas ver o melhor nas pessoas quando mais ceticismo ou crítica é necessário.',
          'Sente dificuldade em tomar decisões quando muitas pessoas com diferentes interesses estão envolvidas.',
          'Pode permitir que conflitos interpessoais permaneçam ocultos sem trazê-los à tona.',
          'Pode perder boas oportunidades para o desenvolvimento e avanço da equipe devido à alta sensibilidade ao risco.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Pesquisas e estudos de viabilidades.',
          'Organização das ideias do grupo.',
          'Gera união no time.',
          'Paciente para ouvir as ideias dos outros.',
        ],
      },
      dificuldades: {
        neutral: [
          'Inflexibilidade com normas e procedimentos já estabelecidos.',
          'Medo de errar.',
          'Pode levar muito tempo para se convencer da necessidade de uma inovação.',
          'Medo do novo.',
        ],
      },
    },
  },
  Cs: {
    resumo: {
      female: '<p>Você costuma adotar uma postura mais reservada e solitária. Opta por interações mais estruturadas, por isso, às vezes, hesita em participar de conversas casuais em grupo.</p><p>Sua inclinação é para uma abordagem séria e racional. Aprecia argumentos lógicos e interage melhor com pessoas que adotam uma abordagem sistemática ou metódica. Pensa cuidadosamente antes de falar e utiliza as palavras de forma precisa.</p>',
      male: '<p>Você costuma adotar uma postura mais reservada e solitária. Opta por interações mais estruturadas, por isso, às vezes, hesita em participar de conversas casuais em grupo.</p><p>Sua inclinação é para uma abordagem séria e racional. Aprecia argumentos lógicos e interage melhor com pessoas que adotam uma abordagem sistemática ou metódica. Pensa cuidadosamente antes de falar e utiliza as palavras de forma precisa.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você oferece suporte e orientação aos outros.',
        'Faz com que os outros se sintam à vontade.',
        'Valoriza a consistência e a previsibilidade.',
        'Prefere evitar brigas em vez de resolver problemas com os outros.',
        'Colabora mais com o grupo quando todos fazem o que foi combinado.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta quando as pessoas pensam cuidadosamente antes de falar e usam palavras claras, evitando sarcasmo.</p>',
      },
      reunioes: {
        neutral: '<p>Devem ser mínimas, agendadas formalmente e com uma pauta preparada.</p>',
      },
      emails: {
        neutral: '<p>Devem ser claros e bem descritivos.</p>',
      },
      feedback: {
        neutral: '<p>Devem ser ponderados, detalhados e entregues com lógica e razão.</p>',
      },
      conflitos: {
        neutral: '<p>Devem ser abordados de uma maneira racional, a fim de descobrir a verdade e trazer à tona questões ocultas que estão afetando os resultados.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você usa uma abordagem analítica para resolver problemas.',
          'Considera diversos fatores ao tomar uma decisão.',
          'Coleta informações e avalia riscos antes de tomar decisões.',
          'Mostra às pessoas como fazer as coisas de maneira passo a passo.',
          'Mantém a qualidade fazendo perguntas frequentemente.',
        ],
        male: [
          'Você usa uma abordagem analítica para resolver problemas.',
          'Considera diversos fatores ao tomar uma decisão.',
          'Coleta informações e avalia riscos antes de tomar decisões.',
          'Mostra às pessoas como fazer as coisas de maneira passo a passo.',
          'Mantém a qualidade fazendo perguntas frequentemente.',
        ],
      },
      cegos: {
        female: [
          'Gasta mais tempo trabalhando sozinha quando a colaboração seria mais eficaz.',
          'Hesita em experimentar novas soluções que não foram testadas.',
          'Gasta tempo demais analisando informações antes de tomar uma decisão.',
          'Torna soluções para problemas simples mais complicadas do que o necessário.',
          'Delega decisões de alto impacto a níveis superiores de autoridade ou exige aprovação.',
          'Espera que os outros sejam tão organizados e atentos aos detalhes quanto você.',
        ],
        male: [
          'Gasta mais tempo trabalhando sozinho quando a colaboração seria mais eficaz.',
          'Hesita em experimentar novas soluções que não foram testadas.',
          'Gasta tempo demais analisando informações antes de tomar uma decisão.',
          'Torna soluções para problemas simples mais complicadas do que o necessário.',
          'Delega decisões de alto impacto a níveis superiores de autoridade ou exige aprovação.',
          'Espera que os outros sejam tão organizados e atentos aos detalhes quanto você.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Considerar cuidadosamente todos os aspectos de uma decisão importante.',
          'Tirar um tempo para pensar em um problema antes de tomar uma decisão final.',
          'Verificar e garantir que as entregas estejam saindo com alta qualidade.',
          'Pesquisar formas anteriores como as pessoas alcançaram metas para melhorar o desempenho.',
          'Fazer frequentemente perguntas para entender melhor o que precisa ser feito.',
        ],
        male: [
          'Considerar cuidadosamente todos os aspectos de uma decisão importante.',
          'Tirar um tempo para pensar em um problema antes de tomar uma decisão final.',
          'Verificar e garantir que as entregas estejam saindo com alta qualidade.',
          'Pesquisar formas anteriores como as pessoas alcançaram metas para melhorar o desempenho.',
          'Fazer frequentemente perguntas para entender melhor o que precisa ser feito.',
        ],
      },
      drena: {
        female: [
          'Interagir regularmente com um grande grupo de pessoas.',
          'Pensar rapidamente e descobrir as coisas à medida que avançam.',
          'Participar de discussões em grupo e sessões de brainstorming.',
          'Discutir ideias abstratas em vez de concretas.',
          'Oferecer incentivo verbal e contar histórias.',
        ],
        male: [
          'Interagir regularmente com um grande grupo de pessoas.',
          'Pensar rapidamente e descobrir as coisas à medida que avançam.',
          'Participar de discussões em grupo e sessões de brainstorming.',
          'Discutir ideias abstratas em vez de concretas.',
          'Oferecer incentivo verbal e contar histórias.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você se sente mais confortável em transmitir mensagens importantes por escrito.',
          'Focada em criar regras e processos para outros seguirem.',
          'Espera que os membros da equipe tomem decisões com lógica e dados de suporte.',
          'Fornece instruções detalhadas e específicas para resolver problemas.',
        ],
        male: [
          'Você se sente mais confortável em transmitir mensagens importantes por escrito.',
          'Focado em criar regras e processos para outros seguirem.',
          'Espera que os membros da equipe tomem decisões com lógica e dados de suporte.',
          'Fornece instruções detalhadas e específicas para resolver problemas.',
        ],
      },
      pontosCegos: {
        female: [
          'Os membros da equipe podem não se sentir emocionalmente conectados e envolvidos.',
          'Pode incentivar a equipe a gastar muito tempo pesquisando e reunindo informações quando uma ação imediata é necessária.',
          'Pode ignorar o impacto emocional ou social de uma decisão, mesmo quando é lógica e prática.',
          'Pode ser excessivamente rígida na criação e aplicação de regras.',
          'Pode restringir os membros da equipe mais criativos exigindo que eles se conformem com práticas padrão, em vez de permitir flexibilidade.',
        ],
        male: [
          'Os membros da equipe podem não se sentir emocionalmente conectados e envolvidos.',
          'Pode incentivar a equipe a gastar muito tempo pesquisando e reunindo informações quando uma ação imediata é necessária.',
          'Pode ignorar o impacto emocional ou social de uma decisão, mesmo quando é lógica e prática.',
          'Pode ser excessivamente rígido na criação e aplicação de regras.',
          'Pode restringir os membros da equipe mais criativos exigindo que eles se conformem com práticas padrão, em vez de permitir flexibilidade.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Pesquisa antes de dar sua opinião.',
          'Pesquisas e estudos de viabilidades.',
          'Checa as implicações legais.',
          'Paciente para ouvir as ideias dos outros.',
        ],
      },
      dificuldades: {
        neutral: [
          'Medo de errar.',
          'Apoio excessivo em informações e dados.',
          'Pode levar muito tempo para se convencer da necessidade de uma inovação.',
          'Inflexibilidade com normas e procedimentos já estabelecidos.',
        ],
      },
    },
  },
  C: {
    resumo: {
      female: '<p>Você é objetiva e lógica, sendo geralmente pragmática ao lidar com problemas, fundamentando suas ações em análises e fatos. Tende a ser reservada em ambientes de grupos, levando tempo para construir confiança suficiente para compartilhar abertamente.</p><p>Aborda a vida de maneira mais ponderada, apreciando atividades solitárias, valorizando sua privacidade e desenvolvendo pensamentos independentes dos estímulos externos.</p>',
      male: '<p>Você é objetivo e lógico, sendo geralmente pragmático ao lidar com problemas, fundamentando suas ações em análises e fatos. Tende a ser reservado em ambientes de grupos, levando tempo para construir confiança suficiente para compartilhar abertamente.</p><p>Aborda a vida de maneira mais ponderada, apreciando atividades solitárias, valorizando sua privacidade e desenvolvendo pensamentos independentes dos estímulos externos.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você avalia pessoas e situações de maneira mais cética e realista.',
        'Gosta de privacidade e atividades solitárias.',
        'Toma decisões objetivas, ao invés de emocionais.',
        'Percebe facilmente a falta de sinceridade nos outros.',
        'Mantém uma postura séria no trabalho.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta de uma postura séria e profissional e faz perguntas objetivas para entender o que as pessoas estão pensando.</p>',
      },
      reunioes: {
        neutral: '<p>Prefere poucas reuniões e agendadas formalmente, com pauta preparada.</p>',
      },
      emails: {
        neutral: '<p>Gosta de e-mails claros, detalhados e baseados em fatos.</p>',
      },
      feedback: {
        neutral: '<p>Devem ser específicos, detalhados e apresentados com raciocínio lógico.</p>',
      },
      conflitos: {
        neutral: '<p>Aborda os conflitos de maneira objetiva, para descobrir futuros problemas.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você dedica tempo para refletir ao tomar decisões.',
          'Fornece procedimentos claramente definidos ao delegar tarefas.',
          'Utiliza uma abordagem cuidadosa e metódica ao resolver problemas.',
          'Sente-se à vontade analisando grandes quantidades de informações.',
          'Delega tarefas e solicita feedback sempre por escrito.',
        ],
        male: [
          'Você dedica tempo para refletir ao tomar decisões.',
          'Fornece procedimentos claramente definidos ao delegar tarefas.',
          'Utiliza uma abordagem cuidadosa e metódica ao resolver problemas.',
          'Sente-se à vontade analisando grandes quantidades de informações.',
          'Delega tarefas e solicita feedback sempre por escrito.',
        ],
      },
      cegos: {
        female: [
          'Pode perseguir uma solução perfeita em vez de uma solução viável.',
          'Tende a gastar muito tempo coletando informações e avaliando riscos antes de tomar decisões.',
          'Evita ou resiste a pessoas que não usam uma abordagem sistemática para organizar o trabalho.',
          'Critica pessoas que não atendem aos seus padrões de qualidade e precisão.',
          'Monitora com muita frequência, fazendo muitas perguntas quando alguém precisa de mais autonomia.',
          'Pode tornar soluções para problemas simples mais complicadas do que o necessário.',
        ],
        male: [
          'Pode perseguir uma solução perfeita em vez de uma solução viável.',
          'Tende a gastar muito tempo coletando informações e avaliando riscos antes de tomar decisões.',
          'Evita ou resiste a pessoas que não usam uma abordagem sistemática para organizar o trabalho.',
          'Critica pessoas que não atendem aos seus padrões de qualidade e precisão.',
          'Monitora com muita frequência, fazendo muitas perguntas quando alguém precisa de mais autonomia.',
          'Pode tornar soluções para problemas simples mais complicadas do que o necessário.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Resolver problemas com uma análise minuciosa dos dados existentes.',
          'Pesquisar a causa raiz de um problema.',
          'Criar procedimentos, regras e diretrizes para outras pessoas seguirem.',
          'Ajudar outros a se tornarem mais eficientes em seus processos.',
          'Trabalhar em projetos de forma independente e trazer os resultados de volta para um grupo.',
        ],
        male: [
          'Resolver problemas com uma análise minuciosa dos dados existentes.',
          'Pesquisar a causa raiz de um problema.',
          'Criar procedimentos, regras e diretrizes para outras pessoas seguirem.',
          'Ajudar outros a se tornarem mais eficientes em seus processos.',
          'Trabalhar em projetos de forma independente e trazer os resultados de volta para um grupo.',
        ],
      },
      drena: {
        female: [
          'Fazer brainstorming sobre ideias muito abstratas em vez de claras.',
          'Gastar tempo para entender como outra pessoa pensa.',
          'Deixar sua agenda aberta e flexível para reuniões espontâneas ao longo do dia.',
          'Explicar as coisas com uma linguagem emocional e expressiva.',
        ],
        male: [
          'Fazer brainstorming sobre ideias muito abstratas em vez de claras.',
          'Gastar tempo para entender como outra pessoa pensa.',
          'Deixar sua agenda aberta e flexível para reuniões espontâneas ao longo do dia.',
          'Explicar as coisas com uma linguagem emocional e expressiva.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você fica confortável em transmitir mensagens importantes por escrito.',
          'Focada em criar regras e processos para outros seguirem.',
          'Espera que os membros da equipe tomem decisões com lógica e dados de suporte.',
          'Fornece instruções detalhadas e específicas para resolver problemas.',
        ],
        male: [
          'Você fica confortável em transmitir mensagens importantes por escrito.',
          'Focada em criar regras e processos para outros seguirem.',
          'Espera que os membros da equipe tomem decisões com lógica e dados de suporte.',
          'Fornece instruções detalhadas e específicas para resolver problemas.',
        ],
      },
      pontosCegos: {
        female: [
          'Os membros da equipe podem encontrar dificuldades em estabelecer conexão com você.',
          'Pode incentivar a equipe a gastar muito tempo pesquisando e reunindo informações quando uma ação imediata é necessária.',
          'Pode ignorar o impacto emocional ou social de uma decisão, mesmo quando é lógica e prática.',
          'Pode ser excessivamente rígido na criação e aplicação de regras.',
          'Pode restringir os membros da equipe mais criativos exigindo que eles se conformem com práticas padrão, em vez de permitir flexibilidade.',
        ],
        male: [
          'Os membros da equipe podem encontrar dificuldades em estabelecer conexão com você.',
          'Pode incentivar a equipe a gastar muito tempo pesquisando e reunindo informações quando uma ação imediata é necessária.',
          'Pode ignorar o impacto emocional ou social de uma decisão, mesmo quando é lógica e prática.',
          'Pode ser excessivamente rígido na criação e aplicação de regras.',
          'Pode restringir os membros da equipe mais criativos exigindo que eles se conformem com práticas padrão, em vez de permitir flexibilidade.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Pesquisas antes de dar sua opinião.',
          'Checa as implicações legais.',
          'Estuda a viabilidade de suas ideias.',
          'Organização ao expor sua proposta.',
        ],
      },
      dificuldades: {
        neutral: [
          'Inflexibilidade com normas e procedimentos já estabelecidos.',
          'Medo de errar.',
          'Pessimismo.',
          'Apoio excessivo em informações e dados.',
        ],
      },
    },
  },
  Cd: {
    resumo: {
      female: '<p>Você adota uma postura mais séria e mantém um pensamento lógico. Prefere atividades reflexivas e independentes e, muitas vezes, estabelece relações mais distantes e desapegadas com os outros.</p><p>Você costuma manter autonomia e exercer um forte controle sobre sua agenda. Tende a adotar uma postura cética diante de afirmações audaciosas não fundamentadas. Valoriza eficiência, precisão e lógica em suas ações e decisões.</p>',
      male: '<p>Você adota uma postura mais séria e mantém um pensamento lógico. Prefere atividades reflexivas e independentes e, muitas vezes, estabelece relações mais distantes e desapegadas com os outros.</p><p>Você costuma manter autonomia e exercer um forte controle sobre sua agenda. Tende a adotar uma postura cética diante de afirmações audaciosas não fundamentadas. Valoriza eficiência, precisão e lógica em suas ações e decisões.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você busca espaço pessoal, privacidade e autonomia.',
        'Evita atividades em grandes grupos.',
        'Se relaciona com os outros de maneira independente e desapegada.',
        'Constrói confiança ou revela informações pessoais mais lentamente do que a maioria das pessoas.',
        'Toma decisões de forma imparcial, sem permitir que as emoções influenciem seu julgamento.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Prefere conversas mais diretas, baseadas em fatos e desprovidas de emoção, fornecendo descrições literais em vez de enfeitadas.</p>',
      },
      reunioes: {
        neutral: '<p>Devem ser mínimas, agendadas formalmente e com uma pauta preparada.</p>',
      },
      emails: {
        neutral: '<p>Devem ser claros, detalhados e baseados em fatos.</p>',
      },
      feedback: {
        neutral: '<p>Devem ser específicos, detalhados e entregues com raciocínio lógico.</p>',
      },
      conflitos: {
        neutral: '<p>Devem focar em abordar problemas específicos para encontrar soluções práticas.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você é direta, objetiva e se mantém atenta à realidade.',
          'Usa uma abordagem analítica para resolver problemas.',
          'Assume responsabilidade e propriedade sobre os resultados.',
          'Concentra-se no que precisa ser feito, por quem e até quando.',
          'Desenvolve os outros especificando requisitos de competência e avaliando o desempenho.',
        ],
        male: [
          'Você é direto, objetivo e se mantém atento à realidade.',
          'Usa uma abordagem analítica para resolver problemas.',
          'Assume responsabilidade e propriedade sobre os resultados.',
          'Concentra-se no que precisa ser feito, por quem e até quando.',
          'Desenvolve os outros especificando requisitos de competência e avaliando o desempenho.',
        ],
      },
      cegos: {
        female: [
          'É crítica com pessoas que não atendem aos seus padrões de qualidade e precisão.',
          'Complica demais soluções para problemas simples.',
          'Sente desconforto em conversas informais, evitando para economizar tempo, mas perdendo os benefícios relacionais.',
          'Gasta tempo trabalhando sozinha quando a colaboração seria mais eficaz.',
          'Reage de forma agressiva quando outros tentam limitar sua autoridade ou autonomia.',
        ],
        male: [
          'É crítico com pessoas que não atendem aos seus padrões de qualidade e precisão.',
          'Complica demais soluções para problemas simples.',
          'Sente desconforto em conversas informais, evitando para economizar tempo, mas perdendo os benefícios relacionais.',
          'Gasta tempo trabalhando sozinho quando a colaboração seria mais eficaz.',
          'Reage de forma agressiva quando outros tentam limitar sua autoridade ou autonomia.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Criar políticas e regras específicas para outros seguirem.',
          'Desenvolver processos mais eficientes.',
          'Ter controle sobre a qualidade de seu trabalho.',
          'Ao direcionar outros, focar no que precisa ser feito, por quem e até quando.',
          'Navegar por sistemas grandes e complexos.',
        ],
        male: [
          'Criar políticas e regras específicas para outros seguirem.',
          'Desenvolver processos mais eficientes.',
          'Ter controle sobre a qualidade de seu trabalho.',
          'Ao direcionar outros, focar no que precisa ser feito, por quem e até quando.',
          'Navegar por sistemas grandes e complexos.',
        ],
      },
      drena: {
        female: [
          'Colaborar regularmente com outras pessoas abrindo mão da sua independência.',
          'Buscar oportunidades abstratas.',
          'Compreender e explicar o impacto humano de uma decisão organizacional.',
          'Ensinar, treinar e aconselhar outras pessoas.',
          'Demonstrar empatia quando alguém está lidando com uma situação emocional.',
        ],
        male: [
          'Colaborar regularmente com outras pessoas abrindo mão da sua independência.',
          'Buscar oportunidades abstratas.',
          'Compreender e explicar o impacto humano de uma decisão organizacional.',
          'Ensinar, treinar e aconselhar outras pessoas.',
          'Demonstrar empatia quando alguém está lidando com uma situação emocional.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Se sente mais confortável em transmitir mensagens importantes por escrito.',
          'Focada em criar regras e processos para outros seguirem.',
          'Espera que os membros da equipe tomem decisões com lógica e dados de suporte.',
          'Fornece instruções detalhadas e específicas para resolver problemas.',
        ],
        male: [
          'Se sente mais confortável em transmitir mensagens importantes por escrito.',
          'Focado em criar regras e processos para outros seguirem.',
          'Espera que os membros da equipe tomem decisões com lógica e dados de suporte.',
          'Fornece instruções detalhadas e específicas para resolver problemas.',
        ],
      },
      pontosCegos: {
        female: [
          'Os membros da equipe podem não se sentir emocionalmente conectados e envolvidos.',
          'Pode incentivar a equipe a gastar muito tempo pesquisando e reunindo informações quando uma ação imediata é necessária.',
          'Pode ignorar o impacto emocional ou social de uma decisão, mesmo quando é lógica e prática.',
          'Pode ser excessivamente rígida na criação e aplicação de regras.',
          'Pode restringir os membros da equipe mais criativos exigindo que eles se conformem com práticas padrão, em vez de permitir flexibilidade.',
        ],
        male: [
          'Os membros da equipe podem não se sentir emocionalmente conectados e envolvidos.',
          'Pode incentivar a equipe a gastar muito tempo pesquisando e reunindo informações quando uma ação imediata é necessária.',
          'Pode ignorar o impacto emocional ou social de uma decisão, mesmo quando é lógica e prática.',
          'Pode ser excessivamente rígido na criação e aplicação de regras.',
          'Pode restringir os membros da equipe mais criativos exigindo que eles se conformem com práticas padrão, em vez de permitir flexibilidade.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Pesquisas e estudos de viabilidades.',
          'Checa as implicações legais.',
          'Organização ao expor sua proposta.',
          'Objetividade ao dizer o que pensa.',
        ],
      },
      dificuldades: {
        neutral: [
          'Inflexibilidade com normas e procedimentos já estabelecidos.',
          'Apoio excessivo em informações e dados.',
          'Pessimismo.',
          'Dificuldade para ouvir críticas.',
        ],
      },
    },
  },
  CD: {
    resumo: {
      female: '<p>Você tem a capacidade de tomar decisões de forma lógica e eficiente, mantendo-se imune às influências das emoções ou opiniões alheias. Sua natureza reservada reflete-se em suas interações, nas quais tende a estabelecer relacionamentos gradualmente e de forma distante.</p><p>Você valoriza seu espaço pessoal, privacidade e autonomia. Sua abordagem é pragmática e desprovida de sentimentalismo.</p>',
      male: '<p>Você tem a capacidade de tomar decisões de forma lógica e eficiente, mantendo-se imune às influências das emoções ou opiniões alheias. Sua natureza reservada reflete-se em suas interações, nas quais tende a estabelecer relacionamentos gradualmente e de forma distante.</p><p>Você valoriza seu espaço pessoal, privacidade e autonomia. Sua abordagem é pragmática e desprovida de sentimentalismo.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você se comunica com uma linguagem objetiva e direta.',
        'Age com propósito e foco.',
        'Busca objetivos em vez de gastar muito tempo interagindo com outros.',
        'Supera a oposição e a concorrência de forma agressiva.',
        'Fica impaciente quando o progresso é interrompido.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta de um comportamento mais contido, direto, desprovido de emoção e evitando afirmações que não possam ser comprovadas.</p>',
      },
      reunioes: {
        neutral: '<p>Devem ser mínimas, agendadas formalmente e com uma pauta preparada.</p>',
      },
      emails: {
        neutral: '<p>Devem ser claros, profissionais e baseados em fatos.</p>',
      },
      feedback: {
        neutral: '<p>Devem ser diretos, críticos e entregues com argumentação lógica.</p>',
      },
      conflitos: {
        neutral: '<p>Devem ser tratados de maneira lógica, sem apego e com as informações necessárias.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você usa uma abordagem reservada e profissional ao interagir com os outros.',
          'Desenvolve táticas eficientes que melhoram o desempenho e mantém a qualidade.',
          'Mostra às pessoas como fazer as coisas em uma sequência lógica.',
          'Direciona os outros de maneira desapaixonada, com clareza e precisão.',
          'Compreende e enfrenta problemas complexos e interligados.',
        ],
        male: [
          'Você usa uma abordagem reservada e profissional ao interagir com os outros.',
          'Desenvolve táticas eficientes que melhoram o desempenho e mantém a qualidade.',
          'Mostra às pessoas como fazer as coisas em uma sequência lógica.',
          'Direciona os outros de maneira desapaixonada, com clareza e precisão.',
          'Compreende e enfrenta problemas complexos e interligados.',
        ],
      },
      cegos: {
        female: [
          'Monitora resultados de perto ao ponto de parecer excessivamente controladora.',
          'Parece fria, distante ou desinteressada ao interagir com os outros.',
          'Mostra frustração quando seus padrões de desempenho não são atendidos.',
          'Critica pessoas que não atendem aos seus padrões de qualidade e precisão.',
          'É excessivamente sucinta na comunicação.',
          'Faz mudanças de forma rápida e decisiva, potencialmente promovendo desconfortos ao trabalho dos outros.',
        ],
        male: [
          'Monitora resultados de perto ao ponto de parecer excessivamente controlador.',
          'Parece frio, distante ou desinteressado ao interagir com os outros.',
          'Mostra frustração quando os padrões de desempenho não são atendidos.',
          'Critica pessoas que não atendem aos seus padrões de qualidade e precisão.',
          'É excessivamente sucinto na comunicação.',
          'Faz mudanças de forma rápida e decisiva, potencialmente promovendo desconfortos ao trabalho dos outros.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Direcionar os outros para seguir regras e procedimentos.',
          'Interagir com uma abordagem reservada e profissional.',
          'Focar nas principais causas de um problema ao invés dos sintomas.',
          'Fornecer feedback a outros sobre como podem melhorar seu desempenho.',
          'Mostrar às pessoas como fazer as coisas da maneira correta.',
          'Concluir projetos seguindo um cronograma rigoroso.',
        ],
        male: [
          'Direcionar os outros para seguir regras e procedimentos.',
          'Interagir com uma abordagem reservada e profissional.',
          'Focar nas principais causas de um problema ao invés dos sintomas.',
          'Fornecer feedback a outros sobre como podem melhorar seu desempenho.',
          'Mostrar às pessoas como fazer as coisas da maneira correta.',
          'Concluir projetos seguindo um cronograma rigoroso.',
        ],
      },
      drena: {
        female: [
          'Manter-se ciente das necessidades pessoais e emocionais de outras pessoas.',
          'Verificar frequentemente se todos estão alinhados com o mesmo propósito.',
          'Abrir-se sobre questões emocionais.',
          'Comunicar-se com um tom amigável e informal.',
          'Fazer perguntas a colegas mais experientes para aprender como fazer algo.',
        ],
        male: [
          'Manter-se ciente das necessidades pessoais e emocionais de outras pessoas.',
          'Verificar frequentemente se todos estão alinhados com o mesmo propósito.',
          'Abrir-se sobre questões emocionais.',
          'Comunicar-se com um tom amigável e informal.',
          'Fazer perguntas a colegas mais experientes para aprender como fazer algo.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você se sente mais confortável em transmitir mensagens importantes por escrito.',
          'Focada em criar regras e processos para outros seguirem.',
          'Espera que os membros da equipe tomem decisões com lógica e dados de suporte.',
          'Fornece instruções detalhadas e específicas para resolver problemas.',
        ],
        male: [
          'Você se sente mais confortável em transmitir mensagens importantes por escrito.',
          'Focado em criar regras e processos para outros seguirem.',
          'Espera que os membros da equipe tomem decisões com lógica e dados de suporte.',
          'Fornece instruções detalhadas e específicas para resolver problemas.',
        ],
      },
      pontosCegos: {
        female: [
          'Os membros da equipe podem não se sentir emocionalmente conectados e envolvidos com você.',
          'Pode incentivar a equipe a gastar muito tempo pesquisando e reunindo informações quando uma ação imediata é necessária.',
          'Pode ignorar o impacto emocional ou social de uma decisão, mesmo quando é lógica e prática.',
          'Pode ser excessivamente rígida na criação e aplicação de regras.',
          'Pode restringir os membros da equipe mais criativos exigindo que eles se conformem com práticas padrão, em vez de permitir flexibilidade.',
        ],
        male: [
          'Os membros da equipe podem não se sentir emocionalmente conectados e envolvidos com você.',
          'Pode incentivar a equipe a gastar muito tempo pesquisando e reunindo informações quando uma ação imediata é necessária.',
          'Pode ignorar o impacto emocional ou social de uma decisão, mesmo quando é lógica e prática.',
          'Pode ser excessivamente rígido na criação e aplicação de regras.',
          'Pode restringir os membros da equipe mais criativos exigindo que eles se conformem com práticas padrão, em vez de permitir flexibilidade.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Pesquisas e estudos de viabilidades.',
          'Checa as implicações legais.',
          'Ousadia para questionar modelos existentes.',
          'Objetividade ao dizer o que pensa.',
        ],
      },
      dificuldades: {
        neutral: [
          'Apoio excessivo em informações e dados.',
          'Medo de errar.',
          'Dificuldade para ouvir críticas. Pode passar a impressão de que acredita que está sempre certo.',
          'Propensão ao autoritarismo, o que pode inibir a participação e criatividade dos outros membros da equipe.',
        ],
      },
    },
  },
  Dc: {
    resumo: {
      female: '<p>Você é conhecida por sua intensidade e abordagem enérgica perante a vida, destacando-se pela sua determinação e independência. Com uma preferência marcante por trilhar seu próprio caminho e liderar suas atividades, você tende a optar por um comportamento mais sério.</p><p>Sua energia é majoritariamente investida em interações objetivas e profissionais, ao invés de conversas sociais leves. Mantendo um foco incisivo em alcançar resultados, você busca ter controle sobre os aspectos que influenciam sua capacidade de atingir objetivos, resistindo ativamente a qualquer distração que possa surgir em seu caminho.</p>',
      male: '<p>Você é conhecido por sua intensidade e abordagem enérgica perante a vida, destacando-se pela sua determinação e independência. Com uma preferência marcante por trilhar seu próprio caminho e liderar suas atividades, você tende a optar por um comportamento mais sério.</p><p>Sua energia é majoritariamente investida em interações objetivas e profissionais, ao invés de conversas sociais leves. Mantendo um foco incisivo em alcançar resultados, você busca ter controle sobre os aspectos que influenciam sua capacidade de atingir objetivos, resistindo ativamente a qualquer distração que possa surgir em seu caminho.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você busca o controle sobre seu ambiente.',
        'Foca o seu tempo profissional mais em resultados do que em relacionamentos.',
        'Tem determinação e firmeza em sua busca por objetivos.',
        'Reage à oposição com firmeza, em vez de passividade.',
        'Tem altas expectativas sobre o desempenho de todos, inclusive o seu.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta de conversas diretas, assertivas, focadas e orientadas para assuntos de trabalho em vez de conversa fiada.</p>',
      },
      reunioes: {
        neutral: '<p>Devem ser breves e marcadas apenas quando necessário.</p>',
      },
      emails: {
        neutral: '<p>Devem ser escritos de forma resumida, com um tom profissional e baseados em fatos.</p>',
      },
      feedback: {
        neutral: '<p>Devem ser diretos, críticos e focados nos resultados.</p>',
      },
      conflitos: {
        neutral: '<p>Devem ser abordados de maneira direta e lógica.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você é focada em resultados e expectativas realistas.',
          'Mantém a eficiência e está orientado para a melhoria contínua no desempenho.',
          'Identifica maneiras específicas de ajudar os outros a melhorar.',
          'Vê o mundo através de uma perspectiva clara, lógica e baseada em fatos.',
          'Considera cuidadosamente decisões percebidas como de alto risco.',
          'Comunica-se diretamente, usando fatos e linguagem precisa.',
          'Assume responsabilidade e propriedade sobre os resultados.',
        ],
        male: [
          'Você é focado em resultados e expectativas realistas.',
          'Mantém a eficiência e está orientado para a melhoria contínua no desempenho.',
          'Identifica maneiras específicas de ajudar os outros a melhorar.',
          'Vê o mundo através de uma perspectiva clara, lógica e baseada em fatos.',
          'Considera cuidadosamente decisões percebidas como de alto risco.',
          'Comunica-se diretamente, usando fatos e linguagem precisa.',
          'Assume responsabilidade e propriedade sobre os resultados.',
        ],
      },
      cegos: {
        female: [
          'É excessivamente rígida e inflexível com os outros ao fornecer instruções.',
          'Quando toma a iniciativa de mudar alguma coisa, não costuma explicar os motivos.',
          'Usa uma abordagem extremamente orientada a objetivos que pode ignorar detalhes importantes.',
          'Aborda conflitos com um tom firme e direto, o que pode ser desconfortável para alguns.',
          'Expressa feedback crítico muito rapidamente, sem consideração pelas emoções.',
        ],
        male: [
          'É excessivamente rígido e inflexível com os outros ao fornecer instruções.',
          'Quando toma a iniciativa de mudar alguma coisa, não costuma explicar os motivos.',
          'Usa uma abordagem extremamente orientada a objetivos que pode ignorar detalhes importantes.',
          'Aborda conflitos com um tom firme e direto, o que pode ser desconfortável para alguns.',
          'Expressa feedback crítico muito rapidamente, sem consideração pelas emoções.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Feedback rápido e comunicação clara.',
          'Tomar decisões rapidamente com dados limitados.',
          'Ter que avaliar diversas informações para tomar uma decisão rápida.',
          'Monitorar resultados de perto.',
          'Usar uma abordagem firme para direcionar e desenvolver outros.',
          'Questionar criticamente práticas e procedimentos existentes.',
        ],
        male: [
          'Feedback rápido e comunicação clara.',
          'Tomar decisões rapidamente com dados limitados.',
          'Ter que avaliar diversas informações para tomar uma decisão rápida.',
          'Monitorar resultados de perto.',
          'Usar uma abordagem firme para direcionar e desenvolver outros.',
          'Questionar criticamente práticas e procedimentos existentes.',
        ],
      },
      drena: {
        female: [
          'Reuniões frequentes.',
          'Observar outros levando muito tempo para concluir tarefas.',
          'Entregar críticas de maneira suave e parceladas.',
          'Garantir que outras pessoas estejam em paz com uma grande mudança antes de implementá-la.',
          'Revelar emoções ou motivações ocultas.',
          'Colaborar e fazer brainstorming em um formato muito aberto.',
          'Oferecer incentivo verbal frequente aos outros.',
        ],
        male: [
          'Reuniões frequentes.',
          'Observar outros levando muito tempo para concluir tarefas.',
          'Entregar críticas de maneira suave e parceladas.',
          'Garantir que outras pessoas estejam em paz com uma grande mudança antes de implementá-la.',
          'Revelar emoções ou motivações ocultas.',
          'Colaborar e fazer brainstorming em um formato muito aberto.',
          'Oferecer incentivo verbal frequente aos outros.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Ansiosa para assumir o comando e fornecer direção clara.',
          'Tende a desafiar os outros com tarefas exigentes e altas expectativas.',
          'Fornece instruções de alto nível focadas no resultado final.',
          'Cria um ambiente de trabalho competitivo e dinâmico.',
        ],
        male: [
          'Ansioso para assumir o comando e fornecer direção clara.',
          'Tende a desafiar os outros com tarefas exigentes e altas expectativas.',
          'Fornece instruções de alto nível focadas no resultado final.',
          'Cria um ambiente de trabalho competitivo e dinâmico.',
        ],
      },
      pontosCegos: {
        female: [
          'O ambiente de trabalho pode parecer excessivamente competitivo ou agressivo para algumas pessoas.',
          'Pode resolver conflitos imediatamente com discussões verbais diretas.',
          'O ritmo de trabalho pode ser rápido demais para que os membros da equipe completem suas tarefas com a qualidade que considera adequada.',
          'Pode não permitir tempo flexível suficiente para os membros da equipe se conhecerem bem e construírem confiança.',
          'Pode fazer mudanças repentinas, sem informar a equipe ou fornecer tempo suficiente para se prepararem.',
        ],
        male: [
          'O ambiente de trabalho pode parecer excessivamente competitivo ou agressivo para algumas pessoas.',
          'Pode resolver conflitos imediatamente com discussões verbais diretas.',
          'O ritmo de trabalho pode ser rápido demais para que os membros da equipe completem suas tarefas com a qualidade que considera adequada.',
          'Pode não permitir tempo flexível suficiente para os membros da equipe se conhecerem bem e construírem confiança.',
          'Pode fazer mudanças repentinas, sem informar a equipe ou fornecer tempo suficiente para se prepararem.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Ousadia para explorar novas abordagens.',
          'Enfrenta o estabelecido.',
          'Objetividade ao dizer o que pensa.',
          'Checa as implicações legais.',
        ],
      },
      dificuldades: {
        neutral: [
          'Tendência a gerar conflitos, já que sua abordagem pode ser vista como indelicada por outros.',
          'Dificuldade para ouvir críticas. Pode passar a impressão de que acredita que está sempre certo.',
          'Propensão ao autoritarismo, o que pode inibir a participação e criatividade dos outros membros da equipe.',
          'Apoio excessivo em informações e dados.',
        ],
      },
    },
  },
  DS: {
    resumo: {
      female: '<p>Você tende a ser uma pessoa calma e paciente, sempre em busca de manter um ambiente harmonioso. Sua personalidade equilibra perfeitamente a assertividade, intensidade e ambição, criando um contraste interessante na forma como você se apresenta e interage com o mundo ao seu redor.</p><p>Na prática, você navega com habilidade entre a ênfase nos resultados e a atenção às pessoas. Suas decisões são tomadas com firmeza e objetividade, e, ao mesmo tempo, você se mostra capaz de ouvir e responder com paciência às necessidades e pedidos dos outros, demonstrando empatia e respeito mesmo nas situações mais desafiadoras.</p>',
      male: '<p>Você tende a ser uma pessoa calma e paciente, sempre em busca de manter um ambiente harmonioso. Sua personalidade equilibra perfeitamente a assertividade, intensidade e ambição, criando um contraste interessante na forma como você se apresenta e interage com o mundo ao seu redor.</p><p>Na prática, você navega com habilidade entre a ênfase nos resultados e a atenção às pessoas. Suas decisões são tomadas com firmeza e objetividade, e, ao mesmo tempo, você se mostra capaz de ouvir e responder com paciência às necessidades e pedidos dos outros, demonstrando empatia e respeito mesmo nas situações mais desafiadoras. </p>',
    },
    caracteristicas: {
      neutral: [
        'Você equilibra intensidade e ambição com calma e paciência.',
        'Se adapta bem a diferentes situações, mantendo um foco pragmático nos resultados.',
        'Age com determinação para alcançar objetivos por conta própria.',
        'Espera muito de si e das pessoas ao seu redor.',
        'Demonstra consideração e sinceridade em interações difíceis.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta quando se atentam aos seus sentimentos e fazem perguntas para compreender suas preocupações. Prefere conversas que deixam os objetivos claros.</p>',
      },
      reunioes: {
        neutral: '<p>As reuniões devem ser organizadas com uma agenda pré-definida, realizadas presencialmente sempre que possível, e agendadas apenas quando estritamente necessárias.</p>',
      },
      emails: {
        neutral: '<p>Gostam quando os e-mails deixam claro o que se deseja, de forma amigável, com sinceridade e contexto. </p>',
      },
      feedback: {
        neutral: '<p>Valoriza feedbacks diretos, práticos e focados no essencial. Antes de dar ou receber um feedback, gosta de refletir sobre com empatia. </p>',
      },
      conflitos: {
        neutral: '<p>Aborda conflitos de forma objetiva, buscando resolvê-los, mas usa de empatia para não machucar as pessoas envolvidas.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você ouve atentamente e responde com empatia às necessidades dos outros.',
          'Sua atenção está principalmente nos resultados e você define expectativas realistas.',
          'Age com senso de urgência quando está segura.',
          'Quando dá direções, faz isso de maneira impessoal, com clareza e precisão.',
          'Expressa o desejo de manter controle e autonomia.',
          'Você acompanha regularmente o time/projeto, sempre se mostrando disponível para ajudar.',
        ],
        male: [
          'Você ouve atentamente e responde com empatia às necessidades dos outros.',
          'Sua atenção está principalmente nos resultados e você define expectativas realistas.',
          'Age com senso de urgência quando está seguro.',
          'Quando dá direções, faz isso de maneira impessoal, com clareza e precisão.',
          'Expressa o desejo de manter controle e autonomia.',
          'Você acompanha regularmente o time/projeto, sempre se mostrando disponível para ajudar.',
        ],
      },
      cegos: {
        female: [
          'Às vezes, sente a necessidade de criticar aqueles que não demonstram senso de urgência quando você entende que é preciso agir rápido.',
          'Delega responsabilidades, mas retém o controle, o que pode ser limitante para os outros.',
          'Ocasionalmente, seu senso de urgência pode gerar estresse desnecessário para as pessoas ao seu redor.',
          'Pode ter dificuldade em comunicar informações negativas.',
          'Pode ser excessivamente passiva quando a assertividade é necessária.',
          'Posterga decisões envolvendo conflitos interpessoais.',
        ],
        male: [
          'Às vezes, sente a necessidade de criticar aqueles que não demonstram senso de urgência quando você entende que é preciso agir rápido.',
          'Delega responsabilidades, mas retém o controle, o que pode ser limitante para os outros.',
          'Ocasionalmente, seu senso de urgência pode gerar estresse desnecessário para as pessoas ao seu redor.',
          'Pode ter dificuldade em comunicar informações negativas.',
          'Pode ser excessivamente passivo quando a assertividade é necessária.',
          'Posterga decisões envolvendo conflitos interpessoais.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Você se sente motivada e cheia de energia quando consegue concluir projetos ambiciosos dentro de prazos apertados.',
          'Ver resultados tangíveis, marcos e conquistas mensuráveis.',
          'Pedir feedback regularmente.',
          'Desempenhar um papel de apoio na equipe.',
          'Promover o trabalho em equipe e a cooperação entre as partes.',
          'Ouvir perguntas de outras pessoas e responder de maneira ponderada.',
        ],
        male: [
          'Você se sente motivado e cheio de energia quando consegue concluir projetos ambiciosos dentro de prazos apertados.',
          'Ver resultados tangíveis, marcos e conquistas mensuráveis.',
          'Pedir feedback regularmente.',
          'Desempenhar um papel de apoio na equipe.',
          'Promover o trabalho em equipe e a cooperação entre as partes.',
          'Ouvir perguntas de outras pessoas e responder de maneira ponderada.',
        ],
      },
      drena: {
        female: [
          'Ter que monitorar como outras pessoas estão lidando com um desafio.',
          'Ter que dar muita atenção às necessidades e preocupações de outras pessoas quando uma demanda é urgente.',
          'Perceber desalinhamentos no time.',
          'Ver decisões sendo tomadas com falta de contexto e consciência.',
        ],
        male: [
          'Ter que monitorar como outras pessoas estão lidando com um desafio.',
          'Ter que dar muita atenção às necessidades e preocupações de outras pessoas quando uma demanda é urgente.',
          'Perceber desalinhamentos no time.',
          'Ver decisões sendo tomadas com falta de contexto e consciência.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você tende a ser criativa, determinada e gosta de alcançar objetivos por conta própria.',
          'Lidera pelo exemplo.',
          'Espera muito de si mesma e das pessoas ao seu redor.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
        ],
        male: [
          'Você tende a ser criativo, determinado e gosta de alcançar objetivos por conta própria.',
          'Lidera pelo exemplo.',
          'Espera muito de si mesmo e das pessoas ao seu redor.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
        ],
      },
      pontosCegos: {
        female: [
          'Às vezes, resolve conflitos imediatamente com discussões verbais diretas.',
          'Ocasionalmente, seu ritmo de trabalho pode ser rápido demais para que os membros da equipe completem suas tarefas com a qualidade que consideram adequada.',
          'Às vezes, não permite tempo flexível suficiente para os membros da equipe se conhecerem bem e construírem confiança.',
          'Pode demorar muito para questionar a equipe por prazos, qualidade e responsabilidades.',
          'Tomar decisões se torna desafiador quando há envolvimento de várias pessoas com interesses divergentes.',
        ],
        male: [
          'Às vezes, resolve conflitos imediatamente com discussões verbais diretas.',
          'Ocasionalmente, seu ritmo de trabalho pode ser rápido demais para que os membros da equipe completem suas tarefas com a qualidade que consideram adequada.',
          'Às vezes, não permite tempo flexível suficiente para os membros da equipe se conhecerem bem e construírem confiança.',
          'Pode demorar muito para questionar a equipe por prazos, qualidade e responsabilidades.',
          'Tomar decisões se torna desafiador quando há envolvimento de várias pessoas com interesses divergentes.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Ousadia para explorar novas abordagens.',
          'Objetividade ao comunicar suas ideias e pensamentos, o que agiliza a tomada de decisões.',
          'Persistente para fazer quantas vezes forem necessárias para viabilizar sua ideia.',
          'Facilidade para se concentrar em uma atividade por um longo tempo.',
        ],
      },
      dificuldades: {
        neutral: [
          'Dificuldade em receber críticas, devido à crença de que está sempre certo, o que pode limitar o processo de aprendizado.',
          'Tendência a gerar conflitos, já que sua abordagem pode ser vista como indelicada por outros.',
          'Pode seguir uma ideia para evitar confronto, mesmo quando discorda.',
          'Pode levar muito tempo para se convencer da necessidade de uma inovação.',
        ],
      },
    },
  },
  IC: {
    resumo: {
      female: '<p>Você combina entusiasmo com uma dose de desconfiança, criando uma abordagem ao mesmo tempo descontraída e cautelosa. Você busca harmonia em suas interações, valorizando as informações que surgem delas e demonstrando habilidade em estabelecer relações interpessoais de maneira leve e divertida.</p><p>Ao mesmo tempo, você navega entre a valorização da independência e da interdependência, mostrando flexibilidade em suas preferências. Com uma visão de mundo mais crítica, você não hesita em expressar seus pontos de vista, compartilhando suas percepções de forma clara e direta.</p>',
      male: '<p>Você combina entusiasmo com uma dose de desconfiança, criando uma abordagem ao mesmo tempo descontraída e cautelosa. Você busca harmonia em suas interações, valorizando as informações que surgem delas e demonstrando habilidade em estabelecer relações interpessoais de maneira leve e divertida.</p><p>Ao mesmo tempo, você navega entre a valorização da independência e da interdependência, mostrando flexibilidade em suas preferências. Com uma visão de mundo mais crítica, você não hesita em expressar seus pontos de vista, compartilhando suas percepções de forma clara e direta.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você gosta de alternar entre atividades em grupo e momentos de privacidade.',
        'Valoriza consistência e previsibilidade, proporcionando um ambiente acolhedor para outros.',
        'Mantém uma relação independente e desapegada com as pessoas.',
        'Encontra prazer no desafio de conhecer novas pessoas, promovendo um ambiente leve e amigável.',
        'Equilibra habilmente organização e espontaneidade.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta de conversas baseadas em fatos, mas valoriza as histórias pessoais.</p>',
      },
      reunioes: {
        neutral: '<p>Prefere reuniões agendadas formalmente, mas com espaço para interações espontâneas.</p>',
      },
      emails: {
        neutral: '<p>Texto amigável, contextualizado e bem formatado, equilibrando formalidade e a descontração.</p>',
      },
      feedback: {
        neutral: '<p>Valoriza feedbacks detalhados e aprecia quando são incentivadores.</p>',
      },
      conflitos: {
        neutral: '<p>Gosta quando o objetivo é encontrar soluções práticas. Evita discussões desnecessárias.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você é direta, objetiva e fala corajosamente o que pensa.',
          'Usa uma abordagem crítica para resolver problemas.',
          'Assume responsabilidade e propriedade sobre os resultados.',
          'Concentra-se no que precisa ser feito, por quem e até quando.',
          'Valoriza relacionamentos e interações pessoais.',
          'Facilita brainstorming em grupo.',
          'Oferece muitos incentivos verbais ao desenvolver os outros.',
          'Valoriza horários flexíveis e abordagens abertas para gerenciamento de tempo.',
          'Traz energia e um toque de diversão para a equipe.',
        ],
        male: [
          'Você é direto, objetivo e fala corajosamente o que pensa.',
          'Usa uma abordagem crítica para resolver problemas.',
          'Assume responsabilidade e propriedade sobre os resultados.',
          'Concentra-se no que precisa ser feito, por quem e até quando.',
          'Valoriza relacionamentos e interações pessoais.',
          'Facilita brainstorming em grupo.',
          'Oferece muitos incentivos verbais ao desenvolver os outros.',
          'Valoriza horários flexíveis e abordagens abertas para gerenciamento de tempo.',
          'Traz energia e um toque de diversão para a equipe.',
        ],
      },
      cegos: {
        female: [
          'Pode ser excessivamente otimista em relação a pessoas ou situações.',
          'Às vezes, adia decisões de alto risco por tempo demais.',
          'Pode hesitar em experimentar soluções não testadas.',
          'Em algumas situações, delega decisões de alto impacto, mas busca equilíbrio na autonomia da equipe.',
          'Pode haver momentos em que não é firme quando necessário, mas traz otimismo para o grupo.',
          'Se distrai com as ideias novas e pode apresentar dificuldade em manter o foco.',
          'Evita decisões que possam resultar em reprovação ou criar uma má impressão.',
          'É crítica com pessoas que não atendem aos seus padrões de qualidade.',
        ],
        male: [
          'Pode ser excessivamente otimista em relação a pessoas ou situações.',
          'Às vezes, adia decisões de alto risco por tempo demais.',
          'Pode hesitar em experimentar soluções não testadas.',
          'Em algumas situações, delega decisões de alto impacto, mas busca equilíbrio na autonomia da equipe.',
          'Pode haver momentos em que não é firme quando necessário, mas traz otimismo para o grupo.',
          'Se distrai com as ideias novas e pode apresentar dificuldade em manter o foco.',
          'Evita decisões que possam resultar em reprovação ou criar uma má impressão.',
          'É crítico com pessoas que não atendem aos seus padrões de qualidade.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Ter controle sobre a qualidade de seu trabalho.',
          'Navegar por sistemas grandes e complexos.',
          'Interagir frequentemente com novas pessoas, com alegria e descontração.',
          'Explorar métodos que levaram as pessoas a alcançarem seus objetivos, promovendo inovação.',
          'Abordar situações com estrutura e análise para minimizar riscos, considerando novas possibilidades.',
          'Fornecer informações específicas e detalhadas, incentivando o pensamento criativo.',
        ],
        male: [
          'Ter controle sobre a qualidade de seu trabalho.',
          'Navegar por sistemas grandes e complexos.',
          'Interagir frequentemente com novas pessoas, com alegria e descontração.',
          'Explorar métodos que levaram as pessoas a alcançarem seus objetivos, promovendo inovação.',
          'Abordar situações com estrutura e análise para minimizar riscos, considerando novas possibilidades.',
          'Fornecer informações específicas e detalhadas, incentivando o pensamento criativo.',
        ],
      },
      drena: {
        female: [
          'Delegar tarefas detalhadas para outras pessoas.',
          'Receber um grande volume de feedback crítico com frequência.',
          'Assumir a responsabilidade principal de projetos extensos.',
          'Passar muito tempo pesquisando a causa raiz de um problema.',
          'Trabalhar sozinha em projetos que representem o grupo.',
          'Resolver problemas com análises detalhadas dos dados existentes, buscando equilíbrio entre estrutura e espontaneidade.',
          'Assumir riscos significativos em situações imprevisíveis, considerando o planejamento necessário.',
          'Tomar decisões rapidamente quando os dados disponíveis são limitados.',
        ],
        male: [
          'Delegar tarefas detalhadas para outras pessoas.',
          'Receber um grande volume de feedback crítico com frequência.',
          'Assumir a responsabilidade principal de projetos extensos.',
          'Passar muito tempo pesquisando a causa raiz de um problema.',
          'Trabalhar sozinha em projetos que representem o grupo.',
          'Resolver problemas com análises detalhadas dos dados existentes, buscando equilíbrio entre estrutura e espontaneidade.',
          'Assumir riscos significativos em situações imprevisíveis, considerando o planejamento necessário.',
          'Tomar decisões rapidamente quando os dados disponíveis são limitados.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você cria um ambiente de trabalho leve, descontraído e extrovertido.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
          'Foca em inspirar os outros com uma visão ousada do futuro, estimulando o pensamento inovador.',
          'Cria um ambiente de trabalho tranquilo e calmo.',
        ],
        male: [
          'Você cria um ambiente de trabalho leve, descontraído e extrovertido.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
          'Foca em inspirar os outros com uma visão ousada do futuro, estimulando o pensamento inovador.',
          'Cria um ambiente de trabalho tranquilo e calmo.',
        ],
      },
      pontosCegos: {
        female: [
          'Por alternar entre qualidade e velocidade, ocasionalmente, membros da equipe podem encontrar dificuldades em estabelecer conexão com você.',
          'Pode incentivar a equipe a gastar muito tempo pesquisando e reunindo informações quando uma ação imediata é necessária.',
          'Pode ignorar o impacto emocional ou social de uma decisão, mesmo quando é lógica e prática.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
        ],
        male: [
          'Por alternar entre qualidade e velocidade, ocasionalmente, membros da equipe podem encontrar dificuldades em estabelecer conexão com você.',
          'Pode incentivar a equipe a gastar muito tempo pesquisando e reunindo informações quando uma ação imediata é necessária.',
          'Pode ignorar o impacto emocional ou social de uma decisão, mesmo quando é lógica e prática.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Pesquisas e estudos de viabilidades.',
          'Organização ao expor sua proposta',
          'Objetividade ao dizer o que pensa.',
          'Contribui para um clima favorável aos bons relacionamentos.',
          'Sente-se confortável em navegar no mundo dos sonhos e possibilidades.',
        ],
      },
      dificuldades: {
        neutral: [
          'Inflexibilidade com normas e procedimentos já estabelecidos.',
          'Dificuldade para ouvir críticas.',
          'Pode ter dificuldade em ficar em silêncio, ouvir os próprios pensamentos e refletir isoladamente.',
          'Pode fazer o grupo perder o foco.',
        ],
      },
    },
  },
  ISC: {
    resumo: {
      female: '<p>Você tem uma combinação única de qualidades de diversos estilos e naturalmente adota uma abordagem mais generalista. Esta capacidade permite que você se adapte com facilidade a diferentes contextos sociais, graças à sua versatilidade que viabiliza uma atuação ampla e eficaz. Você é particularmente hábil em estabelecer e nutrir relacionamentos, criando um clima leve e descontraído ao seu redor.</p><p>Além disso, sua habilidade para se conectar com diversos grupos, fazer novos amigos e promover uma atmosfera positiva é uma característica marcante do seu estilo.</p>',
      male: '<p>Você tem uma combinação única de qualidades de diversos estilos e naturalmente adota uma abordagem mais generalista. Esta capacidade permite que você se adapte com facilidade a diferentes contextos sociais, graças à sua versatilidade que viabiliza uma atuação ampla e eficaz. Você é particularmente hábil em estabelecer e nutrir relacionamentos, criando um clima leve e descontraído ao seu redor.</p><p>Além disso, sua habilidade para se conectar com diversos grupos, fazer novos amigos e promover uma atmosfera positiva é uma característica marcante do seu estilo.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você preza pela interação social.',
        'Segue as diretrizes de um líder em quem confia.',
        'Compromete-se com suas tarefas e colabora com a equipe.',
        'Demonstra habilidade para fornecer apoio e orientação ao grupo.',
        'Aprecia debates que permitem expor suas ideias e adquirir novos conhecimentos.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta da conversa informal, da troca de informações e da atenção recíproca.</p>',
      },
      reunioes: {
        neutral: '<p>Prefere reuniões agendadas formalmente, mas com espaço para interações espontâneas.</p>',
      },
      emails: {
        neutral: '<p>Texto amigável, contextualizado e bem formatado.</p>',
      },
      feedback: {
        neutral: '<p>Lida bem tanto com feedbacks abrangentes quanto com feedbacks mais detalhados.</p>',
      },
      conflitos: {
        neutral: '<p>Usa os conflitos para resolver problemas importantes, mas trata-os com cautela.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você solicita feedbacks em intervalos regulares.',
          'Facilita brainstorming em grupo para encontrar soluções para problemas.',
          'Comunica-se de maneira espontânea.',
          'Usa horários flexíveis e abordagens abertas para gerenciamento de tempo.',
          'Quando precisa delegar tarefas, você fornece informações específicas e detalhadas para garantir que tudo seja bem executado.',
        ],
        male: [
          'Você solicita feedbacks em intervalos regulares.',
          'Facilita brainstorming em grupo para encontrar soluções para problemas.',
          'Comunica-se de maneira espontânea.',
          'Usa horários flexíveis e abordagens abertas para gerenciamento de tempo.',
          'Quando precisa delegar tarefas, você fornece informações específicas e detalhadas para garantir que tudo seja bem executado.',
        ],
      },
      cegos: {
        female: [
          'Tende a se distrair com as ideias novas e pode ter dificuldade em manter o foco.',
          'Pode hesitar em experimentar soluções não testadas, mesmo que sejam potencialmente eficazes.',
          'Pode se esquivar de ser direta ao comunicar informações negativas.',
          'Em algumas situações, você delega decisões de alto impacto para níveis superiores de autoridade ou exige aprovação, o que pode atrasar processos.',
        ],
        male: [
          'Tende a se distrair com as ideias novas e pode ter dificuldade em manter o foco.',
          'Pode hesitar em experimentar soluções não testadas, mesmo que sejam potencialmente eficazes.',
          'Pode se esquivar de ser direta ao comunicar informações negativas.',
          'Em algumas situações, você delega decisões de alto impacto para níveis superiores de autoridade ou exige aprovação, o que pode atrasar processos.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Interagir frequentemente com um grupo diversificado de pessoas.',
          'Participar de discussões em grupo e sessões de brainstorming.',
          'Considerar como as outras pessoas pensam.',
          'Ajudar as pessoas de forma individualizada, com instruções detalhadas, garantindo que entendam completamente o que fazer.',
          'Pedir feedbacks regularmente.',
        ],
        male: [
          'Interagir frequentemente com um grupo diversificado de pessoas.',
          'Participar de discussões em grupo e sessões de brainstorming.',
          'Considerar como as outras pessoas pensam.',
          'Ajudar as pessoas de forma individualizada, com instruções detalhadas, garantindo que entendam completamente o que fazer.',
          'Pedir feedbacks regularmente.',
        ],
      },
      drena: {
        female: [
          'Passar muito tempo pesquisando a causa raiz de um problema.',
          'Inspecionar e manter resultados de alta qualidade.',
          'Assumir riscos significativos em situações imprevisíveis.',
          'Trabalhar frequentemente com prazos apertados.',
          'Trabalhar sozinho por um período longo.',
        ],
        male: [
          'Passar muito tempo pesquisando a causa raiz de um problema.',
          'Inspecionar e manter resultados de alta qualidade.',
          'Assumir riscos significativos em situações imprevisíveis.',
          'Trabalhar frequentemente com prazos apertados.',
          'Trabalhar sozinho por um período longo.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você cria um ambiente de trabalho leve.',
          'Dá aos outros autonomia para encontrar suas próprias soluções para problemas.',
          'Focada em desenvolver a equipe com orientação e instrução individualizada.',
          'Espera muito de si mesma e das pessoas ao seu redor.',
        ],
        male: [
          'Você cria um ambiente de trabalho leve.',
          'Dá aos outros autonomia para encontrar suas próprias soluções para problemas.',
          'Focada em desenvolver a equipe com orientação e instrução individualizada.',
          'Espera muito de si mesma e das pessoas ao seu redor.',
        ],
      },
      pontosCegos: {
        female: [
          'Construir confiança e lealdade a longo prazo com comportamento consistente e previsível pode parecer desgastante.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
          'Pode apenas ver o melhor nas pessoas quando mais ceticismo ou crítica é necessário.',
        ],
        male: [
          'Construir confiança e lealdade a longo prazo com comportamento consistente e previsível pode parecer desgastante.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
          'Pode apenas ver o melhor nas pessoas quando mais ceticismo ou crítica é necessário.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Contribui para um clima favorável aos bons relacionamentos.',
          'Paciente para ouvir as ideias dos outros.',
          'Facilidade em explorar novas abordagens.',
          'Organização das ideias do grupo.',
        ],
      },
      dificuldades: {
        neutral: [
          'Pode ter dificuldade em ficar em silêncio, ouvir os próprios pensamentos e refletir isoladamente.',
          'Pode fazer o grupo perder o foco.',
          'Pode seguir uma ideia para evitar confronto, mesmo quando discorda.',
          'Pode apresentar inflexibilidade com normas e procedimentos já estabelecidos.',
        ],
      },
    },
  },
  DIC: {
    resumo: {
      female: '<p>Você tem uma combinação única de qualidades de diversos estilos e naturalmente adota uma abordagem mais generalista. Esta capacidade permite que você se adapte com facilidade a diferentes contextos sociais.</p><p>Você aborda pessoas e situações com energia e entusiasmo. Gosta de desafios, tem interesse em conhecer novas pessoas e demonstra habilidade social. Geralmente, oferece previsibilidade e consistência em suas interações e espera o mesmo dos outros. E quando precisa decidir algo, costuma fazer com firmeza.</p>',
      male: '<p>Você tem uma combinação única de qualidades de diversos estilos e naturalmente adota uma abordagem mais generalista. Esta capacidade permite que você se adapte com facilidade a diferentes contextos sociais.</p><p>Você aborda pessoas e situações com energia e entusiasmo. Gosta de desafios, tem interesse em conhecer novas pessoas e demonstra habilidade social. Geralmente, oferece previsibilidade e consistência em suas interações e espera o mesmo dos outros. E quando precisa decidir algo, costuma fazer com firmeza.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você aborda as pessoas com energia e entusiasmo.',
        'Assume com entusiasmo a liderança em situações sociais.',
        'Tende a ser uma pessoa criativa, determinada e gosta de alcançar objetivos por conta própria.',
        'Valoriza a interação com os outros.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta quando são objetivas e dão espaço para você usar suas habilidades de persuasão.</p>',
      },
      reunioes: {
        neutral: '<p>Com direcionamento, mas sem muita rigidez e marcadas somente quando necessárias.</p>',
      },
      emails: {
        neutral: '<p>Gostam quando os e-mails deixam claro o que se deseja, mas breves e com os próximos passos.</p>',
      },
      feedback: {
        neutral: '<p>Valoriza os feedbacks frequentes e lida bem tanto com o feedback mais amplo quanto com o mais específico.</p>',
      },
      conflitos: {
        neutral: '<p>Usa os conflitos para resolver problemas importantes.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você solicita feedbacks em intervalos regulares.',
          'Comunica-se de maneira espontânea.',
          'Usa horários flexíveis e abordagens abertas para gerenciamento de tempo.',
          'Facilita brainstorming em grupo para encontrar soluções para problemas.',
          'Sua atenção está nos resultados e valoriza expectativas realistas.',
        ],
        male: [
          'Você solicita feedbacks em intervalos regulares.',
          'Comunica-se de maneira espontânea.',
          'Usa horários flexíveis e abordagens abertas para gerenciamento de tempo.',
          'Facilita brainstorming em grupo para encontrar soluções para problemas.',
          'Sua atenção está nos resultados e valoriza expectativas realistas.',
        ],
      },
      cegos: {
        female: [
          'Pode apresentar dificuldade em seguir rotinas consistentes e previsíveis.',
          'Delega responsabilidades, mas tende a reter o controle, o que pode ser limitante para os outros.',
          'Salta entre muitas ideias ou oportunidades novas simultaneamente.',
          'Se distrai com as ideias novas e pode ter dificuldade em manter o foco.',
          'Pode faltar com empatia.',
        ],
        male: [
          'Pode apresentar dificuldade em seguir rotinas consistentes e previsíveis.',
          'Delega responsabilidades, mas retém o controle, o que pode ser limitante para os outros.',
          'Salta entre muitas ideias ou oportunidades novas simultaneamente.',
          'Se distrai com as ideias novas e pode ter dificuldade em manter o foco.',
          'Pode faltar com empatia.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Você adora ver resultados tangíveis, marcos e conquistas mensuráveis.',
          'Tomar decisões.',
          'Interagir frequentemente com um grupo diversificado de pessoas.',
          'Participar de discussões em grupo e sessões de brainstorming.',
          'Considerar como as outras pessoas pensam.',
          'Pedir feedbacks regularmente.',
        ],
        male: [
          'Você adora ver resultados tangíveis, marcos e conquistas mensuráveis.',
          'Tomar decisões.',
          'Interagir frequentemente com um grupo diversificado de pessoas.',
          'Participar de discussões em grupo e sessões de brainstorming.',
          'Considerar como as outras pessoas pensam.',
          'Pedir feedbacks regularmente.',
        ],
      },
      drena: {
        female: [
          'Comunicar detalhes de uma decisão importante.',
          'Auxiliar outras pessoas na elaboração de planos claramente definidos.',
          'Inspecionar e manter resultados de alta qualidade.',
          'Seguir agendas diárias estruturadas e consistentes.',
          'Trabalhar frequentemente com prazos apertados.',
        ],
        male: [
          'Comunicar detalhes de uma decisão importante.',
          'Auxiliar outras pessoas na elaboração de planos claramente definidos.',
          'Inspecionar e manter resultados de alta qualidade.',
          'Seguir agendas diárias estruturadas e consistentes.',
          'Trabalhar frequentemente com prazos apertados.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você fica ansiosa para assumir o comando e fornecer direção clara.',
          'Cria um ambiente de trabalho competitivo e dinâmico.',
          'Dá aos outros autonomia para encontrar suas próprias soluções para problemas.',
          'Espera muito de si mesma e das pessoas ao seu redor.',
        ],
        male: [
          'Fica ansioso para assumir o comando e fornecer direção clara.',
          'Cria um ambiente de trabalho competitivo e dinâmico.',
          'Dá aos outros autonomia para encontrar suas próprias soluções para problemas.',
          'Espera muito de si mesmo e das pessoas ao seu redor.',
        ],
      },
      pontosCegos: {
        female: [
          'Pode tumultuar o ambiente ao tentar resolver conflitos imediatamente com discussões verbais diretas.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
          'Pode realizar mudanças repentinas, sem comunicar previamente à equipe ou oferecer tempo adequado para preparação.',
          'Pode ter dificuldade de construir confiança e lealdade com comportamento consistente e previsível.',
        ],
        male: [
          'Pode tumultuar o ambiente ao tentar resolver conflitos imediatamente com discussões verbais diretas.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
          'Pode realizar mudanças repentinas, sem comunicar previamente à equipe ou oferecer tempo adequado para preparação.',
          'Pode ter dificuldade de construir confiança e lealdade com comportamento consistente e previsível.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Facilidade em enfrentar o que está estabelecido.',
          'Contribui para um clima favorável aos bons relacionamentos.',
          'Facilidade em explorar novas abordagens.',
          'Organização das ideias do grupo.',
        ],
      },
      dificuldades: {
        neutral: [
          'Dificuldade em receber críticas, devido à convicção de que está sempre certo, o que pode limitar o processo de aprendizado.',
          'Pode apresentar entusiasmo excessivo.',
          'Pode fazer o grupo perder o foco.',
        ],
      },
    },
  },
  DIS: {
    resumo: {
      female: '<p>Você tem uma combinação única de qualidades de diversos estilos e naturalmente adota uma abordagem mais generalista. Esta capacidade permite que você se adapte com facilidade a diferentes contextos sociais.</p><p>Você aborda pessoas e situações com energia e entusiasmo. Gosta de desafios, tem interesse em conhecer novas pessoas e demonstra habilidade social. Geralmente, demonstra sua consideração e sinceridade ao ouvir e responder às necessidades e pedidos dos outros. E quando precisa decidir algo, costuma fazer com firmeza.</p>',
      male: '<p>Você tem uma combinação única de qualidades de diversos estilos e naturalmente adota uma abordagem mais generalista. Esta capacidade permite que você se adapte com facilidade a diferentes contextos sociais.</p><p>Você aborda pessoas e situações com energia e entusiasmo. Gosta de desafios, tem interesse em conhecer novas pessoas e demonstra habilidade social. Geralmente, demonstra sua consideração e sinceridade ao ouvir e responder às necessidades e pedidos dos outros. E quando precisa decidir algo, costuma fazer com firmeza.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você tende a ser uma pessoa criativa, determinada e gosta de alcançar objetivos por conta própria.',
        'Segue as orientações de um líder de confiança.',
        'Valoriza a interação com os outros.',
        'Gosta de oferecer suporte e orientação aos outros.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta do tom descontraído, do compartilhamento de informações, e da atenção mútua.</p>',
      },
      reunioes: {
        neutral: '<p>Com direcionamento e marcadas somente quando necessárias.</p>',
      },
      emails: {
        neutral: '<p>Texto amigável, contextualizado e com próximos passos.</p>',
      },
      feedback: {
        neutral: '<p>Valoriza os feedbacks frequentes e lida bem tanto com o feedback mais amplo quanto com o mais específico.</p>',
      },
      conflitos: {
        neutral: '<p>Usa os conflitos para resolver problemas importantes.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você solicita feedback em intervalos regulares.',
          'Comunica-se de maneira espontânea.',
          'Usa horários flexíveis e abordagens abertas para gerenciamento de tempo.',
          'Facilita brainstorming em grupo para encontrar soluções para problemas.',
          'Valoriza as ações de planejamento focadas em resultados.',
        ],
        male: [
          'Você solicita feedback em intervalos regulares.',
          'Comunica-se de maneira espontânea.',
          'Usa horários flexíveis e abordagens abertas para gerenciamento de tempo.',
          'Facilita brainstorming em grupo para encontrar soluções para problemas.',
          'Valoriza as ações de planejamento focadas em resultados.',
        ],
      },
      cegos: {
        female: [
          'Delega responsabilidades, mas tende a reter o controle, o que pode ser limitante para os outros.',
          'Salta entre muitas ideias ou oportunidades novas simultaneamente.',
          'Se distrai com as ideias novas e pode ter dificuldade em manter o foco.',
          'Pode hesitar em experimentar soluções não testadas, mesmo que sejam potencialmente eficazes.',
        ],
        male: [
          'Delega responsabilidades, mas tende a reter o controle, o que pode ser limitante para os outros.',
          'Salta entre muitas ideias ou oportunidades novas simultaneamente.',
          'Se distrai com as ideias novas e pode ter dificuldade em manter o foco.',
          'Pode hesitar em experimentar soluções não testadas, mesmo que sejam potencialmente eficazes.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Você gosta de participar de discussões em grupo e sessões de brainstorming.',
          'Interagir frequentemente com um grupo diversificado de pessoas.',
          'Tomar decisões.',
          'Considerar como as outras pessoas pensam.',
          'Ajudar as pessoas de forma individualizada.',
        ],
        male: [
          'Você gosta de participar de discussões em grupo e sessões de brainstorming.',
          'Interagir frequentemente com um grupo diversificado de pessoas.',
          'Tomar decisões.',
          'Considerar como as outras pessoas pensam.',
          'Ajudar as pessoas de forma individualizada.',
        ],
      },
      drena: {
        female: [
          'Trabalhar sozinha por um período longo.',
          'Passar muito tempo pesquisando a causa raiz de um problema.',
          'Inspecionar e manter resultados de alta qualidade.',
          'Seguir agendas diárias estruturadas e consistentes.',
        ],
        male: [
          'Trabalhar sozinho por um período longo.',
          'Passar muito tempo pesquisando a causa raiz de um problema.',
          'Inspecionar e manter resultados de alta qualidade.',
          'Seguir agendas diárias estruturadas e consistentes.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Você cria um ambiente de trabalho competitivo e dinâmico.',
          'Dá aos outros autonomia para encontrar suas próprias soluções para problemas.',
          'Espera muito de si mesma e das pessoas ao seu redor.',
          'Se esforça para desenvolver a equipe com desafios e orientação individualizados.',
        ],
        male: [
          'Você cria um ambiente de trabalho competitivo e dinâmico.',
          'Dá aos outros autonomia para encontrar suas próprias soluções para problemas.',
          'Espera muito de si mesmo e das pessoas ao seu redor.',
          'Se esforça para desenvolver a equipe com desafios e orientação individualizados.',
        ],
      },
      pontosCegos: {
        female: [
          'Pode tumultuar o ambiente ao tentar resolver conflitos imediatamente com discussões verbais diretas.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
          'Pode ter dificuldade de construir confiança e lealdade com comportamento consistente e previsível.',
          'Pode apenas ver o melhor nas pessoas quando mais ceticismo ou crítica é necessário.',
        ],
        male: [
          'Pode tumultuar o ambiente ao tentar resolver conflitos imediatamente com discussões verbais diretas.',
          'Pode levar a equipe a perseguir vários objetivos ao mesmo tempo quando mais foco é necessário.',
          'Pode ter dificuldade de construir confiança e lealdade com comportamento consistente e previsível.',
          'Pode apenas ver o melhor nas pessoas quando mais ceticismo ou crítica é necessário.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Facilidade em enfrentar o que está estabelecido.',
          'Contribui para um clima favorável aos bons relacionamentos.',
          'Facilidade em explorar novas abordagens.',
          'Paciente para ouvir as ideias dos outros.',
        ],
      },
      dificuldades: {
        neutral: [
          'Dificuldade em receber críticas, devido à convicção de que está sempre certo, o que pode limitar o processo de aprendizado.',
          'Pode fazer o grupo perder o foco.',
          'Pode seguir uma ideia para evitar confronto, mesmo quando discorda.',
          'Pode levar muito tempo para se convencer da necessidade de uma inovação.',
        ],
      },
    },
  },
  DSC: {
    resumo: {
      female: '<p>Você tem uma combinação única de qualidades de diversos estilos e naturalmente adota uma abordagem mais generalista. Esta capacidade permite que você se adapte com facilidade a diferentes contextos sociais.</p><p>Na prática, você navega com habilidade entre a ênfase nos resultados e a atenção às pessoas. Suas decisões são tomadas baseadas em fatos, com firmeza e objetividade, e, ao mesmo tempo, você se mostra capaz de ouvir e responder com paciência às necessidades das pessoas.</p>',
      male: '<p>Você tem uma combinação única de qualidades de diversos estilos e naturalmente adota uma abordagem mais generalista. Esta capacidade permite que você se adapte com facilidade a diferentes contextos sociais.</p><p>Na prática, você navega com habilidade entre a ênfase nos resultados e a atenção às pessoas. Suas decisões são tomadas baseadas em fatos, com firmeza e objetividade, e, ao mesmo tempo, você se mostra capaz de ouvir e responder com paciência às necessidades das pessoas.</p>',
    },
    caracteristicas: {
      neutral: [
        'Você tende a ser uma pessoa determinada e gosta de alcançar objetivos por conta própria.',
        'Compromete-se com suas tarefas e colabora com a equipe.',
        'Segue as orientações de um líder de confiança.',
        'Gosta de oferecer suporte e orientação aos outros.',
      ],
    },
    comunicacao: {
      conversas: {
        neutral: '<p>Gosta do compartilhamento de informações, e de atenção mútua.</p>',
      },
      reunioes: {
        neutral: '<p>Com direcionamento e marcadas somente quando necessárias.</p>',
      },
      emails: {
        neutral: '<p>Gostam quando os e-mails deixam claro o que se deseja, de forma amigável, com sinceridade e contexto.</p>',
      },
      feedback: {
        neutral: '<p>Valoriza os feedbacks frequentes e lida bem tanto com o feedback mais amplo quanto com o mais específico.</p>',
      },
      conflitos: {
        neutral: '<p>Usa os conflitos para resolver problemas importantes.</p>',
      },
    },
    pontos: {
      resumo: {
        neutral: '<p>Todos temos pontos fortes e pontos cegos, e essa dinâmica torna-se ainda mais evidente no ambiente profissional, onde interagimos com pessoas diversas.</p>',
      },
      forcas: {
        female: [
          'Você solicita feedback em intervalos regulares.',
          'Valoriza as ações de planejamento focadas em resultados.',
          'Define expectativas realistas.',
          'Quando precisa delegar tarefas, você fornece informações específicas e detalhadas para garantir que tudo seja bem executado.',
        ],
        male: [
          'Você solicita feedback em intervalos regulares.',
          'Valoriza as ações de planejamento focadas em resultados.',
          'Define expectativas realistas.',
          'Quando precisa delegar tarefas, você fornece informações específicas e detalhadas para garantir que tudo seja bem executado.',
        ],
      },
      cegos: {
        female: [
          'Pode hesitar em experimentar soluções não testadas, mesmo que sejam potencialmente eficazes.',
          'Delega responsabilidades, mas tende a reter o controle, o que pode ser limitante para os outros.',
          'Pode se esquivar de ser direta ao comunicar informações negativas.',
          'Demonstra frustração quando seus padrões de desempenho não são atendidos.',
        ],
        male: [
          'Pode hesitar em experimentar soluções não testadas, mesmo que sejam potencialmente eficazes.',
          'Delega responsabilidades, mas tende a reter o controle, o que pode ser limitante para os outros.',
          'Pode se esquivar de ser direto ao comunicar informações negativas.',
          'Demonstra frustração quando seus padrões de desempenho não são atendidos.',
        ],
      },
    },
    motEstr: {
      resumo: {
        neutral: '<p>Quando as pessoas experimentam satisfação ou desconforto no trabalho, isso frequentemente está associado a atividades que geram ou drenam energia. Portanto, é crucial compreender quais atividades energizam e quais drenam cada indivíduo.</p>',
      },
      energiza: {
        female: [
          'Você adora ver resultados tangíveis, marcos e conquistas mensuráveis.',
          'Pedir feedback regularmente.',
          'Tomar decisões.',
          'Considerar como as outras pessoas pensam.',
          'Ajudar as pessoas de forma individualizada, com instruções detalhadas, garantindo que entendam completamente o que fazer.',
        ],
        male: [
          'Você adora ver resultados tangíveis, marcos e conquistas mensuráveis.',
          'Pedir feedback regularmente.',
          'Tomar decisões.',
          'Considerar como as outras pessoas pensam.',
          'Ajudar as pessoas de forma individualizada, com instruções detalhadas, garantindo que entendam completamente o que fazer.',
        ],
      },
      drena: {
        female: [
          'Assumir riscos significativos em situações imprevisíveis.',
          'Trabalhar frequentemente com prazos apertados.',
          'Receber um grande volume de feedback crítico com frequência.',
          'Perceber desalinhamentos no time.',
        ],
        male: [
          'Assumir riscos significativos em situações imprevisíveis.',
          'Trabalhar frequentemente com prazos apertados.',
          'Receber um grande volume de feedback crítico com frequência.',
          'Perceber desalinhamentos no time.',
        ],
      },
    },
    lideranca: {
      resumo: {
        neutral: '<p>Seu estilo de liderança compreende comportamentos que podem impulsionar a produtividade, engajamento e integração, ou prejudicar o trabalho, confiança e cooperação da equipe.</p>',
      },
      estilo: {
        female: [
          'Dá aos outros autonomia para encontrar suas próprias soluções para problemas.',
          'Focada em desenvolver a equipe com orientação e instrução individualizada.',
          'Espera muito de si mesma e das pessoas ao seu redor.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
        ],
        male: [
          'Dá aos outros autonomia para encontrar suas próprias soluções para problemas.',
          'Focado em desenvolver a equipe com orientação e instrução individualizada.',
          'Espera muito de si mesmo e das pessoas ao seu redor.',
          'Espera que os membros da equipe sejam estáveis, confiáveis e cooperativos.',
        ],
      },
      pontosCegos: {
        female: [
          'Às vezes, resolve conflitos imediatamente com discussões verbais diretas.',
          'Construir confiança e lealdade a longo prazo com comportamento consistente e previsível pode parecer desgastante.',
          'Pode perder boas oportunidades devido à alta sensibilidade ao risco.',
          'Às vezes, não permite tempo flexível suficiente para os membros da equipe se conhecerem bem e construírem confiança.',
        ],
        male: [
          'Às vezes, resolve conflitos imediatamente com discussões verbais diretas.',
          'Construir confiança e lealdade a longo prazo com comportamento consistente e previsível pode parecer desgastante.',
          'Pode perder boas oportunidades devido à alta sensibilidade ao risco.',
          'Às vezes, não permite tempo flexível suficiente para os membros da equipe se conhecerem bem e construírem confiança.',
        ],
      },
    },
    inovacao: {
      resumo: {
        neutral: '<p>Tecnologia e inovação são conceitos que tomaram conta do mercado e se tornaram o motor do crescimento econômico. Veja como você potencializa a inovação no seu time.</p>',
      },
      comoAcelera: {
        neutral: [
          'Persistente para fazer quantas vezes forem necessárias para viabilizar sua ideia.',
          'Organização das ideias do grupo.',
          'Paciente para ouvir as ideias dos outros.',
          'Pesquisas e estudos de viabilidades.',
        ],
      },
      dificuldades: {
        neutral: [
          'Dificuldade em receber críticas, devido à crença de que está sempre certo, o que pode limitar o processo de aprendizado.',
          'Pode levar muito tempo para se convencer da necessidade de uma inovação.',
          'Inflexibilidade com normas e procedimentos já estabelecidos.',
          'Pode seguir uma ideia para evitar confronto, mesmo quando discorda.',
        ],
      },
    },
  },
};
