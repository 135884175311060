/* eslint-disable no-unused-vars */
import styled, { keyframes } from 'styled-components';
import bg1 from '../../assets/img/bumpers/bg-1.jpg';
import bg2 from '../../assets/img/bumpers/bg-2.jpg';
import bg3 from '../../assets/img/bumpers/bg-3.jpg';
import bg4 from '../../assets/img/bumpers/bg-4.jpg';
import bg5 from '../../assets/img/bumpers/bg-5.jpg';
import bg6 from '../../assets/img/bumpers/bg-6.jpg';
import shape1 from '../../assets/img/bumpers/shape-1.svg';
import shape2 from '../../assets/img/bumpers/shape-2.svg';
import shape3 from '../../assets/img/bumpers/shape-3.svg';
import shape4 from '../../assets/img/bumpers/shape-4.svg';
import shape5 from '../../assets/img/bumpers/shape-5.svg';

const bgs = {
  bg1,
  bg2,
  bg3,
  bg4,
  bg5,
  bg6,
};
const shapes = {
  shape1,
  shape2,
  shape3,
  shape4,
  shape5,
};

export const loadAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const CoverContainer = styled.div`
  position: relative;
  width: 100%;
  background-image: ${({ bg }) => (bg ? `url(${bgs[`bg${bg}`]})` : `url(${bg1})`)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
export const ShapeLayer = styled.div`
  position: relative;
  padding-top: 50.25%; /* Proporção 16:9 (9 / 16 * 100) */
  background-image: ${({ shape }) => (shape ? `url(${shapes[`shape${shape}`]})` : `url(${shape1})`)};;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  top:  0;
`;

export const SoraLayer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90%;
  text-align: center;
  img {
    height: 100%;
    width: auto;
  }
`;
export const LoaderContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const LoaderBefore = styled.div`
  content: "";
  position: absolute;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, ${({ theme }) => theme.colors.secondary.main} 0%, ${({ theme }) => theme.colors.primary.light} 100%);
  animation: ${loadAnimation} 0.5s infinite linear;
`;

export const LoaderAfter = styled.div`
  content: "";
  position: absolute;
  border-radius: inherit;
  width: 85%;
  height: 85%;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
