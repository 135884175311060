// eslint-disable-next-line
// @ts-nocheck
import {
  NovuProvider, PopoverNotificationCenter, NotificationBell, useNotifications,
} from '@novu/notification-center';
import { Sheet } from 'react-modal-sheet';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SoraPreocupada from '../../assets/img/Sora/Preocupada.svg';
import {
  HeaderNavContainer,
  NotificationBody,
  NotificationCreatedAt,
  NotificationHeader,
  NotificationMessage,
  NotificationRead,
  NotificationTitle,
  SingleNotificationContainer,
} from './styles';
import PersoraButton from '../Button';
import defaultTheme from '../../assets/styles/themes/default';
import TermsOfUseSheet from '../../pages/Sheets/TermsOfUse';
import PrivacyPolicySheet from '../../pages/Sheets/PrivacyPolicy';
import {
  Logout, UserPlus, Settings, ScreenShare, DocumentFilled, EyeClosed, Hamburger, CheckSmall,
  User1,
} from '../DuotoneIcon';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import { tempoDecorrido } from '../../utils/dateUtils';
// import ModalBumpers from '../ModalBumpers';
import { UserContext } from '../../Context/UserContext';

const { Context } = require('../../Context/AuthContext');

// eslint-disable-next-line
const novuTest = {
  name: 'bumpers',
  to: {
    subscriberId: '65fdd36cbd08581cc3492614',
  },
  payload: {
    titulo: 'Você está formando o seu time na Persora!',
    mensagem: 'Você tem convidados pendentes de primeiro acesso no seu time na Persora.',
    mensagemPush: 'Você tem convidados pendentes de primeiro acesso no seu time na Persora.',
    bumperId: 'l-signUp-b1',
    customContent1: '2',
    customContent2: '<p>Convite 1: email@convidado1.com<br/>Convite 2: email@convidado2.com</p>',
    url: 'http://localhost:8000/userSpace?activeTapBar=time&triggerBumper=true&bumperId=l-invitesAccepted-b1&customContent1=3&customContent2=%3Cp%3EConvite%201%3A%20email%40convidado1.com%3Cbr%2F%3EConvite%202%3A%20email%40convidado2.com%3C%2Fp%3E',
  },
  overrides: {
    'one-signal': {
      deviceTokens: 'userOneSignalDeviceId',
    },
  },
};

function NovuFooter() {}

function NovuEmptyState() {
  return (
    <div className="text-center">
      <img className="mt-5" src={SoraPreocupada} alt="SoraPreocupada" width="130px" />
      <p className="mt-3" style={{ color: defaultTheme.colors.black60 }}>Ainda não há notificações para você!</p>
    </div>
  );
}

export default function Header({
  userData,
  // workspaceTeams,
  currentTeam,
  activeTapBar,
  setActiveTapBar,
  setAddUserSheetOpen,
  setTeamSettingsSheetOpen,
  setPremiumPricesSheetOpen,
  countCurrentTeamUsers,
}) {
  const { authenticated, handleLogout, setBumperOptionalProps } = useContext(Context);
  const [userSettingsSheetIsOpen, setUserSettingsSheetOpen] = useState(false);
  const [termsOfUseSheetIsOpen, setTermsOfUseSheetOpen] = useState(false);
  const [privacyPolicySheetIsOpen, setPrivacyPolicySheetOpen] = useState(false);
  // const [bumperId, setBumperId] = useState('');
  // const [triggerBumper, setTriggerBumper] = useState(false);
  const { currentUserContextData } = useContext(UserContext);

  function handleOnNotificationClick(message) {
    console.log('click', message);
    if (message?.payload?.url) {
      // window.location.href = message.payload.url;
    }
    if (message?.payload?.activeTapBar) {
      setActiveTapBar(message.payload.activeTapBar);
    }
    if (message?.payload?.bumperId) {
      setBumperOptionalProps({
        bumperId: message.payload.bumperId,
        bumperAvulso: !!message?.payload?.bumperAvulso,
        customContent1: message?.payload?.customContent1,
        customContent2: message?.payload?.customContent2,
      });
      // // setBumperId(message.payload.bumperId);
      // // setTriggerBumper(true);
      // // get current url with current params and set triggerBumper e bumperId
      // const urlParams = new URLSearchParams(window.location.search);
      // urlParams.set('triggerBumper', true);
      // urlParams.set('bumperId', message.payload.bumperId);
      // if (message?.payload?.customContent1) {
      //   urlParams.set('customContent1', message.payload.customContent1);
      // }
      // if (message?.payload?.customContent2) {
      //   urlParams.set('customContent2', message.payload.customContent2);
      // }
      // const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      // window.history.pushState({}, '', newUrl);
    }
    return null;
  }
  // eslint-disable-next-line
  function MyCystomPopoverNotificationCenter() {
    const { markNotificationAsUnRead, markNotificationAsRead, removeMessage } = useNotifications();

    const handleMarkAsRead = (messageId) => {
      markNotificationAsRead(messageId);
    };
    const handleMarkAsUnread = (messageId) => {
      markNotificationAsUnRead(messageId);
    };
    const handleDeleteMessage = (messageId) => {
      removeMessage(messageId);
    };

    return (
      <PopoverNotificationCenter
        colorScheme="light"
        // eslint-disable-next-line
        footer={NovuFooter}
        emptyState={<NovuEmptyState />}
        // eslint-disable-next-line
        onNotificationClick={handleOnNotificationClick}
      // eslint-disable-next-line
      listItem={(
        notification,
        handleActionButtonClick,
        handleNotificationClick,
      ) => (
        <SingleNotificationContainer>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              handleNotificationClick();
            }}
          >
            <NotificationHeader>
              {notification.read
                ? (
                  <NotificationRead className="read">
                    <CheckSmall tamanho={22} />
                    {' '}
                    Lida
                  </NotificationRead>
                )
                : (
                  <NotificationRead>
                    {' '}
                    Não lida
                  </NotificationRead>
                )}
              <NotificationCreatedAt>
                {tempoDecorrido(notification.createdAt)}
              </NotificationCreatedAt>
            </NotificationHeader>
            <NotificationBody>
              <NotificationTitle className={notification.read && 'read'}>
                {notification.payload.titulo ? notification.payload.titulo : 'Título teste só'}
              </NotificationTitle>
              <NotificationMessage className={notification.read && 'read'}>
                {notification.payload.mensagemPush ? notification.payload.mensagemPush : 'Mensagem teste só'}
              </NotificationMessage>
            </NotificationBody>
          </a>
          <div style={{ display: 'flex' }}>
            {notification.read
              ? (
                <PersoraButton estilo="ghost" tamanho="mini" justIcon className="d-none" onClick={() => handleMarkAsUnread(notification._id)}>Mark as Unread</PersoraButton>
              ) : (
                <PersoraButton estilo="ghost" tamanho="mini" justIcon className="d-none" onClick={() => handleMarkAsRead(notification._id)}>Mark as Read</PersoraButton>
              )}
            <PersoraButton estilo="ghost" tamanho="mini" justIcon className="d-none" onClick={() => handleDeleteMessage(notification._id)}>Delete</PersoraButton>
          </div>
        </SingleNotificationContainer>
      )}
      >
        {({ unseenCount }) => (
          <div style={{ padding: '5px 7px', cursor: 'pointer' }}>
            <NotificationBell unseenCount={unseenCount} />
          </div>
        )}
      </PopoverNotificationCenter>
    );
  }

  // eslint-disable-next-line
const novuTest = {
    name: 'bumpers',
    to: {
      subscriberId: '65fdd36cbd08581cc3492614',
    },
    payload: {
      titulo: 'Você está formando o seu time na Persora!',
      mensagem: 'Você tem convidados pendentes de primeiro acesso no seu time na Persora.',
      mensagemPush: 'Você tem convidados pendentes de primeiro acesso no seu time na Persora.',
      bumperId: 'l-signUp-b1',
      customContent1: '2',
      customContent2: '<p>Convite 1: email@convidado1.com<br/>Convite 2: email@convidado2.com</p>',
      url: 'http://localhost:8000/userSpace?activeTapBar=time&triggerBumper=true&bumperId=l-invitesAccepted-b1&customContent1=3&customContent2=%3Cp%3EConvite%201%3A%20email%40convidado1.com%3Cbr%2F%3EConvite%202%3A%20email%40convidado2.com%3C%2Fp%3E',
    },
    overrides: {
      'one-signal': {
        deviceTokens: 'userOneSignalDeviceId',
      },
    },
  };

  // eslint-disable-next-line
const novuStyles = {
    layout: {
      root: {
        maxWidth: '340px !important',
      },
    },
    loader: {
      root: {
        stroke: defaultTheme.colors.secondary.main,
      },
    },
    popover: {
      arrow: {
        right: '30px',
      },
      dropdown: {
        borderRadius: '10px',
        marginTop: '22px',
        maxWidth: '340px !important',
      },
    },
    notifications: {
      listItem: {
        unread: {
          '::before': { background: defaultTheme.colors.secondary.main },
        },
      },
    },
  };

  // console.log(workspaceTeams); // DEBUG

  if (activeTapBar === 'time' || activeTapBar === 'organizacao') {
    return (
      <HeaderNavContainer>
        {/* {triggerBumper && (
          <ModalBumpers
            bumperId={bumperId}
            trigger={triggerBumper}
            setTriggerBumper={setTriggerBumper}
          />
        )} */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="me-2" style={{ fontSize: 22 }}>
            {currentTeam.emoji ? currentTeam.emoji : '🤖'}
          </div>
          {' '}
          <h5 className="mb-0 medium">
            {currentTeam.name}
          </h5>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {userData.type === 'Líder'
          && currentUserContextData.workspaceContext.premium !== true
          && (
            // countCurrentTeamUsers >= process.env.REACT_APP_LIMIT_USER
            countCurrentTeamUsers >= 100 // para liberar limite
              ? (
                <PersoraButton
                  estilo="ghost"
                  tamanho="mini"
                  justIcon
                  onClick={() => {
                    if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
                      ReactGA.event({
                        category: 'Premium',
                        action: 'convidar_sem_creditos',
                      });
                      mixpanel.track('convidar_sem_creditos', {
                        category: 'Premium',
                        company_id: currentUserContextData._idWorkspace[0],
                      });
                    }
                    setPremiumPricesSheetOpen(true);
                  }}
                >
                  <UserPlus />
                </PersoraButton>
              )
              : (
                <PersoraButton estilo="ghost" tamanho="mini" justIcon onClick={() => setAddUserSheetOpen(true)}><UserPlus /></PersoraButton>
              )
          )}
          {currentUserContextData.workspaceContext.premium === true
          && userData.type === 'Líder'
          && (
            <PersoraButton estilo="ghost" tamanho="mini" justIcon onClick={() => setAddUserSheetOpen(true)}><UserPlus /></PersoraButton>
          )}
          {userData.type === 'Líder' && (
            <PersoraButton estilo="ghost" tamanho="mini" justIcon onClick={() => setTeamSettingsSheetOpen(true)}><Settings /></PersoraButton>
          )}
          <NovuProvider
            backendUrl={process.env.REACT_APP_NOVU_API}
            socketUrl={process.env.REACT_APP_NOVU_WS}
            subscriberId={userData._id}
            applicationIdentifier={process.env.REACT_APP_NOVU_APP_ID}
            i18n={{
              lang: 'pt',
              translations: {
                notifications: 'Notificações',
                markAllAsRead: 'Marcar tudo como lido',
                markAsRead: 'Marcar como lida',
                removeMessage: 'Remover mensagem',
              },
            }}
            styles={novuStyles}
          >
            <MyCystomPopoverNotificationCenter />
          </NovuProvider>
        </div>
        <Link to="/userSpace" style={{ display: 'none' }}>LoggedUser</Link>

      </HeaderNavContainer>
    );
  }
  return (
    <HeaderNavContainer>
      {/* {triggerBumper && (
        <>
          <ModalBumpers
            bumperId={bumperId}
            trigger={triggerBumper}
            setTriggerBumper={setTriggerBumper}
          />
          {console.log('RODOOOOU NO HEADER?>!!!!')}
        </>
      )} */}
      {activeTapBar === 'user' ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="me-2">
            <User1 />
          </div>
          {' '}
          <h5 className="mb-0 medium">
            {/* Conta e configurações */}
            Minha conta
          </h5>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="me-2" style={{ fontSize: '1.3rem' }}>
            👋
          </div>
          {' '}
          <h5 className="mb-0 medium">
            {`Olá, ${userData.name.split(' ')[0]}!`}
          </h5>
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <NovuProvider
          backendUrl={process.env.REACT_APP_NOVU_API}
          socketUrl={process.env.REACT_APP_NOVU_WS}
          subscriberId={userData._id}
          applicationIdentifier={process.env.REACT_APP_NOVU_APP_ID}
          i18n={{
            lang: 'pt',
            translations: {
              notifications: 'Notificações',
              markAllAsRead: 'Marcar tudo como lido',
              markAsRead: 'Marcar como lida',
              removeMessage: 'Remover mensagem',
            },
          }}
          styles={novuStyles}
        >
          <MyCystomPopoverNotificationCenter />
        </NovuProvider>
        <PersoraButton estilo="ghost" tamanho="mini" className=" d-none" justIcon onClick={() => setUserSettingsSheetOpen(true)}><Hamburger /></PersoraButton>
      </div>
      <Link to="/userSpace" style={{ display: 'none' }}>LoggedUser</Link>

      <Sheet
        isOpen={userSettingsSheetIsOpen}
        onClose={() => setUserSettingsSheetOpen(false)}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Sheet.Scroller>
              <div style={{ minHeight: 122, padding: '0 24px' }} className="pb-5">
                {authenticated && (
                  <div>
                    <Link to="welcome?revisit=true" style={{ textDecoration: 'none' }} onClick={() => setUserSettingsSheetOpen(false)}>
                      <PersoraButton type="button" estilo="ghost" tamanho="mini" className="mt-2">
                        <ScreenShare cor={defaultTheme.colors.primary.main} tamanho={22} />
                        Rever Boas-Vindas
                      </PersoraButton>
                    </Link>
                    <hr />
                    <PersoraButton type="button" estilo="ghost" tamanho="mini" onClick={() => setTermsOfUseSheetOpen(true)} className="mb-1">
                      <DocumentFilled cor={defaultTheme.colors.primary.main} tamanho={22} />
                      {' '}
                      Termos de Uso
                      {' '}
                    </PersoraButton>
                    <PersoraButton type="button" estilo="ghost" tamanho="mini" onClick={() => setPrivacyPolicySheetOpen(true)} className="mb-2">
                      <EyeClosed cor={defaultTheme.colors.primary.main} tamanho={22} />
                      {' '}
                      Política de Privacidade
                      {' '}
                    </PersoraButton>
                    <hr />
                    <PersoraButton type="button" estilo="ghost" tamanho="mini" onClick={handleLogout} style={{ color: defaultTheme.colors.disc.d.dark }}>
                      <Logout cor={defaultTheme.colors.disc.d.dark} tamanho={22} />
                      {' '}
                      Desconectar da Persora
                      {' '}
                    </PersoraButton>
                  </div>
                )}
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setUserSettingsSheetOpen(false)} />
      </Sheet>
      <Sheet
        isOpen={termsOfUseSheetIsOpen}
        onClose={() => setTermsOfUseSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        // detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
      >
        <TermsOfUseSheet />
        <Sheet.Backdrop onTap={() => setTermsOfUseSheetOpen(false)} />
      </Sheet>
      <Sheet
        isOpen={privacyPolicySheetIsOpen}
        onClose={() => setPrivacyPolicySheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        // detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
      >
        <PrivacyPolicySheet />
        <Sheet.Backdrop onTap={() => setPrivacyPolicySheetOpen(false)} />
      </Sheet>
    </HeaderNavContainer>
  );
}
Header.propTypes = {
  userData: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    owner: PropTypes.bool,
    _id: PropTypes.string,
  }),
  // workspaceTeams: PropTypes.arrayOf(PropTypes.shape({
  //   name: PropTypes.string,
  //   emoji: PropTypes.string,
  // })),
  currentTeam: PropTypes.shape({
    name: PropTypes.string,
    emoji: PropTypes.string,
  }).isRequired,
  activeTapBar: PropTypes.string.isRequired,
  setActiveTapBar: PropTypes.func.isRequired,
  setAddUserSheetOpen: PropTypes.func.isRequired,
  setTeamSettingsSheetOpen: PropTypes.func.isRequired,
  setPremiumPricesSheetOpen: PropTypes.func.isRequired,
  countCurrentTeamUsers: PropTypes.number.isRequired,
};
Header.defaultProps = {
  userData: {},
  // workspaceTeams: [],
};

// {/* <>
// {' '}
// <nav>
//   {' '}
//   {!authenticated && (<Link to="/">Login</Link>)}
//   {!authenticated && (<Link to="/signup">Signup</Link>)}
//   <Link to="/userSpace">LoggedUser</Link>
//   {authenticated && (<button type="button" onClick={handleLogout}>Sair</button>)}
// </nav>
// </> */}
