import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.alerts.generic.background};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.alerts.generic.textColor};
  box-shadow: 0px 2px 12px 0px rgba(6, 16, 42, 0.10);

  &.success {
    background-color: ${({ theme }) => theme.colors.alerts.success.background};
    color: ${({ theme }) => theme.colors.alerts.success.textColor};
    h5 {
      color: ${({ theme }) => theme.colors.alerts.success.titleColor};
    }
  }

  &.warning {
    background-color: ${({ theme }) => theme.colors.alerts.warning.background};
    color: ${({ theme }) => theme.colors.alerts.warning.textColor};
    h5 {
      color: ${({ theme }) => theme.colors.alerts.warning.titleColor};
    }
  }

  &.danger {
    background-color: ${({ theme }) => theme.colors.alerts.danger.background};
    color: ${({ theme }) => theme.colors.alerts.danger.textColor};
    h5 {
      color: ${({ theme }) => theme.colors.alerts.danger.titleColor};
    }
  }

  &.info {
    background-color: ${({ theme }) => theme.colors.alerts.info.background};
    color: ${({ theme }) => theme.colors.alerts.info.textColor};
    h5 {
      color: ${({ theme }) => theme.colors.alerts.info.titleColor};
    }
  }
  &.reference {
    background-color: #f7f7f7;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.primary.light};
    h5 {
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }

`;

export const Header = styled.div`
  padding: ${({ theme }) => theme.spaces.screenEdge};
  color: ${({ theme }) => theme.colors.alerts.generic.titleColor};
  display: flex;
  align-items: start;
  position: relative;

  .close {
    position: absolute;
    top: 12px;
    right: 12px;
  }
`;

export const Icon = styled.div`
  font-size: 26px;
  margin-right: 12px;
  line-height: 26px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const TitleBefore = styled.p`
  padding: ${({ theme }) => theme.spaces.screenEdge};
  padding-bottom: 0;
  margin-bottom: 0%;
  font-weight: 600;
  opacity: .8;
`;
export const Title = styled.div`
  width: calc(100% - 70px);
  padding-top: 3px;
  text-wrap: balance;
  h5 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  &.actionPlan h5 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.6rem;
  }
`;

export const Content = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.opacityBlack10};
  padding: ${({ theme }) => theme.spaces.screenEdge};

  &.actionPlan {
    border-top: none !important;
    padding-top: 0;
  }

  ul, ol {
    padding: 0 20px;
    margin-bottom: 3px;
  }
`;
