/* eslint-disable no-unused-vars */
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { Sheet } from 'react-modal-sheet';
import { tailChase } from 'ldrs';

import '@remirror/styles/all.css';
import {
  Remirror, useActive, useCommands, useRemirror,
} from '@remirror/react';
import { BoldExtension } from '@remirror/extension-bold';
import { ItalicExtension } from '@remirror/extension-italic';
import { UnderlineExtension } from '@remirror/extension-underline';
import { BulletListExtension, OrderedListExtension } from '@remirror/extension-list';
import { HorizontalRuleExtension } from '@remirror/extension-horizontal-rule';
// import { FontSizeExtension } from '@remirror/extension-font-size';
import { HeadingExtension } from '@remirror/extension-heading';
import { prosemirrorNodeToHtml } from '@remirror/core';

import {
  ToggleBoldButton,
  ToggleItalicButton,
  ToggleUnderlineButton,
  ToggleBulletListButton,
  ToggleOrderedListButton,
  InsertHorizontalRuleButton,
  Toolbar,
  ToggleHeadingButton,
} from '@remirror/react-ui';

import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import {
  Accordion, Col, Form, Row,
} from 'react-bootstrap';
import SoraNerd from '../../assets/img/Sora/Nerd.svg';
import api from '../../services/backend';

import { Context } from '../../Context/AuthContext';
import { UserContext } from '../../Context/UserContext';

// modules styles
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import {
  Content, PageFlexContainer, MainContainer,
} from './styles';
import { HeaderNavContainer } from '../../components/Header/styles';
import defaultTheme from '../../assets/styles/themes/default';

import PersoraButton from '../../components/Button';
import MapeamentoLoader from '../../components/MapeamentoLoader';
import {
  Bell,
  Bolt,
  ChevronSmallLeft,
  ChevronSmallUp,
  Clock,
  Cross,
  Filters,
  Mail,
  MessageSquareLines,
  Send2,
  Smartphone,
  Star2,
} from '../../components/DuotoneIcon';
import SoraBumperCover from '../../components/SoraBumperCover';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';

tailChase.register();
// import Disclaimer from '../../components/Disclaimer';

SwiperCore.use([Pagination]);

const gerencialNotificacoesDivStyle = {
  display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '17px', marginBottom: '12px',
};
const renderToggleSwitch = (DuotoneIcon, title, toggleState, handleToggle) => (
  <div style={gerencialNotificacoesDivStyle}>
    <div style={{ display: 'flex', gap: '8px' }}>
      {DuotoneIcon}
      {' '}
      {title}
    </div>
    {/* eslint-disable-next-line */}
    <label>
      <input
        type="checkbox"
        className="toggle-checkbox"
        onClick={() => handleToggle()}
        checked={toggleState}
        readOnly
      />
      <div className="toggle-switch" />
    </label>
  </div>
);

const defaultContent = `<h1>Que tal caprichar no título aqui em cima? 😎</h1><p>Começando com um detalhe: as listas com <em>"bullet points"</em> ganham um fundo azulzinho. 🔵</p><ul><li><p>Sobre o uso do emoji nas listas, particularmente prefiro que eles venham no final da frase. 😬</p></li><li><p>😱 Colocar o emoji no começo deixa a coisa meio esquisita quando rola uma quebra de linha.</p></li><li><p>Mas claro, não tem certo ou errado… só o que faz sentido pra você! 💁‍♂️</p></li></ul><p>Aqui temos um parágrafo normalzinho, sem firulas. </p><p>E aqui mais um outro parágrafo. Aproveitando, as listas numeradas não recebem o fundo azul. 😉</p><ol><li><p>Coisas que eu adoro no café da manhã 🥐</p></li><li><p>Ideias malucas que tive durante o banho 🚿</p></li><li><p>Lugares que ainda quero visitar (mas só se não chover) 🌧️</p></li></ol><p>Por fim, lembrem de colocar uma assinatura para a Sora.</p><p>Sora. ✨</p>
`;

function MyEditor({
  // eslint-disable-next-line
  setContentHtml, contentHtml, setContentObject, contentObject,
}) {
  // const [contentHtml, setContentHtml] = useState('');
  // const [contentObject, setContentObject] = useState({});
  const handleChange = ({ state }) => {
    const content = state.doc.content.toJSON();
    const htmlContent = prosemirrorNodeToHtml(state.doc); // Convertendo para HTML
    setContentObject(content);
    setContentHtml(htmlContent);
  };
  const { manager, state, onChange } = useRemirror({
    extensions: () => [
      new BoldExtension(),
      new ItalicExtension(),
      new UnderlineExtension(),
      new HorizontalRuleExtension(),
      new BulletListExtension(),
      new OrderedListExtension(),
      // new FontSizeExtension({ defaultSize: '16', unit: 'px' }),
      new HeadingExtension(),
    ],
    content: defaultContent,
    stringHandler: 'html',
  });

  const editoButtonStyles = {
    firstButton: {
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
    },
    lastButton: {
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
    },
    centerButton: {
      borderRadius: '0',
    },
  };

  return (
    <div
      className="remirror-theme remirror-sendBumper"
      style={{
        background: '#fff',
        padding: '8px',
        paddingBottom: '2px',
        borderRadius: '8px',
      }}
    >
      {/* the className is used to define css variables necessary for the editor */}
      <Remirror
        manager={manager}
        initialContent={state}
        onChange={handleChange}
        autoFocus
        autoRender="end"
      >
        <Toolbar>
          <ToggleHeadingButton style={editoButtonStyles.firstButton} label="Título" />
          <InsertHorizontalRuleButton className="me-2" />
          <ToggleItalicButton style={editoButtonStyles.firstButton} />
          <ToggleBoldButton style={editoButtonStyles.centerButton} />
          <ToggleUnderlineButton style={editoButtonStyles.lastButton} className="me-2" />
          <ToggleBulletListButton style={editoButtonStyles.firstButton} />
          <ToggleOrderedListButton style={editoButtonStyles.lastButton} className="me-0" />
          {/* <FontSizeButtons /> */}
        </Toolbar>
      </Remirror>
      <div className="d-none">
        <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
        <pre>
          {JSON.stringify(contentObject, null, 2)}
        </pre>
      </div>
    </div>
  );
}

function styledH1andUl(contentHtml) {
  const styledH1 = contentHtml.replace(/<h1>/g, '<h1 style="font-weight: 400; color:#223B59; font-size: 2rem; line-height: 2.4rem;">');
  const styledUl = styledH1.replace(/<ul>/g, '<ul style="background: #E7F6FF; padding: 20px 25px !important; padding-left: 40px !important; border-radius: 16px; margin-top: 20px !important; margin-bottom: 25px !important;">');
  return styledUl;
}

export default function SendBumperPage() {
  const { handleCurrentUserData } = useContext(UserContext);
  const { handleLogout } = useContext(Context);
  const [currentUser, setUser] = useState([]);
  const history = useHistory();
  const [reviewBumperSheetIsOpen, setReviewBumperSheetOpen] = useState(false);

  // ===> channelOptions
  const [sendEmailOption, setSendEmailOption] = useState(false);
  const handleToggleSendEmail = () => {
    setSendEmailOption(!sendEmailOption);
  };
  const [sendInAppOption, setSendInAppOption] = useState(false);
  const handleToggleSendInApp = () => {
    setSendInAppOption(!sendInAppOption);
  };
  const [sendPushOption, setSendPushOption] = useState(false);
  const handleToggleSendPush = () => {
    setSendPushOption(!sendPushOption);
  };

  // ===> workspaceOptions
  const [totalUsersInvitedCondition, setTotalUsersInvitedCondition] = useState('Indiferente');
  const totalUsersInvitedConditionOptions = [
    { label: 'Maior ou igual a ($gte)', value: '$gte' },
    { label: 'Menor ou igual a ($lte)', value: '$lte' },
    { label: 'Igual a ($eq)', value: '$eq' },
    { label: 'Indiferente', value: 'Indiferente' },
  ];
  const handleSetTotalUsersInvitedConditionOption = (value) => {
    setTotalUsersInvitedCondition(value);
  };
  const [totalUsersInvited, setTotalUsersInvited] = useState(0);
  const handleSetTotalUsersInvited = (value) => {
    // Converte o valor para um número inteiro
    const intValue = parseInt(value, 10);
    // Verifica se o valor é um número, inteiro, e maior que 0
    if (!Number.isNaN(intValue) && Number.isInteger(intValue) && intValue >= 0) {
      setTotalUsersInvited(intValue);
    } else {
    // Caso contrário, você pode decidir o que fazer
      setTotalUsersInvited(0); // Exemplo: definir para 1 se o valor não for válido
    }
  };

  const [totalUsersMappedCondition, setTotalUsersMappedCondition] = useState('Indiferente');
  const totalUsersMappedConditionOptions = [
    { label: 'Maior ou igual a ($gte)', value: '$gte' },
    { label: 'Menor ou igual a ($lte)', value: '$lte' },
    { label: 'Igual a ($eq)', value: '$eq' },
    { label: 'Indiferente', value: 'Indiferente' },
  ];
  const handleSetTotalUsersMappedConditionOption = (value) => {
    setTotalUsersMappedCondition(value);
  };
  const [totalUsersMapped, setTotalUsersMapped] = useState(1);
  const handleSetTotalUsersMapped = (value) => {
    // Converte o valor para um número inteiro
    const intValue = parseInt(value, 10);
    // Verifica se o valor é um número, inteiro, e maior que 0
    if (!Number.isNaN(intValue) && Number.isInteger(intValue) && intValue > 0) {
      setTotalUsersMapped(intValue);
    } else {
    // Caso contrário, você pode decidir o que fazer
      setTotalUsersMapped(1); // Exemplo: definir para 1 se o valor não for válido
    }
  };

  // ===> userOptions
  const userTypeOptions = [
    { label: 'Apenas líderes', value: 'Líder' },
    { label: 'Apenas liderados', value: 'Colaborador' },
    { label: 'Todos os usuários', value: 'Indiferente' },
  ];
  const [userType, setUserType] = useState('');
  const handleSetUserTypeOption = (value) => {
    setUserType(value);
  };
  const userWithProfileOptions = [
    { label: 'Usuários com estilo mapeado', value: 'ComPerfil' },
    { label: 'Usuários sem estilo mapeado', value: 'SemPerfil' },
    { label: 'Indiferente', value: 'Indiferente' },
  ];
  const [userWithProfile, setUserWithProfile] = useState('');
  const handleSetUserWithProfileOption = (value) => {
    setUserWithProfile(value);
  };
  const userWithFirstAuthOptions = [
    { label: 'Usuários que já autenticaram', value: 'ComFirstAuth' },
    { label: 'Usuários que ainda não se autenticaram', value: 'SemFirstAuth' },
    { label: 'Indiferente', value: 'Indiferente' },
  ];
  const [userWithFirstAuth, setUserWithFirstAuth] = useState('');
  const handleSetUserWithFirstAuthOption = (value) => {
    setUserWithFirstAuth(value);
  };
  const [activeTapBar, setActiveTapBar] = useState('');
  const [activeTapBarOptions] = useState([
    { label: 'Início', value: 'home' },
    { label: 'Time', value: 'time' },
    { label: 'Indicadores', value: 'organizacao' },
    { label: 'Usuário', value: 'user' },
  ]);
  const handleSetActiveTapBarOption = (value) => {
    setActiveTapBar(value);
  };

  const [emailSubject, setEmailSubject] = useState('');
  const handleEmailSubject = (value) => {
    setEmailSubject(value);
  };
  const [pushTitle, setPushTitle] = useState('');
  const handlePushTitle = (value) => {
    setPushTitle(value);
  };
  const [pushMessage, setPushMessage] = useState('');
  const handlePushMessage = (value) => {
    setPushMessage(value);
  };

  const [contentHtml, setContentHtml] = useState('');
  const [contentObject, setContentObject] = useState({});

  const initialValue = [
    {
      type: 'paragraph',
      children: [
        {
          type: 'paragraph',
          children: [
            { text: 'This is editable ' },
            { text: 'rich', bold: true },
            { text: ' text, ' },
            { text: 'much', italic: true },
            { text: ' better than a ' },
            { text: '<textarea>', code: true },
            { text: '!' },
          ],
        },
      ],
    },
  ];

  const handleCloseEnviarBumper = () => {
    Swal.fire({
      html: `
        Você deseja realmente sair do envio de bumper?<br/><b>Todas as informações já preenchidas serão perdidas</b>.
      `,
      showCancelButton: true,
      confirmButtonText: 'Sair',
      cancelButtonText: 'Continuar editando',
      customClass: {
        confirmButton: 'swal2-button-full-width swal2-button-color-outline-red',
        cancelButton: 'swal2-button-full-width swal2-button-color-filled-primary-main',
      },
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        history.push('/userSpace?activeTapBar=user');
      }
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get('/user');
        setUser(data.user);
        handleCurrentUserData(data);
      } catch (error) {
        if (error.response.data.error === 'Usuário não encontrado.') {
          // Tratamento de erro específico para "Usuário não encontrado"
          handleLogout();
        } else {
          // Outros tratamentos de erro aqui, se necessário
          handleLogout();
          console.error('Erro ao buscar o usuário:', error);
        }
      }
    })();
  }, [handleLogout]);

  const soraCoverOptions = [
    {
      id: 'coverSoraAdmiracao', name: 'Admiração', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Admiracao.png', soraBumperComponentParams: { bg: 4, shape: 5, sora: 'Admiracao' },
    },
    {
      id: 'coverSoraAmor', name: 'Amor', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Amor.png', soraBumperComponentParams: { bg: 4, shape: 2, sora: 'Amor' },
    },
    {
      id: 'coverSoraComemoracao', name: 'Comemoração', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Comemoracao.png', soraBumperComponentParams: { bg: 3, shape: 1, sora: 'Comemoracao' },
    },
    {
      id: 'coverSoraConfusao', name: 'Confusão', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Confusao.png', soraBumperComponentParams: { bg: 6, shape: 1, sora: 'Confusao' },
    },
    {
      id: 'coverSoraConstrangimento', name: 'Constrangimento', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Constrangimento.png', soraBumperComponentParams: { bg: 5, shape: 3, sora: 'Constrangimento' },
    },
    {
      id: 'coverSoraDesapontamento', name: 'Desapontamento', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Desapontamento.png', soraBumperComponentParams: { bg: 6, shape: 3, sora: 'Desapontamento' },
    },
    {
      id: 'coverSoraDeslumbre', name: 'Deslumbre', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Deslumbre.png', soraBumperComponentParams: { bg: 2, shape: 4, sora: 'Deslumbre' },
    },
    {
      id: 'coverSoraExtase', name: 'Êxtase', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Extase.png', soraBumperComponentParams: { bg: 3, shape: 1, sora: 'Extase' },
    },
    {
      id: 'coverSoraNerd', name: 'Nerd', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Nerd.png', soraBumperComponentParams: { bg: 6, shape: 5, sora: 'Nerd' },
    },
    {
      id: 'coverSoraOtimismo', name: 'Otimismo', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Otimismo.png', soraBumperComponentParams: { bg: 3, shape: 3, sora: 'Otimismo' },
    },
    {
      id: 'coverSoraPreocupacao', name: 'Preocupação', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Preocupacao.png', soraBumperComponentParams: { bg: 1, shape: 4, sora: 'Preocupacao' },
    },
    {
      id: 'coverSoraRaiva', name: 'Raiva', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Raiva.png', soraBumperComponentParams: { bg: 5, shape: 3, sora: 'Raiva' },
    },
    {
      id: 'coverSoraReflexao', name: 'Reflexão', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Reflexao.png', soraBumperComponentParams: { bg: 6, shape: 1, sora: 'Reflexao' },
    },
    {
      id: 'coverSoraTorcida', name: 'Torcida', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Torcida.png', soraBumperComponentParams: { bg: 1, shape: 4, sora: 'Torcida' },
    },
    {
      id: 'coverSoraTristeza', name: 'Tristeza', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Tristeza.png', soraBumperComponentParams: { bg: 4, shape: 2, sora: 'Tristeza' },
    },
    {
      id: 'coverSoraVergonha', name: 'Vergonha', publicUrl: 'https://minha.persora.app/email_resources/SoraV2/Vergonha.png', soraBumperComponentParams: { bg: 5, shape: 1, sora: 'Vergonha' },
    },
  ];
  const [soraCover, setSoraCover] = useState(
    soraCoverOptions.find((sora) => sora.id === 'coverSoraAdmiracao'),
  );

  const swiperRef = useRef(null);
  const handleSlideChange = () => {
    if (swiperRef.current) {
      const activeIndex = swiperRef.current.swiper.realIndex;
      const activeSlide = soraCoverOptions[activeIndex];
      const currentSoraData = soraCoverOptions.find((sora) => sora.id === activeSlide.id);
      // console.log(`Slide ativo: ${activeSlide.id}`);
      setSoraCover(currentSoraData);
    }
  };

  const [secret, setSecret] = useState('');
  const handleSecret = (value) => {
    setSecret(value);
  };

  const [btnLoading, setBtnLoading] = useState(false);
  const handleSendBumpers = async () => {
    setBtnLoading(true);
    try {
      // define o body da requisição
      const body = {
        // campos obrigatórios
        channels: {
          email: sendEmailOption, // boolean - true | false
          inApp: sendInAppOption, // boolean - true | false
          push: sendPushOption, // boolean - true | false
        }, // {}
        totalUsersInvitedCondition,
        totalUsersInvited, // number
        totalUsersMappedCondition, // string - "gte" | "lte" | "eq" | "Indiferente"
        totalUsersMapped, // number
        userType, // string "Líder" | "Colaborador" | "Indiferente"
        userWithProfile, // "ComPerfil" | "SemPerfil" | "Indiferente"
        userWithFirstAuth, // "ComFirstAuth" | "SemFirstAuth" | "Indiferente"
        soraCover, // {id, publicUrl, soraBumperComponentParams: {bg, shape, sora}}
        defaultContent,
        contentHtml, // string - EMAIL* & PUSH* & INAPP*
        contentHtmlEmail: styledH1andUl(contentHtml), // string - EMAIL*
        contentObject, // {} - EMAIL* & PUSH* & INAPP*
        secret, // string - EMAIL* & PUSH* & INAPP*
        // campos condicionais
        activeTapBar, // string "home" | "time" | "organizacao" | "user" - PUSH* | INAPP*
        emailSubject, // string - EMAIL*
        pushTitle, // string - PUSH* | INAPP*
        pushMessage, // string - PUSH* | INAPP*
      };
      const { data } = await api.post('/bumper/send', body, {
        headers: { secret },
        // 15 minutos de timeout
        timeout: 900000,
      });
      console.log(`Bumper ${data.bumperId} enviado com sucesso:`, data);
      Swal.fire({
        html: `
          <img src=${SoraNerd} alt='Sora Nerd' class='mb-3'/><br/>
          Bumper enviado com sucesso! Você pode utilizar o rastreador <span class="semibold">${data.bumperId}</span> para acompanhar as métricas no Mailgun e no Mixpanel.<hr/><small>Total de usuários contemplados pelos filtros: <span class="semibold">${data.totalUsersFound}</span></small>
        `,
        showCancelButton: false,
        confirmButtonColor: defaultTheme.colors.primary.main,
        confirmButtonText: 'Entendi',
        customClass: {
          confirmButton: 'swal2-button-full-width',
        },
      });
      setReviewBumperSheetOpen(false);
      setSecret('');
      setBtnLoading(false);
    } catch (error) {
      setReviewBumperSheetOpen(false);
      setSecret('');
      setBtnLoading(false);
      // console.debug('error', error.response.data.error);
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: 'bottom-center',
      });
    }
  };

  if (!currentUser.name) {
    return (
      <PageFlexContainer>
        <MainContainer>
          <MapeamentoLoader />
        </MainContainer>
      </PageFlexContainer>
    );
  }

  return (
    <PageFlexContainer style={{ paddingBottom: '3rem' }}>
      <MainContainer>
        <HeaderNavContainer>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="me-2" style={{ fontSize: '1.3rem' }}>
              <Send2 tamanho={22} />
            </div>
            {' '}
            <h5 className="mb-0 medium">
              Enviar Bumper
            </h5>
          </div>
          <div>
            <PersoraButton estilo="ghost" tamanho="mini" className="py-2 px-0" onClick={handleCloseEnviarBumper}>
              <Cross />
            </PersoraButton>
          </div>
        </HeaderNavContainer>
        <Content style={{ color: defaultTheme.colors.primary.light, paddingTop: '18px' }}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <Bolt tamanho={24} />
                <span className="ms-2 medium dmsans" style={{ fontSize: '1.1rem' }}>Canais de envio</span>
              </Accordion.Header>
              <Accordion.Body>
                <h5 className="mb-3 sourcesans semibold">
                  Selecione por quais canais deseja enviar o bumper
                </h5>
                {renderToggleSwitch(<Mail />, 'E-mail', sendEmailOption, handleToggleSendEmail)}
                {renderToggleSwitch(<Bell />, 'Notificação In-App', sendInAppOption, handleToggleSendInApp)}
                {renderToggleSwitch(<Smartphone />, 'Notificação Push', sendPushOption, handleToggleSendPush)}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="1"
              style={{
                transition: 'all 0.3s',
                pointerEvents: (!sendEmailOption && !sendInAppOption && !sendPushOption ? 'none' : 'auto'),
                opacity: (!sendEmailOption && !sendInAppOption && !sendPushOption ? '0.5' : '1'),
              }}
            >
              <Accordion.Header>
                <Filters />
                <span className="ms-2 medium dmsans" style={{ fontSize: '1.1rem' }}>Filtros para envio</span>
              </Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: defaultTheme.colors.primary.light }}>
                    <h5 className="mb-0 sourcesans semibold">
                      Número de usuários convidados no workspace
                    </h5>
                  </Form.Label>
                  <Row>
                    <Col xs={`${totalUsersInvitedCondition !== 'Indiferente' ? 8 : 12}`}>
                      <Form.Select
                        as="select"
                        defaultValue={totalUsersInvitedCondition}
                        onChange={(e) => handleSetTotalUsersInvitedConditionOption(e.target.value)}
                        autoComplete="off"
                        className={`${totalUsersInvitedCondition ? 'handbookFormControlFilledV2' : ''}`}
                      >
                        <option value="" disabled>Selecione a condição</option>
                        {totalUsersInvitedConditionOptions.map((option) => (
                          <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col className={`${totalUsersInvitedCondition === 'Indiferente' ? 'd-none' : ''}`}>
                      <Form.Control
                        type="text"
                        className="mb-1 handbookFormControlFilledV2"
                        defaultValue={totalUsersInvited}
                        onChange={(e) => handleSetTotalUsersInvited(e.target.value)}
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: defaultTheme.colors.primary.light }}>
                    <h5 className="mb-0 sourcesans semibold">
                      Número de usuários mapeados no workspace
                    </h5>
                  </Form.Label>
                  <Row>
                    <Col xs={`${totalUsersMappedCondition !== 'Indiferente' ? 8 : 12}`}>
                      <Form.Select
                        as="select"
                        name="firstTimeLeaderInputUpdate"
                        defaultValue={totalUsersMappedCondition}
                        onChange={(e) => handleSetTotalUsersMappedConditionOption(e.target.value)}
                        autoComplete="off"
                        className={`${totalUsersMappedCondition ? 'handbookFormControlFilledV2' : ''}`}
                      >
                        <option value="" disabled>Selecione a condição</option>
                        {totalUsersMappedConditionOptions.map((option) => (
                          <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col className={`${totalUsersMappedCondition === 'Indiferente' ? 'd-none' : ''}`}>
                      <Form.Control
                        type="text"
                        className="mb-1 handbookFormControlFilledV2"
                        defaultValue={totalUsersMapped}
                        onChange={(e) => handleSetTotalUsersMapped(e.target.value)}
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <hr />
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: defaultTheme.colors.primary.light }}>
                    <h5 className="mb-0 sourcesans semibold">
                      Tipo de usuário
                    </h5>
                  </Form.Label>
                  <Form.Select
                    as="select"
                    name="firstTimeLeaderInputUpdate"
                    defaultValue={userType}
                    onChange={(e) => handleSetUserTypeOption(e.target.value)}
                    autoComplete="off"
                    className={`${userType ? 'handbookFormControlFilledV2' : ''}`}
                  >
                    <option value="" disabled>Selecione o tipo de usuário</option>
                    {userTypeOptions.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: defaultTheme.colors.primary.light }}>
                    <h5 className="mb-0 sourcesans semibold">
                      Situação do estilo comportamental
                    </h5>
                  </Form.Label>
                  <Form.Select
                    as="select"
                    defaultValue={userWithProfile}
                    onChange={(e) => handleSetUserWithProfileOption(e.target.value)}
                    autoComplete="off"
                    className={`${userWithProfile ? 'handbookFormControlFilledV2' : ''}`}
                  >
                    <option value="" disabled>Selecione a condição</option>
                    {userWithProfileOptions.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: defaultTheme.colors.primary.light }}>
                    <h5 className="mb-0 sourcesans semibold">
                      Primeira autenticação
                    </h5>
                  </Form.Label>
                  <Form.Select
                    as="select"
                    defaultValue={userWithFirstAuth}
                    onChange={(e) => handleSetUserWithFirstAuthOption(e.target.value)}
                    autoComplete="off"
                    className={`${userWithFirstAuth ? 'handbookFormControlFilledV2' : ''}`}
                  >
                    <option value="" disabled>Selecione a condição</option>
                    {userWithFirstAuthOptions.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {(sendPushOption || sendInAppOption) && (
                  <Form.Group className="mb-2">
                    <Form.Label style={{ color: defaultTheme.colors.primary.light }}>
                      <h5 className="mb-0 sourcesans semibold">
                        TapBar ativa ao abrir o App
                      </h5>
                    </Form.Label>
                    <Form.Select
                      as="select"
                      defaultValue=""
                      onChange={(e) => handleSetActiveTapBarOption(e.target.value)}
                      className={`${activeTapBar ? 'handbookFormControlFilledV2' : ''}`}
                    >
                      <option value="" disabled>Selecione</option>
                      {activeTapBarOptions.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}
              </Accordion.Body>
            </Accordion.Item>
            <div style={{
              boxShadow: '0px 2px 12px 0px rgba(6, 16, 42, 0.10)',
              marginBottom: '18px',
              padding: '16px',
              background: defaultTheme.colors.black20,
              borderRadius: '12px',
              overflow: 'hidden',
              color: defaultTheme.colors.primary.main,
              transition: 'all 0.3s',
              pointerEvents: (!sendEmailOption && !sendInAppOption && !sendPushOption ? 'none' : 'auto'),
              opacity: (!sendEmailOption && !sendInAppOption && !sendPushOption ? '0.5' : '1'),
            }}
            >
              <h5 className="mt-1 mb-3">
                <Star2 tamanho={24} />
                <span className="ms-2 medium dmsans" style={{ fontSize: '1.1rem' }}>Capa do bumper</span>
              </h5>
              <h5 className="mt-2 mb-3 sourcesans semibold">
                Selecione uma capa para o bumper
              </h5>
              <Swiper
                ref={swiperRef}
                spaceBetween={0}
                slidesPerView={2.05}
                centeredSlides
                autoHeight
                style={{ zIndex: 0, marginLeft: '-16px', width: 'calc(100% + 32px)' }}
                pagination={{
                  clickable: true,
                  el: '.swiper-goals-tracker-pagination',
                }}
                onSlideChange={handleSlideChange}
                loop
              >
                {soraCoverOptions.map((soraCoverOption) => (
                  <SwiperSlide key={soraCoverOption.id}>
                    <div className="text-center" style={{ padding: '0 0 0 16px' }}>
                      <div style={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <span style={{
                          position: 'absolute',
                          zIndex: 9,
                          left: '16px',
                          top: 0,
                          background: 'rgba(255,255,255, .8)',
                          padding: '4px 7px',
                          fontSize: '0.7rem',
                          borderTopLeftRadius: '12px',
                          borderBottomRightRadius: '11px',
                        }}
                        >
                          {soraCoverOption.name}
                        </span>
                        <SoraBumperCover {...soraCoverOption.soraBumperComponentParams} />
                      </div>
                      <div
                        style={{
                          transition: 'all 0.3s',
                          color: defaultTheme.colors.primary.main,
                          marginTop: soraCover.id === soraCoverOption.id ? '0px' : '12px',
                          opacity: soraCover.id === soraCoverOption.id ? 1 : 0.1,
                        }}
                      >
                        <ChevronSmallUp />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-goals-tracker-pagination mt-0 mb-2" />
            </div>
            <Accordion.Item
              eventKey="2"
              style={{
                pointerEvents: (!sendEmailOption && !sendInAppOption && !sendPushOption ? 'none' : 'auto'),
                opacity: (!sendEmailOption && !sendInAppOption && !sendPushOption ? '0.5' : '1'),
              }}
            >
              <Accordion.Header>
                <MessageSquareLines tamanho={24} />
                <span className="ms-2 medium dmsans" style={{ fontSize: '1.1rem' }}>Conteúdo do bumper</span>
              </Accordion.Header>
              <Accordion.Body>
                {sendEmailOption && (
                  <Form.Group className="mb-3">
                    <Form.Label style={{ color: defaultTheme.colors.primary.light }}>
                      <h5 className="mb-0 sourcesans semibold">
                        Assunto do e-mail
                      </h5>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="mb-1"
                      defaultValue={emailSubject}
                      onChange={(e) => handleEmailSubject(e.target.value)}
                      autoComplete="off"
                      placeholder="Informe um assunto para o e-mail"
                    />
                  </Form.Group>
                )}
                {(sendPushOption || sendInAppOption) && (
                  <Form.Group className="mb-3">
                    <Form.Label style={{ color: defaultTheme.colors.primary.light }}>
                      <h5 className="mb-0 sourcesans semibold">
                        Titulo do push
                      </h5>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="mb-1"
                      defaultValue={pushTitle}
                      onChange={(e) => handlePushTitle(e.target.value)}
                      autoComplete="off"
                      placeholder="Informe um título para o push"
                    />
                  </Form.Group>
                )}
                {(sendPushOption || sendInAppOption) && (
                  <Form.Group className="mb-3">
                    <Form.Label style={{ color: defaultTheme.colors.primary.light }}>
                      <h5 className="mb-0 sourcesans semibold">
                        Mensagem do push
                      </h5>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="mb-1"
                      defaultValue={pushMessage}
                      onChange={(e) => handlePushMessage(e.target.value)}
                      autoComplete="off"
                      placeholder="Informe uma mensagem para o push"
                    />
                  </Form.Group>
                )}
                {(sendEmailOption || sendPushOption || sendInAppOption) && (
                  <Form.Group className="mb-3">
                    <Form.Label style={{ color: defaultTheme.colors.primary.light }}>
                      <h5 className="mb-0 sourcesans semibold">
                        Conteúdo do bumper
                      </h5>
                    </Form.Label>
                    <p className="small mb-2">
                      * Todos os emails começarão com &quot;Olá,
                      {' {Fulano}! '}
                      Tudo bem? 👋&quot;.
                    </p>
                    <MyEditor
                      setContentHtml={setContentHtml}
                      contentHtml={contentHtml}
                      setContentObject={setContentObject}
                      contentObject={contentObject}
                    />
                  </Form.Group>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/* <hr /> */}
          <PersoraButton
            type="button"
            estilo="primary"
            className="mt-1"
            onClick={() => { setReviewBumperSheetOpen(true); }}
            disabled={(
             (
              (!sendEmailOption && !sendPushOption && !sendInAppOption)
              // || (totalUsersInvitedCondition !== 'Indiferente' && totalUsersInvited < 0)
              // || (totalUsersMappedCondition !== 'Indiferente' && totalUsersMapped < 1)
              || !userType
              || !userWithProfile
              || !userWithFirstAuth
              || !soraCover
              || contentHtml === '<p></p>'
              || contentHtml === '<p><br></p>'
              || contentHtml === '<h1></h1>'
              || contentHtml.includes('<h1>Que tal')
              || ((sendPushOption || sendInAppOption) && !activeTapBar)
              || (sendEmailOption && !emailSubject)
              || ((sendPushOption || sendInAppOption) && !pushTitle)
              || ((sendPushOption || sendInAppOption) && !pushMessage)
            )
            )}
          >
            Revisar bumper
          </PersoraButton>
          {/* {(
            (
              (!sendEmailOption && !sendPushOption && !sendInAppOption)
              // || (totalUsersInvitedCondition !== 'Indiferente' && totalUsersInvited < 0)
              // || (totalUsersMappedCondition !== 'Indiferente' && totalUsersMapped < 1)
              || !userType
              || !userWithProfile
              || !userWithFirstAuth
              || !soraCover
              || contentHtml === '<p></p>'
              || contentHtml === '<p><br></p>'
              || contentHtml === '<h1></h1>'
              || contentHtml.includes('<h1>Que tal')
              || ((sendPushOption || sendInAppOption) && !activeTapBar)
              || (sendEmailOption && !emailSubject)
              || contentHtml === defaultContent
              || ((sendPushOption || sendInAppOption) && !pushTitle)
              || ((sendPushOption || sendInAppOption) && !pushMessage)
            )
          ) && (
            <Disclaimer
              estilo="warning"
              titulo="Parece que algo ainda está faltando."
              icone="👀"
              className="mt-3 mb-3"
            />
          )} */}
        </Content>
      </MainContainer>
      <Sheet
        isOpen={reviewBumperSheetIsOpen}
        onClose={() => { setReviewBumperSheetOpen(false); setSecret(''); }}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableScrollLocking
      >
        <Sheet.Container>
          <Sheet.Header className="pt-3 pb-2">
            <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => { setReviewBumperSheetOpen(false); setSecret(''); }}>
              <ChevronSmallLeft tamanho={32} />
            </PersoraButton>
          </Sheet.Header>
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.light,
                }}
                className="mb-5"
              >
                <h2 className="mt-2 mb-4 sourcesans" style={{ color: defaultTheme.colors.primary.main }}>
                  Revisão do bumper
                </h2>
                <div style={{
                  borderRadius: '16px', overflow: 'hidden', border: '1px solid #ccc', padding: '1px',
                }}
                >
                  <div style={{ borderRadius: '11px', overflow: 'hidden' }}>
                    <div style={{
                      display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 12px',
                    }}
                    >
                      <div style={{ fontSize: '.85rem' }}><b>11:25</b></div>
                      <div style={{
                        background: '#111', width: '80px', height: '22px', borderRadius: '20px',
                      }}
                      />
                      <div style={{ fontSize: '.8rem' }}>preview</div>
                    </div>
                    <div
                      style={{
                        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end', padding: '12px 8px', borderTop: '1px solid #f7f7f7',
                      }}
                    >
                      <Cross />
                    </div>
                    <SoraBumperCover {...soraCover.soraBumperComponentParams} />
                    <div className="remirror-sendBumper mt-2" style={{ padding: '16px' }}>
                      <p>
                        Olá,
                        {' {Fulano}! '}
                        Tudo bem? 👋
                        {' '}
                        <span style={{ color: '#999' }}><i><small>(apenas no e-mail)</small></i></span>
                      </p>
                      <div dangerouslySetInnerHTML={{ __html: contentHtml }} className="mt-3" />
                    </div>
                  </div>
                </div>
                <h3 className="premium-text mt-4 mb-3 sourcesans" style={{ olor: defaultTheme.colors.primary.main }}>
                  Canais de envio
                </h3>
                <div style={gerencialNotificacoesDivStyle}>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <Mail />
                    {' '}
                    E-mail
                  </div>
                  <div style={{ fontSize: '17px', color: (!sendEmailOption ? defaultTheme.colors.disc.d.dark : defaultTheme.colors.disc.c.dark) }}>
                    {sendEmailOption ? 'Ativo' : 'Inativo'}
                  </div>
                </div>
                <div style={gerencialNotificacoesDivStyle}>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <Bell />
                    {' '}
                    Notificação In-App
                  </div>
                  <div style={{ fontSize: '17px', color: (!sendInAppOption ? defaultTheme.colors.disc.d.dark : defaultTheme.colors.disc.c.dark) }}>
                    {sendInAppOption ? 'Ativo' : 'Inativo'}
                  </div>
                </div>
                <div style={gerencialNotificacoesDivStyle}>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <Smartphone />
                    {' '}
                    Notificação Push
                  </div>
                  <div style={{ fontSize: '17px', color: (!sendPushOption ? defaultTheme.colors.disc.d.dark : defaultTheme.colors.disc.c.dark) }}>
                    {sendPushOption ? 'Ativo' : 'Inativo'}
                  </div>
                </div>
                <h3 className="premium-text mt-4 mb-3 sourcesans" style={{ olor: defaultTheme.colors.primary.main }}>
                  Filtros associados ao workspace
                </h3>
                <div style={gerencialNotificacoesDivStyle}>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    Número de usuários convidados
                  </div>
                  <div style={{ fontSize: '17px' }}>
                    {totalUsersInvitedCondition !== 'Indiferente' ? `${totalUsersInvitedCondition} ${totalUsersInvited}` : 'Indiferente'}
                  </div>
                </div>
                <div style={gerencialNotificacoesDivStyle}>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    Número de usuários mapeados
                  </div>
                  <div style={{ fontSize: '17px' }}>
                    {totalUsersMappedCondition !== 'Indiferente' ? `${totalUsersMappedCondition} ${totalUsersMapped}` : 'Indiferente'}
                  </div>
                </div>
                <h3 className="premium-text mt-4 mb-3 sourcesans" style={{ olor: defaultTheme.colors.primary.main }}>
                  Filtros associados ao usuário
                </h3>
                <div style={gerencialNotificacoesDivStyle}>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    Tipo de usuário
                  </div>
                  <div style={{ fontSize: '17px' }}>
                    {userType}
                  </div>
                </div>
                <div style={gerencialNotificacoesDivStyle}>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    Situação do estilo comportamental
                  </div>
                  <div style={{ fontSize: '17px' }}>
                    {userWithProfile}
                  </div>
                </div>
                <div style={gerencialNotificacoesDivStyle}>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    Primeira autenticação
                  </div>
                  <div style={{ fontSize: '17px' }}>
                    {userWithFirstAuth}
                  </div>
                </div>
                {(sendPushOption || sendInAppOption) && (
                  <div style={gerencialNotificacoesDivStyle}>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      TapBar ativa ao abrir o App
                    </div>
                    <div style={{ fontSize: '17px', textTransform: 'capitalize' }}>
                      {activeTapBar === 'organizacao' ? 'indicadores' : activeTapBar}
                    </div>
                  </div>
                )}
                <Form.Group className="mt-4 mb-3">
                  <Form.Label style={{ color: defaultTheme.colors.primary.light }}>
                    <h5 className="mb-0 sourcesans semibold">
                      <i>Secret para enviar o bumper</i>
                      {' '}
                      🤫
                    </h5>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="mb-1"
                    defaultValue={secret}
                    onChange={(e) => handleSecret(e.target.value)}
                    autoComplete="off"
                  />
                </Form.Group>
                <PersoraButton tipo="primary" className="mt-4" onClick={handleSendBumpers} disabled={(!secret || btnLoading)}>
                  {btnLoading
                    ? (
                      <>
                        <l-tail-chase
                          size="20"
                          speed="1.75"
                          color="#fff"
                        />
                        {' '}
                        Enviando...
                      </>
                    )
                    : 'Enviar bumper'}
                </PersoraButton>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => { setReviewBumperSheetOpen(false); setSecret(''); }} />
      </Sheet>
    </PageFlexContainer>
  );
}
