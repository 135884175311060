export default {
  Assertivo: { definicao: 'Expressa suas opiniões com confiança e clareza, sem ser agressivo.' },
  Direto: { definicao: 'Comunica-se de forma clara e simples, sem rodeios.' },
  Persuasivo: { definicao: 'Alta capacidade de direcionar os outros por meio da atitude.' },
  Impulsivo: { definicao: 'Age sem muito planejamento, seguindo seus impulsos.' },
  Autoconfiante: { definicao: 'Tem certeza de suas habilidades ou ideais.' },
  Arrojado: { definicao: 'Pré-disposição para enfrentar situações incertas ou arriscadas.' },
  Aventureiro: { definicao: 'Gosta de explorar experiências ou desafios desconhecidos.' },
  Visionário: { definicao: 'Pensa no futuro, tem ideias inovadoras e criativas.' },
  Entusiasmado: { definicao: 'Demonstra grande interesse e excitação por algo.' },
  Receptivo: { definicao: 'Aberto a novas ideias e diferentes pontos de vista.' },
  Adaptável: { definicao: 'Ajusta-se facilmente a mudanças ou novas situações.' },
  Acolhedor: { definicao: 'Faz com que os outros se sintam bem-vindos e valorizados.' },
  Extrovertido: { definicao: 'Sempre toma iniciativa de interagir e se conectar com os outros.' },
  Pacífico: { definicao: 'Busca harmonia e evita conflitos.' },
  Cordial: { definicao: 'Trata os outros com gentileza e empatia.' },
  Diplomático: { definicao: 'Habilidoso em lidar com situações sensíveis sem causar ofensa.' },
  Apoiador: { definicao: 'Oferece suporte e ajuda aos outros.' },
  'Não conflituoso': { definicao: 'Prefere não enfrentar ou desafiar os outros diretamente.' },
  Consistente: { definicao: 'Mantém um comportamento ou atitude constante.' },
  Deliberado: { definicao: 'Age de forma intencional e pensada.' },
  'Averso a risco': { definicao: 'Evita situações de risco ou incertezas.' },
  Estruturado: { definicao: 'Segue um método ou plano organizado.' },
  Detalhista: { definicao: 'Dá especial atenção aos detalhes.' },
  Minucioso: { definicao: 'Age com extrema atenção e cuidado.' },
  Analítico: { definicao: 'Examina informações de forma profunda e lógica.' },
  Formal: { definicao: 'Age de acordo com convenções ou regras estabelecidas.' },
  Imparcial: { definicao: 'Baseia-se em fatos, sem influência de emoções pessoais.' },
  Lógico: { definicao: 'Usa a razão e a lógica em suas decisões.' },
  Metódico: { definicao: 'Segue um método sistemático ou estabelecido.' },
  Questionador: { definicao: 'Questiona aquilo com que não concorda.' },
  Rígido: { definicao: 'Mantém firmemente suas opiniões ou métodos.' },
  Persistente: { definicao: 'Continua a perseguir seus objetivos, apesar dos desafios.' },
};
