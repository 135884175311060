import styled from 'styled-components';

export const BumperContent = styled.div`
  padding: ${({ theme }) => theme.spaces.screenEdge} ${({ theme }) => theme.spaces.screenEdge};
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.primary.main};
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  line-height: 1.8rem;
`;

export const P = styled.p`
  font-size: 1.05rem;
  margin-bottom: .6rem;
`;

export const Citacao = styled.p`
  font-size: 1.15rem;
  margin-bottom: .6rem;
`;

export const Depoimento = styled.p`
  padding-top: 2rem;
  font-size: 1.15rem;
  margin-bottom: .6rem;

  svg {
    fill: ${({ theme }) => theme.colors.disc.i.light};
    path {
      stroke: ${({ theme }) => theme.colors.disc.i.main};
    }
    margin-bottom: 5px;
  }

  &:before {
    content: '“';
    margin-top: -2.5rem;
    font-size: 4.5rem;
    margin-right: .5rem;
    color: ${({ theme }) => theme.colors.secondary.dark};
    font-weight: bold;
    vertical-align: middle;
    line-height: 1;
    font-family: 'Source Serif 4', Times, serif;
    position: absolute;
  }
  &:after {
    content: '”';
    font-size: 5.5rem;
    margin-top: 1.5rem;
    /* margin-left: 1.5rem; */
    color: ${({ theme }) => theme.colors.secondary.dark};
    font-weight: bold;
    vertical-align: middle;
    line-height: 1;
    font-family: 'Source Serif 4', Times, serif;
    position: absolute;
    right: calc(${({ theme }) => theme.spaces.screenEdge} * 2);
  }
`;
export const Fonte = styled.p`
  font-size: .9rem;
  color: ${({ theme }) => theme.colors.black60};
  font-weight: 600;
`;

export const BlueContainer = styled.div`
  text-align: left;
  background-color: ${({ theme }) => theme.colors.secondary.light};
  padding: 1.75rem 2rem;
  border-radius: ${({ theme }) => theme.spaces.screenEdge};
  padding-bottom: 1rem;
  margin: 1.75rem 0;
  hr {
    width: 50px;
    border-color: ${({ theme }) => theme.colors.secondary.main};
    opacity: 1;
  }
`;

export const EmojiItem = styled.div`
  display: flex;
  align-items: flex-start;

  p {
    margin-bottom: 1rem;
  }
`;
export const Emoji = styled.span`
  margin-right: 16px; /* ou o espaçamento desejado */
  font-size: 1.2rem;
`;

export const DepoimentoFooter = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  gap: 16px;
  align-items: center;
`;
export const Foto = styled.img`
  border-radius: 100px;
  width: 60px;
`;
export const Nome = styled.p`
  line-height: 1.3rem;
  margin-bottom: 0;
`;

export const Gif = styled.img`
  border-radius: 12px;
  box-shadow: 0px 2px 20px 0px rgba(37, 46, 70, 0.20) !important;
  display: flex;
  margin: 20px auto;
`;
