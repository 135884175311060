import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% - 2 * ( ${({ theme }) => theme.spaces.screenEdge} ));
  margin-left: ${({ theme }) => theme.spaces.screenEdge};
  display: flex;
  flex-direction: column;
  /* background-color: #fff; */
  /* background with prop backgroundColor  */
  background-color: ${({ profilebackgroundcolor }) => (profilebackgroundcolor || '#fff')};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.alerts.generic.textColor};
  box-shadow: 0px 2px 12px 0px rgba(6, 16, 42, 0.10);

  /* gradiente bottom to top white to tranparent on bottom off div */
  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: calc(100% - 2 * ( ${({ theme }) => theme.spaces.screenEdge} ));
    margin-left: ${({ theme }) => theme.spaces.screenEdge};
    height: 30px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${({ profilebackgroundcolor }) => profilebackgroundcolor || '#fff'} 100%);
    border-radius: 12px;
    pointer-events: none;
  }
`;

export const Header = styled.div`
  padding: ${({ theme }) => theme.spaces.screenEdge};
  color: ${({ theme }) => theme.colors.alerts.generic.titleColor};
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  height: 86px;

  &:after {
    content: '';
    position: absolute;
    top: 84px;
    left: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(0, rgba(255, 255, 255, 0) 0%, ${({ profilebackgroundcolor }) => profilebackgroundcolor || '#fff'} 100%);
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 44px;
    height: 44px;
    mix-blend-mode: darken;
  }
`;

export const Title = styled.div`
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
  margin: 0;
  font-family: 'Source Serif 4', serif;
  letter-spacing: .015rem;
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.spaces.screenEdge};
  /* height: 420px; */
  /* overflow-y: scroll; */

  ul, ol {
    padding: 0 20px;
    margin-bottom: 3px;
  }
`;

export const EmojiSwiper = styled.div`
  animation: emojiSwiper 2s infinite;
  font-size: 2rem;
  position: relative;
  margin-top: -50px;
  width: 90px;
  right: 15px;
  float: right;
  pointer-events: none;

  span {
    position: absolute;
    top: 36px;
    right: 16px;
    font-size: .8rem;
    animation: emojiSwiperOposites 2s infinite;

    color: ${({ theme }) => theme.colors.black40};
  }

  @keyframes emojiSwiper {
    0% {
      transform: translateX(0) rotate(0deg);
    }
    70% {
      transform: translateX(16px) rotate(-3deg);
    }
    100% {
      transform: translateX(0) rotate(0deg);
    }
  }

  @keyframes emojiSwiperOposites {
    0% {
      transform: translateX(0) rotate(0deg);
    }
    70% {
      transform: translateX(-10px) rotate(3deg);
    }
    100% {
      transform: translateX(0) rotate(0deg);
    }
  }

`;
