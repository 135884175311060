import styled from 'styled-components';

export const HeaderNavContainer = styled.header`
  height: 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 12px ${({ theme }) => theme.spaces.screenEdge};
  background: ${({ theme }) => theme.colors.fullWhite};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black20};
  color: ${({ theme }) => theme.colors.textDefault};
  text-align: center;
  z-index: 10; // Para garantir que sobreponha o conteúdo
`;

export const SingleNotificationContainer = styled.div`
  width: calc(100% - 2 * (${({ theme }) => theme.spaces.screenEdge}));
  margin-left: ${({ theme }) => theme.spaces.screenEdge};
  padding: ${({ theme }) => theme.spaces.screenEdge} 0;
  a {
    text-decoration: none !important;
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black20};
  }
`;

export const NotificationHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: calc(${({ theme }) => theme.spaces.screenEdge} / 1.5);
`;

export const NotificationRead = styled.div`
  background-color: ${({ theme }) => theme.colors.black10};
  color: ${({ theme }) => theme.colors.black60};
  font-size: 0.85rem;
  font-weight: 600;
  padding: 2px 9px;
  border-radius: 9px;
  &.read {
    padding: 1px 6px;
    padding-right: 9px;
    background-color: ${({ theme }) => theme.colors.alerts.info.background};
    color: ${({ theme }) => theme.colors.alerts.info.textColor};
  }
`;

export const NotificationCreatedAt = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.black60};
  opacity: .7;
`;

export const NotificationBody = styled.div`
  color: ${({ theme }) => theme.colors.primary.light};
`;

export const NotificationTitle = styled.h5`
  font-family: 'Source Sans 3', sans-serif;
  font-size: 1.025rem;
  font-weight: 600;
  margin-bottom: calc(${({ theme }) => theme.spaces.screenEdge} / 4);
  color: ${({ theme }) => theme.colors.primary.main};
  &.read {
    color: ${({ theme }) => theme.colors.black60};
  }
`;
export const NotificationMessage = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.primary.light};
  &.read {
    color: ${({ theme }) => theme.colors.black60};
  }
  font-size: 1rem;
  line-height: 1.4rem;
`;
