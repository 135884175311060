import styled from 'styled-components';

export const TapBarContainer = styled.nav`
  height: 80px;
  display: flex;
  align-items: top;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  margin-bottom: 0;
  padding: 18px ${({ theme }) => theme.spaces.screenEdge};
  padding-top: 3px;
  background: ${({ theme }) => theme.colors.fullWhite};
  border-top: 1px solid ${({ theme }) => theme.colors.black20};
  color: ${({ theme }) => theme.colors.textDefault};
  text-align: center;
  z-index: 1; // Para garantir que sobreponha o conteúdo
`;

export const ActionButton = styled.div`
  position: sticky;
  bottom: calc(${({ theme }) => theme.spaces.screenEdge} + 80px);
  left: calc(100% - ${({ theme }) => theme.spaces.screenEdge} - 70px  );
  width: fit-content;
  width: 72px;
  height: 72px;
  border-radius: 72px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  outline: none;
  border: none;
  box-shadow: 3px 5px 12px 0px rgba(37, 46, 70, 0.25);
  margin-bottom: -72px;
  img {
    width: 25px;
  }
`;

export const PButton = styled.button`
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  border-radius: 72px;
`;
export const CloseButton = styled.button`
  outline: none;
  box-shadow: none;
  border: none;
  padding: 12px;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
`;
export const ActionButtonDisclaimer = styled.div`
  position: absolute;
  left: calc(100% - ${({ theme }) => theme.spaces.screenEdge} - 289px  );
  width: 305px;
  bottom: calc(${({ theme }) => theme.spaces.screenEdge} + 80px);
  padding: 1.5rem 1.5rem;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.alerts.info.background};
  color: ${({ theme }) => theme.colors.alerts.info.textColor};
  box-shadow: 0px 2px 12px 3px rgba(6, 16, 42, 0.20);
  opacity: 1;
  transition: .5s ease-in-out;
  transition-delay: .9s;

  /* Esconder o componente se estiver aninhado dentro de um elemento com a classe .driver-active */
  /* .driver-active &.copilotButtonDisclaimer { */
  .driver-active & {
    bottom: calc(${({ theme }) => theme.spaces.screenEdge} + 30px);
    opacity: 0;
    /* display: none !important; */
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -24px;
    right: 16px;
    border-width: 12px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.alerts.info.background} transparent transparent transparent;
    transform: scaleY(1.4);
  }
  h5 {
    font-family: 'Source Sans 3', sans-serif;
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
  }
  p {
    margin-bottom: 0.75rem;
  }
`;
