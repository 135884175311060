import { useContext, useEffect, useState } from 'react';
import { Sheet } from 'react-modal-sheet';
import { Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import EmojiPicker from 'emoji-picker-react';
import defaultTheme from '../../assets/styles/themes/default';
import PersoraButton from '../../components/Button';
import api from '../../services/backend';
import {
  ChevronSmallDown, ChevronSmallUp, UserPlus,
} from '../../components/DuotoneIcon';
import UserAdminActions from '../../components/UserAdminActions';
import { UserContext } from '../../Context/UserContext';

export default function TeamSettingsSheet({
  handleUpdateTeamSettings,
  currentTeam,
  setTeamSettingsSheetOpen,
  setAddUserSheetOpen,
  setPremiumPricesSheetOpen,
  countCurrentTeamUsers,
}) {
  const [teamData, setTeamData] = useState([]);
  const [isEmojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [teamEmoji, setTeamEmoji] = useState(currentTeam.emoji ? currentTeam.emoji : '🤖');
  const { currentUserContextData } = useContext(UserContext);
  const [descriptionTeam, setDescriptionTeam] = useState(currentTeam.description);
  const [descriptionLenght, setDescriptionLenght] = useState(currentTeam.description?.length || 0);
  // handleChangeDescription que setará descriptionTeam com setDescriptionTeam
  const handleChangeDescription = (e) => {
    // limit length to 230 chars
    if (e.target.value.length > 230) {
      e.target.value = e.target.value.substring(0, 230);
      setDescriptionLenght(230);
    }
    setDescriptionLenght(e.target.value.length);
    setDescriptionTeam(e.target.value);
  };

  const handleEmojiClick = (emoji) => {
    // console.log('Emoji selecionado:', emoji);
    setTeamEmoji(emoji.emoji);
    setEmojiPickerOpen(false);
  };

  // api get users (/user/getUsers) from currentTeam (_id) async
  // setTeamData(users)
  // TODO Refatorar para pegar os usuários de todo o workspace
  useEffect(() => {
    setTeamData([]);
    (async () => {
      try {
        const body = {
          idTeam: currentTeam._id,
        };
        // console.log('body tem getusers', body); // DEBUG
        const { data } = await api.get('/user/getUsers', body);
        // console.log('data tem getusers', data); // DEBUG
        // order teamData first by firstAuth == true, then by type == 'Lider', then by name
        const sortedData = data.sort((a, b) => {
          if (a.displayedProfileLetters && !b.displayedProfileLetters) {
            return -1;
          }
          if (!a.displayedProfileLetters && b.displayedProfileLetters) {
            return 1;
          }
          if (a.type === 'Líder' && b.type !== 'Líder') {
            return -1;
          }
          if (a.type !== 'Líder' && b.type === 'Líder') {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setTeamData(sortedData);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentTeam]);

  return (
    <Sheet.Container>
      <Sheet.Header />
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              minHeight: 400,
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: 0,
              color: defaultTheme.colors.primary.main,
            }}
          >
            <h3 className="medium">Configuração do time</h3>
            <h5 className="mb-4">
              Ajuste as informações sobre este time para todos em seu workspace.
            </h5>
            <form id="updateTeamSettingsForm" onSubmit={handleUpdateTeamSettings}>
              <Row>
                <Form.Control
                  type="hidden"
                  name="_id"
                  defaultValue={currentTeam._id}
                />
                <Form.Group className="col-8">
                  <Form.Label>
                    Nome
                    {' '}
                    <span> • Nomeie este time</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="É assim que ele será referenciado"
                    className="mb-3"
                    name="name"
                    defaultValue={currentTeam.name}
                  />
                </Form.Group>
                <Form.Group
                  className="col-4 mb-3 text-center"
                  style={{ position: 'relative' }}
                >
                  <Form.Label>Emoji</Form.Label>
                  <Form.Control
                    type="button"
                    className="mb-3 text-start"
                    name="emoji"
                    defaultValue={teamEmoji}
                    style={{ fontSize: '26px', padding: '3px', paddingLeft: '16px' }}
                    onClick={() => setEmojiPickerOpen(!isEmojiPickerOpen)}
                  />
                  <div style={{
                    position: 'absolute', top: '40px', right: '24px', pointerEvents: 'none',
                  }}
                  >
                    {isEmojiPickerOpen ? (<ChevronSmallUp />) : (<ChevronSmallDown />)}
                  </div>
                </Form.Group>
              </Row>
              <EmojiPicker
                lazyLoadEmojis
                className="mb-4"
                searchDisabled
                width="100%"
                height="265px"
                suggestedEmojisMode="recent"
                open={isEmojiPickerOpen}
                onEmojiClick={handleEmojiClick}
                emojiStyle="native"
                previewConfig={{ showPreview: false }}
              />
              <Form.Group style={{ position: 'relative' }}>
                <Form.Label>
                  Descrição
                  {' '}
                  <span> • Defina em uma frase o propósito do time</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Fique à vontade e utilize este espaço para descrever brevemente o propósito deste time."
                  className="mb-3"
                  name="description"
                  style={{
                    height: (descriptionLenght < 100 ? '100px' : '165px'),
                  }}
                  defaultValue={descriptionTeam}
                  onChange={handleChangeDescription}
                />
                <div style={{
                  position: 'absolute',
                  bottom: '3px',
                  right: '10px',
                  color: (descriptionLenght >= 200 ? 'red' : defaultTheme.colors.primary.light),
                  opacity: 0.8,
                }}
                >
                  <small>
                    {descriptionLenght}
                    /230
                  </small>
                </div>
              </Form.Group>
              <PersoraButton className="mt-4 mb-3" type="submit" form="updateTeamSettingsForm">Salvar</PersoraButton>
            </form>
            <div className="row align-items-end mt-4">
              <div className="col">
                <h5 className="sourcesans form-label">Membros do time</h5>
              </div>
              <div className="col" style={{ display: 'flex', justifyContent: 'end' }}>
                {/* quando clicado, fecha teamsettingssheet e abre addusersheet */}
                {currentUserContextData.type === 'Líder'
                && currentUserContextData.workspaceContext.premium !== true
                && (
                  countCurrentTeamUsers >= 6 ? (
                    <PersoraButton
                      estilo="ghost"
                      tamanho="mini"
                      style={{ paddingRight: 0 }}
                      onClick={() => {
                        setTeamSettingsSheetOpen(false);
                        setPremiumPricesSheetOpen(true);
                      }}
                    >
                      <UserPlus tamanho={22} />
                      {' '}
                      Convidar
                    </PersoraButton>
                  ) : (
                    <PersoraButton
                      estilo="ghost"
                      tamanho="mini"
                      style={{ paddingRight: 0 }}
                      onClick={() => {
                        setTeamSettingsSheetOpen(false);
                        setAddUserSheetOpen(true);
                      }}
                    >
                      <UserPlus tamanho={22} />
                      {' '}
                      Convidar
                    </PersoraButton>
                  )
                )}
                {currentUserContextData.workspaceContext.premium === true
                && currentUserContextData.type === 'Líder'
                && (
                  <PersoraButton
                    estilo="ghost"
                    tamanho="mini"
                    style={{ paddingRight: 0 }}
                    onClick={() => {
                      setTeamSettingsSheetOpen(false);
                      setAddUserSheetOpen(true);
                    }}
                  >
                    <UserPlus tamanho={22} />
                      {' '}
                    Convidar
                  </PersoraButton>
                )}
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-12">
                {teamData.map((user) => (
                  <UserAdminActions key={user._id} userData={user} />
                ))}
              </div>
            </div>
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}

TeamSettingsSheet.propTypes = {
  handleUpdateTeamSettings: PropTypes.func.isRequired,
  currentTeam: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    emoji: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  setTeamSettingsSheetOpen: PropTypes.func.isRequired,
  setAddUserSheetOpen: PropTypes.func.isRequired,
  setPremiumPricesSheetOpen: PropTypes.func.isRequired,
  countCurrentTeamUsers: PropTypes.number.isRequired,
};
