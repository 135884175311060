import PropTypes from 'prop-types';
import { discWords } from '../../utils/profileUtils';

// import theme from '../../assets/styles/themes/default';

export default function ProfileWaves({ perfil }) {
  let waves;
  switch (perfil) {
    case 'D':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M282.915 38.4419C303.267 41.6935 300.328 54.3853 314.041 63.6158C321.512 68.6447 329 70.793 338.217 72.2C342.181 72.8052 355.208 73.2286 352.676 74H125C143.61 74 166.138 71.1679 192.693 68.4407C219.248 65.7136 223.058 69.175 235.138 63.6158C247.218 58.0566 235.9 58.7908 253.857 54.8049C271.814 50.819 262.564 35.1902 282.915 38.4419Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M45.9165 73.9342C42.2393 74.0337 39.5638 74.0093 38 73.9342H45.9165C53.4735 73.7296 65.2612 73.0013 80.3254 71.1125C108.318 67.6025 109.55 63.4043 128.921 65.8819C148.292 68.3595 151.763 62.3719 157.474 56.3844C163.185 50.3968 166.096 46.9556 177.517 45.5103C188.938 44.0651 195.656 53.2185 213.46 49.3644C231.263 45.5103 230.592 34.9804 246.604 38.8345C262.615 42.6886 249.851 66.639 282.658 68.2219C315.466 69.8048 325.768 73.9342 356 73.9342H45.9165Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M32.6883 69.5842C22.2459 72.6122 6.5451 73.7296 0 73.9099L301 74C300.554 73.6996 295.533 72.8465 279.022 71.8372C258.383 70.5755 230.157 65.8894 212.307 60.5724C194.456 55.2555 174.263 61.023 162.437 50.2089C150.612 39.3948 140.682 32.2755 130.418 44.171C120.155 56.0666 111.676 59.7614 106.321 58.7701C100.966 57.7788 98.5111 53.9037 91.5941 57.2381C84.6772 60.5724 79.4337 71.6569 70.1738 67.9621C60.914 64.2673 45.7413 65.7993 32.6883 69.5842Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M39.9017 12.1152C33.2002 13.6508 24.8508 49.5839 20.017 60.4867C17.3795 66.4355 12.0567 68.5556 6.97754 70.5C4.60953 71.4065 -1.25781 72 0.242188 73.6929L322.242 74C295.949 73.8976 240.792 72.5258 230.509 67.8576C217.655 62.0223 158.99 73.6929 139.874 54.3443C124.582 38.8654 111.823 34.791 107.356 34.6886C101.313 35.0981 87.4711 34.9036 80.4401 30.8496C71.6513 25.7821 62.9723 29.1604 56.93 27.0106C50.8877 24.8607 46.6032 10.5796 39.9017 12.1152Z" fill="#F49690" />
        </svg>
      );
      break;
    case 'Di':
      waves = (
        <svg className="responsive-svg" width="358" height="74" viewBox="0 0 358 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M94.6967 73.9251C91.586 74.0384 89.3228 74.0106 88 73.9251H94.6967C101.089 73.6921 111.061 72.8626 123.804 70.7114C147.483 66.7139 148.525 61.9327 164.911 64.7544C181.298 67.5761 184.234 60.7569 189.064 53.9377C193.895 47.1185 196.358 43.1995 206.019 41.5534C215.68 39.9074 221.363 50.3322 236.424 45.9428C251.484 41.5534 250.915 29.561 264.46 33.9504C278.005 38.3398 267.207 65.6166 294.96 67.4194C322.712 69.2222 331.426 73.9251 357 73.9251H94.6967Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M299.285 35.4787C315.173 39.0013 312.879 52.7508 323.585 62.7504C329.417 68.1984 335.264 70.5258 342.459 72.05C345.554 72.7056 355.724 73.1643 353.747 74H176C190.529 74 208.117 70.9319 228.848 67.9775C249.58 65.023 252.554 68.7729 261.985 62.7504C271.416 56.7279 262.58 57.5233 276.599 53.2053C290.618 48.8873 283.396 31.9561 299.285 35.4787Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M63.6701 68.1123C54.5113 72.1496 40.7405 73.6395 35 73.8798L299 74C298.609 73.5995 294.205 72.462 279.724 71.1162C261.674 69.439 247.946 63.2224 232.347 56.1589L232.209 56.0966C216.553 49.0073 198.842 56.6974 188.47 42.2785C178.098 27.8597 158.389 18.3673 149.387 34.228C140.385 50.0887 132.948 55.0152 128.251 53.6934C123.554 52.3717 121.402 47.205 115.335 51.6508C109.268 56.0966 104.669 70.8759 96.5478 65.9495C88.4262 61.023 75.1186 63.0657 63.6701 68.1123Z" fill="#FABF69" />
          <path opacity="0.85" d="M40.8491 0.137542C34.1527 1.97036 25.8095 44.8582 20.9793 57.8712C18.3439 64.9714 13.0251 67.5019 7.94968 69.8226C5.58344 70.9045 -0.27948 71.6129 1.21939 73.6334L322.978 74C296.704 73.8778 241.588 72.2405 231.313 66.6687C218.469 59.7041 159.848 73.6334 140.747 50.54C125.466 32.0652 112.717 27.2021 108.253 27.0799C102.215 27.5687 88.3828 27.3365 81.357 22.4979C72.5748 16.4496 63.9024 20.4818 57.8647 17.9158C51.8269 15.3499 47.5456 -1.69527 40.8491 0.137542Z" fill="#F49690" />
        </svg>
      );
      break;
    case 'DI':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M45.894 73.9251C42.2168 74.0384 39.5413 74.0106 37.9775 73.9251H45.894C53.451 73.6921 65.2388 72.8626 80.3029 70.7114C108.296 66.7139 109.528 61.9327 128.899 64.7544C148.27 67.5761 151.741 60.7569 157.451 53.9377C163.162 47.1185 166.073 43.1995 177.494 41.5534C188.916 39.9074 195.634 50.3322 213.437 45.9428C231.241 41.5534 230.569 29.561 246.581 33.9504C262.593 38.3398 249.828 65.6166 282.636 67.4194C315.444 69.2222 325.745 73.9251 355.978 73.9251H45.894Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M290.885 35.4787C308.916 39.0013 306.312 52.7508 318.461 62.7504C325.08 68.1984 331.715 70.5258 339.88 72.05C343.393 72.7056 354.934 73.1643 352.691 74H150.978C167.466 74 187.425 70.9319 210.952 67.9775C234.478 65.023 237.853 68.7729 248.556 62.7504C259.259 56.7279 249.231 57.5233 265.14 53.2053C281.05 48.8873 272.854 31.9561 290.885 35.4787Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M45.6023 64.9231C35.5068 71.1473 20.3277 73.4443 14 73.8147L305 74C304.569 73.3825 299.715 71.6289 283.752 69.5542C263.798 66.9608 236.51 57.3282 219.253 46.3989C201.996 35.4696 182.474 47.3251 171.041 25.0961C159.608 2.86701 150.009 -11.7671 140.086 12.6848C130.163 37.1368 121.966 44.7317 116.788 42.694C111.611 40.6564 104.765 44.545 98.0779 51.3989C91.3907 58.2529 90.7947 69.1837 81.8425 61.5888C72.8903 53.9938 58.2216 57.1429 45.6023 64.9231Z" fill="#FABF69" />
          <path opacity="0.85" d="M36.1568 7.12453C30.0843 8.78397 22.5185 47.6149 18.1383 59.3969C15.7484 65.8254 10.9251 68.1166 6.32257 70.2177C4.17679 71.1973 -1.13989 71.8387 0.21933 73.6681L292 74C268.174 73.8894 218.194 72.4069 208.876 67.3622C197.229 61.0564 144.069 73.6681 126.747 52.7592C112.89 36.032 101.329 31.629 97.2805 31.5183C91.8052 31.9608 79.2619 31.7506 72.8907 27.3697C64.9267 21.8936 57.0623 25.5443 51.5871 23.2211C46.1118 20.8979 42.2294 5.46509 36.1568 7.12453Z" fill="#F49690" />
        </svg>
      );
      break;
    case 'ID':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M45.894 73.9251C42.2168 74.0384 39.5413 74.0106 37.9775 73.9251H45.894C53.451 73.6921 65.2388 72.8626 80.3029 70.7114C108.296 66.7139 109.528 61.9327 128.899 64.7544C148.27 67.5761 151.741 60.7569 157.451 53.9377C163.162 47.1185 166.073 43.1995 177.494 41.5534C188.916 39.9074 195.634 50.3322 213.437 45.9428C231.241 41.5534 230.569 29.561 246.581 33.9504C262.593 38.3398 249.828 65.6166 282.636 67.4194C315.444 69.2222 325.745 73.9251 355.978 73.9251H45.894Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M290.885 35.4787C308.916 39.0013 306.312 52.7508 318.461 62.7504C325.08 68.1984 331.715 70.5258 339.88 72.05C343.393 72.7056 354.934 73.1643 352.691 74H150.978C167.466 74 187.425 70.9319 210.952 67.9775C234.478 65.023 237.853 68.7729 248.556 62.7504C259.259 56.7279 249.231 57.5233 265.14 53.2053C281.05 48.8873 272.854 31.9561 290.885 35.4787Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M38.5095 0.137542C32.0418 1.97036 23.9837 44.8582 19.3185 57.8712C16.7731 64.9714 11.636 67.5019 6.73398 69.8226C4.44858 70.9045 -1.21405 71.6129 0.233612 73.6334L311 74C285.624 73.8778 232.391 72.2405 222.467 66.6687C210.062 59.7041 153.443 73.6334 134.994 50.54C120.235 32.0652 107.922 27.2021 103.61 27.0799C97.7788 27.5687 84.4194 27.3365 77.6336 22.4979C69.1514 16.4496 60.7753 20.4818 54.9438 17.9158C49.1122 15.3499 44.9772 -1.69527 38.5095 0.137542Z" fill="#F49690" />
          <path opacity="0.85" d="M45.6023 64.9231C35.5068 71.1473 20.3277 73.4443 14 73.8147L305 74C304.569 73.3825 299.715 71.6289 283.752 69.5542C263.798 66.9608 236.51 57.3282 219.253 46.3989C201.996 35.4696 182.474 47.3251 171.041 25.0961C159.608 2.86701 150.009 -11.7671 140.086 12.6848C130.163 37.1368 121.966 44.7317 116.788 42.694C111.611 40.6564 104.765 44.545 98.0779 51.3989C91.3907 58.2529 90.7947 69.1837 81.8425 61.5888C72.8903 53.9938 58.2216 57.1429 45.6023 64.9231Z" fill="#FABF69" />
        </svg>
      );
      break;
    case 'Id':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M45.894 73.9251C42.2168 74.0384 39.5413 74.0106 37.9775 73.9251H45.894C53.451 73.6921 65.2388 72.8626 80.3029 70.7114C108.296 66.7139 109.528 61.9327 128.899 64.7544C148.27 67.5761 151.741 60.7569 157.451 53.9377C163.162 47.1185 166.073 43.1995 177.494 41.5534C188.916 39.9074 195.634 50.3322 213.437 45.9428C231.241 41.5534 230.569 29.561 246.581 33.9504C262.593 38.3398 249.828 65.6166 282.636 67.4194C315.444 69.2222 325.745 73.9251 355.978 73.9251H45.894Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M290.885 35.4787C308.916 39.0013 306.312 52.7508 318.461 62.7504C325.08 68.1984 331.715 70.5258 339.88 72.05C343.393 72.7056 354.934 73.1643 352.691 74H150.978C167.466 74 187.425 70.9319 210.952 67.9775C234.478 65.023 237.853 68.7729 248.556 62.7504C259.259 56.7279 249.231 57.5233 265.14 53.2053C281.05 48.8873 272.854 31.9561 290.885 35.4787Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#F49690" />
          <path opacity="0.85" d="M45.6023 64.9231C35.5068 71.1473 20.3277 73.4443 14 73.8147L305 74C304.569 73.3825 299.715 71.6289 283.752 69.5542C263.798 66.9608 236.51 57.3282 219.253 46.3989C201.996 35.4696 182.474 47.3251 171.041 25.0961C159.608 2.86701 150.009 -11.7671 140.086 12.6848C130.163 37.1368 121.966 44.7317 116.788 42.694C111.611 40.6564 104.765 44.545 98.0779 51.3989C91.3907 58.2529 90.7947 69.1837 81.8425 61.5888C72.8903 53.9938 58.2216 57.1429 45.6023 64.9231Z" fill="#FABF69" />
        </svg>
      );
      break;
    case 'I':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M45.894 73.9251C42.2168 74.0384 39.5413 74.0106 37.9775 73.9251H45.894C53.451 73.6921 65.2388 72.8626 80.3029 70.7114C108.296 66.7139 109.528 61.9327 128.899 64.7544C148.27 67.5761 151.741 60.7569 157.451 53.9377C163.162 47.1185 166.073 43.1995 177.494 41.5534C188.916 39.9074 195.634 50.3322 213.437 45.9428C231.241 41.5534 230.569 29.561 246.581 33.9504C262.593 38.3398 249.828 65.6166 282.636 67.4194C315.444 69.2222 325.745 73.9251 355.978 73.9251H45.894Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M290.885 35.4787C308.916 39.0013 306.312 52.7508 318.461 62.7504C325.08 68.1984 331.715 70.5258 339.88 72.05C343.393 72.7056 354.934 73.1643 352.691 74H150.978C167.466 74 187.425 70.9319 210.952 67.9775C234.478 65.023 237.853 68.7729 248.556 62.7504C259.259 56.7279 249.231 57.5233 265.14 53.2053C281.05 48.8873 272.854 31.9561 290.885 35.4787Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M45.6023 64.9231C35.5068 71.1473 20.3277 73.4443 14 73.8147L305 74C304.569 73.3825 299.715 71.6289 283.752 69.5542C263.798 66.9608 236.51 57.3282 219.253 46.3989C201.996 35.4696 182.474 47.3251 171.041 25.0961C159.608 2.86701 150.009 -11.7671 140.086 12.6848C130.163 37.1368 121.966 44.7317 116.788 42.694C111.611 40.6564 104.765 44.545 98.0779 51.3989C91.3907 58.2529 90.7947 69.1837 81.8425 61.5888C72.8903 53.9938 58.2216 57.1429 45.6023 64.9231Z" fill="#FABF69" />
        </svg>
      );
      break;
    case 'Is':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M290.885 35.4787C308.916 39.0013 306.312 52.7508 318.461 62.7504C325.08 68.1984 331.715 70.5258 339.88 72.05C343.393 72.7056 354.934 73.1643 352.691 74H150.978C167.466 74 187.425 70.9319 210.952 67.9775C234.478 65.023 237.853 68.7729 248.556 62.7504C259.259 56.7279 249.231 57.5233 265.14 53.2053C281.05 48.8873 272.854 31.9561 290.885 35.4787Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M45.9165 73.9159C42.2393 74.0431 39.5638 74.0119 38 73.9159H45.9165C53.4735 73.6545 65.2612 72.7239 80.3254 70.3104C108.318 65.8254 109.55 60.461 128.921 63.6269C148.292 66.7927 151.763 59.1419 157.474 51.4911C163.185 43.8403 170.579 37.3467 182 35.5C193.421 33.6533 195.656 47.4459 213.46 42.5212C231.263 37.5965 230.592 24.1417 246.604 29.0663C262.616 33.991 249.851 64.5942 282.658 66.6169C315.466 68.6395 325.768 73.9159 356 73.9159H45.9165Z" fill="#9C91C5" />
          <path opacity="0.4" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M43.3217 64.9231C33.9548 71.1473 19.871 73.4443 14 73.8147L284 74C283.6 73.3825 279.096 71.6289 264.285 69.5542C245.772 66.9608 220.453 57.3282 204.441 46.3989C188.429 35.4696 170.316 47.3251 159.708 25.0961C149.1 2.86701 140.193 -11.7671 130.987 12.6848C121.78 37.1368 114.174 44.7317 109.371 42.694C104.567 40.6564 98.215 44.545 92.0104 51.3989C85.8058 58.2529 85.2528 69.1837 76.9466 61.5888C68.6405 53.9938 55.0304 57.1429 43.3217 64.9231Z" fill="#FABF69" />
        </svg>
      );
      break;
    case 'IS':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M290.885 35.4787C308.916 39.0013 306.312 52.7508 318.461 62.7504C325.08 68.1984 331.715 70.5258 339.88 72.05C343.393 72.7056 354.934 73.1643 352.691 74H150.978C167.466 74 187.425 70.9319 210.952 67.9775C234.478 65.023 237.853 68.7729 248.556 62.7504C259.259 56.7279 249.231 57.5233 265.14 53.2053C281.05 48.8873 272.854 31.9561 290.885 35.4787Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M45.9165 73.8647C42.2393 74.0694 39.5638 74.0191 38 73.8647H45.9165C53.4735 73.4442 65.2612 71.9471 80.3254 68.0645C108.318 60.8495 109.55 52.2199 128.921 57.3128C148.292 62.4057 151.763 50.0979 157.474 37.7901C163.185 25.4822 170.579 15.0361 182 12.0652C193.421 9.09436 195.656 31.2825 213.46 23.3602C231.263 15.4379 230.592 -6.20691 246.604 1.71537C262.616 9.63765 261.851 51.869 294.658 55.1228C327.466 58.3766 325.768 73.8647 356 73.8647H45.9165Z" fill="#9C91C5" />
          <path opacity="0.4" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M43.3217 64.9231C33.9548 71.1473 19.871 73.4443 14 73.8147L284 74C283.6 73.3825 279.096 71.6289 264.285 69.5542C245.772 66.9608 220.453 57.3282 204.441 46.3989C188.429 35.4696 170.316 47.3251 159.708 25.0961C149.1 2.86701 140.193 -11.7671 130.987 12.6848C121.78 37.1368 114.174 44.7317 109.371 42.694C104.567 40.6564 98.215 44.545 92.0104 51.3989C85.8058 58.2529 85.2528 69.1837 76.9466 61.5888C68.6405 53.9938 55.0304 57.1429 43.3217 64.9231Z" fill="#FABF69" />
        </svg>
      );
      break;
    case 'SI':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M290.885 35.4787C308.916 39.0013 306.312 52.7508 318.461 62.7504C325.08 68.1984 331.715 70.5258 339.88 72.05C343.393 72.7056 354.934 73.1643 352.691 74H150.978C167.466 74 187.425 70.9319 210.952 67.9775C234.478 65.023 237.853 68.7729 248.556 62.7504C259.259 56.7279 249.231 57.5233 265.14 53.2053C281.05 48.8873 272.854 31.9561 290.885 35.4787Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M30.8421 64.9231C20.9895 71.1473 6.17544 73.4443 0 73.8147L284 74C283.579 73.3825 278.842 71.6289 263.263 69.5542C243.789 66.9608 217.158 57.3282 200.316 46.3989C183.474 35.4696 164.421 47.3251 153.263 25.0961C142.105 2.86701 132.737 -11.7671 123.053 12.6848C113.368 37.1368 105.368 36.7317 100.316 34.694C95.2632 32.6564 88.5817 33.545 82.0554 40.3989C75.5291 47.2529 74.9474 58.1837 66.2105 50.5888C57.4737 42.9938 43.1579 57.1429 30.8421 64.9231Z" fill="#FABF69" />
          <path opacity="0.85" d="M45.9165 73.8647C42.2393 74.0694 39.5638 74.0191 38 73.8647H45.9165C53.4735 73.4442 65.2612 71.9471 80.3254 68.0645C108.318 60.8495 109.55 52.2199 128.921 57.3128C148.292 62.4057 150.763 51.0979 156.474 38.7901C162.185 26.4822 168.579 19.0361 180 16.0652C191.421 13.0944 195.656 31.2825 213.46 23.3602C231.263 15.4379 230.592 -6.20691 246.604 1.71537C262.616 9.63765 261.851 51.869 294.658 55.1228C327.466 58.3766 325.768 73.8647 356 73.8647H45.9165Z" fill="#9C91C5" />
        </svg>
      );
      break;
    case 'Si':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M290.885 35.4787C308.916 39.0013 306.312 52.7508 318.461 62.7504C325.08 68.1984 331.715 70.5258 339.88 72.05C343.393 72.7056 354.934 73.1643 352.691 74H150.978C167.466 74 187.425 70.9319 210.952 67.9775C234.478 65.023 237.853 68.7729 248.556 62.7504C259.259 56.7279 249.231 57.5233 265.14 53.2053C281.05 48.8873 272.854 31.9561 290.885 35.4787Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M30.8421 68.9709C20.9895 72.4194 6.17544 73.6921 0 73.8974L284 74C283.579 73.6579 278.842 72.6863 263.263 71.5368C243.789 70.0999 217.158 64.7629 200.316 58.7075C183.474 52.6521 164.421 59.2207 153.263 46.9046C142.105 34.5885 132.737 26.4804 123.053 40.0281C113.368 53.5758 105.368 53.3514 100.316 52.2224C95.2632 51.0934 88.5817 51.5857 82.0554 55.3832C75.5291 59.1806 74.9474 65.2369 66.2105 61.0289C57.4737 56.8209 43.1579 64.6603 30.8421 68.9709Z" fill="#FABF69" />
          <path opacity="0.85" d="M45.9165 73.8647C42.2393 74.0694 39.5638 74.0191 38 73.8647H45.9165C53.4735 73.4442 65.2612 71.9471 80.3254 68.0645C108.318 60.8495 109.55 52.2199 128.921 57.3128C148.292 62.4057 150.763 51.0979 156.474 38.7901C162.185 26.4822 168.579 19.0361 180 16.0652C191.421 13.0944 195.656 31.2825 213.46 23.3602C231.263 15.4379 230.592 -6.20691 246.604 1.71537C262.616 9.63765 261.851 51.869 294.658 55.1228C327.466 58.3766 325.768 73.8647 356 73.8647H45.9165Z" fill="#9C91C5" />
        </svg>
      );
      break;
    case 'S':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M290.885 35.4787C308.916 39.0013 306.312 52.7508 318.461 62.7504C325.08 68.1984 331.715 70.5258 339.88 72.05C343.393 72.7056 354.934 73.1643 352.691 74H150.978C167.466 74 187.425 70.9319 210.952 67.9775C234.478 65.023 237.853 68.7729 248.556 62.7504C259.259 56.7279 249.231 57.5233 265.14 53.2053C281.05 48.8873 272.854 31.9561 290.885 35.4787Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M30.8421 68.9709C20.9895 72.4194 6.17544 73.6921 0 73.8974L284 74C283.579 73.6579 278.842 72.6863 263.263 71.5368C243.789 70.0999 217.158 64.7629 200.316 58.7075C183.474 52.6521 164.421 59.2207 153.263 46.9046C142.105 34.5885 132.737 26.4804 123.053 40.0281C113.368 53.5758 105.368 53.3514 100.316 52.2224C95.2632 51.0934 88.5817 51.5857 82.0554 55.3832C75.5291 59.1806 74.9474 65.2369 66.2105 61.0289C57.4737 56.8209 43.1579 64.6603 30.8421 68.9709Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M45.9165 73.8647C42.2393 74.0694 39.5638 74.0191 38 73.8647H45.9165C53.4735 73.4442 65.2612 71.9471 80.3254 68.0645C108.318 60.8495 109.55 52.2199 128.921 57.3128C148.292 62.4057 150.763 51.0979 156.474 38.7901C162.185 26.4822 168.579 19.0361 180 16.0652C191.421 13.0944 195.656 31.2825 213.46 23.3602C231.263 15.4379 230.592 -6.20691 246.604 1.71537C262.616 9.63765 261.851 51.869 294.658 55.1228C327.466 58.3766 325.768 73.8647 356 73.8647H45.9165Z" fill="#9C91C5" />
        </svg>
      );
      break;
    case 'Sc':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.85" d="M290.907 27.5769C308.938 31.8221 306.335 48.392 318.484 60.4428C325.103 67.0083 331.737 69.8131 339.903 71.65C343.415 72.4401 354.956 72.9929 352.713 74H151C167.488 74 187.447 70.3026 210.974 66.7421C234.501 63.1816 237.876 67.7007 248.578 60.4428C259.281 53.1849 249.253 54.1435 265.163 48.9397C281.072 43.736 272.876 23.3317 290.907 27.5769Z" fill="#3FBE89" />
          <path opacity="0.4" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M30.8421 68.9709C20.9895 72.4194 6.17544 73.6921 0 73.8974L284 74C283.579 73.6579 278.842 72.6863 263.263 71.5368C243.789 70.0999 217.158 64.7629 200.316 58.7075C183.474 52.6521 164.421 59.2207 153.263 46.9046C142.105 34.5885 132.737 26.4804 123.053 40.0281C113.368 53.5758 105.368 53.3514 100.316 52.2224C95.2632 51.0934 88.5817 51.5857 82.0554 55.3832C75.5291 59.1806 74.9474 65.2369 66.2105 61.0289C57.4737 56.8209 43.1579 64.6603 30.8421 68.9709Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M45.1448 73.8647C41.826 74.0694 39.4113 74.0191 38 73.8647H45.1448C51.9651 73.4442 62.6037 71.9471 76.1993 68.0645C101.463 60.8495 102.575 52.2199 120.058 57.3128C137.54 62.4057 142.603 58.0979 147.757 45.7901C152.911 33.4822 158.682 26.0361 168.989 23.0652C179.297 20.0944 180.287 31.2825 196.355 23.3602C212.423 15.4379 211.817 -6.20691 226.268 1.71537C240.719 9.63765 240.029 51.869 269.638 55.1228C299.248 58.3766 297.715 73.8647 325 73.8647H45.1448Z" fill="#9C91C5" />
        </svg>
      );
      break;
    case 'SC':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.85" d="M280.149 0.908281C301.303 7.59218 298.249 33.681 312.503 52.6546C320.269 62.9919 328.053 67.4079 337.634 70.3C341.754 71.544 355.295 72.4143 352.664 74H116C135.345 74 158.762 68.1785 186.366 62.5726C213.969 56.9668 217.928 64.0819 230.485 52.6546C243.043 41.2274 231.277 42.7366 249.943 34.5434C268.61 26.3502 258.994 -5.77562 280.149 0.908281Z" fill="#3FBE89" />
          <path opacity="0.4" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M27.2583 68.9709C18.5506 72.4194 5.45787 73.6921 0 73.8974L251 74C250.628 73.6579 246.441 72.6863 232.673 71.5368C215.462 70.0999 191.925 64.7629 177.04 58.7075C162.155 52.6521 145.316 59.2207 135.454 46.9046C125.593 34.5885 117.313 26.4804 108.754 40.0281C100.195 53.5758 93.1249 53.3514 88.6594 52.2224C84.1938 51.0934 78.2887 51.5857 72.5208 55.3832C66.7528 59.1806 66.2387 65.2369 58.5171 61.0289C50.7954 56.8209 38.1431 64.6603 27.2583 68.9709Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M33.1448 73.8647C29.826 74.0694 27.4113 74.0191 26 73.8647H33.1448C39.9651 73.4442 50.6037 71.9471 64.1993 68.0645C89.4634 60.8495 90.575 52.2199 108.058 57.3128C125.54 62.4057 130.603 58.0979 135.757 45.7901C140.911 33.4822 146.682 26.0361 156.989 23.0652C167.297 20.0944 168.287 31.2825 184.355 23.3602C200.423 15.4379 199.817 -6.20691 214.268 1.71537C228.719 9.63765 228.029 51.869 257.638 55.1228C287.248 58.3766 285.715 73.8647 313 73.8647H33.1448Z" fill="#9C91C5" />
        </svg>
      );
      break;
    case 'CS':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M27.2583 68.9709C18.5506 72.4194 5.45787 73.6921 0 73.8974L251 74C250.628 73.6579 246.441 72.6863 232.673 71.5368C215.462 70.0999 191.925 64.7629 177.04 58.7075C162.155 52.6521 145.316 59.2207 135.454 46.9046C125.593 34.5885 117.313 26.4804 108.754 40.0281C100.195 53.5758 93.1249 53.3514 88.6594 52.2224C84.1938 51.0934 78.2887 51.5857 72.5208 55.3832C66.7528 59.1806 66.2387 65.2369 58.5171 61.0289C50.7954 56.8209 38.1431 64.6603 27.2583 68.9709Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M33.1448 73.8647C29.826 74.0694 27.4113 74.0191 26 73.8647H33.1448C39.9651 73.4442 50.6037 71.9471 64.1993 68.0645C89.4634 60.8495 90.575 52.2199 108.058 57.3128C125.54 62.4057 130.603 58.0979 135.757 45.7901C140.911 33.4822 146.682 26.0361 156.989 23.0652C167.297 20.0944 168.287 31.2825 184.355 23.3602C200.423 15.4379 199.817 -6.20691 214.268 1.71537C228.719 9.63765 228.029 51.869 257.638 55.1228C287.248 58.3766 285.715 73.8647 313 73.8647H33.1448Z" fill="#9C91C5" />
          <path opacity="0.9" d="M272.464 0.908281C295.85 7.59218 292.474 33.681 308.231 52.6546C316.816 62.9919 325.422 67.4079 336.013 70.3C340.568 71.544 355.537 72.4143 352.628 74H91C112.385 74 138.273 68.1785 168.788 62.5726C199.303 56.9668 203.68 64.0819 217.562 52.6546C231.444 41.2274 218.437 42.7366 239.072 34.5434C259.708 26.3502 249.077 -5.77562 272.464 0.908281Z" fill="#3FBE89" />
        </svg>
      );
      break;
    case 'Cs':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M27.2583 68.9709C18.5506 72.4194 5.45787 73.6921 0 73.8974L251 74C250.628 73.6579 246.441 72.6863 232.673 71.5368C215.462 70.0999 191.925 64.7629 177.04 58.7075C162.155 52.6521 145.316 59.2207 135.454 46.9046C125.593 34.5885 117.313 26.4804 108.754 40.0281C100.195 53.5758 93.1249 53.3514 88.6594 52.2224C84.1938 51.0934 78.2887 51.5857 72.5208 55.3832C66.7528 59.1806 66.2387 65.2369 58.5171 61.0289C50.7954 56.8209 38.1431 64.6603 27.2583 68.9709Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M33.1448 73.9141C29.826 74.044 27.4113 74.0121 26 73.9141H33.1448C39.9651 73.647 50.6037 72.6961 64.1993 70.2301C89.4634 65.6477 90.575 60.1667 108.058 63.4014C125.54 66.6361 130.603 63.9 135.757 56.0829C140.911 48.2657 146.682 43.5364 156.989 41.6495C167.297 39.7626 168.287 46.8686 184.355 41.8369C200.423 36.8052 199.817 23.0578 214.268 28.0895C228.719 33.1212 228.029 59.9438 257.638 62.0104C287.248 64.077 285.715 73.9141 313 73.9141H33.1448Z" fill="#9C91C5" />
          <path opacity="0.9" d="M272.464 0.908281C295.85 7.59218 292.474 33.681 308.231 52.6546C316.816 62.9919 325.422 67.4079 336.013 70.3C340.568 71.544 355.537 72.4143 352.628 74H91C112.385 74 138.273 68.1785 168.788 62.5726C199.303 56.9668 203.68 64.0819 217.562 52.6546C231.444 41.2274 218.437 42.7366 239.072 34.5434C259.708 26.3502 249.077 -5.77562 272.464 0.908281Z" fill="#3FBE89" />
        </svg>
      );
      break;
    case 'C':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M27.2583 68.9709C18.5506 72.4194 5.45787 73.6921 0 73.8974L251 74C250.628 73.6579 246.441 72.6863 232.673 71.5368C215.462 70.0999 191.925 64.7629 177.04 58.7075C162.155 52.6521 145.316 59.2207 135.454 46.9046C125.593 34.5885 117.313 26.4804 108.754 40.0281C100.195 53.5758 93.1249 53.3514 88.6594 52.2224C84.1938 51.0934 78.2887 51.5857 72.5208 55.3832C66.7528 59.1806 66.2387 65.2369 58.5171 61.0289C50.7954 56.8209 38.1431 64.6603 27.2583 68.9709Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M33.1448 73.9141C29.826 74.044 27.4113 74.0121 26 73.9141H33.1448C39.9651 73.647 50.6037 72.6961 64.1993 70.2301C89.4634 65.6477 90.575 60.1667 108.058 63.4014C125.54 66.6361 130.603 63.9 135.757 56.0829C140.911 48.2657 146.682 43.5364 156.989 41.6495C167.297 39.7626 168.287 46.8686 184.355 41.8369C200.423 36.8052 199.817 23.0578 214.268 28.0895C228.719 33.1212 228.029 59.9438 257.638 62.0104C287.248 64.077 285.715 73.9141 313 73.9141H33.1448Z" fill="#B8BDC7" />
          <path opacity="0.9" d="M272.464 0.908281C295.85 7.59218 292.474 33.681 308.231 52.6546C316.816 62.9919 325.422 67.4079 336.013 70.3C340.568 71.544 355.537 72.4143 352.628 74H91C112.385 74 138.273 68.1785 168.788 62.5726C199.303 56.9668 197.68 65.0819 211.562 53.6546C225.444 42.2274 218.437 42.7366 239.072 34.5434C259.708 26.3502 249.077 -5.77562 272.464 0.908281Z" fill="#3FBE89" />
        </svg>
      );
      break;
    case 'Cd':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M27.2583 68.9709C18.5506 72.4194 5.45787 73.6921 0 73.8974L251 74C250.628 73.6579 246.441 72.6863 232.673 71.5368C215.462 70.0999 191.925 64.7629 177.04 58.7075C162.155 52.6521 145.316 59.2207 135.454 46.9046C125.593 34.5885 117.313 26.4804 108.754 40.0281C100.195 53.5758 93.1249 53.3514 88.6594 52.2224C84.1938 51.0934 78.2887 51.5857 72.5208 55.3832C66.7528 59.1806 66.2387 65.2369 58.5171 61.0289C50.7954 56.8209 38.1431 64.6603 27.2583 68.9709Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M33.1448 73.9141C29.826 74.044 27.4113 74.0121 26 73.9141H33.1448C39.9651 73.647 50.6037 72.6961 64.1993 70.2301C89.4634 65.6477 90.575 60.1667 108.058 63.4014C125.54 66.6361 130.603 63.9 135.757 56.0829C140.911 48.2657 146.682 43.5364 156.989 41.6495C167.297 39.7626 168.287 46.8686 184.355 41.8369C200.423 36.8052 199.817 23.0578 214.268 28.0895C228.719 33.1212 228.029 59.9438 257.638 62.0104C287.248 64.077 285.715 73.9141 313 73.9141H33.1448Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M38.5095 33.0762C32.0418 34.0917 23.9837 57.8539 19.3185 65.0638C16.7731 68.9977 11.636 70.3997 6.73398 71.6855C4.44858 72.2849 -1.21405 72.6774 0.233612 73.7969L311 74C285.624 73.9323 232.391 73.0251 222.467 69.9381C210.062 66.0793 153.443 73.7969 134.994 61.0019C120.235 50.7659 107.922 48.0714 103.61 48.0037C97.7788 48.2745 84.4194 48.1459 77.6336 45.465C69.1514 42.114 60.7753 44.348 54.9438 42.9263C49.1122 41.5047 44.9772 32.0607 38.5095 33.0762Z" fill="#EF6C64" />
          <path opacity="0.9" d="M272.464 0.908281C295.85 7.59218 292.474 33.681 308.231 52.6546C316.816 62.9919 325.422 67.4079 336.013 70.3C340.568 71.544 355.537 72.4143 352.628 74H91C112.385 74 138.273 68.1785 168.788 62.5726C199.303 56.9668 197.68 65.0819 211.562 53.6546C225.444 42.2274 218.437 42.7366 239.072 34.5434C259.708 26.3502 249.077 -5.77562 272.464 0.908281Z" fill="#3FBE89" />
        </svg>
      );
      break;
    case 'CD':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M57.2583 68.9709C48.5506 72.4194 35.4579 73.6921 30 73.8974L281 74C280.628 73.6579 276.441 72.6863 262.673 71.5368C245.462 70.0999 221.925 64.7629 207.04 58.7075C192.155 52.6521 175.316 59.2207 165.454 46.9046C155.593 34.5885 147.313 26.4804 138.754 40.0281C130.195 53.5758 123.125 53.3514 118.659 52.2224C114.194 51.0934 108.289 51.5857 102.521 55.3832C96.7528 59.1806 96.2387 65.2369 88.5171 61.0289C80.7954 56.8209 68.1431 64.6603 57.2583 68.9709Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M33.1448 73.9141C29.826 74.044 27.4113 74.0121 26 73.9141H33.1448C39.9651 73.647 50.6037 72.6961 64.1993 70.2301C89.4634 65.6477 90.575 60.1667 108.058 63.4014C125.54 66.6361 130.603 63.9 135.757 56.0829C140.911 48.2657 146.682 43.5364 156.989 41.6495C167.297 39.7626 168.287 46.8686 184.355 41.8369C200.423 36.8052 199.817 23.0578 214.268 28.0895C228.719 33.1212 228.029 59.9438 257.638 62.0104C287.248 64.077 285.715 73.9141 313 73.9141H33.1448Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M38.5095 6.12639C32.0418 7.8106 23.9837 47.2211 19.3185 59.179C16.7731 65.7034 11.636 68.0288 6.73398 70.1613C4.44858 71.1555 -1.21405 71.8064 0.233612 73.6631L311 74C285.624 73.8877 232.391 72.3831 222.467 67.2632C210.062 60.8632 153.443 73.6631 134.994 52.4421C120.235 35.4653 107.922 30.9965 103.61 30.8843C97.7788 31.3334 84.4194 31.1201 77.6336 26.6737C69.1514 21.1159 60.7753 24.8211 54.9438 22.4632C49.1122 20.1053 44.9772 4.44218 38.5095 6.12639Z" fill="#EF6C64" />
          <path opacity="0.9" d="M272.464 0.908281C295.85 7.59218 292.474 33.681 308.231 52.6546C316.816 62.9919 325.422 67.4079 336.013 70.3C340.568 71.544 355.537 72.4143 352.628 74H91C112.385 74 138.273 68.1785 168.788 62.5726C199.303 56.9668 197.68 65.0819 211.562 53.6546C225.444 42.2274 218.437 42.7366 239.072 34.5434C259.708 26.3502 249.077 -5.77562 272.464 0.908281Z" fill="#3FBE89" />
        </svg>
      );
      break;
    case 'DC':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M57.2583 68.9709C48.5506 72.4194 35.4579 73.6921 30 73.8974L281 74C280.628 73.6579 276.441 72.6863 262.673 71.5368C245.462 70.0999 221.925 64.7629 207.04 58.7075C192.155 52.6521 175.316 59.2207 165.454 46.9046C155.593 34.5885 147.313 26.4804 138.754 40.0281C130.195 53.5758 123.125 53.3514 118.659 52.2224C114.194 51.0934 108.289 51.5857 102.521 55.3832C96.7528 59.1806 96.2387 65.2369 88.5171 61.0289C80.7954 56.8209 68.1431 64.6603 57.2583 68.9709Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M33.1448 73.9141C29.826 74.044 27.4113 74.0121 26 73.9141H33.1448C39.9651 73.647 50.6037 72.6961 64.1993 70.2301C89.4634 65.6477 90.575 60.1667 108.058 63.4014C125.54 66.6361 130.603 63.9 135.757 56.0829C140.911 48.2657 146.682 43.5364 156.989 41.6495C167.297 39.7626 168.287 46.8686 184.355 41.8369C200.423 36.8052 199.817 23.0578 214.268 28.0895C228.719 33.1212 228.029 59.9438 257.638 62.0104C287.248 64.077 285.715 73.9141 313 73.9141H33.1448Z" fill="#B8BDC7" />
          <path opacity="0.9" d="M272.464 0.908281C295.85 7.59218 292.474 33.681 308.231 52.6546C316.816 62.9919 325.422 67.4079 336.013 70.3C340.568 71.544 355.537 72.4143 352.628 74H91C112.385 74 138.273 68.1785 168.788 62.5726C199.303 56.9668 197.68 65.0819 211.562 53.6546C225.444 42.2274 218.437 42.7366 239.072 34.5434C259.708 26.3502 249.077 -5.77562 272.464 0.908281Z" fill="#3FBE89" />
          <path opacity="0.85" d="M38.5095 6.12639C32.0418 7.8106 23.9837 47.2211 19.3185 59.179C16.7731 65.7034 11.636 68.0288 6.73398 70.1613C4.44858 71.1555 -1.21405 71.8064 0.233612 73.6631L311 74C285.624 73.8877 232.391 72.3831 222.467 67.2632C210.062 60.8632 153.443 73.6631 134.994 52.4421C120.235 35.4653 107.922 30.9965 103.61 30.8843C97.7788 31.3334 84.4194 31.1201 77.6336 26.6737C69.1514 21.1159 60.7753 24.8211 54.9438 22.4632C49.1122 20.1053 44.9772 4.44218 38.5095 6.12639Z" fill="#EF6C64" />
        </svg>
      );
      break;
    case 'Dc':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M57.2583 68.9709C48.5506 72.4194 35.4579 73.6921 30 73.8974L281 74C280.628 73.6579 276.441 72.6863 262.673 71.5368C245.462 70.0999 221.925 64.7629 207.04 58.7075C192.155 52.6521 175.316 59.2207 165.454 46.9046C155.593 34.5885 147.313 26.4804 138.754 40.0281C130.195 53.5758 123.125 53.3514 118.659 52.2224C114.194 51.0934 108.289 51.5857 102.521 55.3832C96.7528 59.1806 96.2387 65.2369 88.5171 61.0289C80.7954 56.8209 68.1431 64.6603 57.2583 68.9709Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M33.1448 73.9141C29.826 74.044 27.4113 74.0121 26 73.9141H33.1448C39.9651 73.647 50.6037 72.6961 64.1993 70.2301C89.4634 65.6477 90.575 60.1667 108.058 63.4014C125.54 66.6361 130.603 63.9 135.757 56.0829C140.911 48.2657 146.682 43.5364 156.989 41.6495C167.297 39.7626 168.287 46.8686 184.355 41.8369C200.423 36.8052 199.817 23.0578 214.268 28.0895C228.719 33.1212 228.029 59.9438 257.638 62.0104C287.248 64.077 285.715 73.9141 313 73.9141H33.1448Z" fill="#B8BDC7" />
          <path opacity="0.9" d="M272.464 33.5032C295.85 37.2065 292.474 51.6611 308.231 62.1735C316.816 67.9009 325.422 70.3476 336.013 71.95C340.568 72.6393 355.537 73.1214 352.628 74H91C112.385 74 138.273 70.7746 168.788 67.6686C199.303 64.5627 197.68 69.0589 211.562 62.7276C225.444 56.3962 218.437 56.6784 239.072 52.1389C259.708 47.5995 249.077 29.8 272.464 33.5032Z" fill="#3FBE89" />
          <path opacity="0.85" d="M38.5095 6.12639C32.0418 7.8106 23.9837 47.2211 19.3185 59.179C16.7731 65.7034 11.636 68.0288 6.73398 70.1613C4.44858 71.1555 -1.21405 71.8064 0.233612 73.6631L311 74C285.624 73.8877 232.391 72.3831 222.467 67.2632C210.062 60.8632 153.443 73.6631 134.994 52.4421C120.235 35.4653 107.922 30.9965 103.61 30.8843C97.7788 31.3334 84.4194 31.1201 77.6336 26.6737C69.1514 21.1159 60.7753 24.8211 54.9438 22.4632C49.1122 20.1053 44.9772 4.44218 38.5095 6.12639Z" fill="#EF6C64" />
        </svg>
      );
      break;
    case 'DS':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M272.464 33.5032C295.85 37.2065 292.474 51.6611 308.231 62.1735C316.816 67.9009 325.422 70.3476 336.013 71.95C340.568 72.6393 355.537 73.1214 352.628 74H91C112.385 74 138.273 70.7746 168.788 67.6686C199.303 64.5627 197.68 69.0589 211.562 62.7276C225.444 56.3962 218.437 56.6784 239.072 52.1389C259.708 47.5995 249.077 29.8 272.464 33.5032Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M53.2583 68.9709C44.5506 72.4194 31.4579 73.6921 26 73.8974L277 74C276.628 73.6579 272.441 72.6863 258.673 71.5368C241.462 70.0999 217.925 64.7629 203.04 58.7075C188.155 52.6521 171.316 59.2207 161.454 46.9046C151.593 34.5885 143.313 26.4804 134.754 40.0281C126.195 53.5758 119.125 53.3514 114.659 52.2224C110.194 51.0934 104.289 51.5857 98.5208 55.3832C92.7528 59.1806 92.2387 65.2369 84.5171 61.0289C76.7954 56.8209 64.1431 64.6603 53.2583 68.9709Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M43.1447 73.8757C39.826 74.0637 37.4113 74.0175 36 73.8757H43.1447C49.965 73.4893 60.6036 72.1136 74.1993 68.5457C99.4634 61.9158 100.575 53.9859 118.058 58.6658C135.54 63.3458 140.603 59.3872 145.757 48.0774C150.911 36.7675 156.682 29.925 166.989 27.1951C177.297 24.4651 178.287 34.7461 194.355 27.4661C210.423 20.1862 209.817 0.296354 224.268 7.57629C238.719 14.8562 238.029 53.6634 267.638 56.6534C297.248 59.6433 295.715 73.8757 323 73.8757H43.1447Z" fill="#9C91C5" />
          <path opacity="0.85" d="M38.5095 6.12639C32.0418 7.8106 23.9837 47.2211 19.3185 59.179C16.7731 65.7034 11.636 68.0288 6.73398 70.1613C4.44858 71.1555 -1.21405 71.8064 0.233612 73.6631L311 74C285.624 73.8877 232.391 72.3831 222.467 67.2632C210.062 60.8632 153.443 73.6631 134.994 52.4421C120.235 35.4653 107.922 30.9965 103.61 30.8843C97.7788 31.3334 84.4194 31.1201 77.6336 26.6737C69.1514 21.1159 60.7753 24.8211 54.9438 22.4632C49.1122 20.1053 44.9772 4.44218 38.5095 6.12639Z" fill="#EF6C64" />
        </svg>
      );
      break;
    case 'SD':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M272.464 33.5032C295.85 37.2065 292.474 51.6611 308.231 62.1735C316.816 67.9009 325.422 70.3476 336.013 71.95C340.568 72.6393 355.537 73.1214 352.628 74H91C112.385 74 138.273 70.7746 168.788 67.6686C199.303 64.5627 197.68 69.0589 211.562 62.7276C225.444 56.3962 218.437 56.6784 239.072 52.1389C259.708 47.5995 249.077 29.8 272.464 33.5032Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M53.2583 68.9709C44.5506 72.4194 31.4579 73.6921 26 73.8974L277 74C276.628 73.6579 272.441 72.6863 258.673 71.5368C241.462 70.0999 217.925 64.7629 203.04 58.7075C188.155 52.6521 171.316 59.2207 161.454 46.9046C151.593 34.5885 143.313 26.4804 134.754 40.0281C126.195 53.5758 119.125 53.3514 114.659 52.2224C110.194 51.0934 104.289 51.5857 98.5208 55.3832C92.7528 59.1806 92.2387 65.2369 84.5171 61.0289C76.7954 56.8209 64.1431 64.6603 53.2583 68.9709Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M38.5095 6.12639C32.0418 7.8106 23.9837 47.2211 19.3185 59.179C16.7731 65.7034 11.636 68.0288 6.73398 70.1613C4.44858 71.1555 -1.21405 71.8064 0.233612 73.6631L311 74C285.624 73.8877 232.391 72.3831 222.467 67.2632C210.062 60.8632 153.443 73.6631 134.994 52.4421C120.235 35.4653 107.922 30.9965 103.61 30.8843C97.7788 31.3334 84.4194 31.1201 77.6336 26.6737C69.1514 21.1159 60.7753 24.8211 54.9438 22.4632C49.1122 20.1053 44.9772 4.44218 38.5095 6.12639Z" fill="#EF6C64" />
          <path opacity="0.85" d="M43.1447 73.8757C39.826 74.0637 37.4113 74.0175 36 73.8757H43.1447C49.965 73.4893 60.6036 72.1136 74.1993 68.5457C99.4634 61.9158 100.575 53.9859 118.058 58.6658C135.54 63.3458 140.603 59.3872 145.757 48.0774C150.911 36.7675 156.682 29.925 166.989 27.1951C177.297 24.4651 178.287 34.7461 194.355 27.4661C210.423 20.1862 209.817 0.296354 224.268 7.57629C238.719 14.8562 238.029 53.6634 267.638 56.6534C297.248 59.6433 295.715 73.8757 323 73.8757H43.1447Z" fill="#9C91C5" />
        </svg>
      );
      break;
    case 'IC':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M37.1448 73.9287C33.826 74.0365 31.4113 74.0101 30 73.9287H37.1448C43.9651 73.7071 54.6037 72.9181 68.1993 70.8718C93.4634 67.0694 94.575 62.5213 112.058 65.2054C129.54 67.8895 134.603 65.6192 139.757 59.1326C144.911 52.646 150.682 48.7217 160.989 47.156C171.297 45.5903 172.287 51.4867 188.355 47.3115C204.423 43.1362 203.817 31.7288 218.268 35.904C232.719 40.0793 232.029 62.3364 261.638 64.0512C291.248 65.766 289.715 73.9287 317 73.9287H37.1448Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M38.5095 35.0725C32.0418 36.0384 23.9837 58.6415 19.3185 65.4997C16.7731 69.2417 11.636 70.5753 6.73398 71.7984C4.44858 72.3686 -1.21405 72.7419 0.233612 73.8068L311 74C285.624 73.9356 232.391 73.0727 222.467 70.1362C210.062 66.4656 153.443 73.8068 134.994 61.6359C120.235 51.8992 107.922 49.3363 103.61 49.2719C97.7788 49.5294 84.4194 49.4071 77.6336 46.857C69.1514 43.6694 60.7753 45.7945 54.9438 44.4421C49.1122 43.0898 44.9772 34.1065 38.5095 35.0725Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M272.464 6.83464C295.85 12.9766 292.474 36.9501 308.231 54.3853C316.816 63.8844 325.422 67.9424 336.013 70.6C340.568 71.7431 355.537 72.5429 352.628 74H91C112.385 74 138.273 68.6505 168.788 63.4992C199.303 58.3479 197.68 65.805 211.562 55.3043C225.444 44.8035 218.437 45.2715 239.072 37.7426C259.708 30.2137 249.077 0.692671 272.464 6.83464Z" fill="#3FBE89" />
          <path opacity="0.85" d="M41.2583 65.6591C32.5506 71.3786 19.4579 73.4893 14 73.8298L265 74C264.628 73.4326 260.441 71.8211 246.673 69.9147C229.462 67.5315 205.925 58.68 191.04 48.6368C176.155 38.5937 159.316 49.488 149.454 29.0613C139.593 8.63455 131.313 -4.81303 122.754 17.6563C114.195 40.1257 107.125 39.7535 102.659 37.881C98.1938 36.0086 92.2887 36.8251 86.5208 43.1233C80.7528 49.4216 80.2387 59.4661 72.5171 52.487C64.7954 45.5078 52.1431 58.5097 41.2583 65.6591Z" fill="#FABF69" />
        </svg>
      );
      break;
    case 'CI':
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M37.1448 73.9287C33.826 74.0365 31.4113 74.0101 30 73.9287H37.1448C43.9651 73.7071 54.6037 72.9181 68.1993 70.8718C93.4634 67.0694 94.575 62.5213 112.058 65.2054C129.54 67.8895 134.603 65.6192 139.757 59.1326C144.911 52.646 150.682 48.7217 160.989 47.156C171.297 45.5903 172.287 51.4867 188.355 47.3115C204.423 43.1362 203.817 31.7288 218.268 35.904C232.719 40.0793 232.029 62.3364 261.638 64.0512C291.248 65.766 289.715 73.9287 317 73.9287H37.1448Z" fill="#B8BDC7" />
          <path opacity="0.4" d="M38.5095 35.0725C32.0418 36.0384 23.9837 58.6415 19.3185 65.4997C16.7731 69.2417 11.636 70.5753 6.73398 71.7984C4.44858 72.3686 -1.21405 72.7419 0.233612 73.8068L311 74C285.624 73.9356 232.391 73.0727 222.467 70.1362C210.062 66.4656 153.443 73.8068 134.994 61.6359C120.235 51.8992 107.922 49.3363 103.61 49.2719C97.7788 49.5294 84.4194 49.4071 77.6336 46.857C69.1514 43.6694 60.7753 45.7945 54.9438 44.4421C49.1122 43.0898 44.9772 34.1065 38.5095 35.0725Z" fill="#B8BDC7" />
          <path opacity="0.85" d="M41.2583 65.6591C32.5506 71.3786 19.4579 73.4893 14 73.8298L265 74C264.628 73.4326 260.441 71.8211 246.673 69.9147C229.462 67.5315 205.925 58.68 191.04 48.6368C176.155 38.5937 159.316 49.488 149.454 29.0613C139.593 8.63455 131.313 -4.81303 122.754 17.6563C114.195 40.1257 107.125 39.7535 102.659 37.881C98.1938 36.0086 92.2887 36.8251 86.5208 43.1233C80.7528 49.4216 80.2387 59.4661 72.5171 52.487C64.7954 45.5078 52.1431 58.5097 41.2583 65.6591Z" fill="#FABF69" />
          <path opacity="0.85" d="M272.464 6.83464C295.85 12.9766 292.474 36.9501 308.231 54.3853C316.816 63.8844 325.422 67.9424 336.013 70.6C340.568 71.7431 355.537 72.5429 352.628 74H91C112.385 74 138.273 68.6505 168.788 63.4992C199.303 58.3479 197.68 65.805 211.562 55.3043C225.444 44.8035 218.437 45.2715 239.072 37.7426C259.708 30.2137 249.077 0.692671 272.464 6.83464Z" fill="#3FBE89" />
        </svg>
      );
      break;
    default:
      waves = (
        <svg className="responsive-svg" width="357" height="74" viewBox="0 0 357 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.8" d="M290.655 4.85918C308.622 11.1818 306.028 35.8604 318.134 53.8084C324.73 63.5869 331.341 67.7643 339.478 70.5C342.977 71.6768 354.477 72.5 352.243 74H151.243C167.672 74 187.561 68.4932 211.005 63.1903C234.448 57.8875 237.811 64.618 248.476 53.8084C259.141 42.9989 249.148 44.4265 265.002 36.6762C280.855 28.9259 272.688 -1.46343 290.655 4.85918Z" fill="#666E7F" />
          <path opacity="0.8" d="M46.1586 73.8647C42.4814 74.0694 39.806 74.0191 38.2422 73.8647H46.1586C53.7156 73.4442 65.5033 71.9471 80.5675 68.0645C108.561 60.8495 109.792 52.2199 129.163 57.3128C148.534 62.4057 152.006 50.0979 157.716 37.7901C163.427 25.4822 166.338 18.4088 177.759 15.4379C189.18 12.4671 195.899 31.2825 213.702 23.3602C231.506 15.4379 230.834 -6.20691 246.846 1.71537C262.858 9.63765 250.093 58.869 282.901 62.1228C315.708 65.3766 326.01 73.8647 356.242 73.8647H46.1586Z" fill="#495165" />
          <path opacity="0.8" d="M32.9305 64.9231C22.4881 71.1473 6.78728 73.4443 0.242188 73.8147L301.242 74C300.796 73.3825 295.776 71.6289 279.264 69.5542C258.625 66.9608 230.399 57.3282 212.549 46.3989C194.698 35.4696 174.505 47.3251 162.68 25.0961C150.854 2.86701 140.925 -11.7671 130.661 12.6848C120.397 37.1368 111.918 44.7317 106.563 42.694C101.208 40.6564 98.7533 32.691 91.8363 39.5449C84.9194 46.3989 79.6758 69.1837 70.416 61.5888C61.1562 53.9938 45.9835 57.1429 32.9305 64.9231Z" fill="#666E7F" />
          <path opacity="0.8" d="M39.9017 12.1152C33.2002 13.6508 24.8508 49.5839 20.017 60.4867C17.3795 66.4355 12.0567 68.5556 6.97754 70.5C4.60953 71.4065 -1.25781 72 0.242188 73.6929L322.242 74C295.949 73.8976 240.792 72.5258 230.509 67.8576C217.655 62.0223 158.99 73.6929 139.874 54.3443C124.582 38.8654 111.823 34.791 107.356 34.6886C101.313 35.0981 87.4711 34.9036 80.4401 30.8496C71.6513 25.7821 62.9723 29.1604 56.93 27.0106C50.8877 24.8607 46.6032 10.5796 39.9017 12.1152Z" fill="#B8BDC7" />
        </svg>
      );
  }

  const userDiscWords = discWords(perfil);
  return (
    <div style={{ position: 'relative' }}>
      <h5 dangerouslySetInnerHTML={{ __html: userDiscWords.persora }} className="bold mb-0 px-2 py-1" style={{ position: 'absolute', background: '#E7F6FF', borderRadius: '8px' }} />
      <div className="pt-4">{waves}</div>
    </div>
  );
}
ProfileWaves.propTypes = {
  perfil: PropTypes.string,
};
ProfileWaves.defaultProps = {
  perfil: '',
};
