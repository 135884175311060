import styled from 'styled-components';

export const SoraSpeech = styled.div`
  margin-top: 1rem;
  /* margin-bottom: 2rem; */
  width: 100%;
  display: flex;
  align-items: end;
`;
export const Foto = styled.img`
  width: 80px;
`;
export const SpeechBubble = styled.div`
  position: relative;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.alerts.danger.titleColor};
  background-color: #fff;
  border-radius: 14px;
  width: calc(100% - 82px - ${({ theme }) => theme.spaces.screenEdge});
  max-width: fit-content;
  left: 20px;
  background: ${({ theme }) => theme.colors.alerts.danger.background};
  box-shadow: 0px 4px 10px 0px rgba(37, 46, 70, 0.10) !important;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.alerts.danger.titleColor};
  font-size: 1.2rem;
  font-weight: 600;
  &:before {
    content: '';
    position: absolute;
    bottom: 20px;
    left: 0;
    margin-top: -10px;
    margin-left: -29px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent ${({ theme }) => theme.colors.alerts.danger.titleColor} transparent transparent;
    transform: scaleX(1.4);
  }
  &:after {
      content: '';
      position: absolute;
      bottom: 20px;
      left: 0;
      margin-top: -10px;
      margin-left: -27px;
      border-width: 12px;
      border-style: solid;
      border-color: transparent ${({ theme }) => theme.colors.alerts.danger.background} transparent transparent;
      transform: scaleX(1.4);
    }
`;
export const ColPipData = styled.div`
  h2 {
    display: flex;
    margin-top: 10px;
    margin-bottom: 0;
    font-family: 'Source Sans 3', sans-serif;
    margin-bottom: calc(1rem + 10px);
  }
  p {
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 1.05rem;
    line-height: 1.4rem;
    margin-bottom: 1.4rem;
    &.menorNota {
      color: ${({ theme }) => theme.colors.alerts.danger.backgroundRed};
      font-weight: 700;
    }
  }
  @media (max-width: 372px) {
    h2 {
      font-size: 1.4rem;
    }
  }
`;
export const DimensaoButton = styled.button`
  outline: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  display: flex;
  text-align: left;

  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.05rem;
  line-height: 1.4rem;
  margin-bottom: 1.4rem;
  color: ${({ theme }) => theme.colors.primary.main} !important;
  &.menorNota {
    color: ${({ theme }) => theme.colors.alerts.danger.backgroundRed} !important;
    font-weight: 700;
  }
`;
export const ColPipDataValues = styled.div`
  background: ${({ theme }) => theme.colors.black10};
  padding: 10px 0 1px 0;
  border-radius: 16px;
  h2 {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center
  }
  p {
    margin-bottom: 1rem;
    font-size: 1.05rem;
    &.menorNota {
      color: ${({ theme }) => theme.colors.alerts.danger.backgroundRed};
      font-weight: 700;
    }
  }
  &.leastRecent {
    background: none;
    h2 {
      margin-bottom: calc(20px + 5px);
    }
  }
`;

export const ProgressContainer = styled.div`
  flex-grow: 1;
`;
export const ProgressBar = styled.div`
  position: relative;
  width: ${({ progresscontent }) => (progresscontent ? 'calc(100% - 38px)' : '100%')};
  height: 32px;
  background: ${({ theme }) => theme.colors.black20};
  border-radius: 10px;
  margin-bottom: 6px;
  &::before {
    content: '';
    display: block;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ percentual }) => (percentual ? `${percentual > 100 ? '100' : percentual}%` : '0%')};
    height: 100%;
    background: ${({ color, theme }) => (color ? theme.colors[color].main : theme.colors.secondary.main)};
    border-radius: 6px;
    transition: ease-in-out 0.2s;
  }
  &::after {
    content: ${({ progresscontent }) => (progresscontent ? `'${progresscontent}'` : '')};
    position: absolute;
    right: -30px;
    font-size: .8rem;
    font-weight: 700;
    margin-top: 5px;
    color: ${({ theme }) => theme.colors.primary.light}
  }
`;

export const ActionPlanContent = styled.div`
  background: #FFFFFF;
  padding: ${({ theme }) => theme.spaces.screenEdge};
  p {
    font-size: 1.05rem;
    margin-bottom: .5rem;
  }
`;
export const GrayBox = styled.div`
  color: ${({ theme }) => theme.colors.primary.light};
  background: ${({ theme }) => theme.colors.black10};
  border-radius: 16px;
  padding: ${({ theme }) => theme.spaces.screenEdge};
  margin-bottom: 1.6rem;
  p {
    font-size: 1.05rem;
    margin-bottom: .5rem;
  }
  hr:last-child {
    display: none;
  }
`;
export const BlueContainer = styled.div`
  text-align: left;
  background-color: ${({ theme }) => theme.colors.secondary.light};
  color: ${({ theme }) => theme.colors.primary.main};
  padding: 1.75rem;
  border-radius: ${({ theme }) => theme.spaces.screenEdge};
  padding-bottom: 1rem;
  margin: 1.75rem 0;
  font-size: 1.05rem;
  ul li {
    margin-bottom: 0.75rem;
  }
  h4 {
    line-height: 1.55rem;
  }
`;
export const ActionPlanTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 1.4rem;
  margin-bottom: .6rem;
`;
export const ActionPlanTitleEmoji = styled.div`
  background-color: ${({ theme }) => theme.colors.alerts.danger.background};
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  border-radius: 50px;
`;
export const MediumText = styled.div`
  p {
    font-size: 1.05rem;
    line-height: 1.6rem;
    margin-bottom: 1rem;
  }
  ul li {
    font-size: 1.05rem;
    line-height: 1.6rem;
    margin-bottom: .4rem;
  }
  ul li ul {
    margin-top: .4rem;
  }
`;
export const LargeText = styled.div`
  p {
    font-size: 1.15rem;
    line-height: 1.65rem;
    margin-bottom: 1rem;
  }
`;

export const PidDateRow = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 25px;
  padding-left: 15px;
`;

export const PipDateCol = styled.div`
  width: 100%;
  max-width: 180px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  text-align: center;
  padding-right: 5px;
  p {
    font-size: .95rem;
    margin-bottom: 0;
  }
`;

export const PipTabsMenu = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.black10};
  flex-grow: 1;
  width: 100%;
  max-width: 380px;
  height: 52px;
  margin: 0 auto;
  border-radius: 16px;
  overflow: hidden;
  padding: 2px;
`;
export const PipTabsMenuItem = styled.button`
  background: none;
  width: 100%;
  text-align: center;
  border: none;
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.colors.black10};
  transition: ease-in-out 0.2s;

  &.active {
    background: #fff;
    border: 1px solid ${({ theme }) => theme.colors.black20};
    border-radius: 14px;
    width: 140%;
  }
  &.blob::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50px;
    background: red;
    position: absolute;
    margin-top: -4px;
    margin-left: 22px;
  }
`;

export const PipSubDimensionTitle = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.primary.light};
  font-weight: 600;
`;

export const OldPipDisclaimer = styled.div`
  background-color: ${({ theme }) => theme.colors.alerts.warning.background};
  margin-top: 2.2rem;
  margin-bottom: 2.2rem;
  padding: ${({ theme }) => theme.spaces.screenEdge};
  width: calc(100% + (2 * ${({ theme }) => theme.spaces.screenEdge}));
  margin-left: calc(${({ theme }) => theme.spaces.screenEdge} * -1);
  box-shadow: inset 0px -2px 10px 0px rgba(37, 46, 70, 0.1) !important;
  color: ${({ theme }) => theme.colors.alerts.warning.titleColor};
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.45rem;
  display: flex;
  gap: 10px;
  border-top: 1px solid #f7e9b1;
  border-bottom: 1px solid #f7e9b1;
`;
