import PropTypes from 'prop-types';
import {
  UserIcon, CardHeader, Container,
} from './styles';
import { Crown } from '../DuotoneIcon';
import UserTapBarIcon from '../UserTapBarIcon';

export default function UserAdminActions({ userData }) {
  let name = userData.email;
  const userProfileLetters = (userData.displayedProfileLetters ? userData.displayedProfileLetters : '');
  if (userData.name) {
    name = userData.name;
  }
  return (
    <Container className={`my-2 mb-4 ${!userData.firstAuth && 'firstAuthFalse'} `}>
      {userData.type === 'Líder' ? <UserIcon><Crown cor="#E3A74F" /></UserIcon> : ''}
      <CardHeader>
        <div><UserTapBarIcon name={name} perfil={userProfileLetters} showProfileLetters /></div>
        <div className="pt-2 ms-3">
          { userData.name ? <h4 className="mb-1 medium">{userData.name}</h4> : '' }
          { !userData.name ? <h4 className="mb-0 medium">Aceite pendente!</h4> : '' }
          <h6 className="mb-1">{userData.email}</h6>
        </div>
      </CardHeader>
    </Container>
  );
}

UserAdminActions.propTypes = {
  userData: PropTypes.shape({
    name: PropTypes.string,
    displayedProfileLetters: PropTypes.string,
    cargo: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string,
    firstAuth: PropTypes.bool,
  }).isRequired,
};
