import styled, { keyframes } from 'styled-components';

export const loadAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60dvh;
  /* width: 100vw; */
  /* height with prop shrinkLoader */
  &.shrinkLoader {
    height: fit-content;
  }
`;
export const LoaderContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const LoaderBefore = styled.div`
  content: "";
  position: absolute;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, ${({ theme }) => theme.colors.secondary.main} 0%, ${({ theme }) => theme.colors.primary.light} 100%);
  animation: ${loadAnimation} 0.5s infinite linear;
`;

export const LoaderAfter = styled.div`
  content: "";
  position: absolute;
  border-radius: inherit;
  width: 85%;
  height: 85%;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
