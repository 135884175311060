import { createContext, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';
import Cookies from 'js-cookie';

import api from '../services/backend';

const Context = createContext();

function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [bumperOptionalProps, setBumperOptionalProps] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
      setAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const history = useHistory(); // Inicialize o useHistory

  async function handleGenerateCode(email) {
    // 200 processado, usuario não encontrado
    // 403 processado, usuario encontrado, sem name
    // 201 sucesso, usuario encontrado, com name
    if (!email) {
      toast.error('Endereço de email não informado!', {
        autoClose: 3000,
        position: 'bottom-center',
      });
      return;
    }
    if (!email.includes('@') || !email.includes('.')) {
      toast.error('Endereço de email inválido!', {
        autoClose: 3000,
        position: 'bottom-center',
      });
      return;
    }
    if (email) {
      console.log('e agora o que é 1', email);
      setBtnLoading(true);
      try {
        console.log('e agora o que é 2', email);
        // define o body da requisição
        const body = {
          email: email.toLowerCase(),
        };
        const { data } = await api.post('/user/generateCode', body);
        console.debug('data', data);
        toast.success('Código gerado com sucesso!', {
          autoClose: 3000,
          position: 'bottom-center',
        });
        // history.push('/authUser?email='.concat(email));
        // push email encoded to authUser
        const encodedEmail = encodeURIComponent(email);
        setBtnLoading(false);
        history.push(`/authUser?email=${encodedEmail}`);
        console.log('e agora o que é 3', email);
      } catch (error) {
        console.log('e agora o que é 4', email);
        // if error === 403, user found, but without name
        // redirect to signup page with email as url param
        if (error.response.status === 403) {
          // history.push('/signup?email='.concat(email));
          // push email encoded to signup
          const encodedEmail = encodeURIComponent(email);
          history.push(`/signup?email=${encodedEmail}`);
          console.log('e agora o que é 5', email);
          setBtnLoading(false);
          return;
        }
        // console.error('Cannot authenticate');
        console.debug('error', error.response.data.error);
        toast.error(error.response.data.error, {
          autoClose: 3000,
          position: 'bottom-center',
        });
        setBtnLoading(false);
      }
    }
  }

  async function handleAuth(email, code) {
    // get onesignal_push_id on browser cookie and save in const
    const onesignalPushId = Cookies.get('onesignal_push_id');
    if (!email) {
      toast.error('Endereço de email não informado!', {
        autoClose: 3000,
        position: 'bottom-center',
      });
      return;
    }
    if (!email.includes('@') || !email.includes('.')) {
      toast.error('Endereço de email inválido!', {
        autoClose: 3000,
        position: 'bottom-center',
      });
      return;
    }
    if (email) {
      setBtnLoading(true);
      try {
        // define o body da requisição
        // const body = {
        //   email: email.toLowerCase(),
        //   code,
        //   ...(onesignalPushId && { onesignal_push_id: onesignalPushId }),
        // };
        const body = (onesignalPushId
          ? {
            email: email.toLowerCase(),
            code,
            onesignal_push_id: onesignalPushId,
          }
          : {
            email: email.toLowerCase(),
            code,
          }
        );
        const { data } = await api.post('/user/auth', body);
        localStorage.setItem('token', JSON.stringify(data.token));
        api.defaults.headers.Authorization = `Bearer ${data.token}`;
        setAuthenticated(true);
        setBtnLoading(false);
        // Redirecione o usuário para a rota /userSpace
        history.push('/userSpace');
        console.debug('authData', data);
      } catch (error) {
        // console.error('Cannot authenticate');
        setBtnLoading(false);
        toast.error('Falha ao autenticar.', {
          autoClose: 3000,
          position: 'bottom-center',
        });
      }
    }
  }

  async function handleSignup(email = '') {
    setBtnLoading(true);
    try {
      // define o body da requisição
      const body = {
        email: email.toLowerCase(),
      };
      const { data } = await api.post('/user', body);

      console.debug('data', data);
      toast.success('Usuário cadastrado com sucesso!', {
        autoClose: 3000,
        position: 'bottom-center',
      });
      // history.push('/authUser?email='.concat(email));
      const encodedEmail = encodeURIComponent(email);
      setBtnLoading(false);
      history.push(`/authUser?email=${encodedEmail}`);
    } catch (error) {
      // console.error('Cannot authenticate');
      setBtnLoading(false);
      console.debug('error', error.response.data.error);
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: 'bottom-center',
      });
    }
  }

  async function handleLogout() {
    // get onesignal_push_id on browser cookie and save in const
    const onesignalPushId = Cookies.get('onesignal_push_id');
    if (onesignalPushId) {
      try {
        // define o body da requisição
        const body = {
          onesignal_push_id: onesignalPushId,
        };
        await api.patch('/user/removeOneSignalId', body);
      } catch (error) {
        toast.error(`${error.response.data.error} - Falha ao atualizar onesignal_push_id ${onesignalPushId}.`, {
          autoClose: 3000,
          position: 'bottom-center',
        });
      }
    }
    setAuthenticated(false);
    if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
      mixpanel.reset();
    }
    localStorage.removeItem('token');
    api.defaults.headers.Authorization = undefined;
    history.push('/login');
  }

  if (loading) {
    return <p>loading...</p>;
  }

  return (
    <Context.Provider value={{
      authenticated,
      handleAuth,
      handleLogout,
      handleGenerateCode,
      handleSignup,
      btnLoading,
      bumperOptionalProps,
      setBumperOptionalProps,
    }}
    >
      {children}
    </Context.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Context, AuthProvider };
