import { Sheet } from 'react-modal-sheet';
import styled from 'styled-components';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import defaultTheme from '../../assets/styles/themes/default';
import PersoraButton from '../../components/Button';
import { Clock } from '../../components/DuotoneIcon';
import api from '../../services/backend';
import { UserContext } from '../../Context/UserContext';

export const RateContainer = styled.div`
  background-color: ${defaultTheme.colors.black10};
  box-shadow: 0px 2px 12px 0px rgba(6, 16, 42, 0.10);
  border-radius: 24px !important;
  display: flex;
  justify-content: space-between;
  padding: calc(${defaultTheme.spaces.screenEdge}/2) ${defaultTheme.spaces.screenEdge};
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 18px;
`;
export const RateButton = styled.button`
  background-color: ${defaultTheme.colors.black20};
  color: ${defaultTheme.colors.primary.main};
  width: 38px;
  height: 38px;
  line-height: 40px;
  text-align: center;
  border: none !important;
  border-radius: 24px;
  font-size: 1.2rem;
  transition: all 0.1s ease-in-out;
  &.selected {
    background-color: ${defaultTheme.colors.primary.main};
    color: ${defaultTheme.colors.fullWhite};
    font-weight: 600;
  }
`;
const RateCaptionContainer = styled.div`
  padding: 0 calc(${defaultTheme.spaces.screenEdge}/2 + 6px);
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  margin: 0 auto;
  margin-top: calc(${defaultTheme.spaces.screenEdge}/3);
`;

export default function FirstMapRate({ mapId }) {
  const history = useHistory();
  const [mapRate, setMapRate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUserContextData } = useContext(UserContext);

  const handleFirstMapRate = (value) => {
    setMapRate((prevRate) => (prevRate === value ? null : value));
  };

  const handleFinishOnboardingFirstMap = async () => {
    setIsLoading(true);
    if (mapRate === null) {
      try {
        // Segunda requisição para /user/onboarding apenas se a primeira for bem-sucedida
        await api.patch('/user/disclaimer', { firstMap: true });
        // Redirect user to /userSpace route
        history.push('/userSpace?activeTapBar=time');
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    }
    try {
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Onboarding',
          action: 'mapeamento_avaliado',
          score: mapRate,
          stage: 'Em seguida',
        });
        mixpanel.track('mapeamento_avaliado', {
          category: 'Onboarding',
          company_id: currentUserContextData._idWorkspace[0],
          score: mapRate,
          stage: 'Em seguida',
        });
      }
      const responseProfile = await api.patch(`/profile/rating/${mapId}`, {
        rating: mapRate,
      });
        // Verifica se a resposta da primeira requisição foi bem-sucedida
      if (responseProfile.status === 201) {
        // Segunda requisição para /user/onboarding apenas se a primeira for bem-sucedida
        await api.patch('/user/disclaimer', { firstMap: true });
        // Redirect user to /userSpace route
        history.push('/userSpace?activeTapBar=time');
      } else {
        console.log('Erro na requisição para /profile');
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <Sheet.Container>
      <Sheet.Header />
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: 0,
              color: defaultTheme.colors.primary.main,
            }}
          >
            <p className="semibold lead mb-4">
              De 1 a 5, o quanto você se reconheceu nas informações sobre seu estilo.
            </p>
            <RateContainer>
              <RateButton onClick={() => handleFirstMapRate(1)} className={(mapRate === 1 && 'selected')}>
                {mapRate === 1 ? '😢' : '1'}
              </RateButton>
              <RateButton onClick={() => handleFirstMapRate(2)} className={(mapRate === 2 && 'selected')}>
                {mapRate === 2 ? '😕' : '2'}
              </RateButton>
              <RateButton onClick={() => handleFirstMapRate(3)} className={(mapRate === 3 && 'selected')}>
                {mapRate === 3 ? '🙂' : '3'}
              </RateButton>
              <RateButton onClick={() => handleFirstMapRate(4)} className={(mapRate === 4 && 'selected')}>
                {mapRate === 4 ? '😄' : '4'}
              </RateButton>
              <RateButton onClick={() => handleFirstMapRate(5)} className={(mapRate === 5 && 'selected')}>
                {mapRate === 5 ? '🤩' : '5'}
              </RateButton>
            </RateContainer>
            <RateCaptionContainer>
              <p className="semibold small text-center">
                Pouco
              </p>
              <p className="semibold small text-center">
                Muito
              </p>
            </RateCaptionContainer>
            <PersoraButton
              className="mt-4 mb-3"
              disabled={(!mapRate || isLoading)}
              onClick={handleFinishOnboardingFirstMap}
            >
              {isLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Enviar'}
            </PersoraButton>
            <PersoraButton
              estilo="ghost"
              className="mb-3"
              onClick={handleFinishOnboardingFirstMap}
              disabled={(isLoading)}
            >
              Pular
            </PersoraButton>
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}
FirstMapRate.propTypes = {
  mapId: PropTypes.string.isRequired,
};
