import styled from 'styled-components';

// ${({ percentual }) => percentual || '0%'};

export const Container = styled.span`
 width: fit-content;
 padding: 0;
 display: flex;
 align-items: center;
 gap: 4px;
`;

export const Icon = styled.span``;

export const Number = styled.span`
  font-weight: 700;
`;
