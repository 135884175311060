import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { Sheet } from 'react-modal-sheet';
import { toast } from 'react-toastify';
import PersoraButton from '../../components/Button';
import {
  ChevronLeft, ChevronSmallRight, Clock, Cross, Mail,
  Users,
} from '../../components/DuotoneIcon';
import HandbookTab from '../Tabs/HandbookTab';
import { UserContext } from '../../Context/UserContext';
import defaultTheme from '../../assets/styles/themes/default';
import UserTapBarIcon from '../../components/UserTapBarIcon';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
// eslint-disable-next-line
import api from '../../services/backend';
import { validateEmail, validateFirstNameAndLastName } from '../../utils/validators';

export default function UserConfigModal({ show, handleClose }) {
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  // sheet states
  const [notificationPushSheetIsOpen, setNotificationPushSheetOpen] = useState(false);
  const handleCloseNotificationPushSheet = () => { setNotificationPushSheetOpen(false); };
  const [notificationEmailSheetIsOpen, setNotificationEmailSheetOpen] = useState(false);
  const [tempEmail, setTempEmail] = useState('');
  const [tempEmailCode, setTempEmailCode] = useState('');
  const [emailChangedSheetIsOpen, setEmailChangedSheetOpen] = useState(false);
  // modal states
  const [showUserModal, setShowUserModal] = useState(false);
  const handleCloseUserModal = () => { setShowUserModal(false); };
  const handleShowUserModal = () => setShowUserModal(true);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const handleCloseTeamModal = () => { setShowTeamModal(false); };
  const handleShowTeamModal = () => setShowTeamModal(true);

  const gerencialNotificacoesDivStyle = {
    display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '17px',
  };
  const OutroStyle = {
    padding: '16px', paddingBottom: '24px', borderRadius: '6px', border: `1px solid ${defaultTheme.colors.black20}`, background: '#f3f3f3',
  };

  const [countDown1min, setCountDown1min] = useState(59);
  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown1min > 0) {
        setCountDown1min(countDown1min - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [countDown1min]);

  const requestToogleEmailNotifications = async () => {
    console.log('requestToogleEmailNotifications');
    setIsLoading(true);
    try {
      const request = await api.patch('user/notificationSettings', { 'general.email': !currentUserContextData.notificationSettings.general.email });
      toast.dismiss();
      toast.success(`As notificações por e-email foram ${!currentUserContextData.notificationSettings.general.email === true ? 'habilitadas' : 'desabilitadas'}.`, {
        autoClose: 3000,
        position: 'top-center',
      });
      setCurrentUserContextData({
        ...currentUserContextData,
        notificationSettings: request.data.notificationSettings,
      });
      setIsLoading(false);
      if (notificationEmailSheetIsOpen) {
        setNotificationEmailSheetOpen(false);
      }
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Configurações e preferências ',
          action: 'notificacoes_de_email_alteradas',
          status: currentUserContextData.notificationSettings.general.email ? 'Ativada' : 'Desativada',
        });
        mixpanel.track('notificacoes_de_email_alteradas', {
          category: 'Configurações e preferências ',
          company_id: currentUserContextData._idWorkspace[0],
          status: currentUserContextData.notificationSettings.general.email ? 'Ativada' : 'Desativada',
        });
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(`Erro ao ${!currentUserContextData.notificationSettings.general.email === true ? 'habilitar' : 'desabilitar'} as notificações por e-mail.`, {
        autoClose: 3000,
        position: 'top-center',
      });
      setIsLoading(false);
    }
  };

  const handleToogleEmailNotifications = () => {
    if (currentUserContextData.notificationSettings.general.email === true) {
      setNotificationEmailSheetOpen(true);
    } else {
      requestToogleEmailNotifications();
    }
  };

  // eslint-disable-next-line
  const [userPronouns, setUserPronouns] = useState(currentUserContextData.settings.pronouns ? currentUserContextData.settings.pronouns : '');
  // eslint-disable-next-line
  const [firstTimeLeader, setFirstTimeLeader] = useState(currentUserContextData.settings.firstTimeLeader ? currentUserContextData.settings.firstTimeLeader : '');
  const firstTimeLeaderOptions = [
    { label: '< 1990', value: '<1990' },
    { label: '1990-2000', value: '1990-2000' },
    { label: '2001-2010', value: '2001-2010' },
    { label: '2011-2020', value: '2011-2020' },
    { label: '2021-2022', value: '2021-2022' },
    { label: '2023-2024', value: '2023-2024' },
  ];
  // eslint-disable-next-line
  const [ledNumber, setLedNumber] = useState(currentUserContextData.settings.ledNumber ? currentUserContextData.settings.ledNumber : ''); // user
  const ledNumberOptions = [
    { label: '< 3', value: '<3' },
    { label: '3-4', value: '3-4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8-12', value: '8-12' },
    { label: '13-16', value: '13-16' },
    { label: '17-20', value: '17-20' },
    { label: '> 20', value: '>20' },
  ];

  // CHANGE HOW MET PERSORA
  // eslint-disable-next-line
  const [howMetPersora, setHowMetPersora] = useState(() => {
    const howMet = currentUserContextData?.settings?.howMetPersora;
    if (howMet) {
      return howMet.includes('Outro: ') ? 'Outro' : howMet;
    }
    return '';
  }); // user
  const howMetPersoraOptions = [
    { label: 'Mecanismo de pesquisa (Google, Bing etc.)', value: 'Mecanismo de pesquisa (Google, Bing etc.)' },
    { label: 'Facebook/Instagram', value: 'Facebook/Instagram' },
    { label: 'TikTok', value: 'TikTok' },
    { label: 'LinkedIn', value: 'LinkedIn' },
    { label: 'Youtube', value: 'Youtube' },
    { label: 'Reddit', value: 'Reddit' },
    { label: 'Indicação', value: 'Indicação' },
    { label: 'Outro', value: 'Outro' },
  ];
  // eslint-disable-next-line
  const [specifcHowMetPersora, setSpecificHowMetPersora] = useState(() => {
    const howMet = currentUserContextData?.settings?.howMetPersora;
    if (howMet && howMet.includes('Outro: ')) {
      return howMet.split('Outro: ')[1];
    }
    return '';
  }); // user
  const [changingSpecifcHowMetPersora, setChangingSpecifcHowMetPersora] = useState(false);
  const handleHowMetPersoraUpdate = async (value) => {
    try {
      await api.patch('/user/updateSettings', { howMetPersora: value });
      toast.dismiss();
      toast.success('Informação atualizada com sucesso!', {
        autoClose: 3000,
        position: 'top-center',
      });
      setCurrentUserContextData({
        ...currentUserContextData,
        settings: {
          ...currentUserContextData.settings,
          howMetPersora: value,
        },
      });
      setChangingSpecifcHowMetPersora(false);
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Configurações e preferências ',
          action: 'dados_pessoais_alterados',
          field: 'Como conheci a Persora',
        });
        mixpanel.track('dados_pessoais_alterados', {
          category: 'Configurações e preferências ',
          company_id: currentUserContextData._idWorkspace[0],
          field: 'Como conheci a Persora',
        });
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error('Algo não saiu como o esperado.', {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };

  const handleHowMetPersoraChange = (e) => {
    console.log('========> e.target.value', e.target.value);
    if (e.target.value === 'Outro') {
      setSpecificHowMetPersora('');
      // scroll page to down
      const scrollableElement = document.getElementById('UserConfigModalUserModal');
      scrollableElement.scrollTop = scrollableElement.scrollHeight;
      console.log('scroollll');
    }
    setHowMetPersora(e.target.value);
    if (e.target.value === 'Outro') {
      setChangingSpecifcHowMetPersora(true);
    }
    if (e.target.value !== 'Outro') {
      e.target.blur();
      setSpecificHowMetPersora('');
      handleHowMetPersoraUpdate(e.target.value);
      setChangingSpecifcHowMetPersora(false);
    }
  };
  const handleSpecificHowMetPersoraChange = (e) => {
    if (e.target.value) {
      setSpecificHowMetPersora(e.target.value);
      handleHowMetPersoraUpdate(`Outro: ${e.target.value}`);
    }
  };

  // CHANGE TIME QUE LIDERO
  // eslint-disable-next-line
  const [teamDepartment, setTeamDepartment] = useState(() => {
    const department = currentUserContextData?.workspaceContext?.teamDepartment;
    if (department) {
      return department.includes('Outro: ') ? 'Outro' : department;
    }
    return '';
  }); // user - outra rota
  const teamDepartmentOptions = [
    { label: 'TI', value: 'TI' },
    { label: 'Produto', value: 'Produto' },
    { label: 'Marketing', value: 'Marketing' },
    { label: 'Design', value: 'Design' },
    { label: 'CS', value: 'CS' },
    { label: 'Vendas', value: 'Vendas' },
    { label: 'RH', value: 'RH' },
    { label: 'Financeiro', value: 'Financeiro' },
    { label: 'Administrativo', value: 'Administrativo' },
    { label: 'Misto/Squad', value: 'Misto/Squad' },
    { label: 'Outro', value: 'Outro' },
  ];
  // eslint-disable-next-line
  const [specificTeamDepartment, setSpecificTeamDepartment] = useState(() => {
    const department = currentUserContextData?.workspaceContext?.teamDepartment;
    if (department && department.includes('Outro: ')) {
      return department.split('Outro: ')[1];
    }
    return '';
  });
  const [changingSpecifcTeamDepartment, setChangingSpecifcTeamDepartment] = useState(false);
  const handleTeamDepartmentUpdate = async (value) => {
    try {
      const body = {
        idWorkspace: currentUserContextData._idWorkspace[0],
        teamDepartment: value,
      };
      const request = await api.put('/user/setDepartment', body);
      console.log('======> requeste', request);
      toast.dismiss();
      toast.success('Informação atualizada com sucesso!', {
        autoClose: 3000,
        position: 'top-center',
      });
      setCurrentUserContextData({
        ...currentUserContextData,
        workspaceContext: {
          ...currentUserContextData.workspaceContext,
          teamDepartment: value,
        },
      });
      setChangingSpecifcTeamDepartment(false);
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Configurações e preferências ',
          action: 'dados_do_time_alterados',
          field: 'Área do time',
        });
        mixpanel.track('dados_do_time_alterados', {
          category: 'Configurações e preferências ',
          company_id: currentUserContextData._idWorkspace[0],
          field: 'Área do time',
        });
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error('Algo não saiu como o esperado.', {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };
  const handleTeamDepartmentChange = (e) => {
    if (e.target.value === 'Outro') {
      setSpecificTeamDepartment('');
    }
    setTeamDepartment(e.target.value);
    if (e.target.value === 'Outro') {
      setChangingSpecifcTeamDepartment(true);
    }
    if (e.target.value !== 'Outro') {
      e.target.blur();
      setSpecificTeamDepartment('');
      handleTeamDepartmentUpdate(e.target.value);
      setChangingSpecifcTeamDepartment(false);
    }
  };
  const handleSpecificTeamDepartmentChange = (e) => {
    if (e.target.value) {
      setSpecificTeamDepartment(e.target.value);
      handleTeamDepartmentUpdate(`Outro: ${e.target.value}`);
    }
  };

  // CHANGE SEGMENTO DA EMPRESA (coompanySegment)
  const [companySegment, setCompanySegment] = useState(() => {
    const segment = currentUserContextData?.workspaceContext?.segment;
    if (segment) {
      return segment.includes('Outro: ') ? 'Outro' : segment;
    }
    return '';
  });
  const [specificCompanySegment, setSpecificCompanySegment] = useState(() => {
    const segment = currentUserContextData?.workspaceContext?.segment;
    if (segment && segment.includes('Outro: ')) {
      return segment.split('Outro: ')[1];
    }
    return '';
  });
  const companySegmentOptions = [
    { label: 'Tecnologia', value: 'Tecnologia' },
    { label: 'Startup', value: 'Startup' },
    { label: 'Indústria', value: 'Indústria' },
    { label: 'Comércio', value: 'Comércio' },
    { label: 'Consultoria', value: 'Consultoria' },
    { label: 'Serviços', value: 'Serviços' },
    { label: 'Outro', value: 'Outro' },
  ];
  const [changingSpecifcCompanySegment, setChangingSpecifcCompanySegment] = useState(false);
  const handleCompanySegmentUpdate = async (value) => {
    try {
      const body = {
        idWorkspace: currentUserContextData._idWorkspace[0],
        segment: value,
      };
      await api.put('/workspace/setSegment', body);
      toast.dismiss();
      toast.success('Informação atualizada com sucesso!', {
        autoClose: 3000,
        position: 'top-center',
      });
      setCurrentUserContextData({
        ...currentUserContextData,
        workspaceContext: {
          ...currentUserContextData.workspaceContext,
          segment: value,
        },
      });
      setChangingSpecifcCompanySegment(false);
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Configurações e preferências ',
          action: 'dados_do_time_alterados',
          field: 'Segmento da empresa',
        });
        mixpanel.track('dados_do_time_alterados', {
          category: 'Configurações e preferências ',
          company_id: currentUserContextData._idWorkspace[0],
          field: 'Segmento da empresa',
        });
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error('Algo não saiu como o esperado.', {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };
  const handleCompanySegmentChange = (e) => {
    if (e.target.value === 'Outro') {
      setSpecificCompanySegment('');
    }
    setCompanySegment(e.target.value);
    if (e.target.value === 'Outro') {
      setChangingSpecifcCompanySegment(true);
    }
    if (e.target.value !== 'Outro') {
      e.target.blur();
      setSpecificCompanySegment('');
      handleCompanySegmentUpdate(e.target.value);
      setChangingSpecifcCompanySegment(false);
    }
  };
  const handleSpecificCompanySegmentChange = (e) => {
    if (e.target.value) {
      setSpecificCompanySegment(e.target.value);
      handleCompanySegmentUpdate(`Outro: ${e.target.value}`);
    }
  };

  const handleNameChange = async (e) => {
    if (e.target.value === currentUserContextData.name) {
      console.log('Nome não alterado');
      return;
    }
    const isValidName = validateFirstNameAndLastName(e.target.value);
    if (!isValidName) {
      toast.dismiss();
      toast.error('Por favor, insira seu nome e sobrenome.', {
        autoClose: 3000,
        position: 'top-center',
      });
      e.target.value = currentUserContextData.name;
      return;
    }
    try {
      await api.patch('/user/updateSettings', { name: e.target.value });
      toast.dismiss();
      toast.success('Nome atualizado com sucesso!', {
        autoClose: 3000,
        position: 'top-center',
      });
      setCurrentUserContextData({
        ...currentUserContextData,
        name: e.target.value,
      });
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Configurações e preferências ',
          action: 'dados_pessoais_alterados',
          field: 'Nome',
        });
        mixpanel.track('dados_pessoais_alterados', {
          category: 'Configurações e preferências ',
          company_id: currentUserContextData._idWorkspace[0],
          field: 'Nome',
        });
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error('Erro ao atualizar nome.', {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };

  const handlePronounsChange = async (e) => {
    e.target.blur();
    if (e.target.value === currentUserContextData.settings.pronouns) {
      return;
    }
    try {
      await api.patch('/user/updateSettings', { pronouns: e.target.value });
      if (currentUserContextData.type === 'Líder') {
        toast.dismiss();
        toast.success(`${e.target.value === 'male' ? '⭐️ Eu sou um líder humano.' : '🌟 Eu sou uma líder humana.'}`, {
          autoClose: 3000,
          position: 'top-center',
        });
      } else {
        toast.dismiss();
        toast.success(`${e.target.value === 'male' ? '⭐️ Eu sou um profissional humano.' : '🌟 Eu sou uma profissional humana.'}`, {
          autoClose: 3000,
          position: 'top-center',
        });
      }
      setCurrentUserContextData({
        ...currentUserContextData,
        settings: {
          ...currentUserContextData.settings,
          pronouns: e.target.value,
        },
      });
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Configurações e preferências ',
          action: 'dados_pessoais_alterados',
          field: 'Pronome',
        });
        mixpanel.track('dados_pessoais_alterados', {
          category: 'Configurações e preferências ',
          company_id: currentUserContextData._idWorkspace[0],
          field: 'Pronome',
        });
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error('Algo não saiu como o esperado.', {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };

  const handleEmailChange = async (e) => {
    // put email in lowercase and remove spaces
    e.target.value = e.target.value.toLowerCase().replace(/\s/g, '');
    if (e.target.value === currentUserContextData.email) {
      console.log('Email não alterado');
      return;
    }
    const isValidEmail = validateEmail(e.target.value);
    if (!isValidEmail) {
      toast.dismiss();
      toast.error('Por favor, insira um e-mail válido.', {
        autoClose: 3000,
        position: 'top-center',
      });
      e.target.value = currentUserContextData.email;
      return;
    }
    if (e.target.value !== currentUserContextData.email) {
      console.log('Email changed');
      try {
        await api.patch('/user/updateEmail', { email: e.target.value });
        setCountDown1min(59);
        setEmailChangedSheetOpen(true);
        setTempEmail(e.target.value);
        setTempEmailCode('');
      } catch (error) {
        console.log(error);
        e.target.value = currentUserContextData.email;
        // se código do error for 409, email já existe
        if (error.response.status === 409) {
          toast.dismiss();
          toast.error('Esse e-mail já está em uso.', {
            autoClose: 3000,
            position: 'top-center',
          });
        } else {
          toast.dismiss();
          toast.error(error.response.data.message, {
            autoClose: 3000,
            position: 'top-center',
          });
        }
      }
    }
  };

  const handleEmailCode = (e) => {
    const inputValue = e.target.value.replace(/[^\d]/g, '');
    setTempEmailCode(inputValue);
  };

  const handleResendCodeEmail = async (e) => {
    e.preventDefault();
    setCountDown1min(59);
    console.log('Reenviando código para ', tempEmail);
    try {
      await api.patch('/user/resendVerificationCode', { email: tempEmail });
      toast.dismiss();
      toast.success(`O código foi reenviado para ${tempEmail}`, {
        autoClose: 3000,
        position: 'top-center',
      });
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error('Erro ao reenviar código.', {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };

  const handleConfirmEmailChange = async () => {
    setIsLoading(true);
    try {
      await api.patch('/user/updateEmail', { email: tempEmail, code: tempEmailCode });
      toast.dismiss();
      toast.success('E-mail atualizado com sucesso!', {
        autoClose: 3000,
        position: 'top-center',
      });
      setCurrentUserContextData({
        ...currentUserContextData,
        email: tempEmail,
      });
      setEmailChangedSheetOpen(false);
      setIsLoading(false);
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Configurações e preferências ',
          action: 'dados_pessoais_alterados',
          field: 'E-mail',
        });
        mixpanel.track('dados_pessoais_alterados', {
          category: 'Configurações e preferências ',
          company_id: currentUserContextData._idWorkspace[0],
          field: 'E-mail',
        });
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error('Erro ao atualizar e-mail. Parece que o código informado não está certo.', {
        autoClose: 3000,
        position: 'top-center',
      });
      setIsLoading(false);
    }
  };

  const handleFirstTimeLeaderChange = async (e) => {
    e.target.blur();
    if (e.target.value === currentUserContextData.settings.firstTimeLeader) {
      return;
    }
    try {
      await api.patch('/user/updateSettings', { firstTimeLeader: e.target.value });
      toast.dismiss();
      toast.success('Informação atualizada com sucesso!', {
        autoClose: 3000,
        position: 'top-center',
      });
      setCurrentUserContextData({
        ...currentUserContextData,
        settings: {
          ...currentUserContextData.settings,
          firstTimeLeader: e.target.value,
        },
      });
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Configurações e preferências ',
          action: 'dados_pessoais_alterados',
          field: 'Primeira liderança',
        });
        mixpanel.track('dados_pessoais_alterados', {
          category: 'Configurações e preferências ',
          company_id: currentUserContextData._idWorkspace[0],
          field: 'Primeira liderança',
        });
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error('Algo não saiu como o esperado.', {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };

  const handleLedNumberChange = async (e) => {
    e.target.blur();
    if (e.target.value === currentUserContextData.settings.ledNumber) {
      return;
    }
    try {
      await api.patch('/user/updateSettings', { ledNumber: e.target.value });
      toast.dismiss();
      toast.success('Informação atualizada com sucesso!', {
        autoClose: 3000,
        position: 'top-center',
      });
      setCurrentUserContextData({
        ...currentUserContextData,
        settings: {
          ...currentUserContextData.settings,
          ledNumber: e.target.value,
        },
      });
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Configurações e preferências ',
          action: 'dados_pessoais_alterados',
          field: 'Número de liderados',
        });
        mixpanel.track('dados_pessoais_alterados', {
          category: 'Configurações e preferências ',
          company_id: currentUserContextData._idWorkspace[0],
          field: 'Número de liderados',
        });
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error('Algo não saiu como o esperado.', {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} fullscreen animation={false} className="modalFullscreenPage" id="UserConfigModal">
        <Modal.Header>
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            justIcon
            onClick={handleClose}
            style={{ marginLeft: -12 }}
          >
            <ChevronLeft />
          </PersoraButton>
          <Modal.Title style={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '1.15rem',
            // background: 'green',
            justifyContent: 'center',
            marginLeft: '-8px',
          }}
          >
            <span className="semibold">
              Configurações
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          overflowY: 'auto',
          background: '#FFF',
          paddingBottom: 0,
        }}
        >
          <div style={{
            position: 'relative',
          }}
          >
            {/* <hr className="mt-5" /> */}
            <PersoraButton type="button" estilo="ghost" tamanho="mini" onClick={() => handleShowUserModal()} className="py-3 pb-2 ps-0 w-100" style={{ justifyContent: 'left' }}>
              <UserTapBarIcon
                name={currentUserContextData.name}
                perfil={currentUserContextData.displayedProfileLetters}
                showProfileLetters
              />
              <div className="text-start pt-2">
                <h4 className="mb-0 semibold sourcesans">
                  {currentUserContextData.name}
                </h4>
                <small style={{ color: defaultTheme.colors.primary.light }}>
                  {currentUserContextData.email}
                </small>
              </div>
              <span style={{ position: 'absolute', right: defaultTheme.spaces.screenEdge }}>
                <ChevronSmallRight tamanho={22} />
              </span>
            </PersoraButton>
            <hr />
            {currentUserContextData.type === 'Líder' && (
              <>
                <PersoraButton type="button" estilo="ghost" tamanho="mini" onClick={() => handleShowTeamModal()} className="mb-1 ps-1 w-100" style={{ justifyContent: 'left', color: defaultTheme.colors.primary.light }}>
                  <Users cor={defaultTheme.colors.primary.light} tamanho={22} />
                  {' '}
                  Informações do time
                  <span style={{ position: 'absolute', right: defaultTheme.spaces.screenEdge }}>
                    <ChevronSmallRight tamanho={22} />
                  </span>
                </PersoraButton>
                <hr />
              </>
            )}
            <div
              className="mb-1 ps-1 w-100 dmsans medium"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                gap: '12px',
                padding: '6px 16px',
              }}
            >
              <Mail cor={defaultTheme.colors.primary.light} tamanho={22} />
              {' '}
              Gerenciar notificações
            </div>
            <div style={gerencialNotificacoesDivStyle}>
              <div style={{ padding: '14px calc(22px + 16px)' }}>
                Notificações do app
              </div>
              {/* eslint-disable-next-line */}
            <label htmlFor="pip-toogle-xx">
              <input
                id="pip-toogle-xx"
                type="checkbox"
                className="toggle-checkbox"
                onClick={() => setNotificationPushSheetOpen(true)}
                checked
                readOnly
              />
              <div className="toggle-switch" />
            </label>
            </div>
            <div style={gerencialNotificacoesDivStyle}>
              <div style={{ padding: '14px calc(22px + 16px)' }}>
                E-mail
              </div>
              {/* eslint-disable-next-line */}
            <label htmlFor="pip-toogle-yy">
              <input
                id="pip-toogle-yy"
                type="checkbox"
                className="toggle-checkbox"
                onClick={() => handleToogleEmailNotifications()}
                checked={currentUserContextData.notificationSettings.general.email}
                readOnly
              />
              <div className="toggle-switch" />
            </label>
            </div>
            <hr />
          </div>
          <div className="d-none">
            <HandbookTab userSettings={currentUserContextData.settings} />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showUserModal} onHide={handleCloseUserModal} fullscreen animation={false} className="modalFullscreenPage" id="UserConfigModalUserModal">
        <Modal.Header>
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            justIcon
            onClick={handleCloseUserModal}
            style={{ marginLeft: -12 }}
          >
            <ChevronLeft />
          </PersoraButton>
          <Modal.Title style={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '1.15rem',
            // background: 'green',
            justifyContent: 'center',
            marginLeft: '-8px',
          }}
          >
            <span className="semibold">
              Informações pessoais
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          overflowY: 'auto',
          background: '#FFF',
          paddingBottom: '3rem',
        }}
        >
          <div style={{
            position: 'relative',
          }}
          >
            {/* ========> NOME */}
            <Form.Group>
              <Form.Label>
                Meu nome é
              </Form.Label>
              <Form.Control
                type="text"
                className={`mb-1 ${currentUserContextData.name ? 'handbookFormControlFilledV2' : ''}`}
                name="nameInputUpdate"
                onBlur={handleNameChange}
                defaultValue={currentUserContextData.name}
                autoComplete="off"
              />
            </Form.Group>
            {/* ========> PRONOME */}
            <Form.Group className="mt-4">
              <Form.Label>
                No meu time
              </Form.Label>
              <Form.Select
                as="select"
                className={`mb-1 ${userPronouns ? 'handbookFormControlFilledV2' : ''}`}
                name="pronounsInputUpdate"
                defaultValue={userPronouns}
                onChange={handlePronounsChange}
                autoComplete="off"
              >
                <option value="female">
                  🌟 Eu sou uma
                  {' '}
                  {currentUserContextData.type === 'Líder' ? 'líder' : 'profissional'}
                  {' '}
                  humana
                </option>
                <option value="male">
                  ⭐️ Eu sou um
                  {' '}
                  {currentUserContextData.type === 'Líder' ? 'líder' : 'profissional'}
                  {' '}
                  humano
                </option>
              </Form.Select>
              <p className="mb-0 small" />
            </Form.Group>
            {/* ========> EMAIL */}
            <Form.Group className="mt-4">
              <Form.Label>
                Meu melhor e-mail é
              </Form.Label>
              <Form.Control
                type="text"
                className={`mb-1 ${currentUserContextData.email ? 'handbookFormControlFilledV2' : ''}`}
                name="emailInputUpdate"
                onBlur={handleEmailChange}
                defaultValue={currentUserContextData.email}
                autoComplete="off"
              />
            </Form.Group>
            {currentUserContextData.type === 'Líder' && (
              <>
                {/* ========> LIDERANÇA */}
                <Form.Group className="mt-4">
                  <Form.Label>
                    Assumi minha primeira liderança em
                  </Form.Label>
                  <Form.Select
                    as="select"
                    className={`mb-1 ${firstTimeLeader ? 'handbookFormControlFilledV2' : ''}`}
                    name="firstTimeLeaderInputUpdate"
                    defaultValue={firstTimeLeader}
                    onChange={handleFirstTimeLeaderChange}
                    autoComplete="off"
                  >
                    {firstTimeLeaderOptions.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* ========> NUMERO DE PESSOAS QUE LIDERO */}
                <Form.Group className="mt-4">
                  <Form.Label>
                    E hoje lidero diretamente
                  </Form.Label>
                  {}
                  <Form.Select
                    as="select"
                    className={`mb-1 ${ledNumber ? 'handbookFormControlFilledV2' : ''}`}
                    name="ledNumberInputUpdate"
                    defaultValue={ledNumber}
                    onChange={handleLedNumberChange}
                    autoComplete="off"
                  >
                    {ledNumberOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                        {' '}
                        pessoas
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* ========> COMO CONHECEU A PERSORA */}
                <div
                  className="mt-4"
                  style={howMetPersora === 'Outro' && changingSpecifcHowMetPersora === true ? OutroStyle : {}}
                >
                  <Form.Group>
                    <Form.Label>
                      Conheci a Persora através de
                    </Form.Label>
                    <Form.Select
                      as="select"
                      className={`mb-1 ${howMetPersora ? 'handbookFormControlFilledV2' : ''}`}
                      name="howMetPersoraInputUpdate"
                      defaultValue={howMetPersora}
                      onFocus={howMetPersora === 'Outro' ? () => setChangingSpecifcHowMetPersora(true) : () => setChangingSpecifcHowMetPersora(false)}
                      onChange={handleHowMetPersoraChange}
                      autoComplete="off"
                    >
                      {howMetPersoraOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label === 'Outro' ? `Outro: ${specifcHowMetPersora}` : option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className={`mt-4 ${!changingSpecifcHowMetPersora ? 'd-none' : ''}`}>
                    <Form.Label>
                      Especifique:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="specificHowMetPersoraConfig"
                      className={`mb-1 ${currentUserContextData.settings.howMetPersora ? 'handbookFormControlFilledV2' : ''}`}
                      name="specificHowMetPersoraInputUpdate"
                      onBlur={handleSpecificHowMetPersoraChange}
                      defaultValue={specifcHowMetPersora}
                      autoComplete="off"
                    />
                    <p className="mb-0 small" />
                  </Form.Group>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showTeamModal} onHide={handleCloseTeamModal} fullscreen animation={false} className="modalFullscreenPage" id="UserConfigModalTeamModal">
        <Modal.Header>
          <PersoraButton
            estilo="ghost"
            tamanho="mini"
            justIcon
            onClick={handleCloseTeamModal}
            style={{ marginLeft: -12 }}
          >
            <ChevronLeft />
          </PersoraButton>
          <Modal.Title style={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '1.15rem',
            // background: 'green',
            justifyContent: 'center',
            marginLeft: '-8px',
          }}
          >
            <span className="semibold">
              Informações do time
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          overflowY: 'auto',
          background: '#FFF',
          paddingBottom: '3rem',
        }}
        >
          <div style={{
            position: 'relative',
          }}
          >
            {/* ========> LIDERO UM TIME DE... */}
            <div
              style={teamDepartment === 'Outro' && changingSpecifcTeamDepartment === true ? OutroStyle : {}}
            >
              <Form.Group>
                <Form.Label>
                  Lidero um time de
                </Form.Label>
                <Form.Select
                  as="select"
                  className={`mb-1 ${teamDepartment ? 'handbookFormControlFilledV2' : ''}`}
                  name="teamDepartmentInputUpdate"
                  defaultValue={teamDepartment}
                  onFocus={teamDepartment === 'Outro' ? () => setChangingSpecifcTeamDepartment(true) : () => setChangingSpecifcTeamDepartment(false)}
                  onChange={handleTeamDepartmentChange}
                  autoComplete="off"
                >
                  {teamDepartmentOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label === 'Outro' ? `Outro: ${specificTeamDepartment}` : option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className={`mt-4 ${!changingSpecifcTeamDepartment ? 'd-none' : ''}`}>
                <Form.Label>
                  Especifique:
                </Form.Label>
                <Form.Control
                  type="text"
                  id="specificTeamDepartmentConfig"
                  className={`mb-1 ${currentUserContextData.workspaceContext.teamDepartment ? 'handbookFormControlFilledV2' : ''}`}
                  name="specificTeamDepartmentInputUpdate"
                  onBlur={handleSpecificTeamDepartmentChange}
                  defaultValue={specificTeamDepartment}
                  autoComplete="off"
                />
                <p className="mb-0 small" />
              </Form.Group>
            </div>
            {/* ========> SEGMENTO DA EMPRESA */}
            <div
              className="mt-4"
              style={companySegment === 'Outro' && changingSpecifcCompanySegment === true ? OutroStyle : {}}
            >
              <Form.Group>
                <Form.Label>
                  Em uma empresa do segmento de
                </Form.Label>
                <Form.Select
                  as="select"
                  className={`mb-1 ${companySegment ? 'handbookFormControlFilledV2' : ''}`}
                  name="companySegmentInputUpdate"
                  defaultValue={companySegment}
                  onFocus={companySegment === 'Outro' ? () => setChangingSpecifcCompanySegment(true) : () => setChangingSpecifcCompanySegment(false)}
                  onChange={handleCompanySegmentChange}
                  autoComplete="off"
                >
                  {companySegmentOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label === 'Outro' ? `Outro: ${specificCompanySegment}` : option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className={`mt-4 ${!changingSpecifcCompanySegment ? 'd-none' : ''}`}>
                <Form.Label>
                  Especifique:
                </Form.Label>
                <Form.Control
                  type="text"
                  id="specificCompanySegmentConfig"
                  className={`mb-1 ${currentUserContextData.workspaceContext.segment ? 'handbookFormControlFilledV2' : ''}`}
                  name="specificCompanySegmentInputUpdate"
                  onBlur={handleSpecificCompanySegmentChange}
                  defaultValue={specificCompanySegment}
                  autoComplete="off"
                />
                <p className="mb-0 small" />
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Sheet
        isOpen={notificationPushSheetIsOpen}
        onClose={handleCloseNotificationPushSheet}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        // disableScrollLocking
      >
        <Sheet.Container>
          <Sheet.Header className="pt-2 pb-0 px-2" style={{ display: 'flex', justifyContent: 'end' }}>
            <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={handleCloseNotificationPushSheet}>
              <Cross />
            </PersoraButton>
          </Sheet.Header>
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.light,
                }}
              >
                <h2 className="medium sourcesans mb-2 pe-4" style={{ color: defaultTheme.colors.primary.main }}>
                  Essa desativação só é possível na configuração do seu celular
                </h2>
                <p className="mb-2">
                  Mas, sugerimos que mantenha as notificações ativas,
                  {' '}
                  pois enviamos apenas alertas importantes para apoiar sua liderança no dia a dia.
                </p>
                <PersoraButton className="mt-4 mb-4" onClick={handleCloseNotificationPushSheet}>
                  Ok, entendi
                </PersoraButton>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={handleCloseNotificationPushSheet} />
      </Sheet>
      <Sheet
        isOpen={notificationEmailSheetIsOpen}
        onClose={() => setNotificationEmailSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableScrollLocking
      >
        <Sheet.Container>
          <Sheet.Header className="pt-2 pb-0 px-2" style={{ display: 'flex', justifyContent: 'end' }}>
            <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setNotificationEmailSheetOpen(false)}>
              <Cross />
            </PersoraButton>
          </Sheet.Header>
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.light,
                }}
              >
                <h2 className="medium sourcesans mb-2 pe-4" style={{ color: defaultTheme.colors.primary.main }}>
                  Tem certeza de que não quer mais receber e-mails?
                </h2>
                <p className="mb-2">
                  Ao desabilitar você não será notificado por e-mail sobre como
                  {' '}
                  o seu time está e o que fazer para continuar se desenvolvendo como líder.
                </p>
                <p className="mb-2">
                  Porém, ainda receberá os e-mails essenciais para o acesso do sistema.
                </p>
                <PersoraButton className="mt-4 mb-4" onClick={() => requestToogleEmailNotifications()} disabled={isLoading}>
                  {isLoading ? <Clock cor="#FFFFFF" /> : 'Desativar e-mails'}
                </PersoraButton>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setNotificationEmailSheetOpen(false)} />
      </Sheet>
      <Sheet
        isOpen={emailChangedSheetIsOpen}
        onClose={() => setEmailChangedSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableScrollLocking
        mountPoint={document.getElementById('UserConfigModalUserModal')}
      >
        <Sheet.Container>
          <Sheet.Header className="pt-2 pb-0 px-2" style={{ display: 'flex', justifyContent: 'end' }}>
            <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setEmailChangedSheetOpen(false)}>
              <Cross />
            </PersoraButton>
          </Sheet.Header>
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.light,
                }}
              >
                <h2 className="medium sourcesans mb-2 pe-4" style={{ color: defaultTheme.colors.primary.main }}>
                  Alteração de e-mail
                </h2>
                <p className="mb-4">
                  Enviamos um código de confirmação para o seu novo e-mail.
                </p>
                <Form.Group>
                  <Form.Label>
                    Insira o código
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="mb-1"
                    name="changedEmailCode"
                    onChange={handleEmailCode}
                    // onBlur={handleInputChage}
                    data-success="Seu email foi atualizado!"
                    autoComplete="off"
                    defaultValue={tempEmailCode}
                  />
                  <p className="mb-3 small">Dica: Verifique a caixa de entrada e pasta de spam.</p>
                </Form.Group>
                <PersoraButton estilo="outline" tamanho="condensed" onClick={handleResendCodeEmail} disabled={countDown1min !== 0}>
                  Reenviar
                  {countDown1min === 0 ? '' : ` em 00:${countDown1min < 10 ? `0${countDown1min}` : countDown1min}`}
                </PersoraButton>
                <PersoraButton className="mt-5 mb-4" onClick={handleConfirmEmailChange} disabled={isLoading || tempEmailCode.length !== 6}>
                  {isLoading ? <Clock cor="#FFFFFF" /> : 'Alterar e-mail'}
                </PersoraButton>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}
UserConfigModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
