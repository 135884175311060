import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Container, Content, Header, Icon, Title,
  TitleBefore,
} from './styles';
import { Cross } from '../DuotoneIcon';
import PersoraButton from '../Button';
import api from '../../services/backend';

export default function Disclaimer({
  estilo,
  tituloAnterior,
  titulo,
  icone,
  descartavel,
  atributo,
  children,
  isActionPlanDisclaimer,
  ...props
}) {
  // replace <br/> or <br /> with \n
  const tituloWithBreaklines = titulo.replace(/<br\s*\/?>/gm, '\n');
  const [isVisible, setIsVisible] = useState(true);
  const handleDismissDisclaimer = async (dismissAttribute) => {
    try {
      setIsVisible(false);
      const body = {
        [dismissAttribute]: true,
      };
      const { data } = await api.patch('/user/disclaimer', body);
      console.log(data);
    } catch (error) {
      setIsVisible(true);
      console.log(error);
    }
  };

  return isVisible ? (
    <Container {...props} className={`${props.className} ${estilo}`}>
      {tituloAnterior && (
      <TitleBefore>
        {tituloAnterior}
      </TitleBefore>
      )}
      <Header>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {icone && <Icon>{icone}</Icon>}
          <Title className={`${isActionPlanDisclaimer ? 'actionPlan' : ''}`}>
            <h5>{tituloWithBreaklines}</h5>
          </Title>
        </div>
        {(descartavel && atributo) && (
        <PersoraButton estilo="ghost" tamanho="mini" className="p-1 close" onClick={() => handleDismissDisclaimer(atributo)}>
          <Cross />
        </PersoraButton>
        )}
      </Header>
      {children && <Content className={`${isActionPlanDisclaimer ? 'actionPlan' : ''}`}>{children}</Content>}
    </Container>
  ) : null;
}

Disclaimer.propTypes = {
  children: PropTypes.node,
  estilo: PropTypes.oneOf(['generic', 'success', 'warning', 'danger', 'info', 'reference']),
  tituloAnterior: PropTypes.string,
  titulo: PropTypes.string.isRequired,
  icone: PropTypes.string,
  className: PropTypes.string,
  atributo: PropTypes.string,
  descartavel: PropTypes.bool,
  isActionPlanDisclaimer: PropTypes.bool,
};
Disclaimer.defaultProps = {
  children: null,
  estilo: 'generic',
  tituloAnterior: '',
  icone: '',
  className: '',
  atributo: '',
  descartavel: false,
  isActionPlanDisclaimer: false,
};
