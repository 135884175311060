export function startAndroidSheetHack() {
  document.getElementById('root').classList.add('androidSheetHack');
  // Obter a posição atual de rolagem
  const currentScrollPosition = window.scrollY;
  // Calcular a nova posição de rolagem adicionando 1 pixel
  const newScrollPosition = currentScrollPosition + 1;
  // Rolar para a nova posição
  window.scrollTo({ top: newScrollPosition, behavior: 'smooth' });
}

export function breakAndroidSheetHack() {
  document.getElementById('root').classList.remove('androidSheetHack');
}
