/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import PropTypes, { func } from 'prop-types';
import { Sheet } from 'react-modal-sheet';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { useContext, useState } from 'react';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import defaultTheme from '../../assets/styles/themes/default';
import PersoraButton from '../../components/Button';
import { ChevronLeft, Clock, Cross } from '../../components/DuotoneIcon';
import { RateButton, RateContainer } from './FirstMapRate';
import Disclaimer from '../../components/Disclaimer';
import api from '../../services/backend';
// import OlhosSuplicantes2 from '../../assets/img/Sora/OlhosSuplicantes2.svg';
import PerguntasPip from '../../assets/img/pip/perguntas.png';
// import Paz from '../../assets/img/Sora/Paz.svg';
import SoraBustoConstrangimento from '../../assets/img/SoraV2/BustoCortado/Constrangimento.svg';
import SoraBustoOtimismo from '../../assets/img/SoraV2/BustoCortado/Otimismo.svg';
import SoraBustoAdmiracao from '../../assets/img/SoraV2/BustoCortado/Admiracao.svg';
// import Comemorando from '../../assets/img/Sora/Comemorando.svg';
import { UserContext } from '../../Context/UserContext';

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: `${defaultTheme.colors.surfaces.lightBlue}`,
    borderColor: `${defaultTheme.colors.black40}`,
    color: `${defaultTheme.colors.primary.light} !important`,
    boxShadow: '0px -4px 10px 0px rgba(37, 46, 70, 0.10) !important',
    padding: '5px 8px',
    borderRadius: '8px',
  }),
};

const DocContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export function PipForm({ setPipFormOpen, handleTapBarClick }) {
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);
  const [btnLoading, setBtnLoading] = useState(false);
  const pipQuestions = [
    {
      id: 'PipForm-opt1',
      pergunta: 'Nas últimas duas semanas, você conseguiu identificar suas habilidades mais fortes?',
    },
    {
      id: 'PipForm-opt2',
      pergunta: 'Nas últimas duas semanas, com que frequência você teve a oportunidade de fazer no trabalho o que você faz de melhor?',
    },
    {
      id: 'PipForm-opt3',
      pergunta: 'Nas últimas duas semanas, com que frequência você teve a oportunidade de aprender e crescer no trabalho?',
    },
    {
      id: 'PipForm-opt4',
      pergunta: 'Nas últimas duas semanas, você sabia exatamente o que sua liderança esperava de você no trabalho?',
    },
    {
      id: 'PipForm-opt5',
      pergunta: 'Nas últimas duas semanas, você se sentiu à vontade com sua liderança para dar e receber feedbacks?',
    },
    {
      id: 'PipForm-opt6',
      pergunta: 'Nas últimas duas semanas, sua liderança estimulou seu desenvolvimento?',
    },
    {
      id: 'PipForm-opt7',
      pergunta: 'Nas últimas duas semanas, sua opinião foi levada em conta nas tomadas de decisão?',
    },
    {
      id: 'PipForm-opt8',
      pergunta: 'Nas últimas duas semanas, seus colegas estiveram comprometidos em fazer o seu melhor no trabalho?',
    },
    {
      id: 'PipForm-opt9',
      pergunta: 'Nas últimas duas semanas, você recebeu ajuda e apoio de colegas de trabalho quando precisou?',
    },
    {
      id: 'PipForm-opt10',
      pergunta: 'Nas últimas duas semanas, os valores do time estiveram claros para você?',
    },
    {
      id: 'PipForm-opt11',
      pergunta: 'Nas últimas duas semanas, com que frequência os valores do time foram colocados em prática pelas pessoas no dia a dia?',
    },
    {
      id: 'PipForm-opt12',
      pergunta: 'Nas últimas duas semanas, seus valores pessoais estiveram alinhados com os valores do time?',
    },
  ];
  const teamOptions = currentUserContextData.workspaceContext.teams
    .filter((team) => currentUserContextData.isLedAt.includes(team._id))
    .map((team) => ({
      value: team._id,
      label: `${team.emoji ? team.emoji : '🤖'} ${team.name}`,
    }));

  const dismissOptions = [
    {
      id: 'PipForm-dismissOptions1',
      value: 'away',
      label: 'Não responderei pois estive ausente neste período.',
    },
    {
      id: 'PipForm-dismissOptions2',
      value: 'notSafe',
      label: 'Não me sinto seguro para responder.',
    },
  ];

  const [formState, setFormState] = useState(1);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [rangeValue, setRangeValue] = useState(null);
  const [pipNote, setPipNote] = useState(null);
  const [pipNoteLenght, setPipNoteLenght] = useState(0);
  const [skipReason, setSkipReason] = useState(null);

  const handleRangeChange = (event) => {
    setRangeValue(event.target.value);
  };

  const goToOrganizacaoTab = () => {
    handleTapBarClick('organizacao');
  };

  // handleChangeDescription que setará pipNote com setPipNote
  const handleChangeDescription = (e) => {
    // limit length to 230 chars
    if (e.target.value.length > 350) {
      e.target.value = e.target.value.substring(0, 350);
      setPipNoteLenght(350);
    }
    setPipNoteLenght(e.target.value.length);
    setPipNote(e.target.value);
  };

  const [ratings, setRatings] = useState(Array(pipQuestions.length).fill(null));
  const handleFormState1 = (opt) => {
    console.log(opt);
    setSelectedTeam(opt);
  };
  const handleRatingChange = (index, rate) => {
    const newRatings = [...ratings];
    newRatings[index] = rate;
    setRatings(newRatings);
  };
  const renderRadios = (index) => (
    <RateContainer>
      {[1, 2, 3, 4, 5].map((rate) => (
        <RateButton
          key={rate}
          onClick={() => handleRatingChange(index, rate)}
          className={ratings[index] === rate ? 'selected' : ''}
        >
          {ratings[index] === rate ? <b>{rate}</b> : rate}
        </RateButton>
      ))}
    </RateContainer>
  );

  // const handleSubmitPip = () => {
  //   console.log('submit');
  //   setPipFormOpen(false);
  //   Swal.fire({
  //     html: `
  //     <img src=${Comemorando} alt='Sora Nerd' class='mb-3'/><br/>
  //     <h4 class="sourcesans semibold">Pesquisa enviada com sucesso.</h4>
  //     É ótimo ver você contribuindo ativamente com sua liderança e equipe.
  //   `,
  //     showCancelButton: false,
  //     confirmButtonColor: defaultTheme.colors.primary.main,
  //     showConfirmButton: false,
  //     showCloseButton: true,
  //   });
  // };

  const handleSubmitPip = async () => {
    // if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
    //   ReactGA.event({
    //     category: 'Premium',
    //     action: 'assinar_premium',
    //   });
    //   mixpanel.track('assinar_premium', {
    //     category: 'Premium',
    //   });
    // }
    setBtnLoading(true);
    const updatedRatings = [...ratings];
    updatedRatings.splice(12, 0, +rangeValue); // 12 porque splice é baseado em zero
    try {
      const body = {
        idTeam: selectedTeam.value,
        idSurvey: currentUserContextData?.pipContext[0]?._id,
        answers: updatedRatings,
        note: (pipNote || null),
      };
      const { data } = await api.post('/questSurvey/setAnswer', body);
      setCurrentUserContextData((prevContextData) => {
        const updatedPipContext = [...prevContextData.pipContext];
        // Adiciona a nova resposta ao array de respostas existentes
        updatedPipContext[0] = {
          ...updatedPipContext[0],
          answers: [...updatedPipContext[0].answers, data.answer],
        };
        console.log('updatedPipContext', updatedPipContext);
        return {
          ...prevContextData,
          pipContext: updatedPipContext,
          tasksGoals: {
            ...prevContextData.tasksGoals,
            pipSendAnswer: [selectedTeam.value],
          },
        };
      });
      goToOrganizacaoTab();
      setBtnLoading(false);
      setPipFormOpen(false);
      Swal.fire({
        html: `
        <img src=${SoraBustoAdmiracao} alt='Sora Nerd' class='mb-3'/><br/>
        <h4 class="sourcesans semibold">Pesquisa enviada com sucesso.</h4>
        É ótimo ver você contribuindo ativamente com sua liderança e equipe.
      `,
        showCancelButton: false,
        confirmButtonColor: defaultTheme.colors.primary.main,
        showConfirmButton: false,
        showCloseButton: true,
      });
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Engajamento',
          action: 'pip_respondida',
          type_of_answer: 'Resposta efetiva',
        });
        mixpanel.track('pip_respondida', {
          category: 'Engajamento',
          company_id: currentUserContextData._idWorkspace[0],
          type_of_answer: 'Resposta efetiva',
        });
      }
    } catch (error) {
      // console.error('Cannot authenticate');
      console.log('ERROR ANSWER PIP', error);
      setBtnLoading(false);
      toast.error('Não foi possível enviar sua resposta.', {
        autoClose: 3000,
        position: 'bottom-center',
      });
    }
  };

  // const handleSubmitDismissPip = () => {
  //   console.log('submit');
  //   setPipFormOpen(false);
  //   if (skipReason === 'notSafe') {
  //     Swal.fire({
  //       html: `
  //         <img src=${OlhosSuplicantes2} alt='Sora Nerd' class='mb-3' width='130px'/><br/>
  //         <h4 class="sourcesans semibold">Expressar sua opinião de forma honesta pode ser um desafio mesmo.</h4>
  //         Mas a Persora não vai desistir de apoiar sua liderança e time na construção dessa segurança. Até a próxima PIP!
  //       `,
  //       showCancelButton: false,
  //       confirmButtonColor: defaultTheme.colors.primary.main,
  //       showConfirmButton: false,
  //       showCloseButton: true,
  //     });
  //   } else if (skipReason === 'away') {
  //     Swal.fire({
  //       html: `
  //         <img src=${Paz} alt='Sora Paz' class='mb-3' width='130px' style='margin-left: -35px;' /><br/>
  //         <h4 class="sourcesans semibold">Entendi. Conto com você na próxima PIP, então!</h4>
  //       `,
  //       showCancelButton: false,
  //       confirmButtonColor: defaultTheme.colors.primary.main,
  //       showConfirmButton: false,
  //       showCloseButton: true,
  //     });
  //   }
  // };

  const handleSubmitDismissPip = async () => {
    // if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
    //   ReactGA.event({
    //     category: 'Premium',
    //     action: 'assinar_premium',
    //   });
    //   mixpanel.track('assinar_premium', {
    //     category: 'Premium',
    //   });
    // }
    setBtnLoading(true);
    try {
      const body = {
        idTeam: selectedTeam.value,
        idSurvey: currentUserContextData?.pipContext[0]?._id,
        answers: skipReason,
      };
      const { data } = await api.post('/questSurvey/setAnswer', body);
      console.log('answeraqui', data.answer);
      setCurrentUserContextData((prevContextData) => {
        const updatedPipContext = [...prevContextData.pipContext];
        // Adiciona a nova resposta ao array de respostas existentes
        updatedPipContext[0] = {
          ...updatedPipContext[0],
          answers: [...updatedPipContext[0].answers, data.answer],
        };
        console.log('updatedPipContext', updatedPipContext);
        return {
          ...prevContextData,
          pipContext: updatedPipContext,
          tasksGoals: {
            ...prevContextData.tasksGoals,
            pipSendAnswer: [selectedTeam.value],
          },
        };
      });
      goToOrganizacaoTab();
      setBtnLoading(false);
      setPipFormOpen(false);
      if (skipReason === 'notSafe') {
        Swal.fire({
          html: `
            <img src=${SoraBustoOtimismo} alt='Sora Nerd' class='mb-3' width='130px'/><br/>
            <h4 class="sourcesans semibold">Expressar sua opinião de forma honesta pode ser um desafio mesmo.</h4>
            Mas a Persora não vai desistir de apoiar sua liderança e time na construção dessa segurança. Até a próxima PIP!
          `,
          showCancelButton: false,
          confirmButtonColor: defaultTheme.colors.primary.main,
          showConfirmButton: false,
          showCloseButton: true,
        });
        if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
          ReactGA.event({
            category: 'Engajamento',
            action: 'pip_respondida',
            type_of_answer: 'Não respondeu por insegurança',
          });
          mixpanel.track('pip_respondida', {
            category: 'Engajamento',
            company_id: currentUserContextData._idWorkspace[0],
            type_of_answer: 'Não respondeu por insegurança',
          });
        }
      } else if (skipReason === 'away') {
        Swal.fire({
          html: `
            <img src=${SoraBustoConstrangimento} alt='Sora Paz' class='mb-3' width='130px' style='margin-left: -35px;' /><br/>
            <h4 class="sourcesans semibold">Entendi. Conto com você na próxima PIP, então!</h4>
          `,
          showCancelButton: false,
          confirmButtonColor: defaultTheme.colors.primary.main,
          showConfirmButton: false,
          showCloseButton: true,
        });
        if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
          ReactGA.event({
            category: 'Engajamento',
            action: 'pip_respondida',
            type_of_answer: 'Não respondeu por ausência',
          });
          mixpanel.track('pip_respondida', {
            category: 'Engajamento',
            company_id: currentUserContextData._idWorkspace[0],
            type_of_answer: 'Não respondeu por ausência',
          });
        }
      }
    } catch (error) {
      // console.error('Cannot authenticate');
      setBtnLoading(false);
      toast.error('Não foi possível enviar sua resposta.', {
        autoClose: 3000,
        position: 'bottom-center',
      });
    }
  };

  return (
    <Sheet.Container>
      {/* <Sheet.Header /> */}
      {formState === 2 || formState === 3 || formState === 4 ? (
        <Sheet.Header className="pt-3 ps-1 pe-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setFormState(formState === 4 ? 1 : formState - 1)}>
            <ChevronLeft />
          </PersoraButton>
        </Sheet.Header>
      ) : (
        <Sheet.Header className="pt-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
          {' '}
        </Sheet.Header>
      )}
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: '1rem',
              paddingBottom: '2rem',
              color: defaultTheme.colors.primary.main,
            }}
            className="docsList"
          >
            {formState === 1 && (
              <>
                <h2 className="sourcesans medium mb-2">Hora de responder a PIP</h2>
                <div style={{ color: defaultTheme.colors.primary.light }}>
                  <p className="mb-1">A PIP é a voz do time! É uma pesquisa quinzenal que acompanha continuamente a equipe em quatro dimensões: indivíduo, liderança, time e regras do jogo.</p>
                  <p>Leva menos de 1 minuto para responder e é anônima.</p>
                </div>
                <h3 className="sourcesans mb-3">Selecione o time sobre o qual você responderá (o que mais atuou nas últimas duas semanas).</h3>
                <Select
                  options={teamOptions}
                  onChange={(opt) => handleFormState1(opt)}
                  isOptionDisabled={(opt) => opt.disabled}
                  isClearable
                  placeholder="Selecionar time..."
                  className="mb-4"
                  styles={customStyles}
                  menuPlacement="bottom"
                  maxMenuHeight={145}
                  value={selectedTeam}
                />
                <PersoraButton className="mt-4 mb-1" onClick={() => setFormState(2)} disabled={!selectedTeam}>Próxima</PersoraButton>
                <PersoraButton estilo="ghost" onClick={() => setFormState(4)} disabled={!selectedTeam}>Não responder esta PIP</PersoraButton>
              </>
            )}
            {formState === 2 && (
              <>
                <h6
                  style={{ color: defaultTheme.colors.primary.light, letterSpacing: '0.05rem' }}
                  className="sourcesans semibold text-uppercase mb-3"
                >
                  Pesquisa para Impacto Positivo
                </h6>
                <h2 className="sourcesans medium mb-1">Avalie as 2 últimas semanas!</h2>
                <p className="mb-4" style={{ color: defaultTheme.colors.primary.light }}>Uma premissa nossa: nunca expor ninguém. 🥷</p>

                {/* {selectedTeam ? selectedTeam.label : ''} */}
                {pipQuestions.map((option, index) => (
                  <div key={`pipQ-${index + 1}`}>
                    <h4 className="sourcesans mb-2 pb-1">
                      <b>
                        {index + 1}
                        .
                      </b>
                      {' '}
                      {option.pergunta}
                    </h4>
                    <div className="mt-2 mb-4">
                      {renderRadios(index)}
                      <div
                        className="semibold"
                        style={{
                          display: 'flex', justifyContent: 'space-between', fontSize: '1rem', color: defaultTheme.colors.primary.light,
                        }}
                      >
                        <span>Nunca</span>
                        <span>Sempre</span>
                      </div>
                    </div>

                  </div>
                ))}
                <h4 className="sourcesans mb-2 pb-1">
                  <b>
                    13.
                    {' '}
                  </b>
                  Baseado nas últimas duas semanas, de 0 a 10, qual é a probabilidade de você recomendar a empresa onde trabalha como um bom lugar para se trabalhar?
                </h4>
                <RateContainer style={{ alignItems: 'center', gap: '18px' }} className="mb-5">
                  <Form.Range
                    min={0}
                    max={10}
                    value={rangeValue === null ? 0 : rangeValue}
                    onChange={handleRangeChange}
                    style={rangeValue === null ? { opacity: 0.5 } : { opacity: 1 }}
                    className="pipRange py-3"
                  />
                  <div
                    className="bold"
                    style={{
                      width: '40px',
                      textAlign: 'center',
                      background: '#fff',
                      padding: '2px 4px',
                      borderRadius: '20px',
                      color: defaultTheme.colors.primary.light,
                    }}
                  >
                    {rangeValue === null ? '?' : rangeValue}
                  </div>
                </RateContainer>

                <PersoraButton
                  className="mt-4 mb-1"
                  onClick={() => { console.log(ratings); setFormState(3); }}
                  disabled={
                  (
                    ratings.includes(null)
                    || ratings.includes(undefined)
                    || ratings.includes(NaN)
                    || rangeValue === null
                  )
                }
                >
                  Próxima
                </PersoraButton>
                { (ratings.includes(null)
                    || ratings.includes(undefined)
                    || ratings.includes(NaN)
                    || rangeValue === null)
                  && (
                    <Disclaimer
                      estilo="warning"
                      titulo="Cheque suas respostas. Parece que você esqueceu de responder alguma pergunta."
                      icone="👀"
                      className="mt-4 mb-2"
                    />
                  )}
              </>
            )}
            {formState === 3 && (
              <>
                <h2 className="sourcesans medium mb-3">Registrar ideias, preocupações e/ou sugestões anônimas para sua liderança.</h2>
                <Form.Group style={{ position: 'relative' }}>
                  <Form.Control
                    as="textarea"
                    placeholder="Utilize este espaço para escrever (opcional e anônimo)..."
                    className="mb-3"
                    name="description"
                    style={{
                      height: (pipNoteLenght < 120 ? '100px' : '185px'),
                    }}
                    defaultValue={pipNote}
                    onChange={handleChangeDescription}
                  />
                  <div style={{
                    position: 'absolute',
                    bottom: '3px',
                    right: '10px',
                    color: (pipNoteLenght >= 300 ? 'red' : defaultTheme.colors.primary.light),
                    opacity: 0.8,
                  }}
                  >
                    <small>
                      {pipNoteLenght}
                      /350
                    </small>
                  </div>
                </Form.Group>
                {/* <PersoraButton className="mt-4 mb-1" onClick={() => { console.log(ratings); handleSubmitPip(); }}>Responder PIP</PersoraButton> */}
                <PersoraButton className="mt-4 mb-1" onClick={handleSubmitPip} disabled={btnLoading}>
                  {btnLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Responder PIP'}
                </PersoraButton>
              </>
            )}
            {formState === 4 && (
              <>
                <h2 className="sourcesans medium mb-3">Por que você não participará desta PIP?</h2>
                <Form className="mb-4 pb-1">
                  {dismissOptions.map((option) => (
                    <div
                      key={option.id}
                      className="mb-3"
                      style={{ fontSize: '1.15rem', color: defaultTheme.colors.primary.light }}
                    >
                      <Form.Check // prettier-ignore
                        type="radio"
                        name="group1"
                        id={option.id}
                        label={option.label}
                        value={option.value}
                        onChange={() => setSkipReason(option.value)}
                        defaultChecked={(skipReason === option.value)}
                      />
                    </div>
                  ))}
                </Form>
                <PersoraButton className="mt-4 mb-1" onClick={handleSubmitDismissPip} disabled={btnLoading || !skipReason}>
                  {btnLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Enviar'}
                </PersoraButton>
              </>
            )}
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}
PipForm.propTypes = {
  setPipFormOpen: PropTypes.func.isRequired,
  handleTapBarClick: PropTypes.func.isRequired,
};

export function LiderColocouPlanoEmPratica() {
  const options = [
    {
      id: 'LiderColocouPlanoEmPratica-opt1',
      value: '0',
      label: 'Sim, coloquei em prática',
    },
    {
      id: 'LiderColocouPlanoEmPratica-opt2',
      value: '1',
      label: 'Ainda não fiz',
    },
  ];
  return (
    <Sheet.Container>
      {/* <Sheet.Header /> */}
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: '2rem',
              paddingBottom: '3rem',
              color: defaultTheme.colors.primary.main,
            }}
            className="docsList"
          >
            <h3 className="sourcesans medium mb-4">Com base no resultado da PIP, personalizamos um plano de ação para você. Você colocou em prática?</h3>
            <Form className="mb-4 pb-1">
              {options.map((option) => (
                <div
                  key={option.id}
                  className="mb-3"
                  style={{ fontSize: '1.15rem', color: defaultTheme.colors.primary.light }}
                >
                  <Form.Check // prettier-ignore
                    type="radio"
                    name="group1"
                    id={option.id}
                    label={option.label}
                    value={option.value}
                  />
                </div>
              ))}
            </Form>
            <PersoraButton>Enviar</PersoraButton>
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}

export function LiderAvaliaResultado({
  setPipQuestLiderAvaliaResultadoOpen, setHiddenPipQuestLiderAvaliaResultado, idSurvey, currentTeamId,
}) {
  const { currentUserContextData } = useContext(UserContext);
  const options = [
    {
      id: 'LiderAvaliaResultado-opt1',
      value: '0',
      label: 'Concordo totalmente',
    },
    {
      id: 'LiderAvaliaResultado-opt2',
      value: '1',
      label: 'Concordo parcialmente',
    },
    {
      id: 'LiderAvaliaResultado-opt3',
      value: '2',
      label: 'Indiferente',
    },
    {
      id: 'LiderAvaliaResultado-opt4',
      value: '3',
      label: 'Discordo parcialmente',
    },
    {
      id: 'LiderAvaliaResultado-opt5',
      value: '4',
      label: 'Discordo totalmente',
    },
  ];
  const [selectedOption, setSelectedOption] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleSubmitPipAvaliacao = async () => {
    setBtnLoading(true);
    try {
      const body = {
        idTeam: currentTeamId,
        idSurvey,
        avaliacao: selectedOption,
      };
      await api.post('/questSurvey/setAvaliacaoResultado', body);
      setBtnLoading(false);
      setPipQuestLiderAvaliaResultadoOpen(false);
      setHiddenPipQuestLiderAvaliaResultado(true);
      toast.success('Tudo certo! Você faz parte do desenvolvimento da Persora.', {
        autoClose: 3000,
        position: 'top-center',
      });
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Engajamento',
          action: 'pip_avaliada',
          question: 'O que você achou do resultado da PIP?',
          answer: selectedOption,
        });
        mixpanel.track('pip_avaliada', {
          category: 'Engajamento',
          company_id: currentUserContextData._idWorkspace[0],
          question: 'O que você achou do resultado da PIP?',
          answer: selectedOption,
        });
      }
    } catch (error) {
      // console.error('Cannot authenticate');
      setBtnLoading(false);
      toast.error('Não foi possível enviar sua resposta.', {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };
  return (
    <Sheet.Container>
      <Sheet.Header className="pt-2 pb-1 px-2" style={{ display: 'flex', justifyContent: 'end' }}>
        <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setPipQuestLiderAvaliaResultadoOpen(false)}>
          <Cross />
        </PersoraButton>
      </Sheet.Header>
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: '0rem',
              paddingBottom: '3rem',
              color: defaultTheme.colors.primary.main,
            }}
            className="docsList"
          >
            <h3 className="sourcesans medium mb-4 pe-5">O que você achou do resultado da PIP?</h3>
            <Form className="mb-4 pb-1">
              {options.map((option) => (
                <div
                  key={option.id}
                  className="mb-3"
                  style={{ fontSize: '1.15rem', color: defaultTheme.colors.primary.light }}
                >
                  <Form.Check // prettier-ignore
                    type="radio"
                    name="group1"
                    id={option.id}
                    label={option.label}
                    value={option.value}
                    onChange={() => setSelectedOption(option.label)}
                  />
                </div>
              ))}
            </Form>
            <PersoraButton
              onClick={handleSubmitPipAvaliacao}
              disabled={!selectedOption || btnLoading}
            >
              {btnLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Enviar'}
            </PersoraButton>
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}
LiderAvaliaResultado.propTypes = {
  setPipQuestLiderAvaliaResultadoOpen: func.isRequired,
  setHiddenPipQuestLiderAvaliaResultado: func.isRequired,
  idSurvey: PropTypes.string.isRequired,
  currentTeamId: PropTypes.string.isRequired,
};

export function LiderAvaliaEfetividade() {
  const options = [
    {
      id: 'LiderAvaliaEfetividade-opt1',
      value: '0',
      label: 'Bastante positivo',
    },
    {
      id: 'LiderAvaliaEfetividade-opt2',
      value: '1',
      label: 'Senti que o time reagiu bem',
    },
    {
      id: 'LiderAvaliaEfetividade-opt3',
      value: '2',
      label: 'Não apliquei o Plano de Ação',
    },
    {
      id: 'LiderAvaliaEfetividade-opt4',
      value: '3',
      label: 'Senti que o time reagiu um pouco mal',
    },
    {
      id: 'LiderAvaliaEfetividade-opt5',
      value: '4',
      label: 'Bastante negativo',
    },
  ];
  return (
    <Sheet.Container>
      {/* <Sheet.Header /> */}
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: '2rem',
              paddingBottom: '3rem',
              color: defaultTheme.colors.primary.main,
            }}
            className="docsList"
          >
            <h3 className="sourcesans medium mb-4 pe-1">Como você avalia o impacto do Plano de Ação que realizou com o time?</h3>
            <Form className="mb-4 pb-1">
              {options.map((option) => (
                <div
                  key={option.id}
                  className="mb-3"
                  style={{ fontSize: '1.15rem', color: defaultTheme.colors.primary.light }}
                >
                  <Form.Check // prettier-ignore
                    type="radio"
                    name="group1"
                    id={option.id}
                    label={option.label}
                    value={option.value}
                  />
                </div>
              ))}
            </Form>
            <PersoraButton>Enviar</PersoraButton>
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}

export function LideradoPercebeuPlanoEmPratica() {
  const options = [
    {
      id: 'LideradoPercebeuPlanoEmPratica-opt1',
      value: '0',
      label: 'Sim, percebi e achei positivo.',
    },
    {
      id: 'LideradoPercebeuPlanoEmPratica-opt2',
      value: '1',
      label: 'Sim, percebi e achei que não teve efeito.',
    },
    {
      id: 'LideradoPercebeuPlanoEmPratica-opt3',
      value: '2',
      label: 'Sim, percebi e achei negativo.',
    },
    {
      id: 'LideradoPercebeuPlanoEmPratica-opt4',
      value: '3',
      label: 'Não percebi.',
    },
    {
      id: 'LideradoPercebeuPlanoEmPratica-opt5',
      value: '4',
      label: 'Certamente não houve ação.',
    },
  ];
  return (
    <Sheet.Container>
      {/* <Sheet.Header /> */}
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: '2rem',
              paddingBottom: '3rem',
              color: defaultTheme.colors.primary.main,
            }}
            className="docsList"
          >
            <h3 className="sourcesans medium mb-4">Percebeu alguma ação do líder com o time após o resultado da PIP?</h3>
            <Form className="mb-4 pb-1">
              {options.map((option) => (
                <div
                  key={option.id}
                  className="mb-3"
                  style={{ fontSize: '1.15rem', color: defaultTheme.colors.primary.light }}
                >
                  <Form.Check // prettier-ignore
                    type="radio"
                    name="group1"
                    id={option.id}
                    label={option.label}
                    value={option.value}
                  />
                </div>
              ))}
            </Form>
            <PersoraButton>Enviar</PersoraButton>
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}

export function PipQuestionsDemo({ setPipQuestionsDemoSheetOpen }) {
  return (
    <Sheet.Container>
      <Sheet.Header className="pt-2 pb-0 px-2" style={{ display: 'flex', justifyContent: 'end' }}>
        <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setPipQuestionsDemoSheetOpen(false)}>
          <Cross />
        </PersoraButton>
      </Sheet.Header>
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: '1rem',
              paddingBottom: '3rem',
              color: defaultTheme.colors.primary.light,
            }}
          >
            <h3 className="sourcesans medium mb-3" style={{ color: defaultTheme.colors.primary.main }}>O que o meu time responde?</h3>
            <p className="mb-2">A PIP é uma análise quinzenal que funciona como um &quot;Raio X&quot; do time, permitindo à liderança conhecer a percepção do time sobre Indivíduo, Time, Liderança e Cultura.</p>
            <p>Baseada nos estudos da Gallup, a PIP é uma pesquisa padronizada e situacional, com perguntas fixas, objetivas e anônimas que levam menos de um minuto para responder.</p>
            <img
              src={PerguntasPip}
              alt="Perguntas da PIP"
              className="mt-4"
              style={{
                width: '100%', maxWidth: '500px', border: `1px solid ${defaultTheme.colors.black10}`, borderRadius: '16px', padding: '10px', margin: '0 auto',
              }}
            />
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}
PipQuestionsDemo.propTypes = {
  setPipQuestionsDemoSheetOpen: func.isRequired,
};
