// função para formatar a data ISODate (1900-10-10T00:00:00.000+00:00) para o formato dd/mm/aaaa
export const formatDateToInputField = (date) => {
  const dateToString = date.toString();
  const year = dateToString.substring(0, 4);
  const month = dateToString.substring(5, 7);
  const day = dateToString.substring(8, 10);
  return `${year}-${month}-${day}`;
};
// função para formatar a data ISODate (1900-10-10T00:00:00.000+00:00) para o formato dd/mm/aaaa
export const formatDateDDMMYYYY = (date, separador = '-') => {
  const dateToString = date.toString();
  const year = dateToString.substring(0, 4);
  const month = dateToString.substring(5, 7);
  const day = dateToString.substring(8, 10);
  return `${day}${separador}${month}${separador}${year}`;
};
export const formatDateDDMMYY = (date, separador = '-') => {
  const dateToString = date.toString();
  const year = dateToString.substring(2, 4);
  const month = dateToString.substring(5, 7);
  const day = dateToString.substring(8, 10);
  return `${day}${separador}${month}${separador}${year}`;
};
export const formatDateDDMM = (date, separador = '-') => {
  const dateToString = date.toString();
  // const year = dateToString.substring(0, 4);
  const month = dateToString.substring(5, 7);
  const day = dateToString.substring(8, 10);
  return `${day}${separador}${month}`;
};
// função para retornar o tempo decorrido desde uma data recebida
export const tempoDecorrido = (createdAt) => {
  const dataCriacao = new Date(createdAt);
  const dataAtual = new Date();
  const diferenca = Math.floor((dataAtual - dataCriacao) / 1000); // diferença em segundos

  const segundos = diferenca % 60;
  const minutos = Math.floor(diferenca / 60) % 60;
  const horas = Math.floor(diferenca / (60 * 60)) % 24;
  const dias = Math.floor(diferenca / (60 * 60 * 24)) % 7;
  const semanas = Math.floor(diferenca / (60 * 60 * 24 * 7)) % 4;
  const meses = Math.floor(diferenca / (60 * 60 * 24 * 30));

  if (meses > 0) {
    return `há ${meses} ${meses === 1 ? 'mês' : 'meses'}`;
  } if (semanas > 0) {
    return `há ${semanas} ${semanas === 1 ? 'semana' : 'semanas'}`;
  } if (dias > 0) {
    return `há ${dias} ${dias === 1 ? 'dia' : 'dias'}`;
  } if (horas > 0) {
    return `há ${horas} ${horas === 1 ? 'hora' : 'horas'}`;
  } if (minutos > 0) {
    return `há ${minutos} ${minutos === 1 ? 'minuto' : 'minutos'}`;
  }
  return `há ${segundos} ${segundos === 1 ? 'segundo' : 'segundos'}`;
};
// function to check status da Pip com base nas suas datas
// Pode ser Agendada, Aberta ou Fechada
export const returnQuestStatus = ({ startDate, finalDate }) => {
  const refDate = new Date();
  const startDateObj = new Date(startDate);
  const finalDateObj = new Date(finalDate);
  if (refDate < startDateObj) {
    return {
      status: 'Agendada',
      tagColor: 'warning',
    };
  }
  if (refDate > finalDateObj) {
    return {
      status: 'Fechada',
      tagColor: 'danger',
    };
  }
  return {
    status: 'Em andamento',
    tagColor: 'success',
  };
};

export const checkIfPipEndsToday = (finalDate) => {
  const today = new Date();
  const newFinalDate = new Date(finalDate);
  // Remove time part for comparison
  today.setHours(0, 0, 0, 0);
  newFinalDate.setHours(0, 0, 0, 0);
  return today.getTime() === newFinalDate.getTime();
};
