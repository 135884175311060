import { useEffect, useState, useContext } from 'react';
import { Sheet } from 'react-modal-sheet';
import PropTypes from 'prop-types';
import defaultTheme from '../../assets/styles/themes/default';
import api from '../../services/backend';
import PersoraLoader from '../../components/PersoraLoader';
import UserProfileResult from '../../components/UserProfileResult';
import MapeamentoLoader from '../../components/MapeamentoLoader';
import { UserContext } from '../../Context/UserContext';
import { CardSora, SoraImage } from '../../components/UserCard/styles';

// import SoraPaz from '../../assets/img/Sora/Paz.svg';
import SoraPiscando from '../../assets/img/SoraV2/Rosto/Piscando2.svg';

export default function ViewUserProfile({ userIdToViewUserProfile, soraUserData }) {
  const [userData, setUserData] = useState({});
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);
  const { meetSora } = currentUserContextData.tasksGoals;

  // api get users (/user/getUsers) from currentTeam (_id) async
  // setTeamData(users)
  // TODO Refatorar para pegar os usuários de todo o workspace
  useEffect(() => {
    if (soraUserData !== null) {
      setUserData(soraUserData);
      if (meetSora !== 1) {
        (async () => {
          try {
            const body = {
              meetSora: 1,
            };
            await api.patch('/user/updateTask', body);
            setCurrentUserContextData((prevData) => ({
              ...prevData,
              tasksGoals: {
                ...prevData.tasksGoals,
                meetSora: 1,
              },
            }));
            // console.log('Tarefa de Meet Sora atualizada com sucesso!'); // DEBUG
          } catch (error) {
            console.log(error);
          }
        })();
      }
    } else {
      (async () => {
        try {
          const { data } = await api.get(`/user/getUserData?id=${userIdToViewUserProfile}`);
          setUserData(data);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  let lastProfile = null;
  if (soraUserData !== null) {
    lastProfile = userData;
  } else {
    lastProfile = (userData.profiles && userData.profiles[userData.profiles.length - 1]);
  }

  if (lastProfile) {
    return (
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <Sheet.Scroller>
            {!userData.name && <PersoraLoader isLoading />}
            {userData.name && lastProfile && (
              <div
                style={{
                // minHeight: 400,
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                }}
              >
                {soraUserData
                && (
                  <CardSora>
                    <SoraImage src={SoraPiscando} alt="Sora" />
                    <div>
                      Olá! Sou a Sora, a assistente da Persora que atua como sua
                      {' '}
                      copiloto. Esse é o meu estilo,
                      {' '}
                      fique à vontade para me  conhecer melhor.
                    </div>
                  </CardSora>
                )}
                <UserProfileResult
                  userName={userData.name}
                  userPronouns={userData.settings.pronouns}
                  userProfile={lastProfile}
                  aboutAnotherUser={userData._id !== currentUserContextData._id}
                />
              </div>
            )}
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
    );
  }
  return (
    <Sheet.Container>
      <Sheet.Header />
      <Sheet.Content>
        <Sheet.Scroller>
          <MapeamentoLoader text={null} isLoading />
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}

ViewUserProfile.propTypes = {
  userIdToViewUserProfile: PropTypes.string.isRequired,
  soraUserData: PropTypes.shape({}),
};
ViewUserProfile.defaultProps = {
  soraUserData: null,
};
