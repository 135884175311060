import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
  background: none;
  border: none;
  &:disabled {
    opacity: .6;
  }
`;
export default function PersoraTextButton({ children, ...props }) {
  return (
    <Button
      type="button"
      {...props}
      style={{
        color: 'inherit', ...props.style,
      }}
    >
      {children}
    </Button>
  );
}
PersoraTextButton.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
};
PersoraTextButton.defaultProps = {
  style: {},
};
