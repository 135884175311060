import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import Swal from 'sweetalert2';
import {
  Container, Row, Col,
} from 'react-bootstrap';
// import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
// import { useHistory } from 'react-router-dom';
import { Sheet } from 'react-modal-sheet';
// import logo from '../../assets/img/logo-horizontal.svg';
// import welcomeLider1 from '../../assets/img/welcome-lider-1.svg';
// import ReactPlayer from 'react-player/youtube';
// import SoraOlhosSuplicantes from '../../assets/img/Sora/OlhosSuplicantes2.svg';
import SoraComemorando from '../../assets/img/Sora/Comemorando.svg';
import SoraTorcida from '../../assets/img/SoraV2/BustoCortado/Torcida.svg';
import SoraPreocupacao from '../../assets/img/SoraV2/BustoCortado/Preocupacao.svg';
import welcomePipLider1 from '../../assets/img/pip/welcome-lider-1.png';
import welcomePipLider2 from '../../assets/img/pip/welcome-lider-2.png';
import welcomePipLider3 from '../../assets/img/pip/welcome-lider-3.png';
import welcomePipLider4 from '../../assets/img/pip/welcome-lider-4.png';
import welcomePipLider5 from '../../assets/img/pip/welcome-lider-5.png';
import api from '../../services/backend';
import PersoraButton from '../Button';
import defaultTheme from '../../assets/styles/themes/default';
// modules styles
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import {
  ButtonsDiv,
  Left,
  ListItensWithBlueBg,
  PaginationDiv,
  WelcomeBody,
  WelcomeContainer,
  WelcomeFooter,
  WelcomeImage,
} from './styles';
import {
  ArrowRight, Cardiology, Clock, QuestionCircle,
} from '../DuotoneIcon';
// import PersoraTextButton from '../TextButton';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import { UserContext } from '../../Context/UserContext';
import { textToClipboard } from '../../utils/copyToClipboard';
import { PipQuestionsDemo } from '../../pages/Sheets/PipForms';
import { returnQuestStatus } from '../../utils/dateUtils';

SwiperCore.use([Pagination, Navigation]);

export default function WelcomePip({ currentTeam, setDispararWelcomePip }) {
  // const history = useHistory();
  // const currentURL = new URL(window.location.href);
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);
  console.log('currentUserContextData@welcomepip', currentUserContextData);
  const mensagemConvitePip = 'Oi! Agora com a Persora temos uma pesquisa rápida quinzenal sobre o nosso time. É super importante para entendermos as necessidades da equipe e só teremos resultado se atingirmos o mínimo de 3 respostas efetivas. Leva menos de 1 minuto para responder. Topa contribuir?';
  console.log(currentUserContextData);
  // Verifica se a URL possui o parâmetro "revisit"
  // const hasRevisitParam = currentURL.searchParams.has('revisit');

  const [isLoading, setIsLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [pipQuestionsDemoSheetIsOpen, setPipQuestionsDemoSheetOpen] = useState(false);

  // eslint-disable-next-line
  const pipQuantity = currentUserContextData?.pipContext?.length;
  const [currentPipCardStatus, setCurrentPipCardStatus] = useState(null);
  useEffect(() => {
    if (pipQuantity === 1) {
      setCurrentPipCardStatus(
        returnQuestStatus({
          startDate: currentUserContextData.pipContext[0].startDate,
          finalDate: currentUserContextData.pipContext[0].finalDate,
        }),
      );
    } else if (pipQuantity > 1) {
      const today = new Date();
      const mostRecentPipStartDate = new Date(currentUserContextData.pipContext[0]?.startDate);
      // Calcula a diferença em milissegundos entre
      // a data atual e a data de início do PIP mais recente
      const timeDifference = mostRecentPipStartDate - today;
      // Converte a diferença de tempo para horas
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      // Se mostRecentPip.startDate for no dia seguinte, currentPipCard receberá mostRecentPip
      if (hoursDifference <= 24) {
        setCurrentPipCardStatus(
          returnQuestStatus({
            startDate: currentUserContextData.pipContext[0].startDate,
            finalDate: currentUserContextData.pipContext[0].finalDate,
          }),
        );
      } else {
        // Se não, currentPipCard deverá receber leastRecentPip
        setCurrentPipCardStatus(
          returnQuestStatus({
            startDate: currentUserContextData.pipContext[1].startDate,
            finalDate: currentUserContextData.pipContext[1].finalDate,
          }),
        );
      }
    }
  }, [currentUserContextData]);

  const swalHtmlMessagePipEnabled = (status) => {
    if (status === 'Agendada') {
      return `
        <img src=${SoraTorcida} alt='Sora Nerd' class='mb-4'/><br/>
        <div class="text-start">
          <h3>Tudo pronto!</h3>
          <p>Seu time será convidado a responder à PIP em breve. Preparei uma sugestão de mensagem para você enviar no canal de comunicação do seu time.</p>
          <textarea class="form-control mt-3" style="text-align: left;" rows="4" readonly>${mensagemConvitePip}</textarea>
        </div>
      `;
    }
    if (status === 'Em andamento') {
      return `
        <img src=${SoraComemorando} alt='Sora Nerd' class='mb-4'/><br/>
        <div class="text-start">
          <h3>Tudo pronto!</h3>
          <p>Seu time já está respondendo a PIP. Preparei uma sugestão de mensagem para você enviar no canal de comunicação do seu time.</p>
          <textarea class="form-control mt-3" style="text-align: left;" rows="4" readonly>${mensagemConvitePip}</textarea>
        </div>
      `;
    }
    if (status === 'Fechada') {
      return `
        <img src=${SoraComemorando} alt='Sora Nerd' class='mb-4'/><br/>
        <div class="text-start">
          <h3>Tudo pronto!</h3>
          <p>Seu time já respondeu a PIP e os resultados estão disponíveis na aba Indicadores.</p>
      `;
    }
    return '';
  };

  const handleEnablePip = async (event) => {
    event.preventDefault();
    console.log('handleEnablePip trigger');
    setIsLoading(true);
    try {
      await api.patch('/user/disclaimer', { welcomePip: true });
      setCurrentUserContextData((prevData) => ({
        ...prevData,
        disclaimers: {
          ...prevData.disclaimers,
          welcomePip: true,
        },
      }));
      Swal.fire({
        // html: `
        //   <img src=${SoraComemorando} alt='Sora Nerd' class='mb-4'/><br/>
        //   <div class="text-start">
        //     <h3>Tudo pronto!</h3>
        //     ${currentPipCardStatus.status}
        // eslint-disable-next-line
        //     <p>Seu time será convidado a responder à PIP em breve. Preparei uma sugestão de mensagem para você enviar no canal de comunicação do seu time.</p>
        // eslint-disable-next-line
        //     <textarea class="form-control mt-3" style="text-align: left;" rows="4" readonly>${mensagemConvitePip}</textarea>
        //   </div>
        // `,
        html: swalHtmlMessagePipEnabled(currentPipCardStatus.status),
        showCloseButton: true,
        closeButtonHtml: '&times;',
        showCancelButton: false,
        confirmButtonColor: defaultTheme.colors.primary.main,
        confirmButtonText: 'Copiar mensagem',
        customClass: {
          confirmButton: (currentPipCardStatus.status === 'Agendada' || currentPipCardStatus.status === 'Em andamento' ? 'swal2-button-full-width' : 'd-none'),
        },
        preConfirm: () => {
          textToClipboard(mensagemConvitePip);
          console.log('copiado');
          // Retorna falso para evitar o fechamento do alert automaticamente
          return false;
        },
      });
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Onboarding',
          action: 'onboarding_concluido',
          onboarding_title: 'PIP',
        });
        mixpanel.track('onboarding_concluido', {
          category: 'Onboarding',
          company_id: currentUserContextData._idWorkspace[0],
          onboarding_title: 'PIP',
        });
      }
      // redirect user to /userSpace route
      // history.push('/userSpace');
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleDisablePip = async (event) => {
    event.preventDefault();
    console.log('handleDisablePip trigger');
    const result = await Swal.fire({
      html: `
        Você realmente deseja desabilitar a PIP? Sem essa pesquisa não conseguirei orientá-lo sobre o que há de mais crítico no time.
      `,
      showCancelButton: true,
      // confirmButtonColor: defaultTheme.colors.alerts.danger.,
      // confirmButtonColor: defaultTheme.colors.alerts.danger.backgroundRed,
      confirmButtonText: 'Desabilitar a PIP',
      cancelButtonText: 'Manter a PIP habilitada',
      customClass: {
        confirmButton: 'swal2-button-full-width swal2-button-color-outline-red',
        cancelButton: 'swal2-button-full-width swal2-button-color-filled-primary-main',
      },
    });
    if (result.isConfirmed) {
      console.log(currentUserContextData.teams);
      try {
        await api.patch('/user/disclaimer', { welcomePip: true });
        await api.patch(`/team/settings/?idTeam=${currentTeam._id}`, { pipEnabled: false });
        setCurrentUserContextData((prevData) => ({
          ...prevData,
          disclaimers: {
            ...prevData.disclaimers,
            welcomePip: true,
          },
          workspaceContext: {
            ...prevData.workspaceContext,
            teams: prevData.workspaceContext.teams.map((team) => {
              if (team._id === currentTeam._id) {
                return {
                  ...team,
                  toolsSettings: {
                    ...team.toolsSettings,
                    pipEnabled: false,
                  },
                };
              }
              return team;
            }),
          },
        }));
        setDispararWelcomePip(false);
        if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
          ReactGA.event({
            category: 'Onboarding',
            action: 'onboarding_concluido',
            onboarding_title: 'PIP',
          });
          mixpanel.track('onboarding_concluido', {
            category: 'Onboarding',
            company_id: currentUserContextData._idWorkspace[0],
            onboarding_title: 'PIP',
          });
        }
        Swal.fire({
          html: `
            <img src=${SoraPreocupacao} alt='Sora Nerd' class='mb-3'/><br/>
            Entendo. A PIP foi desabilitada.
          `,
          showCancelButton: false,
          confirmButtonColor: defaultTheme.colors.primary.main,
          confirmButtonText: 'Voltar para o App',
          customClass: {
            confirmButton: 'swal2-button-full-width',
          },
        });
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      console.log('cancel');
      setIsLoading(true);
      try {
        await api.patch('/user/disclaimer', { welcomePip: true });
        setCurrentUserContextData((prevData) => ({
          ...prevData,
          disclaimers: {
            ...prevData.disclaimers,
            welcomePip: true,
          },
        }));
        Swal.fire({
          html: swalHtmlMessagePipEnabled(currentPipCardStatus.status),
          showCloseButton: true,
          closeButtonHtml: '&times;',
          showCancelButton: false,
          confirmButtonColor: defaultTheme.colors.primary.main,
          confirmButtonText: 'Copiar mensagem',
          customClass: {
            confirmButton: (currentPipCardStatus.status === 'Agendada' || currentPipCardStatus.status === 'Em andamento' ? 'swal2-button-full-width' : 'd-none'),
          },
          preConfirm: () => {
            textToClipboard(mensagemConvitePip);
            console.log('copiado');
            // Retorna falso para evitar o fechamento do alert automaticamente
            return false;
          },
        });
        if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
          ReactGA.event({
            category: 'Onboarding',
            action: 'onboarding_concluido',
            onboarding_title: 'PIP',
          });
          mixpanel.track('onboarding_concluido', {
            category: 'Onboarding',
            company_id: currentUserContextData._idWorkspace[0],
            onboarding_title: 'PIP',
          });
        }
        // redirect user to /userSpace route
        // history.push('/userSpace');
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  const handleWelcomeDone = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await api.patch('/user/disclaimer', { welcomePip: true });
      setCurrentUserContextData((prevData) => ({
        ...prevData,
        disclaimers: {
          ...prevData.disclaimers,
          welcomePip: true,
        },
      }));
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Onboarding',
          action: 'onboarding_concluido',
          onboarding_title: 'PIP',
        });
        mixpanel.track('onboarding_concluido', {
          category: 'Onboarding',
          company_id: currentUserContextData._idWorkspace[0],
          onboarding_title: 'PIP',
        });
      }
      // redirect user to /userSpace route
      // history.push('/userSpace');
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  // const youtubeVideoLink = 'https://www.youtube.com/embed/-DVsjjJzDw8';
  // const handleProgress = (state) => {
  //   const playedPercentage = state.played * 100;
  //   if (playedPercentage >= 70 && playedPercentage < 71) {
  //     console.log('A reprodução atingiu 70%');
  //     if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
  //       ReactGA.event({
  //         category: 'BoasVindas',
  //         action: 'assistir_video_boasvindas',
  //       });
  //       mixpanel.track('assistir_video_boasvindas', {
  //         category: 'BoasVindas',
  //       });
  //     }
  //   }
  // };

  return (
    <WelcomeContainer>
      <WelcomeBody>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoHeight
          style={{ zIndex: 0 }}
          pagination={{
            clickable: true,
            el: '.swiper-pagination',
          }}
          className="swiper-goals-tracker"
          onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
          navigation={{
            prevEl: '.prev',
            nextEl: '.next',
          }}
        >
          <SwiperSlide>
            <Container>
              <Row style={{ height: 'calc(100dvh - 110px)', marginTop: 10 }}>
                <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start">
                  {currentUserContextData.type === 'Líder'
                    ? (
                      <div className="px-2 text-center w-100 pt-3">
                        <WelcomeImage src={welcomePipLider1} alt="Welcome 1" className="mb-4" />
                        <h4 className="bold" style={{ color: defaultTheme.colors.primary.main }}>PIP - Pesquisa para Impacto Positivo</h4>
                        <p className="mb-2">
                          A PIP é a pesquisa recorrente da Persora que acompanha continuamente
                          a equipe em quatro dimensões:
                          {' '}
                          <b>indivíduo</b>
                          ,
                          {' '}
                          <b>liderança</b>
                          ,
                          {' '}
                          <b>time</b>
                          {' '}
                          e
                          {' '}
                          <b>regras do jogo</b>
                          .
                        </p>
                        <PersoraButton
                          tamanho="condensed"
                          estilo="outline"
                          onClick={() => setPipQuestionsDemoSheetOpen(true)}
                          style={{ margin: '0 auto' }}
                          className="mt-3 px-3"
                        >
                          <span style={{ marginRight: '-5px' }}>
                            <QuestionCircle tamanho={24} />
                          </span>
                          O que o meu time responde?
                        </PersoraButton>
                      </div>
                    )
                    : (
                      <div className="px-2 text-center w-100">
                        <WelcomeImage src={welcomePipLider1} alt="Welcome 1" className="mb-4" />
                        <h4 className="bold" style={{ color: defaultTheme.colors.primary.main }}>PIP - Pesquisa para Impacto Positivo</h4>
                        <p className="mb-2">
                          A PIP é a voz do time! É uma pesquisa quinzenal que acompanha
                          {' '}
                          continuamente a equipe em quatro dimensões:
                          {' '}
                          <b>indivíduo</b>
                          ,
                          {' '}
                          <b>liderança</b>
                          ,
                          {' '}
                          <b>time</b>
                          {' '}
                          e
                          {' '}
                          <b>regras do jogo</b>
                          .
                        </p>
                      </div>
                    )}
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
          <SwiperSlide>
            <Container>
              <Row style={{ height: 'calc(100dvh - 110px)', marginTop: 10 }}>
                <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start">
                  {currentUserContextData.type === 'Líder'
                    ? (
                      <div className="px-2 text-center w-100 pt-3">
                        <WelcomeImage src={welcomePipLider2} alt="Welcome 1" className="mb-4" />
                        <h4 className="bold" style={{ color: defaultTheme.colors.primary.main }}>Com base em como time está, você acessa um plano de ação</h4>
                        <p className="mb-2">
                          Além de entregar dados sobre como o time está, com
                          {' '}
                          base nos resultados, a Sora personaliza orientações
                          {' '}
                          para a liderança sobre &#34;o que fazer&#34;.
                        </p>
                      </div>
                    )
                    : (
                      <div className="px-4 text-center w-100">
                        <WelcomeImage src={welcomePipLider4} alt="Welcome 1" className="mb-4" />
                        <h4 className="bold" style={{ color: defaultTheme.colors.primary.main }}>
                          Sem comprometer o anonimato, o seu líder acessa um plano de ação.
                        </h4>
                        <p className="mb-2">
                          Com base nos resultados, a Sora personaliza orientações
                          {' '}
                          para ajudar a liderança a agir nas necessidades reais do time.
                        </p>
                        <p className="mb-2">
                          <b>Uma premissa nossa: nunca expor ninguém!</b>
                        </p>
                      </div>
                    )}
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
          <SwiperSlide>
            <Container>
              <Row style={{ height: 'calc(100dvh - 110px)', marginTop: 10 }}>
                <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start">
                  {currentUserContextData.type === 'Líder'
                    ? (
                      <div className="px-2 text-center w-100 pt-3">
                        <h4 className="bold" style={{ color: defaultTheme.colors.primary.main }}>Como funciona a PIP</h4>
                        <ListItensWithBlueBg className="my-4">
                          <div className="emoji-paragraph">
                            <span>📅</span>
                            <p className="mb-3">
                              É disparada automaticamente a cada 2 semanas.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>📢</span>
                            <p className="mb-3">
                              Fica disponível para resposta durante 2 dias.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>😊</span>
                            <p className="mb-3">
                              Aborda as percepções individuais com base nas últimas duas semanas.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>🥷</span>
                            <p className="mb-3">
                              É anônima e só gera resultado a partir de três respostas efetivas.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>😎</span>
                            <p className="mb-3">
                              A liderança nunca responde a PIP do time que lidera.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>😌</span>
                            <p className="mb-3">
                              É possível não responder por desconforto ou ausência.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>📊</span>
                            <p className="mb-3">
                              Apenas a liderança acessa o resultado completo.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>🚫</span>
                            <p className="mb-2">
                              O resultado não deve ser utilizado como avaliações de desempenho.
                            </p>
                          </div>
                        </ListItensWithBlueBg>
                      </div>
                    )
                    : (
                      <div className="px-4 text-center w-100">
                        <h4 className="bold" style={{ color: defaultTheme.colors.primary.main }}>Como funciona a PIP</h4>
                        <ListItensWithBlueBg className="my-4">
                          <div className="emoji-paragraph">
                            <span>📅</span>
                            <p className="mb-3">
                              É disparada automaticamente a cada 2 semanas.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>⚡</span>
                            <p className="mb-3">
                              Leva 1 min para responder e fica disponível durante 2 dias.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>🥷</span>
                            <p className="mb-3">
                              É anônima e só gera resultado a partir de três respostas efetivas.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>😌</span>
                            <p className="mb-3">
                              É possível não responder por desconforto ou ausência.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>📊</span>
                            <p className="mb-3">
                              O time acessa o resumo do resultado.
                            </p>
                          </div>
                          <div className="emoji-paragraph">
                            <span>🚫</span>
                            <p className="mb-2">
                              Não tem conexão com as avaliações de desempenho.
                            </p>
                          </div>
                        </ListItensWithBlueBg>
                      </div>
                    )}
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
          <SwiperSlide>
            <Container>
              <Row style={{ height: 'calc(100dvh - 110px)', marginTop: 10 }}>
                <Col md={{ span: 10, offset: 1 }} className="d-flex align-items-start">
                  {currentUserContextData.type === 'Líder' ? (
                    <div className="px-2 text-center w-100 pt-3">
                      <WelcomeImage src={welcomePipLider3} alt="Welcome 1" className="mb-4" />
                      <p className="mb-2">
                        Você acessa detalhes sobre a PIP sempre
                        <br />
                        que desejar em
                        <span className="ps-1">
                          <Cardiology />
                          {' '}
                          <b>Indicadores.</b>
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div className="px-2 text-center w-100 pt-3">
                      <WelcomeImage src={welcomePipLider5} alt="Welcome 1" className="mb-4" />
                      <p className="mb-2">
                        Você acessa detalhes sobre a PIP sempre
                        <br />
                        que desejar em
                        <span className="ps-1">
                          <Cardiology />
                          {' '}
                          <b>Indicadores.</b>
                        </span>
                      </p>
                      <p>
                        Para manter sua
                        {' '}
                        <b>voz ativa</b>
                        {' '}
                        e não perder nenhuma PIP,
                        {' '}
                        <b>habilite as notificações</b>
                        {' '}
                        da Persora no seu celular.
                      </p>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
        </Swiper>
      </WelcomeBody>
      {currentUserContextData.type === 'Líder'
        ? (
          <WelcomeFooter style={{ background: 'none' }}>
            <PaginationDiv style={{ paddingTop: 10 }}>
              <div style={{ marginTop: (currentSlide === 3 ? '-40px' : 10) }}>
                <div className={`swiper-pagination swiper-pagination-oboarding-pip ${currentSlide === 3 && 'd-none'}`} />
              </div>
            </PaginationDiv>
            <ButtonsDiv>
              {currentSlide === 3 && (
                <div style={{
                  flexDirection: 'row', width: '100%',
                }}
                >

                  <PersoraButton
                    className="gtm-boasVindas-concluir mt-0 mb-2"
                    onClick={handleEnablePip}
                    disabled={isLoading}
                    value="concluir"
                  >
                    {isLoading ? <Clock cor="#FFF" /> : 'Entendi'}
                  </PersoraButton>
                  <PersoraButton
                    className="gtm-boasVindas-concluir mt-0 mb-5"
                    onClick={handleDisablePip}
                    disabled={isLoading}
                    value="concluir"
                    estilo="ghost"
                  >
                    {isLoading ? <Clock cor="#FFF" /> : 'Não quero habilitar agora'}
                  </PersoraButton>
                </div>
              )}
              <Left style={{ display: 'flex', flexDirection: 'column' }}>
                {currentSlide > 0 && currentSlide <= 2 && (
                <PersoraButton
                  tamanho="mini"
                  estilo="ghost"
                  style={{ color: defaultTheme.colors.primary.main, fontWeight: 600 }}
                  disabled={isLoading}
                  className="prev px-2 sourcesans d-none"
                  value="Voltar"
                >
                  {isLoading ? 'Aguarde...' : 'Voltar'}
                </PersoraButton>
                )}
              </Left>
              <div>
                {/* add class name d-none to button .next if slide < 2 */}
                <PersoraButton className={`next ${currentSlide > 2 && 'd-none'}`} style={{ borderRadius: 50 }} justIcon>
                  <ArrowRight cor="#FFF" />
                </PersoraButton>
                <PersoraButton
                  className={`next ${currentSlide !== 3 ? 'd-none' : null} d-none`}
                  onClick={handleWelcomeDone}
                  style={{ borderRadius: 50 }}
                  justIcon
                  disabled={isLoading}
                >
                  {isLoading ? <Clock cor="#FFF" /> : <ArrowRight cor="#FFF" />}
                  a
                </PersoraButton>
              </div>
            </ButtonsDiv>
          </WelcomeFooter>
        )
        : (
          <WelcomeFooter>
            <PaginationDiv>
              <div>
                <div className={`swiper-pagination swiper-pagination-oboarding-pip ${currentSlide === 3 && 'd-none'}`} />
              </div>
            </PaginationDiv>
            <ButtonsDiv>
              {currentSlide === 3 && (
              <PersoraButton
                className="gtm-boasVindas-concluir mt-0"
                onClick={handleWelcomeDone}
                disabled={isLoading}
                value="concluir"
              >
                {isLoading ? <Clock cor="#FFF" /> : 'Entendi'}
              </PersoraButton>
              )}
              <Left style={{ display: 'flex', flexDirection: 'column' }}>
                {currentSlide > 0 && currentSlide <= 2 && (
                <PersoraButton
                  tamanho="mini"
                  estilo="ghost"
                  style={{
                    color: defaultTheme.colors.primary.main, fontWeight: 600,
                  }}
                  disabled={isLoading}
                  className="prev px-2 sourcesans d-none"
                  value="Voltar"
                >
                  {isLoading ? 'Aguarde...' : 'Voltar'}
                </PersoraButton>
                )}
              </Left>
              <div>
                {/* add class name d-none to button .next if slide < 2 */}
                <PersoraButton className={`next ${currentSlide > 2 && 'd-none'}`} style={{ borderRadius: 50 }} justIcon>
                  <ArrowRight cor="#FFF" />
                </PersoraButton>
                <PersoraButton
                  className={`next ${currentSlide !== 3 ? 'd-none' : null} d-none`}
                  onClick={handleWelcomeDone}
                  style={{ borderRadius: 50 }}
                  justIcon
                  disabled={isLoading}
                >
                  {isLoading ? <Clock cor="#FFF" /> : <ArrowRight cor="#FFF" />}
                </PersoraButton>
              </div>
            </ButtonsDiv>
          </WelcomeFooter>
        )}

      <Sheet
        isOpen={pipQuestionsDemoSheetIsOpen}
        onClose={() => setPipQuestionsDemoSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="full-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
      >
        <PipQuestionsDemo setPipQuestionsDemoSheetOpen={setPipQuestionsDemoSheetOpen} />
        <Sheet.Backdrop onTap={() => setPipQuestionsDemoSheetOpen(false)} />
      </Sheet>
    </WelcomeContainer>
  );
}
WelcomePip.propTypes = {
  currentTeam: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }),
  setDispararWelcomePip: PropTypes.func.isRequired,
};
WelcomePip.defaultProps = {
  currentTeam: {},
};
