import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import api from '../../services/backend';
import { formatDateToInputField } from '../../utils/dateUtils';

export default function HandbookTab({ userSettings }) {
  const handleInputChage = async (e) => {
    // try catch patch na rota user/updateSettings com body { [e.target.name]: e.target.value }
    try {
      if (e.target.name === 'birthday') {
        if (e.target.value === '') {
          const body = {
            birthday: '',
          };
          await api.patch('/user/updateSettings', body);
          return;
        }
        const date = new Date(e.target.value);
        const isoDate = date.toISOString();
        const body = {
          birthday: isoDate,
        };
        await api.patch('/user/updateSettings', body);
      } else if (e.target.name === 'birthdayFelicitations') {
        const body = {
          birthdayFelicitations: e.target.checked,
        };
        await api.patch('/user/updateSettings', body);
      } else {
        const body = {
          [e.target.name]: e.target.value,
        };
        await api.patch('/user/updateSettings', body);
      }
      if (e.target.dataset.success) {
        toast.success(e.target.dataset.success, {
          autoClose: 3000,
          position: 'top-center',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleControlInputStyle = (e) => {
    // se tiver valor, inclui className handbookFormControlFilled
    // se não tiver valor, remove className handbookFormControlFilled
    if (e.target.value) {
      e.target.classList.add('handbookFormControlFilled');
    } else {
      e.target.classList.remove('handbookFormControlFilled');
    }
    // addclass to <p> after input
    if (e.target.value) {
      e.target.nextSibling.classList.add('d-none');
    } else {
      e.target.nextSibling.classList.remove('d-none');
    }
  };

  return (
    <div className="mt-4">
      <Form.Group>
        <Form.Label>
          Como gosto de ser chamado
          {' '}
          <span> • Um apelido? Sobrenome?</span>
        </Form.Label>
        <Form.Control
          type="text"
          className={`mb-1 ${userSettings && userSettings.nickname ? 'handbookFormControlFilled' : ''}`}
          name="nickname"
          onChange={handleControlInputStyle}
          onBlur={handleInputChage}
          data-success="Seu nickname foi atualizado!"
          defaultValue={userSettings && userSettings.nickname ? userSettings.nickname : ''}
          autoComplete="off"
        />
        <p className={`mb-0 small ${userSettings && userSettings.nickname ? 'd-none' : ''}`}><i>Ex.: Duda</i></p>
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label>
          Data de nascimento
        </Form.Label>
        <Form.Control
          type="date"
          className={
            `mb-1 ${userSettings && userSettings.birthday ? 'handbookFormControlFilled' : ''}`
          }
          name="birthday"
          onChange={handleControlInputStyle}
          onBlur={handleInputChage}
          data-success="Sua data de nascimento foi atualizada!"
          defaultValue={
            userSettings
            && userSettings.birthday
            && userSettings.birthday !== 'NaN/NaN/NaN'
              ? formatDateToInputField(userSettings.birthday)
              : ''
          }
          autoComplete="off"
        />
        <p className="mb-0 small" />
      </Form.Group>
      <Form.Group className="mt-2">
        <Form.Check
          type="checkbox"
          label={(
            <span>
              Gostaria de receber felicitações
              <br />
              no dia do meu aniversário
            </span>
          )}
          className="mb-1"
          id="birthdayFelicitations"
          name="birthdayFelicitations"
          onChange={handleInputChage}
          data-success="Sua preferência foi atualizada!"
          autoComplete="off"
          defaultChecked={userSettings && userSettings.birthdayFelicitations === true}
        />
        <p className="mb-0 small" />
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label>
          Localização
          {' '}
          <span> • Onde eu moro</span>
        </Form.Label>
        <Form.Control
          type="text"
          className={`mb-0 mb-1 ${userSettings && userSettings.location ? 'handbookFormControlFilled' : ''}`}
          name="location"
          defaultValue={userSettings && userSettings.location ? userSettings.location : ''}
          onChange={handleControlInputStyle}
          onBlur={handleInputChage}
          data-success="Sua localização foi atualizada!"
          autoComplete="off"
        />
        <p
          className={`mb-0 small ${userSettings && userSettings.location ? 'd-none' : ''}`}
        >
          <i>
            Ex.: Vitória, ES. Brasil.
          </i>
        </p>
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label>
          Modelo de trabalho
          {' '}
          <span> • Remoto, presencial ou híbrido</span>
        </Form.Label>
        {/* dropdown */}
        <Form.Select
          as="select"
          className={`mb-1 ${userSettings && userSettings.workModel ? 'handbookFormControlFilled' : ''}`}
          name="workModel"
          defaultValue={userSettings && userSettings.workModel ? userSettings.workModel : ''}
          onChange={handleControlInputStyle}
          onBlur={handleInputChage}
          data-success="Seu modelo de trabalho foi atualizado!"
          autoComplete="off"
        >
          <option value="">{' '}</option>
          <option value="Remoto">Remoto</option>
          <option value="Presencial">Presencial</option>
          <option value="Híbrido">Híbrido</option>
        </Form.Select>
        <p className="mb-0 small" />
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label>
          Cargo
          {' '}
          <span> • Minha função</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          className={`mb-1 ${userSettings && userSettings.role ? 'handbookFormControlFilled' : ''}`}
          name="role"
          defaultValue={userSettings && userSettings.role ? userSettings.role : ''}
          onChange={handleControlInputStyle}
          onBlur={handleInputChage}
          data-success="Seu cargo foi atualizado!"
          autoComplete="off"
        />
        <p className={`mb-0 small ${userSettings && userSettings.role ? 'd-none' : ''}`}><i>Ex.: Designer e Desenvolvedor Frontend</i></p>
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label>
          Atuação
          {' '}
          <span> • O que melhor descreve o que eu faço</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder=""
          className={`mb-1 ${userSettings && userSettings.roleDescription ? 'handbookFormControlFilled' : ''}`}
          name="roleDescription"
          defaultValue={userSettings && userSettings.roleDescription ? userSettings.roleDescription : ''}
          onChange={handleControlInputStyle}
          onBlur={handleInputChage}
          data-success="A descrição da sua atuação foi atualizada!"
          autoComplete="off"
        />
        <p
          className={`mb-0 small ${userSettings && userSettings.roleDescription ? 'd-none' : ''}`}
        >
          <i>
            Ex.: Resposável sobretudo pelo design e
            implementação das aplicações digitais da Persora...
          </i>
        </p>
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label>
          Sobre mim
          {' '}
          <span> • Algumas coisas que precisam saber</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          className={`mb-1 ${userSettings && userSettings.aboutMe ? 'handbookFormControlFilled' : ''}`}
          name="aboutMe"
          defaultValue={userSettings && userSettings.aboutMe ? userSettings.aboutMe : ''}
          onChange={handleControlInputStyle}
          onBlur={handleInputChage}
          data-success="O texto sobre você foi atualizado!"
          autoComplete="off"
        />
        <p className={`mb-0 small ${userSettings && userSettings.aboutMe ? 'd-none' : ''}`}>
          <i>
            Ex.: O que eu mais gosto no meu trabalho...
            Eu realmente gosto quando...
            Não gosto quando as pessoas...
          </i>
        </p>
      </Form.Group>
      <Form.Group className="mt-4">
        <Form.Label>
          No meu tempo livre
          {' '}
          <span> • Algumas coisas que eu amo fazer</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          className={`mb-1 ${userSettings && userSettings.OutOfWork ? 'handbookFormControlFilled' : ''}`}
          name="OutOfWork"
          defaultValue={userSettings && userSettings.OutOfWork ? userSettings.OutOfWork : ''}
          onChange={handleControlInputStyle}
          onBlur={handleInputChage}
          data-success="O texto sobre o que você faz no seu tempo livre foi atualizado!"
          autoComplete="off"
        />
        <p className={`mb-0 small ${userSettings && userSettings.OutOfWork ? 'd-none' : ''}`}>
          <i>
            Ex.: Aos finais de semana, gosto de...
            Sempre que posso, faço uma viagem para...
            Sou apaixonado por...
          </i>
        </p>
      </Form.Group>
    </div>
  );
}

HandbookTab.propTypes = {
  userSettings: PropTypes.shape({
    profilePictureUrl: PropTypes.string,
    nickname: PropTypes.string,
    birthday: PropTypes.string,
    birthdayFelicitations: PropTypes.bool,
    location: PropTypes.string,
    workModel: PropTypes.string,
    role: PropTypes.string,
    roleDescription: PropTypes.string,
    aboutMe: PropTypes.string,
    OutOfWork: PropTypes.string,
    phone: PropTypes.string,
  }),
};
HandbookTab.defaultProps = {
  userSettings: {},
};
