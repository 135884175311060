/* eslint-disable max-len */
import { Sheet } from 'react-modal-sheet';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { toast } from 'react-toastify';
import api from '../../services/backend';
import defaultTheme from '../../assets/styles/themes/default';
import PersoraButton from '../../components/Button';
import {
  Check, ChevronSmallLeft, Clock, Cross,
} from '../../components/DuotoneIcon';
import { UserContext } from '../../Context/UserContext';

const StyledPremiumDiv = styled.div`
  background: ${defaultTheme.colors.secondary.light};
  color: ${defaultTheme.colors.primary.main};
  border-radius: 13px;
  padding: 24px 20px;
`;
const StyledDiv = styled.div`
  width: 100%;
  background: ${defaultTheme.colors.black10};
  color: ${defaultTheme.colors.black60};
  box-shadow: none;
  border: none;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Source Sans 3', sans-serif;
  transition: all 0.2s ease-in-out;
  height: fit-content;
  text-align: left;
  padding: 24px 20px;
  position: relative;
  &.premium {
    background: linear-gradient(135deg,  rgba(34,59,89,1) 1%,rgba(33,131,166,1) 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    padding: 3px;
  }
  p {
    &.tag {
      font-size: .75rem;
      background: ${defaultTheme.colors.black20};
      width: fit-content;
      text-transform: uppercase;
      font-weight: 700;
      padding: 4px 10px;
      border-radius: 8px;
      letter-spacing: .04rem;
      margin-bottom: 8px;
      &.premium {
        background: ${defaultTheme.colors.alerts.info.background};
      }
    }
    &.plano {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 0;
    }
    &.descricao {
      font-size: 1rem;
      line-height: .8rem;
      margin-bottom: .45rem;
    }
    &.valor {
      font-size: 1.1rem;
      text-align: right;
      line-height: 1.1rem;
      margin-bottom: 0;
    }
    &.premium-text {
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#223b59+0,2183a6+62 */
      background: linear-gradient(135deg,  rgba(34,59,89,1) 0%,rgba(33,131,166,1) 62%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;
const PremiumCheck = styled.div`
  position: absolute;
  right: -12px;
  margin-top: -15px;
  background: linear-gradient(135deg,  rgba(34,59,89,1) 0%,rgba(33,131,166,1) 62%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  border-radius: 30px;
`;
const PremiumEmBreve = styled.div`
  position: absolute;
  margin-top: -6px;
  right: 24px;
  font-size: .75rem;
  width: fit-content;
  font-weight: 600;
  padding: 4px 10px;
  border-radius: 8px;
  margin-bottom: 8px;
  background: linear-gradient(135deg,  rgba(34,59,89,1) 0%,rgba(33,131,166,1) 62%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  color: #FFF;
`;

export default function PremiumPrices({
  setPremiumPricesSheetOpen,
  setPremiumAboutSheetOpen,
  exceededLimitVersion,
}) {
  const [premiumRequested, setPremiumRequested] = useState(false);
  const [premiumMessage, setPremiumMessage] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);

  const { currentUserContextData } = useContext(UserContext);

  const handlePremiumMessage = (event) => {
    setPremiumMessage(event.target.value);
  };

  const handlePremiumRequested = async () => {
    if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
      ReactGA.event({
        category: 'Premium',
        action: 'premium_solicitado',
      });
      mixpanel.track('premium_solicitado', {
        category: 'Premium',
        company_id: currentUserContextData._idWorkspace[0],
      });
    }
    setBtnLoading(true);
    try {
      // define o body da requisição
      const body = premiumMessage
        ? {
          idWorkspace: currentUserContextData._idWorkspace,
          message: premiumMessage,
        }
        : {
          idWorkspace: currentUserContextData._idWorkspace,
        };
      if (premiumMessage) {
        const { data } = await api.post('/workspace/requestPremium', body);
        console.log(data);
        toast.success('Sua mensagem foi enviada com sucesso.', {
          autoClose: 3000,
          position: 'top-center',
        });
      }
      setPremiumAboutSheetOpen(false);
      if (premiumRequested) {
        setPremiumPricesSheetOpen(false);
      }
      setPremiumRequested(true);
      setBtnLoading(false);
    } catch (error) {
      // console.error('Cannot authenticate');
      setBtnLoading(false);
      // toast.error('Falha ao solicitar o premium.', {
      toast.error('Algo não saiu como o esperado.', {
        autoClose: 3000,
        position: 'top-center',
      });
    }
  };

  return (
    <Sheet.Container>
      {!exceededLimitVersion
        ? (
          <Sheet.Header className="pt-3 pb-2">
            <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setPremiumPricesSheetOpen(false)}>
              <ChevronSmallLeft tamanho={32} />
            </PersoraButton>
          </Sheet.Header>
        )
        : (
          <Sheet.Header className="pt-3 pb-0 px-2" style={{ display: 'flex', justifyContent: 'end' }}>
            <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setPremiumPricesSheetOpen(false)}>
              <Cross />
            </PersoraButton>
          </Sheet.Header>
        )}
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: 0,
              color: defaultTheme.colors.primary.light,
            }}
          >
            {/* {premiumRequested
            && (
              <>
                <h2 className="premium-text mt-2 sourcesans" style={{ olor: defaultTheme.colors.primary.main }}>
                  Parabéns!
                </h2>
                <h5 className="sourcesans semibold mb-3">Ativação Premium solicitada com sucesso.</h5>
                <p className="mb-2">Em poucos minutos, você poderá inserir toda a sua equipe no app.</p>
                <p>Entraremos em contato por e-mail para orientações sobre pagamento.</p>
                <h5 className="sourcesans semibold mt-4 mb-2">Fale conosco</h5>
                <p>Se ficou dúvidas, pode registrar nesse campo que o nosso time te auxiliará.</p>
                <Form.Group className="mb-5">
                  <Form.Control
                    as="textarea"
                    placeholder="Opcional"
                    style={{ height: '90px' }}
                    defaultValue=""
                    name="msg"
                    onChange={handlePremiumMessage}
                  />
                </Form.Group>
                {!premiumMessage
                  ? (
                    <PersoraButton className="mt-4 mb-3" onClick={() => setPremiumPricesSheetOpen(false)}>
                      Entendi
                    </PersoraButton>
                  )
                  : (
                    <PersoraButton className="mt-4 mb-3" onClick={handlePremiumRequested} disabled={btnLoading}>
                      {btnLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Enviar dúvida'}
                    </PersoraButton>
                  )}
              </>
            )} */}
            {premiumRequested
            && (
              <>
                <h2 className="premium-text mt-2 sourcesans" style={{ olor: defaultTheme.colors.primary.main }}>
                  Dúvidas ou sugestões
                </h2>
                <p className="mb-2">A Persora está sendo construída lado a lado com nossos usuários. Suas dúvidas e sugestões são preciosas para a gente e ajudam no nosso crescimento.</p>
                <Form.Group className="mt-3 mb-4">
                  <Form.Control
                    as="textarea"
                    placeholder="Utilize este espaço para escrever..."
                    style={{ height: '90px' }}
                    defaultValue=""
                    name="msg"
                    onChange={handlePremiumMessage}
                  />
                </Form.Group>
                {!premiumMessage
                  ? (
                    <PersoraButton className="mt-4 mb-3" onClick={() => setPremiumPricesSheetOpen(false)} disabled={!premiumMessage}>
                      Enviar
                    </PersoraButton>
                  )
                  : (
                    <PersoraButton className="mt-4 mb-3" onClick={handlePremiumRequested} disabled={btnLoading}>
                      {btnLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Enviar'}
                    </PersoraButton>
                  )}
              </>
            )}
            {premiumRequested === false
              && (
              <>
                  {!exceededLimitVersion
                    ? (
                      <h2 className="premium-text mt-2 mb-4 sourcesans" style={{ lineHeight: '1.9rem' }}>
                        Lidere com a Persora
                        <br />
                        {' '}
                        ao seu lado
                      </h2>
                    )
                    : (
                      <h2 className="premium-text mt-0 sourcesans" style={{ lineHeight: '1.9rem' }}>
                        Você já utilizou todos os
                        <br />
                        {' '}
                        seus créditos.
                      </h2>
                    )}
                {exceededLimitVersion && <p className="mb-4 pb-3">Assine o Premium e adicione todo o seu time.</p>}
                <StyledDiv className="mb-4">
                  <p className="tag">Até 5 usuários</p>
                  <p className="plano">Gratuito</p>
                  <p className="descricao">1 Líder + 4 Liderados</p>
                  <p className="valor">R$ 0 usuário/mês</p>
                </StyledDiv>
                <StyledDiv className="mb-2 premium">
                  <PremiumCheck className="d-none">
                    <Check cor="#FFFFFF" tamanho={20} />
                  </PremiumCheck>
                  <StyledPremiumDiv>
                    <PremiumEmBreve>
                      Em breve
                    </PremiumEmBreve>
                    <p className="tag premium">6+ usuários</p>
                    <p className="plano premium-text">Premium</p>
                    <p className="valor">R$ 40 usuário/mês</p>
                  </StyledPremiumDiv>
                </StyledDiv>
                <p className="text-center mb-5" style={{ color: defaultTheme.colors.primary.main }}>
                  Cobrança recorrente. Cancele quando quiser.
                </p>
                <p className="small" style={{ color: defaultTheme.colors.primary.light }}>O valor mensal será determinado pelo número total de usuários na equipe.</p>
                <PersoraButton className="mt-3 mb-3" onClick={handlePremiumRequested} disabled={btnLoading}>
                  {btnLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Enviar dúvidas ou sugestões'}
                </PersoraButton>
              </>
              )}
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}

PremiumPrices.propTypes = {
  exceededLimitVersion: PropTypes.bool,
  setPremiumPricesSheetOpen: PropTypes.func.isRequired,
  setPremiumAboutSheetOpen: PropTypes.func,
};
PremiumPrices.defaultProps = {
  exceededLimitVersion: false,
  setPremiumAboutSheetOpen: () => {},
};
