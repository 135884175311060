/* eslint-disable no-unused-vars */

import PropTypes, { oneOf } from 'prop-types';

import {
  CoverContainer, ShapeLayer, SoraLayer,
} from './styles';

import Animada from '../../assets/img/Sora/Animada.svg';
import Chateada from '../../assets/img/Sora/Chateada.svg';
import Comemorando from '../../assets/img/Sora/Comemorando.svg';
import Confusa from '../../assets/img/Sora/Confusa.svg';
import Deslumbrada from '../../assets/img/Sora/Deslumbrada.svg';
import Doente from '../../assets/img/Sora/Doente.svg';
// import Nerd from '../../assets/img/Sora/Nerd.svg';
import Nervosa1 from '../../assets/img/Sora/Nervosa1.svg';
import Nervosa2 from '../../assets/img/Sora/Nervosa2.svg';
import Nervosa3 from '../../assets/img/Sora/Nervosa3.svg';
import OlhosCarinhosos from '../../assets/img/Sora/OlhosCarinhosos.svg';
import OlhosSuplicantes from '../../assets/img/Sora/OlhosSuplicantes.svg';
import Pensativa1 from '../../assets/img/Sora/Pensativa1.svg';
import Pensativa2 from '../../assets/img/Sora/Pensativa2.svg';
import Preocupada from '../../assets/img/Sora/Preocupada.svg';
import RindoSutilmente from '../../assets/img/Sora/RindoSutilmente.svg';
import Surpresa from '../../assets/img/Sora/Surpresa.svg';
import Triste from '../../assets/img/Sora/Triste.svg';

import Admiracao from '../../assets/img/SoraV2/BustoCortado/Admiracao.svg';
import Amor from '../../assets/img/SoraV2/BustoCortado/Amor.svg';
import Comemoracao from '../../assets/img/SoraV2/Busto/Comemoracao.svg';
import Confusao from '../../assets/img/SoraV2/Busto/Confusao.svg';
import Constrangimento from '../../assets/img/SoraV2/BustoCortado/Constrangimento.svg';
import Desapontamento from '../../assets/img/SoraV2/BustoCortado/Desapontamento.svg';
import Deslumbre from '../../assets/img/SoraV2/BustoCortado/Deslumbre.svg';
import Extase from '../../assets/img/SoraV2/BustoCortado/Extase.svg';
import Nerd from '../../assets/img/SoraV2/BustoCortado/Nerd.svg';
import Otimismo from '../../assets/img/SoraV2/BustoCortado/Otimismo.svg';
import Preocupacao from '../../assets/img/SoraV2/BustoCortado/Preocupacao.svg';
import Raiva from '../../assets/img/SoraV2/BustoCortado/Raiva.svg';
import Reflexao from '../../assets/img/SoraV2/BustoCortado/Reflexao.svg';
import Torcida from '../../assets/img/SoraV2/BustoCortado/Torcida.svg';
import Tristeza from '../../assets/img/SoraV2/BustoCortado/Tristeza.svg';
import Vergonha from '../../assets/img/SoraV2/BustoCortado/Vergonha.svg';

/**
 * Componente para renderizar capa dos bumpers
 * @param {number} [props.bg='1']
 * (1: laranja-roxo, 2: verde-amarelo, 3: laranja-vermelho, 4: roxo-vermelho, 5: vermelho-verde).
 * @param {number} [props.shape='1']
 * (1: elípses, 2: descontruidos, 3: dinamico, 4: triangulo, 5: organico).
 * @param {string} [props.sora='Nerd']
 * ('Animada', 'Chateada', 'Comemorando', 'Confusa', 'Deslumbrada', 'Doente', 'Nerd',
 * 'Nervosa1', 'Nervosa2', '', '', '',  )
 * @returns {JSX.Element} Elemento JSX representando SoraBumperCover.
*/

function SoraBumperCover({ bg, shape, sora }) {
  const soraImages = {
    Animada,
    Chateada,
    Comemorando,
    Confusa,
    Deslumbrada,
    Doente,
    Nerd,
    Nervosa1,
    Nervosa2,
    Nervosa3,
    OlhosCarinhosos,
    OlhosSuplicantes,
    Pensativa1,
    Pensativa2,
    Preocupada,
    RindoSutilmente,
    Surpresa,
    Triste,
    Admiracao,
    Amor,
    Comemoracao,
    Confusao,
    Constrangimento,
    Desapontamento,
    Deslumbre,
    Extase,
    Otimismo,
    Preocupacao,
    Raiva,
    Reflexao,
    Torcida,
    Tristeza,
    Vergonha,
  };
  return (
    <CoverContainer bg={bg}>
      <ShapeLayer shape={shape}>
        <SoraLayer>
          <img src={soraImages[sora]} alt={`Sora ${sora}`} />
        </SoraLayer>
      </ShapeLayer>
    </CoverContainer>
  );
}
SoraBumperCover.propTypes = {
  bg: PropTypes.number,
  shape: PropTypes.number,
  sora: PropTypes.oneOf([
    'Animada',
    'Chateada',
    'Comemorando',
    'Confusa',
    'Deslumbrada',
    'Doente',
    'Nerd',
    'Nervosa1',
    'Nervosa2',
    'Nervosa3',
    'OlhosCarinhosos',
    'OlhosSuplicantes',
    'Pensativa1',
    'Pensativa2',
    'Preocupada',
    'RindoSutilmente',
    'Surpresa',
    'Triste',
    'Admiracao',
    'Amor',
    'Comemoracao',
    'Confusao',
    'Constrangimento',
    'Desapontamento',
    'Deslumbre',
    'Extase',
    'Otimismo',
    'Preocupacao',
    'Raiva',
    'Reflexao',
    'Torcida',
    'Tristeza',
    'Vergonha',
  ]),
};
SoraBumperCover.defaultProps = {
  bg: 1,
  shape: 1,
  sora: 'Nerd',
};

export default SoraBumperCover;
