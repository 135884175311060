import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% - 2 * ( ${({ theme }) => theme.spaces.screenEdge} ));
  margin-left: ${({ theme }) => theme.spaces.screenEdge};
  background-color: ${({ theme }) => theme.colors.alerts.generic.background};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.alerts.generic.textColor};
  box-shadow: 0px 2px 12px 0px rgba(6, 16, 42, 0.10);
  padding: 1rem;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  h5 {
    font-family: 'Source Sans 3', sans-serif;
    letter-spacing: .025rem;
    color: ${({ theme }) => theme.colors.primary.light};
    font-weight: 600;
    margin-bottom: .8rem;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #fff;
  margin-right: 12px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  svg {
    width: 24px;
    height: 24px;
  }
  img {
    width: 40px;
    height: 40px;
  }
`;

export const Title = styled.div`
  width: calc(100% - 70px);
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

// progress div envolve progress steps each step is a div with 60px
export const Progress = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  margin-top: 6px;
  padding-bottom: 6px;

  div {
    width: 24px;
    height: 6px;
    border-radius: 3px;
    background: #FFF;
    margin-right: 6px;
  }
`;

export const Content = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.opacityBlack10};
  padding: ${({ theme }) => theme.spaces.screenEdge};

  ul, ol {
    padding: 0 20px;
    margin-bottom: 3px;
  }
`;
