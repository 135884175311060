/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import { Sheet } from 'react-modal-sheet';
import styled from 'styled-components';
import defaultTheme from '../../assets/styles/themes/default';

const DocContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const SectionLevel = styled.div`
  margin-top: 20px;
`;
const SectionTitle = styled.h5`
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
`;
const SectionContent = styled.div`
  margin-top: 15px;
`;
const I = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;
`;
const INumber = styled.div`
  font-weight: bold;
  text-align:  right;
  width: 30px;
  font-size: .9rem;
`;
const IContent = styled.div`
  text-align:  left;
  width: calc(100% - 30px - 10px);
`;
const SubI = styled.div`
  width: 100%;
  padding-left: 40px;
`;

export default function PrivacyPolicy() {
  return (
    <Sheet.Container>
      <Sheet.Header />
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              minHeight: 400,
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: 0,
              color: defaultTheme.colors.primary.main,
            }}
            className="docsList"
          >
            <h3 className="medium mb-4">
              Política de Privacidade
            </h3>
            <p>Agradecemos por utilizar a Persora!</p>
            <p>A proteção dos seus dados pessoais é uma prioridade para nós. Esta Política de Privacidade descreve como coletamos, usamos, armazenamos e protegemos as informações pessoais dos usuários, em conformidade com a Lei Geral de Proteção de Dados (Lei nº 13.709/2018) do Brasil. Leia com atenção para compreender de que modo iremos coletar e utilizar suas informações.</p>
            <p>Se você não nos fornecer os Dados Pessoais necessários (nós indicaremos quando for o caso, por exemplo, esclarecendo essa informação em nossos formulários de registro), nós talvez não possamos te fornecer nossos produtos e/ou serviços.</p>
            <DocContainer>
              {/* ######## SECTION 1 ######## */}
              <SectionLevel>
                <SectionTitle>1. Quem somos</SectionTitle>
                <SectionContent>
                  <p>A Persora é a copiloto do líder tech, para reduzir as dificuldades que ele normalmente enfrenta no processo de liderança.</p>
                  <p>É uma plataforma Web e Mobile na qual todas as pessoas do time, líderes e liderados, são inseridas. Com uma metodologia própria, a Persora começa a acompanhar todo o time através de mapeamentos recorrentes. Nossos algoritmos processam os dados gerados pelos mapeamentos e cruzam indivíduo, time e líder, e direciona este para agir de forma ágil e personalizada com cada liderado, com o objetivo de construir e fortalecer continuamente um ambiente de segurança psicológica, representado por pessoas pertencentes, motivadas, alinhadas e autônomas, e um time produtivo e inovador.</p>
                  <p>Em determinadas situações, somos os responsáveis pelo Tratamento dos seus Dados Pessoais e determinamos as respectivas finalidades e os meios de tratamento, ou seja, agimos como Controladores.</p>
                  <p>Por isso, abaixo indicamos nossas informações de identificação:</p>
                  <p>
                    <b>PERSORA TECNOLOGIA LTDA</b>
                    <br />
                    CNPJ nº 46.723.016/0001-23
                    <br />
                    Avenida João Baptista Parra, 673 - Praia do Suá, Vitória - ES, 29.052-123
                  </p>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 2 ######## */}
              <SectionLevel>
                <SectionTitle>2. Definições</SectionTitle>
                <SectionContent>

                  <I>
                    <INumber>2.1</INumber>
                    <IContent>Vamos definir os termos utilizados.</IContent>
                  </I>
                  <SubI>
                    <I>
                      <INumber>i.</INumber>
                      <IContent>
                        <b>Dados Pessoais:</b>
                        {' '}
                        são todas as informações que permitem a sua identificação, como, por exemplo, seu nome, e-mail, telefone, entre outros.
                      </IContent>
                    </I>
                    <I>
                      <INumber>ii.</INumber>
                      <IContent>
                        <b>Dados Pessoais Sensíveis:</b>
                        {' '}
                        são dados pessoais relacionados à origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculados a você.
                      </IContent>
                    </I>
                    <I>
                      <INumber>iii.</INumber>
                      <IContent>
                        <b>Terceiros:</b>
                        {' '}
                        refere-se, mas não está limitado, a toda e qualquer pessoa física ou jurídica que nós nos relacionemos ou venhamos a nos relacionar, prestador de serviços, fornecedor, consultor, parceiro, terceiro contratado ou subcontratado.
                      </IContent>
                    </I>
                    <I>
                      <INumber>iv.</INumber>
                      <IContent>
                        <b>Titular:</b>
                        {' '}
                        É a pessoa física a quem os dados pessoais se referem.
                      </IContent>
                    </I>
                  </SubI>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 3 ######## */}
              <SectionLevel>
                <SectionTitle>3. Fontes de Coleta de Dados</SectionTitle>
                <SectionContent>
                  <I>
                    <INumber>3.1</INumber>
                    <IContent>Tratamento de Dados Pessoais significa qualquer operação, como a coleta, produção, recepção, utilização, acesso, distribuição, processamento, armazenamento, eliminação, modificação ou comunicação, realizada com dados pessoais. E, para construirmos e mantermos a nossa relação de confiança, é importante que você tenha ciência sobre como tratamos os seus Dados Pessoais, e, principalmente, sobre quais são eles, como e onde coletamos, conforme segue:</IContent>
                  </I>
                  <SubI>
                    <I>
                      <INumber>i.</INumber>
                      <IContent>
                        <b>Meios de coleta:</b>
                        {' '}
                        (i) Sites que operamos sob nossos próprios domínios/URLs; (ii) aplicativos móveis; (iii) E-mail, mensagens de texto e outras mensagens eletrônicas; (iv) Formulários de registro offline; (v) Interações com anúncios.
                      </IContent>
                    </I>
                    <I>
                      <INumber>ii.</INumber>
                      <IContent>
                        <b>Informações coletadas:</b>
                        {' '}
                        <u>Coletamos informações pessoais fornecidas pelos usuários</u>
                        {' '}
                        durante o cadastro no aplicativo, incluindo, mas não se limitando a nome, e-mail, idade, cargo, número de telefone, Informações financeiras e de pagamento, e demais informações identificáveis. Também podemos coletar dados de uso do aplicativo, como atividades realizadas, preferências e interações com o conteúdo.
                      </IContent>
                    </I>
                    <SubI>
                      <I>
                        <INumber>a.</INumber>
                        <IContent>O acesso aos serviços se dará apenas com códigos aleatórios, como chaves de acesso, dispensando o uso de senhas.</IContent>
                      </I>
                      <I>
                        <INumber>b.</INumber>
                        <IContent>A Persora não coleta ou processa informações de cartão de crédito ou débito (“Cartão de Pagamento”). O nosso processador de pagamentos coleta as informações do Cartão de Pagamento referentes às compras realizadas através dos Websites. Esses processadores de pagamento geralmente nos fornecem algumas informações limitadas relacionadas a você, tais como um token exclusivo que permite que você faça compras adicionais usando as informações que armazenaram e o tipo do seu cartão, data de vencimento, endereço da fatura e quatros últimos dígitos do seu cartão.</IContent>
                      </I>
                    </SubI>
                    <I>
                      <INumber>iii.</INumber>
                      <IContent>
                        <b>Dados sensíveis:</b>
                        {' '}
                        Não coletamos ou processamos dados sensíveis, tais como informações sobre raça, orientação sexual, religião, opiniões políticas, ou outras categorias sensíveis, exceto quando expressamente autorizado pelo titular ou exigido por lei.
                      </IContent>
                    </I>
                    <I>
                      <INumber>iv.</INumber>
                      <IContent><b>Preenchimento da Plataforma:</b></IContent>
                    </I>
                    <SubI>
                      <I>
                        <INumber>a.</INumber>
                        <IContent>O líder apenas incluirá o liderado na equipe (pelo email), de modo que o próprio liderado administrará e alimentará os dados de sua área.</IContent>
                      </I>
                      <SubI>
                        <I>
                          <INumber>a.1.</INumber>
                          <IContent>Os usuários mapearão seu perfil comportamental e o resultado do mapeamento estará disponível de forma completa para o próprio usuário e, em resumo, para os demais usuários. Os dados serão utilizados pela plataforma, para que consiga entregar a personalização necessária para atingir sua proposta de valor.</IContent>
                        </I>
                        <I>
                          <INumber>a.2.</INumber>
                          <IContent>Os usuários personalizarão dados pessoais em uma espécie de “Bio”, na qual poderá ser escrita informações diversas, como modelo de trabalho, atuação, o que não gosta, data de aniversário, e etc. É necessário consciência de que tudo estará disponível para todo o time e poderá ser visto por todos. O compartilhamento de informações desse tipo com o time ajuda na construção de segurança psicológica.</IContent>
                        </I>
                      </SubI>
                      <I>
                        <INumber>b.</INumber>
                        <IContent>Os usuários serão convidados a responder pesquisas e mapeamentos liberados automaticamente pela plataforma com o intuito de coletar dados que ajudem o líder e as demais pessoas do time a construírem segurança psicológica de forma contínua. </IContent>
                      </I>
                      <SubI>
                        <I>
                          <INumber>b.1.</INumber>
                          <IContent>Objetiva-se avaliar como o time está e direcionar o líder a agir pontualmente para melhoria contínua do próprio time por meio de ações claras.</IContent>
                        </I>
                        <I>
                          <INumber>b.2.</INumber>
                          <IContent>Os dados gerados também alimentarão a inteligência da plataforma, que poderá direcionar ações individuais personalizadas ou  gerais, apoiando o líder na melhoria contínua do time.</IContent>
                        </I>
                        <I>
                          <INumber>b.3.</INumber>
                          <IContent>Todas as pesquisas e mapeamentos terão explicação dos seus objetivos, da privacidade envolvida e do tempo demandado para resposta.</IContent>
                        </I>
                      </SubI>
                      <I>
                        <INumber>c.</INumber>
                        <IContent>O líder poderá preencher um Guia de Conduta do time, com informações como propósito e objetivos do time, o que se espera das pessoas no dia a dia, o que não se tolera, e o que os liderados podem esperar do líder.</IContent>
                      </I>
                      <SubI>
                        <I>
                          <INumber>c.1.</INumber>
                          <IContent>Objetiva-se que o time possa melhorar o trabalho em equipe através da segurança psicológica que se obtém com autoconhecimento, conhecimento sobre o outro e o funcionamento do time.</IContent>
                        </I>
                      </SubI>
                    </SubI>
                  </SubI>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 4 ######## */}
              <SectionLevel>
                <SectionTitle>4. Uso das Informações</SectionTitle>
                <SectionContent>
                  <I>
                    <INumber>4.1</INumber>
                    <IContent>Utilizamos as informações coletadas para personalizar a experiência do usuário, conforme explicitado a seguir. Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para cumprir obrigações legais ou com o seu consentimento.</IContent>
                  </I>
                  <SubI>
                    <I>
                      <INumber>i.</INumber>
                      <IContent>
                        <b>Fornecimento de Serviços:</b>
                        {' '}
                        Utilizamos suas informações pessoais para fornecer os serviços que você solicitou, como processamento de pedidos, atendimento ao cliente e personalização da sua experiência.
                      </IContent>
                    </I>
                    <SubI>
                      <I>
                        <INumber>a.</INumber>
                        <IContent>Existem dados que são coletados e direcionados ao líder. Deixaremos você sempre informado como será utilizado o resultado de uma pesquisa ou um questionário que você seja solicitado a responder, e você sempre terá a opção de não responder.</IContent>
                      </I>
                    </SubI>
                    <I>
                      <INumber>ii.</INumber>
                      <IContent>
                        <b>Comunicação:</b>
                        {' '}
                        Podemos usar suas informações para encaminhar comunicações importantes relacionadas com os nossos serviços solicitados, a exemplo: nudges (mensagens curtas que direcionam para uma mudança positiva), atualizações, avisos e alterações em nossos termos e políticas.
                      </IContent>
                    </I>
                    <I>
                      <INumber>iii.</INumber>
                      <IContent>
                        <b>Boletins Informativos ou Outras Comunicações Eletrônicas:</b>
                        {' '}
                        Apenas com seu consentimento, encaminharemos comunicações de marketing, materiais promocionais e ofertas especiais.
                      </IContent>
                    </I>
                    <SubI>
                      <I>
                        <INumber>a.</INumber>
                        <IContent>Se você nos informar que deseja cancelar o recebimento de materiais promocionais por e-mail selecionando “Cancelar inscrição” no final de cada comunicado ou enviando um e-mail para nós pelo suporte@persora.com.br, nós o removeremos da nossa lista de correio. Se você não desejar receber mais notificações push, você pode desativá-las no próprio dispositivo.</IContent>
                      </I>
                    </SubI>
                  </SubI>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 5 ######## */}
              <SectionLevel>
                <SectionTitle>5. Tecnologias De Rastreio</SectionTitle>
                <SectionContent>
                  <I>
                    <INumber>5.1</INumber>
                    <IContent>A Persora e seus parceiros de analítica usam tecnologias como cookies, beacons, tags e scripts para permitir que um serviço reconheça o seu dispositivo para que você não precise fornecer as mesmas informações diversas vezes durante uma tarefa, reconhecer que você já forneceu uma senha de modo que você não precise fornecê-la para cada página web solicitada, e para medir como as pessoas estão usando a Plataforma.</IContent>
                  </I>
                  <I>
                    <INumber>5.2</INumber>
                    <IContent>Usamos armazenamento local, tal como HTML5, para armazenar informações de conteúdo e preferências. Os terceiros com quais fazemos parcerias para providenciar certas funções na Plataforma também usam HTML5 para coletar e armazenar informações. Vários navegadores podem oferecer as suas próprias ferramentas de gestão para remover HTML5.</IContent>
                  </I>
                  <I>
                    <INumber>5.3</INumber>
                    <IContent>Temos parcerias com terceiros tais como o Facebook e Google para gerenciar as propagandas dos Produtos em outros sites ou plataformas, bem como nos seus outros dispositivos, com base em suas visitas anteriores ao nosso site. Nossos parceiros de terceiros podem usar tecnologias tais como cookies para obter informações sobre as suas atividades dentro dos Produtos para apresentar propagandas para você, tais como redirecionamento de anúncios. Obteremos o seu consentimento para usar esses rastreadores para fins de propaganda, conforme exigido pela lei aplicável. Atualmente não respondemos a sinais de não rastrear. Você pode desativar a opção de receber anúncios em diferentes dispositivos ajustando a sua preferência de anúncios em sua conta Google.</IContent>
                  </I>
                  <I>
                    <INumber>5.4</INumber>
                    <IContent>Usamos rastreadores de terceiros para sabermos quando os usuários visitaram a Plataforma ao clicarem em nosso conteúdo ou anúncios patrocinados hospedados em plataformas de terceiros. Os Produtos usam código do Google Analytics para coletar informações estatísticas. O Google Analytics define cookies para nos ajudar a estimar com precisão o número de visitantes dos Produtos e os volumes de uso dos Produtos. Isto é feito para assegurar que os Produtos estejam disponíveis quando você os quiser e sejam rápidos. Para mais informações sobre como o Google Analytics processa essas informações, visite www.google.com/analytics.</IContent>
                  </I>
                  <I>
                    <INumber>5.5</INumber>
                    <IContent>
                      <b>Analítica Móvel</b>
                      <br />
                      Usamos software de analítica móvel para que possamos compreender melhor a funcionalidade do nosso software móvel em seu telefone. Este software pode registrar informações tais como com que frequência você se engaja com os Produtos, os eventos que ocorrem dentro dos Produtos, dados de desempenho e uso agregado, e de onde os Aplicativos são baixados. Podemos cruzar as informações que armazenamos no software de analítica com quaisquer informações pessoais que você submeter no aplicativo móvel.
                    </IContent>
                  </I>
                  <I>
                    <INumber>5.6</INumber>
                    <IContent>
                      <b>Arquivos De Log</b>
                      <br />
                      Usamos software de analítica móvel para que possamos compreender melhor a funcionalidade do nosso software móvel em seu telefone. Este software pode registrar informações tais como com que frequência você se engaja com os Produtos, os eventos que ocorrem dentro dos Produtos, dados de desempenho e uso agregado, e de onde os Aplicativos são baixados. Podemos cruzar as informações que armazenamos no software de analítica com quaisquer informações pessoais que você submeter no aplicativo móvel.
                    </IContent>
                  </I>
                  <SubI>
                    <I>
                      <INumber>i.</INumber>
                      <IContent>
                        Como a maioria dos sites, coletamos certas informações e as armazenamos automaticamente em logs de dados. Essas informações podem incluir endereços de IP (Protocolo de Internet), tipo de navegador, ISP (provedor de serviço de internet), páginas de referência/saída, sistema operacional, marcação de data/hora e/ou dados de clickstream.
                      </IContent>
                    </I>
                    <I>
                      <INumber>ii.</INumber>
                      <IContent>
                        Se você receber a versão do nosso informativo em formato HTML, a sua abertura da newsletter é informada para nós e salva. Os seus clicks nos links do informativo também são salvos. Essas e as estatísticas abertas são usadas de modo agregado para nos dar uma indicação da popularidade do conteúdo e nos ajudar a tomar decisões sobre conteúdo e formatos futuros.
                      </IContent>
                    </I>
                  </SubI>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 6 ######## */}
              <SectionLevel>
                <SectionTitle>6. Armazenamento e Segurança</SectionTitle>
                <SectionContent>
                  <I>
                    <INumber>6.1</INumber>
                    <IContent>Armazenamos suas informações em servidores seguros e adotamos medidas técnicas e organizacionais para proteger seus dados contra acesso não autorizado, uso indevido ou divulgação. Não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes situações:</IContent>
                  </I>
                  <SubI>
                    <I>
                      <INumber>i.</INumber>
                      <IContent>
                        <b>Parceiros de Negócios:</b>
                        {' '}
                        Suas informações poderão ser compartilhadas com nossos parceiros de negócios confiáveis, que nos auxiliam na prestação de serviços, no processamento de pagamentos e envio de produtos.
                      </IContent>
                    </I>
                    <I>
                      <INumber>ii.</INumber>
                      <IContent>
                        <b>Requisitos Legais:</b>
                        {' '}
                        Tal compartilhamento apenas ocorrerá se acreditarmos, de boa-fé, que a divulgação é imprescindível para cumprir as obrigações legais, proteger nossos direitos, resolver disputas ou responder solicitações governamentais.
                      </IContent>
                    </I>
                  </SubI>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 7 ######## */}
              <SectionLevel>
                <SectionTitle>7. Assinatura Única</SectionTitle>
                <SectionContent>
                  <I>
                    <INumber>7.1</INumber>
                    <IContent>Você pode fazer o login em nossa Plataforma usando serviços de assinatura como Facebook, Google ou um fornecedor de Open ID. Esses serviços autenticam a sua identidade, fornecem a opção de compartilhar conosco certas informações pessoais (tais como seu nome e endereço de e-mail), já preenchendo alguns campos do nosso formulário de inscrição. Serviços como Facebook dão a você a opção de postar informações sobre as suas atividades em nossa Plataforma ou em seu perfil para compartilhar com outras pessoas da sua rede de contatos.</IContent>
                  </I>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 8 ######## */}
              <SectionLevel>
                <SectionTitle>8. Retenção dos dados</SectionTitle>
                <SectionContent>
                  <I>
                    <INumber>8.1</INumber>
                    <IContent>Retemos suas informações pelo tempo necessário para cumprir as finalidades descritas nesta Política, a menos que seja exigido por lei o período de retenção mais longo.</IContent>
                  </I>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 9 ######## */}
              <SectionLevel>
                <SectionTitle>9. Segurança de Dados</SectionTitle>
                <SectionContent>
                  <I>
                    <INumber>9.1</INumber>
                    <IContent>Implementamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, uso indevido ou divulgação. Regularmente revisamos e atualizamos nossas práticas de segurança para garantir a proteção contínua dos seus dados.</IContent>
                  </I>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 10 ######## */}
              <SectionLevel>
                <SectionTitle>10. Direitos do Usuário</SectionTitle>
                <SectionContent>
                  <I>
                    <INumber>10.1</INumber>
                    <IContent>De acordo com a LGPD, você tem direitos relacionados às suas informações pessoais, sendo eles:</IContent>
                  </I>
                  <SubI>
                    <I>
                      <INumber>i.</INumber>
                      <IContent>Acessar seus dados pessoais que mantemos armazenados;</IContent>
                    </I>
                    <I>
                      <INumber>ii.</INumber>
                      <IContent>Corrigir suas informações que estiverem imprecisas ou desatualizadas;</IContent>
                    </I>
                    <I>
                      <INumber>iii.</INumber>
                      <IContent>Excluir seus dados pessoais, sujeito a obrigações legais ou regulatórias;</IContent>
                    </I>
                    <I>
                      <INumber>iv.</INumber>
                      <IContent>Revogar o consentimento proferido para o processamento dos seus dados pessoais;</IContent>
                    </I>
                    <I>
                      <INumber>v.</INumber>
                      <IContent>Ter acesso sobre as informações de compartilhamento dos seus dados com terceiros.</IContent>
                    </I>
                  </SubI>
                  <I>
                    <INumber>10.2</INumber>
                    <IContent>Para exercer os direitos acima descritos ou fazer outros questionamentos acerca das nossas práticas de privacidade, basta entrar em contato conosco através dos nossos canais de comunicação. Responderemos à sua solicitação em tempo hábil, conforme exigido pela lei aplicável.</IContent>
                  </I>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 11 ######## */}
              <SectionLevel>
                <SectionTitle>11. Links para Sites de Terceiros</SectionTitle>
                <SectionContent>
                  <I>
                    <INumber>11.1</INumber>
                    <IContent>Nossa plataforma pode, a qualquer momento, conter links para outros produtos de nossas redes, anunciantes e afiliadas parceiras. Se você seguir algum link para esses sites externos, ressaltamos que eles terão as suas próprias políticas de privacidade e não aceitamos responsabilidade de qualquer natureza quanto a esses sites ou suas políticas. Recomendamos verificar as políticas desses sites antes de enviar qualquer informação pessoal.</IContent>
                  </I>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 12 ######## */}
              <SectionLevel>
                <SectionTitle>12. Widgets de Redes Sociais</SectionTitle>
                <SectionContent>
                  <I>
                    <INumber>12.1</INumber>
                    <IContent>A nossa Plataforma inclui recursos de redes sociais tais como o botão de Curtir do Facebook e widgets como “Compartilhe isto” ou mini programas interativos. Esses recursos podem coletar o seu endereço de IP, qual página ou serviço você está visitando e pode instalar um cookie para permitir que o recurso funcione adequadamente. Os recursos e widgets das redes sociais são hospedados por terceiros ou diretamente no nosso Aplicativo. As suas interações com esses recursos são regidas pela declaração de privacidade da empresa fornecedora.</IContent>
                  </I>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 13 ######## */}
              <SectionLevel>
                <SectionTitle>13. Alterações na Política de Privacidade</SectionTitle>
                <SectionContent>
                  <I>
                    <INumber>13.1</INumber>
                    <IContent>Podemos atualizar esta Política de Privacidade periodicamente. Recomendamos que você revise regularmente as alterações. A data da última atualização será sempre exibida no início do documento. O uso continuado do aplicativo após qualquer modificação constitui sua concordância com as novas práticas de privacidade.</IContent>
                  </I>
                </SectionContent>
              </SectionLevel>
              {/* ######## SECTION 14 ######## */}
              <SectionLevel>
                <SectionTitle>14. Contato</SectionTitle>
                <SectionContent>
                  <I>
                    <INumber>14.1</INumber>
                    <IContent>Se tiver dúvidas, preocupações ou solicitações relacionadas à privacidade ou a esta Política, entre em contato conosco pelo e-mail suporte@persora.com.br. Agradecemos pela confiança e estamos comprometidos em proteger sua privacidade e garantir uma experiência segura e gratificante na Persora.</IContent>
                  </I>
                  <I>
                    <INumber>14.2</INumber>
                    <IContent>Esta Política de Privacidade entra em vigor a partir da data de seu aceite ao usar os serviços Persora.</IContent>
                  </I>
                </SectionContent>
              </SectionLevel>
            </DocContainer>
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}
