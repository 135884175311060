import { Sheet } from 'react-modal-sheet';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import {
  useContext, useEffect, useState,
} from 'react';
import { UserContext } from '../../Context/UserContext';
import defaultTheme from '../../assets/styles/themes/default';
import api from '../../services/backend';
import PersoraButton from '../../components/Button';
// import SoraAvatar from '../../assets/img/sora-avatar.svg';
import CoverSora from '../../assets/img/copiloto-capa-sora.svg';
import CoverIcone1 from '../../assets/img/copiloto-capa-icone1.svg';
import CoverIcone2 from '../../assets/img/copiloto-capa-icone2.svg';
import CoverIcone3 from '../../assets/img/copiloto-capa-icone3.svg';
import CoverIcone4 from '../../assets/img/copiloto-capa-icone4.svg';
import MapeamentoLoader from '../../components/MapeamentoLoader';
import { defineRepository } from '../../utils/copilotContents';
import {
  Brain,
  ChevronLeft, Cross, PlussSmall, Wand,
} from '../../components/DuotoneIcon';
import { DiscWordsContainer } from '../../components/FirstMap/styles';
import UserTapBarIcon from '../../components/UserTapBarIcon';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import Disclaimer from '../../components/Disclaimer';

export const SoraSpeech = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: end;
`;
export const Foto = styled.img`
  border-radius: 100px;
  width: 80px;
`;
export const SpeechBubble = styled.div`
  position: relative;
  padding: 15px;
  border: 1px solid ${defaultTheme.colors.black40};
  background-color: #fff;
  border-radius: 14px;
  width: calc(100% - 82px - ${defaultTheme.spaces.screenEdge});
  max-width: fit-content;
  left: 20px;
  background: ${defaultTheme.colors.surfaces.lightBlue};
  box-shadow: 0px 4px 10px 0px rgba(37, 46, 70, 0.10) !important;
  margin-bottom: 10px;
  color: ${defaultTheme.colors.primary.light};
  &:before {
    content: '';
    position: absolute;
    bottom: 20px;
    left: 0;
    margin-top: -10px;
    margin-left: -29px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent ${defaultTheme.colors.black40} transparent transparent;
    transform: scaleX(1.4);
  }
  &:after {
      content: '';
      position: absolute;
      bottom: 20px;
      left: 0;
      margin-top: -10px;
      margin-left: -27px;
      border-width: 12px;
      border-style: solid;
      border-color: transparent ${defaultTheme.colors.surfaces.lightBlue} transparent transparent;
      transform: scaleX(1.4);
    }
`;

const BlueContainer = styled.div`
  text-align: left;
  background-color: ${({ theme }) => theme.colors.secondary.light};
  color: ${({ theme }) => theme.colors.primary.light};
  padding: 1.75rem;
  border-radius: ${({ theme }) => theme.spaces.screenEdge};
  padding-bottom: 1rem;
  margin: 1.75rem 0;
  font-size: 1.05rem;
  ul li {
    margin-bottom: 0.75rem;
  }
`;

const StyledDivHelpResult = styled.div`
  p {
    font-size: 1.05rem;
    margin-bottom: .4rem;
    color: ${({ theme }) => theme.colors.primary.light};
  }
  ul li {
    font-size: 1.05rem;
    color: ${({ theme }) => theme.colors.primary.light} !important;
  }
`;

export const CopilotCover = styled.div`
  position: relative; width: 100%; max-width: 390px; margin: 0 auto;
  img.sora {
    border-radius: 20px;
    width: 100%;
  }
  img.icone1 {
    position: absolute; width: 11%; left: 18%; top: 20%;
    animation: floatingA 4s ease-in-out infinite;
  }
  img.icone2 {
    position: absolute; width: 11%; right: 23%; top: 16%;
    animation: floatingB 4.5s ease-in-out infinite;
  }
  img.icone3 {
    position: absolute; width: 9.5%; left: 11%; top: 63%;
    animation: floatingB 4s ease-in-out infinite;
  }
  img.icone4 {
    position: absolute; width: 13%; right: 10%; top: 70%;
    animation: floatingA 3s ease-in-out infinite;
  }
  @keyframes floatingA {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  @keyframes floatingB {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(10px); }
  }
`;

export default function SoraCopilotV2({
  closeSoraCopilotSheet,
  currentTeam,
}) {
  const [copilotPage, setCopilotPage] = useState(1);
  const { currentUserContextData, setCurrentUserContextData } = useContext(UserContext);
  const currentUserFirstName = currentUserContextData.name.split(' ')[0];
  const [teamData, setTeamData] = useState([]);
  const [targetOptions, setTargetOptions] = useState('');
  const [targetRepository, setTargetRepository] = useState(null);
  const [selectedTargetFirstName, setSelectedTargetFirstName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [helpResult, setHelpResult] = useState(null);

  const situationsOptions = [
    {
      value: 'reuniao',
      label: 'Reunião',
      soraSpeech: `Em uma reunião com ${selectedTargetFirstName}:`,
      to: 'para ter uma reunião',
    },
    {
      value: 'construirConfianca',
      label: 'Construir confiança',
      soraSpeech: `Para construir confiança com ${selectedTargetFirstName}:`,
      to: 'para construir confiança',
    },
    {
      value: 'conversaDificil',
      label: 'Conversa difícil',
      soraSpeech: `Em uma conversa difícil com ${selectedTargetFirstName}:`,
      to: 'para ter uma conversa difícil',
    },
    {
      value: 'feedback',
      label: 'Feedback',
      soraSpeech: `Em um feedback com ${selectedTargetFirstName}:`,
      to: 'para dar um feedback',
    },
    {
      value: 'comunicacao',
      label: 'Comunicação',
      soraSpeech: `Ao se comunicar com ${selectedTargetFirstName}:`,
      to: 'para se comunicar',
    },
    {
      value: 'identificarPontosFortes',
      label: 'Identificar pontos fortes',
      soraSpeech: `Tendem a ser pontos fortes de ${selectedTargetFirstName}:`,
      to: 'para identificar pontos fortes',
    },
    {
      value: 'identificarPontosCegos',
      label: 'Identificar pontos cegos',
      soraSpeech: `Tendem a ser pontos cegos de ${selectedTargetFirstName}:`,
      to: 'para identificar pontos cegos',
    },
    {
      value: 'convencer',
      label: 'Convencer',
      soraSpeech: `Para convencer ${selectedTargetFirstName}:`,
      to: 'para convencer',
    },
    {
      value: 'email',
      label: 'E-mail/Mensagem',
      soraSpeech: `Ao enviar um e-mail para ${selectedTargetFirstName}:`,
      to: 'para enviar um e-mail/mensagem',
    },
    {
      value: 'lidarComConflito',
      label: 'Lidar com conflito',
      soraSpeech: `Para lidar com um conflito com ${selectedTargetFirstName}:`,
      to: 'para lidar com conflito',
    },
    {
      value: 'finalizarUmaConversa',
      label: 'Finalizar uma conversa',
      soraSpeech: `Para encorajar ${selectedTargetFirstName} à ação, experimente fazer perguntas como:`,
      to: 'para finalizar uma conversa',
    },
    {
      value: 'ensinarUmaTarefa',
      label: 'Ensinar uma tarefa',
      soraSpeech: `Ao ensinar uma tarefa para ${selectedTargetFirstName}:`,
      to: 'para ensinar uma tarefa',
    },
    {
      value: 'melhorarProdutividade',
      label: 'Melhorar a produtividade',
      basedOnBothProfiles: true,
      soraSpeech: `Para aumentar a produtividade de ${selectedTargetFirstName}:`,
      to: 'para melhorar a produtividade',
    },
  ];

  useEffect(() => {
    setTeamData([]);
    (async () => {
      try {
        const body = {
          idTeam: currentTeam._id,
        };
        // console.log('body tem getusers', body); // DEBUG
        const { data } = await api.get('/user/getUsers', body);
        // console.log('data tem getusers', data); // DEBUG
        // order teamData first by firstAuth == true, then by type == 'Lider', then by name
        const sortedData = data.sort((a, b) => {
          if (a.displayedProfileLetters && !b.displayedProfileLetters) {
            return -1;
          }
          if (!a.displayedProfileLetters && b.displayedProfileLetters) {
            return 1;
          }
          if (a.type === 'Líder' && b.type !== 'Líder') {
            return -1;
          }
          if (a.type !== 'Líder' && b.type === 'Líder') {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        // setTeamData(sortedData);
        if (currentUserContextData.workspaceContext.profileSora) {
          sortedData.splice(1, 0, currentUserContextData.workspaceContext.profileSora);
        }
        const sortedDataFinal = sortedData.filter(
          (user) => user._id !== currentUserContextData._id,
        );
        setTeamData(sortedDataFinal);
        // Mapear sortedDataFinal para opções do Select
        const selectOptionsMapped = sortedDataFinal.map((user) => ({
          value: user.name,
          label: user.name || user.email,
          displayedProfileLetters: user.displayedProfileLetters,
          disabled: !user.displayedProfileLetters,
          profileLetters: user.profileLetters,
          id: user._id,
          type: user.type,
        }));
        setTargetOptions(selectOptionsMapped);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentTeam]);

  // TODO Refatorando componente...

  const [targetSheetIsOpen, setTargetSheetIsOpen] = useState(false);
  const [situation, setSituation] = useState(null);
  const handleSituation = (opt) => {
    console.log(opt);
    setSituation(opt);
  };
  // eslint-disable-next-line
  const [target, setTarget] = useState(null);
  const handleTarget = (opt) => {
    console.log(opt);
    setTarget(opt);
    setSelectedTargetFirstName(opt.value.split(' ')[0]);
    setTargetRepository(defineRepository(
      { targetPerfil: opt.profileLetters, targetDisplayedProfile: opt.displayedProfileLetters },
    ));
    // mixpanel track
    if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
      ReactGA.event({
        category: 'Engajamento',
        action: 'alvo_escolhido',
        to: opt.type,
      });
      mixpanel.track('alvo_escolhido', {
        category: 'Engajamento',
        company_id: currentUserContextData._idWorkspace[0],
        to: opt.type,
      });
    }
    console.log('category', 'Engajamento', 'company_id', currentUserContextData._idWorkspace[0], 'to', opt.type);
  };
  // const [resumeData, setResumeData] = useState(null);
  const [resumeDataFirstP, setResumeDataFirstP] = useState(null);
  const [resumeDataOthersP, setResumeDataOthersP] = useState(null);
  const [situationData, setSituationData] = useState(null);

  const handleHelp = async () => {
    setCopilotPage(2);
    setIsLoading(true);
    const endpoint = (situation.value === 'melhorarProdutividade'
      ? '/profile/getInteractionBothProfiles'
      : '/profile/getInteractionTargetProfile'
    );
    const targetDisplayedProfile = (situation.value === 'melhorarProdutividade'
      ? target.displayedProfileLetters
      : targetRepository
    );
    const queryParamsResumo = new URLSearchParams({
      userName: currentUserFirstName,
      userDisplayedProfile: currentUserContextData.displayedProfileLetters,
      targetName: target.value.split(' ')[0],
      targetDisplayedProfile: target.displayedProfileLetters,
      situation: 'resumo',
    });
    const queryParams = new URLSearchParams({
      userName: currentUserFirstName,
      userDisplayedProfile: currentUserContextData.displayedProfileLetters,
      targetName: target.value.split(' ')[0],
      targetDisplayedProfile,
      situation: situation.value,
    });
    console.log(`/profile/getInteractionBothProfiles?${queryParamsResumo.toString()}`);
    // console.log(queryParams.toString()); // DEBUG
    try {
      // Primeira requisição para obter o resumo
      const { data: firstData } = await api.get(`/profile/getInteractionBothProfiles?${queryParamsResumo.toString()}`);
      // console.log(firstData); // DEBUG
      // setResumeData(firstData);
      setResumeDataFirstP(firstData.split('</p>')[0]);
      setResumeDataOthersP(firstData.split('</p>').slice(1).join('</p>'));

      // atrasar 1 segundos
      await new Promise((resolve) => { setTimeout(resolve, 700); });

      // Segunda requisição para obter a interação
      const { data: secondData } = await api.get(`${endpoint}?${queryParams.toString()}`);
      // console.log(secondData); // DEBUG
      setSituationData(secondData);

      if (selectedTargetFirstName === 'Sora' && currentUserContextData.tasksGoals.interactionSora !== 1) {
        const secondEndpoint = '/user/updateTask';
        const secondBody = {
          interactionSora: 1,
        };
        await api.patch(secondEndpoint, secondBody);
        setCurrentUserContextData((prevData) => ({
          ...prevData,
          tasksGoals: {
            ...prevData.tasksGoals,
            interactionSora: 1,
          },
        }));
        console.log('Tarefa de Interaction Sora atualizada com sucesso!'); // DEBUG
      }
      if (
        selectedTargetFirstName !== 'Sora'
          && (
            currentUserContextData.tasksGoals.interactionTeam !== 1
              || currentUserContextData.tasksGoals.interactionTeamOneDay !== 1
          )
      ) {
        const secondEndpoint = `/user/updateTask?idUserTarget=${target.id}`;
        const secondBody = {
          interactionTeam: 1,
          // idUserTarget: selectedTargetOptions.id,
        };
        console.log(secondEndpoint, secondBody); // DEBUG
        await api.patch(secondEndpoint, secondBody);
        setCurrentUserContextData((prevData) => ({
          ...prevData,
          tasksGoals: {
            ...prevData.tasksGoals,
            interactionTeam: 1,
          },
        }));
        // console.log('Tarefa de Interaction Team atualizada com sucesso!'); // DEBUG
      }
      setCopilotPage(2);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setCopilotPage(1);
      setIsLoading(false);
    }
  };

  if (teamData.length > 0) {
    return (
      <>
        <Sheet.Container>
          {copilotPage === 1
          && (
            <Sheet.Header className="pt-2 pb-2 ps-1 pe-2" style={{ display: 'flex', justifyContent: 'end' }}>
              <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={closeSoraCopilotSheet}>
                <Cross />
              </PersoraButton>
            </Sheet.Header>
          )}
          {copilotPage === 2
          && (
            <Sheet.Header className="pt-2 pb-2 ps-1 pe-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setCopilotPage(1)}>
                <ChevronLeft />
              </PersoraButton>
            </Sheet.Header>
          )}
          <Sheet.Content>
            <Sheet.Scroller style={{ overflowX: 'hidden' }}>
              <div
                style={{
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.light,
                }}
                className={`${copilotPage === 2 && 'd-none'}`}
              >
                {/* CoverSora */}
                <CopilotCover className="mb-4">
                  <img src={CoverSora} alt="Capa Sora" className="sora" />
                  <img src={CoverIcone1} alt="Ícone 1" className="icone1" />
                  <img src={CoverIcone2} alt="Ícone 2" className="icone2" />
                  <img src={CoverIcone3} alt="Ícone 3" className="icone3" />
                  <img src={CoverIcone4} alt="Ícone 4" className="icone4" />
                </CopilotCover>
                <h1 className="pt-2 sourcesans medium" style={{ color: defaultTheme.colors.primary.main }}>
                  Ambiente de ação
                </h1>
                <p className="mb-3">
                  Com base no estilo de cada um, acesse orientações personalizadas
                  {' '}
                  para interagir com o time em diversas situações do dia a dia.
                </p>
                <Disclaimer
                  estilo="reference"
                  titulo="Referência"
                  icone={<Brain cor={defaultTheme.colors.primary.main} />}
                  className="mb-4"
                >
                  <p className="mb-1">
                    Por motivos de segurança, ainda não utilizamos
                    {' '}
                    <i>IA generativa</i>
                    {' '}
                    em nossas orientações. Atualmente, elas são
                    {' '}
                    desenvolvidas com base em
                    {' '}
                    <b>algoritmos proprietários da Persora.</b>
                  </p>
                </Disclaimer>
                <p className="sourcesans semibold mb-1" style={{ color: defaultTheme.colors.primary.main }}>
                  Escolha uma situação
                </p>
                <DiscWordsContainer>
                  {situationsOptions.map((word) => (
                    <PersoraButton
                      tamanho="mini"
                      estilo="outline"
                      className={`mb-3 me-2 discWord ${word?.value === situation?.value ? 'selected' : ''}`}
                      key={word.value}
                      onClick={() => { handleSituation(word); }}
                    >
                      {word.label}
                    </PersoraButton>
                  ))}
                </DiscWordsContainer>
                <div style={{
                  opacity: (situation ? 1 : 0.9),
                  pointerEvents: (situation ? 'auto' : 'none'),
                  // colocar cor acinzantada / filtro
                  filter: (situation ? 'grayscale(0)' : 'grayscale(.95)'),
                  transition: 'opacity 0.3s, filter 1s',
                }}
                >
                  <p className="sourcesans semibold mb-0 pb-1" style={{ color: defaultTheme.colors.primary.main, textWrap: 'pretty' }}>
                    Escolha uma pessoa do seu time
                    {situation ? ` ${situation.to}` : ' para...'}
                  </p>
                  <BlueContainer
                    className="mt-2 mb-0 p-4"
                    style={{
                      display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center',
                    }}
                  >
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                      alignItems: 'center',
                      position: 'relative',
                      fontSize: '.95rem',
                      fontWeight: '600',
                      fontFamily: 'Source Sans 3',
                      color: defaultTheme.colors.primary.light,
                      minWidth: '100px',
                    }}
                    >
                      <UserTapBarIcon
                        name={currentUserContextData.name}
                        perfil={currentUserContextData.displayedProfileLetters}
                        showProfileLetters
                      />
                      {currentUserContextData.name.split(' ')[0]}
                      {' '}
                      (você)
                    </div>
                    <div className="mx-2">
                      <PlussSmall tamanho={32} />
                    </div>
                    <div>
                      <PersoraButton
                        estilo="ghost"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '16px',
                          alignItems: 'center',
                          position: 'relative',
                          fontSize: '.95rem',
                          fontWeight: '600',
                          fontFamily: 'Source Sans 3',
                          color: defaultTheme.colors.primary.light,
                          minWidth: '100px',
                        }}
                        onClick={() => setTargetSheetIsOpen(true)}
                        className="px-0"
                      >
                        <UserTapBarIcon
                          name={target ? target.value : '?'}
                          perfil={target ? target.displayedProfileLetters : ''}
                          showProfileLetters
                        />
                        {target ? `${target.label.split(' ')[0]}` : 'Escolher...'}
                      </PersoraButton>
                    </div>
                  </BlueContainer>
                </div>
                <PersoraButton
                  className="mt-4 mb-4 px-1"
                  disabled={(target === null || situation === null)}
                  style={{
                    color: (target === null || situation === null ? '#aaa' : '#fff'),
                    backgroundColor: (target === null || situation === null ? '#f1f1f1' : defaultTheme.colors.primary.main),
                    borderColor: (target === null || situation === null ? '#f1f1f1' : defaultTheme.colors.primary.main),
                  }}
                  onClick={() => {
                    // mixpanel "pergunta_a_sora_concluida"
                    if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
                      ReactGA.event({
                        category: 'Engajamento',
                        action: 'pergunta_a_sora_concluida',
                      });
                      mixpanel.track('pergunta_a_sora_concluida', {
                        category: 'Engajamento',
                        company_id: currentUserContextData._idWorkspace[0],
                        to: target.type,
                        subject: situation.label,
                      });
                    }
                    console.log('category', 'Engajamento', 'company_id', currentUserContextData._idWorkspace[0], 'to', target.type, situation.label);
                    handleHelp();
                  }}
                >
                  <Wand cor={(target === null || situation === null ? '#aaa' : '#fff')} />
                  Acessar orientações da Sora
                </PersoraButton>
              </div>

              <div
                style={{
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.main,
                }}
                className={`${(copilotPage === 1 || isLoading) && 'd-none'}`}
              >
                <h1 className="pt-2 sourcesans" style={{ color: defaultTheme.colors.primary.main }}>
                  Você e
                  {' '}
                  {target?.value?.split(' ')[0]}
                  ...
                </h1>
                {/* <SoraSpeech>
                  <Foto src={SoraAvatar} alt="Sora - Copiloto" />
                  <SpeechBubble>
                    Vamos lá!
                  </SpeechBubble>
                </SoraSpeech> */}
                <StyledDivHelpResult
                  dangerouslySetInnerHTML={{ __html: resumeDataFirstP }}
                />
                {situationsOptions.find((sit) => sit.value === situation?.value)?.soraSpeech && (
                  <h4 className="pt-3 sourcesans semibold">
                    {situationsOptions.find((sit) => sit.value === situation?.value).soraSpeech}
                  </h4>
                )}
                <StyledDivHelpResult
                  dangerouslySetInnerHTML={{ __html: situationData }}
                />
                <h4 className="pt-2 sourcesans semibold">Lembre-se:</h4>
                <StyledDivHelpResult
                  dangerouslySetInnerHTML={{ __html: resumeDataOthersP }}
                />
                <Disclaimer
                  estilo="reference"
                  titulo="Referência"
                  icone={<Brain cor={defaultTheme.colors.primary.main} />}
                  className="mt-4 mb-4 text-start"
                >
                  <p className="mb-1">
                    Utilizamos algoritmo próprio desenvolvido com base nas metodologias
                    validadas
                    {' '}
                    <b>16personalities</b>
                    {' '}
                    e
                    {' '}
                    <b>DISC</b>
                    . As informações são personalizadas
                    de acordo com o seu estilo e o da pessoa selecionada.
                  </p>
                </Disclaimer>
              </div>
              {isLoading && <MapeamentoLoader text={null} shrinkLoader />}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet
          isOpen={targetSheetIsOpen}
          onClose={() => setTargetSheetIsOpen(false)}
          onOpenStart={startAndroidSheetHack}
          onCloseEnd={breakAndroidSheetHack}
          detent="content-height"
          tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
          disableScrollLocking
        >
          <Sheet.Container>
            <Sheet.Header className="pt-2 pb-2 px-1" style={{ display: 'flex', justifyContent: 'start' }}>
              <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setTargetSheetIsOpen(false)}>
                <ChevronLeft />
              </PersoraButton>
            </Sheet.Header>
            <Sheet.Content>
              <Sheet.Scroller>
                <div
                  style={{
                    padding: defaultTheme.spaces.screenEdge,
                    paddingTop: 0,
                    color: defaultTheme.colors.primary.light,
                  }}
                >
                  <h2 className="medium sourcesans mb-4" style={{ color: defaultTheme.colors.primary.main, textWrap: 'balance' }}>
                    Escolha uma pessoa do seu time
                  </h2>
                  {/* users aqui */}
                  {targetOptions.map((user) => (
                    <div key={user.id} className="mb-3">
                      <PersoraButton
                        estilo="outline"
                        className={`discWord py-3 px-3 ${user.id === target?.id ? 'selected' : ''}`}
                        onClick={() => { handleTarget(user); setTargetSheetIsOpen(false); }}
                        disabled={user.disabled}
                        style={{ justifyContent: 'start' }}
                      >
                        {user.label}
                      </PersoraButton>
                    </div>
                  ))}
                </div>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={() => setTargetSheetIsOpen(false)} />
        </Sheet>
      </>
    );
  }
  return (
    <Sheet.Container>
      <Sheet.Header className="pt-2 pb-0 px-2" style={{ display: 'flex', justifyContent: 'end' }}>
        <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={closeSoraCopilotSheet}>
          <Cross />
        </PersoraButton>
      </Sheet.Header>
      <Sheet.Content>
        <Sheet.Scroller>
          <MapeamentoLoader text={null} />
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}

SoraCopilotV2.propTypes = {
  closeSoraCopilotSheet: PropTypes.func.isRequired,
  currentTeam: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};
