import React, { useContext } from 'react';
import {
  Route, Switch, useLocation, Redirect,
} from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import { PropTypes } from 'prop-types';

import { Context } from './Context/AuthContext';

import Login from './pages/Login';
import AuthUser from './pages/AuthUser';
import Signup from './pages/Signup';
import LoggedUser from './pages/LoggedUser';
import NotFound from './pages/NotFound';
import Welcome from './components/Welcome';
import TermsOfUse from './pages/Sheets/TermsOfUse';
import Maintenance from './pages/Maintenance';
import SendBumperPage from './pages/SendBumper';

// const history = useHistory();

function CustomRoutes({ isPrivate, ...rest }) {
  const { authenticated } = useContext(Context);
  if (isPrivate && !authenticated) {
    return <Redirect to="/login" />;
  }
  return <Route {...rest} />;
}
CustomRoutes.propTypes = {
  isPrivate: PropTypes.bool,
};
CustomRoutes.defaultProps = {
  isPrivate: false,
};

export default function Routes() {
  const location = useLocation();
  const transitions = useTransition(location, {
    from: { opacity: 0, transform: 'translateY(10px)' }, // estilo antes de entrar na tela
    enter: { opacity: 1, transform: 'translateY(0)' }, // estilo ao entrar na tela
    leave: { opacity: 0, transform: 'translateY(10px)' }, // estilo ao sair da tela
    config: { tension: 210, friction: 20 }, // configuração da animação
  });

  return transitions((props, item) => (
    <animated.div style={{ ...props, position: 'absolute', width: '100%' }}>
      <Switch location={item}>
        { process.env.REACT_APP_PROD_MAINTENANCE === 'true' && <CustomRoutes path="*" component={Maintenance} /> }
        <CustomRoutes exact path="/" component={Login} />
        <CustomRoutes exact path="/login" component={Login} />
        <CustomRoutes exact path="/authUser" component={AuthUser} />
        <CustomRoutes exact path="/signup" component={Signup} />
        <CustomRoutes exact path="/termos" render={() => <TermsOfUse isSinglePage />} />
        <CustomRoutes isPrivate exact path="/userSpace" component={LoggedUser} />
        <CustomRoutes isPrivate exact path="/sendBumper" component={SendBumperPage} />
        <CustomRoutes isPrivate exact path="/welcome" component={Welcome} />
        <CustomRoutes path="*" component={NotFound} />
      </Switch>
    </animated.div>
  ));
}
