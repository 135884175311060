import React, { useState, useEffect } from 'react';
import { ring } from 'ldrs';
import PropTypes from 'prop-types';

import { Container } from './styles';
import theme from '../../assets/styles/themes/default';

ring.register();

function MapeamentoLoader({ text, shrinkLoader }) {
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const colors = [
    theme.colors.disc.d.main,
    theme.colors.disc.i.main,
    theme.colors.disc.s.main,
    theme.colors.disc.c.main,
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 600); // Altere a frequência da alternância de cores conforme necessário

    return () => clearInterval(intervalId);
  }, []);

  const currentColor = colors[currentColorIndex];

  return (
    <Container className={shrinkLoader && 'shrinkLoader'}>
      <div>
        <l-ring size={75} color={currentColor} />
      </div>
      <h3 style={{ marginTop: '40px', fontWeight: 500 }} className="text-center">
        {text}
      </h3>
    </Container>
  );
}

MapeamentoLoader.propTypes = {
  text: PropTypes.string,
  shrinkLoader: PropTypes.bool,
};

MapeamentoLoader.defaultProps = {
  text: 'Carregando...',
  shrinkLoader: false,
};

export default MapeamentoLoader;
