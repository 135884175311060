/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import { Sheet } from 'react-modal-sheet';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { useContext, useRef, useState } from 'react';
import api from '../../services/backend';
import PersoraButton from '../Button';
import defaultTheme from '../../assets/styles/themes/default';

import {
  Cardiology,
  Circle,
  CircleChecked,
  Cross,
  Home1, UsersMore,
  Wand,
} from '../DuotoneIcon';
import {
  TapBarContainer, ActionButton, ActionButtonDisclaimer, PButton, CloseButton,
} from './styles';
import UserTapBarIcon from '../UserTapBarIcon';
import SoraCopilotAvatar from '../../assets/img/p-copilot-icon.svg';
// eslint-disable-next-line
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import SoraCopilotV2 from '../../pages/Sheets/SoraCopilotV2';
import PersoraTextButton from '../TextButton';
import { UserContext } from '../../Context/UserContext';

export default function TapBar({
  onTapBarClick, activeTapBar, userName, userProfileLetters, currentUser, currentTeam,
}) {
  const [soraCopilotSheetIsOpen, setSoraCopilotSheetOpen] = useState(false);
  const openSoraCopilotSheet = () => setSoraCopilotSheetOpen(true);
  const closeSoraCopilotSheet = () => setSoraCopilotSheetOpen(false);
  const [disclaimerIsVisible, setDisclaimerIsVisible] = useState(true);
  const [dismissDisclaimerForever, setDismissDisclaimerForever] = useState(false);
  const { currentUserContextData } = useContext(UserContext);

  const handleDismissDisclaimerButton = () => {
    setDismissDisclaimerForever(!dismissDisclaimerForever);
  };

  const handleDismissDisclaimer = async (dismissAttribute) => {
    console.log('clicou', dismissAttribute);
    if (dismissDisclaimerForever) {
      try {
        setDisclaimerIsVisible(false);
        const body = {
          [dismissAttribute]: true,
        };
        const { data } = await api.patch('/user/disclaimer', body);
        console.log(data);
      } catch (error) {
        setDisclaimerIsVisible(true);
        console.log(error);
      }
    } else {
      setDisclaimerIsVisible(false);
    }
  };

  return (
    <>
      <ActionButton className="d-none">
        {/* {currentUser.disclaimers
        && !currentUser.disclaimers.actionButtonPresentation
        && disclaimerIsVisible && (
          <ActionButtonDisclaimer>
            <div>
              <h5>Botão copiloto</h5>
              <CloseButton onClick={() => handleDismissDisclaimer('actionButtonPresentation')}>
                <Cross />
              </CloseButton>
            </div>
            <p>
              Permita que a copiloto facilite sua comunicação com o time.
            </p>
            {console.log(dismissDisclaimerForever)}
            <PersoraTextButton
              onClick={handleDismissDisclaimerButton}
              style={{ opacity: '.85', display: 'flex', alignItems: 'center' }}
            >
              {dismissDisclaimerForever
                ? <CircleChecked tamanho={20} cor={defaultTheme.colors.primary.light} />
                : <Circle tamanho={20} cor={defaultTheme.colors.primary.light} />}
              <span
                className="ps-2"
                style={{ color: defaultTheme.colors.primary.light, fontSize: '.95rem' }}
              >
                Não exibir lembrete novamente.
              </span>
            </PersoraTextButton>
          </ActionButtonDisclaimer>
        )} */}
        <PButton onClick={() => {
          if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
            ReactGA.event({
              category: 'Engajamento',
              action: 'pergunta_a_sora_iniciada',
            });
            mixpanel.track('pergunta_a_sora_iniciada', {
              category: 'Engajamento',
              company_id: currentUserContextData._idWorkspace[0],
            });
          }
          setSoraCopilotSheetOpen(true);
        }}
        >
          <img src={SoraCopilotAvatar} alt="Sora Copilot" />
        </PButton>
      </ActionButton>
      <TapBarContainer>
        <div style={{
          display: 'flex',
          flex: 'row',
          gap: '10px',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          maxWidth: '360px',
          margin: '0 auto',
        }}
        >
          <PersoraButton estilo="ghost" tamanho="mini" onClick={() => onTapBarClick('home')} justIcon ativo={activeTapBar === 'home' ? 'tapBarActive' : ''} style={{ width: '60px' }}>
            <Home1 />
          </PersoraButton>
          <PersoraButton estilo="ghost" tamanho="mini" onClick={() => onTapBarClick('time')} justIcon ativo={activeTapBar === 'time' ? 'tapBarActive' : ''} style={{ width: '44px', borderRadius: '' }} className="TapBarTime">
            <UsersMore />
          </PersoraButton>
          <PButton
            style={{
              background: defaultTheme.colors.primary.main, width: '54px', height: '54px', borderRadius: '50px',
            }}
            onClick={() => {
              if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
                ReactGA.event({
                  category: 'Engajamento',
                  action: 'pergunta_a_sora_iniciada',
                });
                mixpanel.track('pergunta_a_sora_iniciada', {
                  category: 'Engajamento',
                  company_id: currentUserContextData._idWorkspace[0],
                });
              }
              setSoraCopilotSheetOpen(true);
            }}
          >
            <Wand cor="#FFF" tamanho={30} />
          </PButton>
          <PersoraButton estilo="ghost" tamanho="mini" onClick={() => onTapBarClick('organizacao')} justIcon ativo={activeTapBar === 'organizacao' ? 'tapBarActive' : ''} style={{ width: '44px', borderRadius: '' }} className="TapBarIndicadores">
            <Cardiology />
          </PersoraButton>
          <PersoraButton estilo="ghost" tamanho="mini" onClick={() => onTapBarClick('user')} justIcon ativo={activeTapBar === 'user' ? 'tapBarActive' : ''}>
            <UserTapBarIcon name={userName} perfil={userProfileLetters} />
          </PersoraButton>
        </div>
      </TapBarContainer>
      <Sheet
        isOpen={soraCopilotSheetIsOpen}
        onClose={closeSoraCopilotSheet}
        // onOpenStart={startAndroidSheetHack}
        // onCloseEnd={breakAndroidSheetHack}
        // detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableScrollLocking
        disableDrag={!soraCopilotSheetIsOpen}
      >
        <SoraCopilotV2
          closeSoraCopilotSheet={closeSoraCopilotSheet}
          currentUser={currentUser}
          currentTeam={currentTeam}
        />
        <Sheet.Backdrop onTap={closeSoraCopilotSheet} />
      </Sheet>
    </>
  );
}

TapBar.propTypes = {
  onTapBarClick: PropTypes.func.isRequired,
  activeTapBar: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userProfileLetters: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    disclaimers: PropTypes.shape({
      actionButtonPresentation: PropTypes.bool,
    }),
  }).isRequired,
  currentTeam: PropTypes.shape({
    emoji: PropTypes.string,
  }).isRequired,
};
