import styled from 'styled-components';

export const UserTapBarIconContainer = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${({ profilebackgroundcolor, theme }) => profilebackgroundcolor || theme.colors.black20};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #FFF;
  color: ${({ theme }) => theme.colors.black60};

  font-size: 18px;
  font-weight: 600;
  &.perfil-d {
    background: ${({ theme }) => theme.colors.disc.d.lighter};
    color: ${({ theme }) => theme.colors.disc.d.dark};
  }
  &.perfil-i {
    background: ${({ theme }) => theme.colors.disc.i.lighter};
    color: ${({ theme }) => theme.colors.disc.i.dark};
  }
  &.perfil-s {
    background: ${({ theme }) => theme.colors.disc.s.lighter};
    color: ${({ theme }) => theme.colors.disc.s.dark};
  }
  &.perfil-c {
    background: ${({ theme }) => theme.colors.disc.c.lighter};
    color: ${({ theme }) => theme.colors.disc.c.dark};
  }
`;

export const ProfileLetters = styled.span`
  /* box over circle centered midle */
  position: absolute;
  margin-top: 44px;
  background: #FFFFFF;
  padding: 0px 6px;
  font-size: 12px;
  border-radius: 8px;
  font-weight: 700;
`;
