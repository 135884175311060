import PropTypes from 'prop-types';
import {
  Container, Content, Header, SoraFace,
} from './styles';

import SoraPreocupada from '../../assets/img/pip-sora-preocupacao.svg';
import SoraTristeza from '../../assets/img/pip-sora-tristeza.svg';

export default function DisclaimerV2({
  estilo,
  children,
  soraSize,
  contentAlign,
  ...props
}) {
  const soraSizeClass = soraSize === 'small' ? 'soraSizeSmall' : 'soraSizeNormal';
  const contentAlignClass = contentAlign === 'center' ? 'contentAlignCenter' : 'contentAlignLeft';
  // replace <br/> or <br /> with \n
  return (
    <Container {...props} className={`${props.className} ${estilo}`}>
      <Header className={`${estilo} ${soraSizeClass}`}>
        {estilo === 'danger' && <SoraFace src={SoraPreocupada} alt="Sora Preocupada" className={`${soraSizeClass} ${contentAlignClass}`} />}
        {estilo === 'warning' && <SoraFace src={SoraTristeza} alt="Sora Tristeza" className={`${soraSizeClass} ${contentAlignClass}`} />}
      </Header>
      {children && <Content className={`${contentAlignClass}`}>{children}</Content>}
    </Container>
  );
}

DisclaimerV2.propTypes = {
  children: PropTypes.node,
  estilo: PropTypes.oneOf(['danger', 'warning']),
  className: PropTypes.string,
  soraSize: PropTypes.oneOf(['small', 'normal']),
  contentAlign: PropTypes.oneOf(['center', 'left']),
};
DisclaimerV2.defaultProps = {
  children: null,
  estilo: 'danger',
  className: '',
  soraSize: 'normal',
  contentAlign: 'center',
};
