import { useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { Sheet } from 'react-modal-sheet';
import Cookies from 'js-cookie';
import defaultTheme from '../../assets/styles/themes/default';
import logo from '../../assets/img/logo-horizontal.svg';

import { Context } from '../../Context/AuthContext';

import {
  Container, Content, FormGroup, TextLink,
} from './styles';
import PersoraButton from '../../components/Button';
import PersoraTextButton from '../../components/TextButton';
import TermsOfUseSheet from '../Sheets/TermsOfUse';
import PrivacyPolicySheet from '../Sheets/PrivacyPolicy';
import { Clock } from '../../components/DuotoneIcon';

export default function Login() {
  const { authenticated, handleGenerateCode, btnLoading } = useContext(Context);
  const [termsOfUseSheetIsOpen, setTermsOfUseSheetOpen] = useState(false);
  const [privacyPolicySheetIsOpen, setPrivacyPolicySheetOpen] = useState(false);

  console.debug('authenticated', authenticated);
  /** Se usuário está autenticado, redireciona ele para /userSpace */
  if (authenticated) {
    return <Redirect to="/userSpace" />;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    handleGenerateCode(email);
  };

  const [onesignalPushIdToPrint, setOnesignalPushIdToPrint] = useState(Cookies.get('onesignal_push_id') ? Cookies.get('onesignal_push_id') : '');

  // a cada 10segundos, verifica se o onesignal_push_id mudou
  setInterval(() => {
    const onesignalPushId = Cookies.get('onesignal_push_id');
    if (onesignalPushId !== onesignalPushIdToPrint) {
      setOnesignalPushIdToPrint(onesignalPushId);
    }
  }, 500);

  return (
    <Container>
      <Content>
        <img src={logo} alt="Logo" width="160px" />
        <h2 className="mb-3">Bom ver você aqui! 👋</h2>
        <p className="big d-none">
          Acesse sua conta e continue sendo o líder que cada colaborador precisa.
        </p>
        <form id="loginForm" onSubmit={handleSubmit}>
          <FormGroup>
            <div htmlFor="login-email">Seu e-mail</div>
            <input type="email" className="form-control" id="login-email" name="email" required />
          </FormGroup>
          <PersoraButton className="mt-4" type="submit" form="loginForm" disabled={btnLoading}>
            {btnLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Entrar com e-mail'}
          </PersoraButton>
        </form>
        <TextLink className="small">
          É novo na Persora?
          <Link to="/signup" style={{ marginLeft: '3px' }}>Cadastre-se! 🔥</Link>
        </TextLink>
        <div style={{ fontSize: '.7rem', color: '#ccc', textAlign: 'center' }} className="mt-0">
          {onesignalPushIdToPrint ? '⎷' : '—'}
        </div>
        {/* <Link type="button" onClick={handleNavigate}>Cadastrar</Link> */}
        <p className="small mt-5" style={{ color: defaultTheme.colors.black60 }}>
          Ao continuar, você concordará com nossos
          {' '}
          <PersoraTextButton
            className="bold"
            style={{ display: 'contents' }}
            onClick={() => {
              setTermsOfUseSheetOpen(true);
            }}
          >
            <u>Termos de Uso</u>
          </PersoraTextButton>
          {' '}
          e
          {' '}
          <PersoraTextButton
            className="bold"
            style={{ display: 'contents' }}
            onClick={() => {
              setPrivacyPolicySheetOpen(true);
            }}
          >
            <u>Política de Privacidade</u>
          </PersoraTextButton>
          .
        </p>
      </Content>
      <Sheet
        isOpen={termsOfUseSheetIsOpen}
        onClose={() => setTermsOfUseSheetOpen(false)}
        // detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
      >
        <TermsOfUseSheet />
        <Sheet.Backdrop onTap={() => setTermsOfUseSheetOpen(false)} />
      </Sheet>
      <Sheet
        isOpen={privacyPolicySheetIsOpen}
        onClose={() => setPrivacyPolicySheetOpen(false)}
        // detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
      >
        <PrivacyPolicySheet />
        <Sheet.Backdrop onTap={() => setPrivacyPolicySheetOpen(false)} />
      </Sheet>
    </Container>
  );
}
