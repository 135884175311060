import PropTypes from 'prop-types';
import { ProfileLetters, UserTapBarIconContainer } from './styles';

import { coloredLetters } from '../../utils/profileUtils';

import defaultTheme from '../../assets/styles/themes/default';

export default function UserTapBarIcon({
  name, perfil, showProfileLetters, ...props
}) {
  const firstLetterProfile = perfil.charAt(0).toLowerCase();
  const profilebackgroundcolor = (
    firstLetterProfile
      ? defaultTheme.colors.disc[firstLetterProfile].lighter
      : defaultTheme.colors.black20
  );

  return (
    <UserTapBarIconContainer {...props} className={`perfil-${firstLetterProfile}`} style={{ backgroundColor: profilebackgroundcolor }}>
      <span className="mb-0 bold text-uppercase">
        {name[0]}
      </span>
      {showProfileLetters && perfil && (
        <ProfileLetters dangerouslySetInnerHTML={{ __html: coloredLetters(perfil) }} />
      )}
    </UserTapBarIconContainer>
  );
}

UserTapBarIcon.propTypes = {
  name: PropTypes.string.isRequired,
  perfil: PropTypes.string,
  showProfileLetters: PropTypes.bool,
};
UserTapBarIcon.defaultProps = {
  perfil: '',
  showProfileLetters: false,
};
