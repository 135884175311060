import styled from 'styled-components';

export const StyledButton = styled.button`
  width: 100%;
  padding: 0;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 4px 10px 0px rgba(37, 46, 70, 0.10) !important;

  border: none;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.surfaces.lightBlue};
  border-color: ${({ theme }) => theme.colors.primary.main};
  font-size: 18px;
  transition: all 0.2s ease-in-out;
  height: fit-content;
  text-align: left;

  &:disabled {
    background: ${({ theme }) => theme.colors.black20};
    color: ${({ theme }) => theme.colors.black60};
    cursor: not-allowed;
    pointer-events: none;
    /* filter grayscale */
    filter: grayscale(1);
    opacity: .8;
  }
`;

export const CardLabel = styled.p`
  background-size: cover;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.35rem;
  margin-bottom: 0;
  width: calc(100% - 32px);
`;

export const CardReadAbout = styled.span`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: ${({ theme }) => theme.colors.primary.main};
  opacity: .7;
  width: calc(100% - 32px);
  margin-bottom: 1.2rem;
`;
