import styled from 'styled-components';

// welcomeContainer with 100dvh height, with fixed header with 50px and fixed footer with 50px

export const WelcomeContainer = styled.div`
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
`;
export const WelcomeHeader = styled.div`

  height: 50px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  text-align: center;
  padding-top: 12px;
`;
export const WelcomeBody = styled.div`
  height: calc(100dvh - 110px);
  width: 100%;
  max-width: 460px;
  margin-top: 10px;
`;

export const WelcomeFooter = styled.div`
  padding: 0 ${({ theme }) => theme.spaces.screenEdge};
  background-color: #fff;
  height: 150px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

export const PaginationDiv = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;

  .swiper-pagination {
    width: auto; /* Ajuste a largura conforme necessário */
    display: flex;
    align-items: center;
    margin-left: -45px;
    margin-top: -10px;
    .swiper-pagination-bullet {
      background-color: ${({ theme }) => theme.colors.primary.light} !important;
      margin-right: 6px;
      height: 6px;
      width: 12px;
      border-radius: 6px;
      transition: all 0.3s ease-in-out;
    }
    .swiper-pagination-bullet-active {
      width: 30px;
    }
  }
`;
export const ButtonsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  padding-left: 10px;
  align-items: center;
`;

export const Left = styled.div`
  button {
    margin-left: 0;
  }
`;

export const WelcomeImage = styled.img`
  width: 100%;
  max-width: 260px;
  @media (max-height: 700px) {
    max-width: 200px;
  }
`;

export const ListItensWithBlueBg = styled.div`
  text-align: left;
  background-color: ${({ theme }) => theme.colors.secondary.light};
  padding: 1.75rem;
  border-radius: ${({ theme }) => theme.spaces.screenEdge};
`;
