import PropTypes from 'prop-types';
import { Container } from './styles';
import { PlussSmall } from '../DuotoneIcon';

export default function RemainingUsersCard({ totalUsers, ...props }) {
  // const limitUsers = process.env.REACT_APP_LIMIT_USER;
  // const remainingUsers = limitUsers - totalUsers;

  // if (remainingUsers > 0) {
  //   return (
  //     <Container className="mb-3" {...props}>
  //       Adicionar novos usuários
  //       {' '}
  //       <br />
  //       <span className="regular small">
  //         (
  //         {remainingUsers}
  //         {' '}
  //         usuário
  //         {remainingUsers > 1 && 's'}
  //         {' '}
  //         gratuito
  //         {remainingUsers > 1 && 's'}
  //         {' '}
  //         {remainingUsers > 1 ? 'disponíveis' : 'disponível'}
  //         )
  //       </span>
  //     </Container>
  //   );
  // }
  return (
    <Container {...props}>
      {totalUsers <= 4 && (
        <>
          Ao adicionar
          {' '}
          <b>pelo menos 3 liderados,</b>
          {' '}
          você libera a funcionalidade que
          {' '}
          te permite saber como o time está e o que fazer para melhorar.
          <hr />
        </>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <b className="semibold">
          Adicionar novos usuários
        </b>
        <PlussSmall />
      </div>
    </Container>
  );
}

RemainingUsersCard.propTypes = {
  totalUsers: PropTypes.number.isRequired,
};
