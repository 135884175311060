import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.alerts.generic.textColor};
  box-shadow: 0px 2px 12px 0px rgba(6, 16, 42, 0.10);
  overflow: hidden;

  /* &.success {
    background-color: ${({ theme }) => theme.colors.alerts.success.background};
    color: ${({ theme }) => theme.colors.alerts.success.textColor};
    h5 {
      color: ${({ theme }) => theme.colors.alerts.success.titleColor};
    }
  }

  &.warning {
    background-color: ${({ theme }) => theme.colors.alerts.warning.background};
    color: ${({ theme }) => theme.colors.alerts.warning.textColor};
    h5 {
      color: ${({ theme }) => theme.colors.alerts.warning.titleColor};
    }
  }

  &.danger {
    background-color: ${({ theme }) => theme.colors.alerts.danger.background};
    color: ${({ theme }) => theme.colors.alerts.danger.textColor};
    h5 {
      color: ${({ theme }) => theme.colors.alerts.danger.titleColor};
    }
  }

  &.info {
    background-color: ${({ theme }) => theme.colors.alerts.info.background};
    color: ${({ theme }) => theme.colors.alerts.info.textColor};
    h5 {
      color: ${({ theme }) => theme.colors.alerts.info.titleColor};
    }
  }
  &.reference {
    background-color: #f7f7f7;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.primary.light};
    h5 {
      color: ${({ theme }) => theme.colors.primary.main};
    }
  } */

`;

export const Header = styled.div`
  padding: ${({ theme }) => theme.spaces.screenEdge};
  color: ${({ theme }) => theme.colors.alerts.generic.titleColor};
  display: flex;
  align-items: start;
  position: relative;
  background-color: ${({ theme }) => theme.colors.black20};
  height: 80px;
  margin-bottom: 35px;
  &.soraSizeSmall {
    margin-bottom: 20px;
  }
  &.soraSizeNormal {
    margin-bottom: 30px;
  }
  &.danger {
    background-color: ${({ theme }) => theme.colors.alerts.danger.background};
  }
  &.warning {
    background-color: ${({ theme }) => theme.colors.alerts.warning.background};
  }
`;

export const SoraFace = styled.img`
  max-width: 98px;
  border-radius: 80px;
  border: 4px solid #fff;
  margin: 0 auto;
  z-index: 1;
  margin-top: 10px;
  &.soraSizeSmall {
    max-width: 74px;
    margin-top: 15px;
  }
  &.soraSizeNormal {
    max-width: 98px;
    margin-top: 10px;
  }
  &.contentAlignCenter {
    margin: 0 auto;
  }
  &.contentAlignLeft {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  /* border-top: 1px solid ${({ theme }) => theme.colors.opacityBlack10}; */
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  &.contentAlignCenter {
    text-align: center;
  }
  &.contentAlignLeft {
    text-align: left;
  }
`;
