import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import {
  CardButtons,
  CardHeader,
  Container,
  Description,
  Tag,
  Title,
} from './styles';
// modules styles
import 'swiper/components/pagination/pagination.min.css';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import PersoraTextButton from '../TextButton';
import {
  ChevronSmallRight,
  CircleChecked,
  Copy,
  QuestionCircle,
} from '../DuotoneIcon';
import PersoraButton from '../Button';
import defaultTheme from '../../assets/styles/themes/default';
import { textToClipboard } from '../../utils/copyToClipboard';

// import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';

/*
  Pip pode estar Desabilitada | Aberta | Fechada
  Por time:
    Para lider:
    A pip pode estar inativa por falta de 3 usuarios ainda
    A pip pode estar inativa por foi desativada após onboarding
*/

/**
 * @param {string} [props.instrumentTag=''] - Ex.: PIP | CULTURA | SÁUDE MENTAL | etc
 * @param {string} [props.statusTag=''] - Ex.: Desabilitada | Aberta | Fechada | etc
 * @param {string} [props.styleTag='default'] - ('default', 'success', 'warning', 'danger', 'info')
 * @returns {JSX.Element}
 */
export default function InstrumentComponent({
  instrumentTag,
  statusTag,
  styleTag,
  title,
  description,
  buttonText,
  buttonAction,
  disabledButton,
  enabledButton,
  enabledButtonAction,
  copyMessageButton,
  questionCircleButton,
  ...props
}) {
  return (
    <Container {...props}>
      {questionCircleButton !== null && (
        <PersoraButton
          estilo="ghost"
          tamanho="mini"
          style={{
            position: 'absolute', right: '0', top: '0', marginTop: '6px',
          }}
          onClick={() => questionCircleButton(true)}
        >
          <QuestionCircle />
        </PersoraButton>
      )}
      <CardHeader>
        <div>
          {instrumentTag && <Tag className="instrumentTag">{instrumentTag}</Tag>}
          {' '}
          {statusTag && <Tag className={styleTag}>{statusTag}</Tag>}
          <Title className="sourcesans">{title}</Title>
          <Description>{description}</Description>
          {disabledButton !== '' && (
            <PersoraButton tamanho="condensed" style={{ color: defaultTheme.colors.black60, background: defaultTheme.colors.black20 }} className="mt-2 px-3" disabled>
              <CircleChecked tamanho={20} cor={defaultTheme.colors.black60} />
              {disabledButton}
            </PersoraButton>
          )}
          {enabledButton !== '' && (
            <PersoraButton tamanho="condensed" estilo="outline" className="mt-2 px-3" onClick={() => enabledButtonAction(true)}>
              {/* <CircleChecked tamanho={20} /> */}
              {enabledButton}
            </PersoraButton>
          )}
          {copyMessageButton && (
            <>
              <div className="form-label mt-2">Sugestão de mensagem para enviar ao time:</div>
              <div className="form-control" style={{ height: '94px', overflowY: 'scroll' }}>
                <p className="mb-0">
                  {copyMessageButton}
                </p>
              </div>
              <PersoraButton
                className="mt-3"
                onClick={() => {
                  // if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
                  //   ReactGA.event({
                  //     category: 'Convites',
                  //     action: 'copiar_mensagem_convite',
                  //   });
                  //   mixpanel.track('copiar_mensagem_convite', {
                  //     category: 'Convites',
                  //   });
                  // }
                  textToClipboard(copyMessageButton);
                  toast.success('A mensagem foi copiada! 😉', {
                    autoClose: 2500,
                    position: 'top-center',
                  });
                }}
              >
                <Copy cor="#fff" />
                Copiar mensagem
              </PersoraButton>
            </>
          )}
        </div>
      </CardHeader>
      <CardButtons>
        <div style={{ width: '100%' }}>
          <PersoraTextButton
            className="mb-0 small semibold"
            onClick={buttonAction}
            style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
          >
            <div>
              {buttonText}
            </div>
            <div style={{ marginRight: '-6px' }}>
              <ChevronSmallRight tamanho={22} />
            </div>
          </PersoraTextButton>
        </div>
      </CardButtons>
    </Container>
  );
}

InstrumentComponent.propTypes = {
  instrumentTag: PropTypes.string,
  statusTag: PropTypes.string,
  styleTag: PropTypes.oneOf(['default', 'success', 'warning', 'danger', 'info']),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
  disabledButton: PropTypes.string,
  enabledButton: PropTypes.string,
  enabledButtonAction: PropTypes.func,
  copyMessageButton: PropTypes.string,
  questionCircleButton: PropTypes.func,
};
InstrumentComponent.defaultProps = {
  instrumentTag: '',
  statusTag: '',
  styleTag: 'default',
  buttonText: '',
  buttonAction: () => {},
  disabledButton: '',
  enabledButton: '',
  enabledButtonAction: () => {},
  copyMessageButton: '',
  questionCircleButton: null,
};
