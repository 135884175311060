// função para validar se email é valido, recebe um email e retorna true ou false
export const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const validateFirstNameAndLastName = (string) => {
  const trimmedValue = string.trim(); // Remove espaços em branco no início e no final
  if (trimmedValue.split(' ').length >= 2 && trimmedValue !== '') {
    return true;
  }
  return false;
};
