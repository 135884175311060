import styled from 'styled-components';

// ${({ percentual }) => percentual || '0%'};

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.surfaces.lightBlue};
  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px rgba(6, 16, 42, 0.10);
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.surfaces.lightBlue};
  position: relative;
  z-index: 0;
`;

export const CardHeader = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  padding: ${({ theme }) => theme.spaces.screenEdge};
  display: flex;
  align-items: center;
`;

export const ProgressContainer = styled.div`
  flex-grow: 1;
`;

export const IconStatus = styled.div`
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.black20};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #FFF;
  color: ${({ theme }) => theme.colors.black60};
  font-size: 22px;
  font-weight: 600;
  padding-top: 2px;
  &.done {
    background-color: ${({ theme }) => theme.colors.secondary.main};
  }
`;

export const ProgressBar = styled.div`
  position: relative;
  width: ${({ progresscontent }) => (progresscontent ? 'calc(100% - 30px)' : '100%')};
  height: 10px;
  background: ${({ theme }) => theme.colors.black20};
  border-radius: 10px;
  margin-bottom: 6px;

  &::before {
    content: '';
    display: block;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ percentual }) => `${percentual > 100 ? '100' : percentual}%` || '0%'};
    height: 100%;
    background: ${({ color, theme }) => (color ? theme.colors[color].main : theme.colors.primary.main)};
    border-radius: 6px;
    transition: ease-in-out 0.2s;
  }

  &::after {
    content: ${({ progresscontent }) => (progresscontent ? `'${progresscontent}'` : '')};
    position: absolute;
    right: -30px;
    font-size: .8rem;
    font-weight: 700;
    margin-top: -5px;
    color: ${({ theme }) => theme.colors.primary.light}
  }
`;

export const CardButtons = styled.div`
background: ${({ theme }) => theme.colors.surfaces.lightBlue};
padding: 12px ${({ theme }) => theme.spaces.screenEdge};
border-top: 1px solid ${({ theme }) => theme.colors.black20};
display: flex;
justify-content: space-between;
  &.firstAuthFalse {
    background: ${({ theme }) => theme.colors.black10};
  }
`;

export const ListItem = styled.div`
  padding-top: 1rem;
  &::before {
    content: '';
    width: calc(100% - (2* ${({ theme }) => theme.spaces.screenEdge}));
    margin-top: calc(-1rem - 2px);
    height: 40px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+3,ffffff+100&1+4,0+100 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 3%,rgba(255,255,255,1) 4%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    position: absolute;
  }
`;

export const SingleItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1.2rem 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black20};
  }
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.primary.light};
  margin-bottom: .2rem !important;
  text-wrap: balance;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.black60};
  margin-bottom: 0.5rem !important;
`;
