export const textToClipboard = (text) => {
  if (!navigator.clipboard) {
    text.select();
    text.setSelectionRange(0, 99999);
    document.execCommand('copy');
  } else {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('O texto foi copiado para a área de transferência.');
      },
    )
      .catch(
        () => {
          console.log('Erro ao copiar  o texto para a área de transferência.');
        },
      );
  }
};
