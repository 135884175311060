import { Sheet } from 'react-modal-sheet';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import defaultTheme from '../../assets/styles/themes/default';
import PersoraButton from '../../components/Button';
import { UserContext } from '../../Context/UserContext';
import { ChevronLeft, Clock } from '../../components/DuotoneIcon';
import PremiumDisclaimer from '../../components/PremiumDisclaimer';
import { validateEmail } from '../../utils/validators';
import { breakAndroidSheetHack, startAndroidSheetHack } from '../../utils/androidSheetHack';
import AudioPlayer from '../../components/AudioPlayer';
import FotoMilena from '../../assets/img/foto_milena.jpg';
import audioConvite from '../../assets/audios/convidando-o-time.mp3';

// eslint-disable-next-line
const ListItensWithBlueBg = styled.div`
  text-align: left;
  background-color: ${({ theme }) => theme.colors.secondary.light};
  padding: 1.75rem 2rem;
  border-radius: ${({ theme }) => theme.spaces.screenEdge};
  color: ${({ theme }) => theme.colors.primary.light};
`;

const BetaTestesContainer = styled.div`
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2183a6+11,223b59+100 */
  background: linear-gradient(135deg,  #2183a6 11%,#223b59 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  border-radius: ${({ theme }) => theme.spaces.screenEdge};
  padding: 2px;
`;
const BetaTesterContent = styled.div`
  text-align: left;
  background-color: ${({ theme }) => theme.colors.surfaces.lightBlue};
  padding: 1.5rem;
  border-radius: calc(${({ theme }) => theme.spaces.screenEdge} - 3px);
  color: ${({ theme }) => theme.colors.primary.light};
`;

export default function AddUserSheet({
  handleInviteUsers,
  handleInviteUsersIsLoading,
  workspaceTeams,
}) {
  const { currentUserContextData } = useContext(UserContext);
  const { totalInvitedUsers } = currentUserContextData.workspaceContext;
  // eslint-disable-next-line
  const { totalUsers } = currentUserContextData.workspaceContext;
  const [emails, setEmails] = useState([{
    key: uuidv4(),
    value: '',
  }]);
  const [totalValidEmails, setTotalValidEmails] = useState(0);
  const [addUserConfirmSheetIsOpen, setAddUserConfirmSheetOpen] = useState(false);

  const handleChange = (emailKey, newValue) => {
    // set newValue to lowercase and remove any spaces
    const toLowerCaseAndNoSpaceValue = newValue.toLowerCase().replace(/\s/g, '');
    setEmails(emails.map((item) => (item.key === emailKey
      ? { ...item, value: toLowerCaseAndNoSpaceValue }
      : item)));
    setTotalValidEmails(emails.filter((email) => validateEmail(email.value)).length);
  };

  const handleEmails = () => {
    // if (currentUserContextData.workspaceContext.premium !== true) {
    //   if (emails.length >= process.env.REACT_APP_LIMIT_USER - 1 - totalUsers) {
    //     return; // Limita o número de emails a 4
    //   }
    // } // comentar para liberar limite
    // Verifica se o último email inserido é válido
    const lastEmail = emails[emails.length - 1];
    const isValidEmail = /\S+@\S+\.\S+/.test(lastEmail.value); // Verifica se o email tem um formato válido
    if (isValidEmail) {
      // Adiciona um novo campo de email vazio
      setEmails((prevEmails) => [...prevEmails, { key: uuidv4(), value: '' }]);
    }
    // else {
    //     // Se o último email não for válido, remove-o do array
    //     // setEmails(emails.slice(0, -1));
    //   }
  };

  const audioTranscript = [
    'Eu sou a Milena e quero sugerir uma forma assertiva para você convidar o time para ser beta tester na Persora. Bom, a equipe compra a ideia quando sente real benefício para o time. Então, minha sugestão é que você vá direto ao ponto, mas tenha sensibilidade: não imponha, dê espaço para o time aceitar ser esse apoio, porque isso gera compromisso. Você pode dizer assim:',
    '"Pessoal, me cadastrei como beta tester da solução Persora, que tem a proposta de apoiar a gestão do time. Vai chegar um convite para vocês, e não é spam, vai chegar por e-mail, fui eu que mandei. No convite, vai ter um link para baixar o app. Vocês farão um micro teste de estilo comportamental e, na mesma hora, já vão acessar o seu estilo e o de todo o time. É aí que a gente começa a melhorar a nossa interação nas situações do dia a dia: one-on-one, feedback... O algoritmo da Persora personaliza de acordo com o estilo de cada pessoa do time, o que tende a nos ajudar a ter esses pontos de contato com mais qualidade. E aí, topam me ajudar nesse teste?"',
    'A mensagem é bem simples, e agora quero reforçar com algumas dicas. Quando as equipes começam a usar, elas sentem necessidade de espaço para falar sobre o assunto. Muitos líderes relataram que, na reunião seguinte, as pessoas queriam contar como elas eram, queriam ouvir dos colegas como eles eram; eles gostam de falar da percepção deles sobre essa descoberta. Isso reforça que a solução não é só do líder, é do time todo e que você está focado em ser um líder melhor para a equipe.',
    'Nós estamos unindo tecnologia e metodologia para entregar para você uma copiloto que fará parte do seu workflow, que será o seu suporte, que ajudará você a ter uma liderança ainda mais segura. Fazemos isso envolvendo todo mundo, e o time passa a te apoiar no dia a dia. Conta com a gente!',
  ];

  return (
    <>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <Sheet.Scroller>
            <div
              style={{
                minHeight: 400,
                padding: defaultTheme.spaces.screenEdge,
                paddingTop: 0,
                color: defaultTheme.colors.primary.light,
              }}
            >
              <h3 className="medium">
                Convidando o time
              </h3>
              <h4 className="sourcesans mb-3" style={{ lineHeight: '1.5rem', textWrap: 'balance' }}>
                Aqui está uma sugestão de como você pode contar para o time sobre a Persora.
              </h4>
              <AudioPlayer
                src={audioConvite}
                photo={FotoMilena}
                transcript={audioTranscript}
                mixpanelAudioTitleToTrack="Áudio genérico sobre a PIP"
                className="mt-2 my-4"
              />
              <form id="inviteUsersForm" onSubmit={(event) => handleInviteUsers(event, emails)}>
                {/* <FloatingLabel controlId="floatingTextarea2" label="Endereços de email"> */}
                <Form.Group>
                  <Form.Label>
                    Endereços de e-mail
                  </Form.Label>
                  {/* map as <Form.Control
                  as="input"
                  placeholder="E-mail "
                  className="mb-3"
                  name="emails"
                  type="email"
                /> */}
                  {emails.map((email) => (
                    <Form.Control
                      as="input"
                      placeholder="E-mail "
                      className="mb-3"
                      name="emails[]"
                      type="email"
                      key={email.key}
                      value={email.value}
                      onChange={(e) => {
                        handleChange(email.key, e.target.value);
                        handleEmails();
                      }}
                    />
                  ))}

                </Form.Group>
                {/* </FloatingLabel> */}
                {/* <FloatingLabel controlId="floatingTextarea3" label="Mensagem do convite"> */}
                <Form.Group className="d-none">
                  <Form.Label>Mensagem do convite</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Mensagem do convite que será enviada por email"
                    style={{ height: '140px' }}
                    defaultValue="Olá! Estou utilizando a Persora, uma ferramenta para ajudar na comunicação da equipe. Enviei o convite para você ingressar no time e gostaria de contar com a sua participação."
                    className="mb-3"
                    name="msg"
                  />
                </Form.Group>
                {/* </FloatingLabel> */}
                <Form.Group className="d-none">
                  <Form.Label>
                    Time
                    {' '}
                    <span> • Defina o time inicial destes membros</span>
                  </Form.Label>
                  <Form.Select aria-label="Time padrão" name="team">
                    {/* TODO Ajustar time default */}
                    {workspaceTeams.map((team) => (
                      <option key={team._id} value={team._id}>
                        {team.emoji ? team.emoji : '🤖'}
                        {' '}
                        {team.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <BetaTestesContainer className="my-4">
                  <BetaTesterContent>
                    <h2 className="medium sourcesans mb-3">
                      Você é um
                      {' '}
                      <b className="premium-text">beta tester!</b>
                      {' '}
                      👾
                    </h2>
                    <div className="emoji-paragraph">
                      <span>👑</span>
                      <p className="mb-2">1 usuário líder</p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>✨</span>
                      <p className="mb-2">
                        Até
                        {' '}
                        <s>4 liderados</s>
                        {' '}
                        20 liderados
                      </p>
                    </div>
                    <div className="emoji-paragraph">
                      <span>😎</span>
                      <p className="mb-2">A partir de 3 liderados, você acessa todas as funcionalidades.</p>
                    </div>
                    <p className="small mb-0">*As condições estão sujeitas a alterações, as quais serão comunicadas com 30 dias de antecedência através de aviso prévio.</p>
                    <PremiumDisclaimer modelo="botaoComoSera" />
                  </BetaTesterContent>
                </BetaTestesContainer>
                {totalInvitedUsers + totalValidEmails < 3 ? (
                  <PersoraButton
                    className="mt-4 mb-3"
                    type="button"
                    onClick={() => setAddUserConfirmSheetOpen(true)}
                  >
                    Convidar meu time
                  </PersoraButton>
                ) : (
                  <PersoraButton
                    className="mt-4 mb-3"
                    type="submit"
                    form="inviteUsersForm"
                    disabled={handleInviteUsersIsLoading}
                  >
                    {handleInviteUsersIsLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Convidar meu time'}
                  </PersoraButton>
                )}
              </form>
            </div>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet
        isOpen={addUserConfirmSheetIsOpen}
        onClose={() => setAddUserConfirmSheetOpen(false)}
        onOpenStart={startAndroidSheetHack}
        onCloseEnd={breakAndroidSheetHack}
        detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
        disableScrollLocking
      >
        <Sheet.Container>
          <Sheet.Header className="pt-2 pb-2 px-1" style={{ display: 'flex', justifyContent: 'start' }}>
            <PersoraButton tamanho="condensed" estilo="ghost" justIcon onClick={() => setAddUserConfirmSheetOpen(false)}>
              <ChevronLeft />
            </PersoraButton>
          </Sheet.Header>
          <Sheet.Content>
            <Sheet.Scroller>
              <div
                style={{
                  padding: defaultTheme.spaces.screenEdge,
                  paddingTop: 0,
                  color: defaultTheme.colors.primary.light,
                }}
              >
                <h2 className="medium sourcesans mb-2" style={{ color: defaultTheme.colors.primary.main }}>
                  Tem certeza que não quer adicionar mais
                  {' '}
                  {3 - (totalInvitedUsers + totalValidEmails)}
                  {' '}
                  liderado
                  {(3 - (totalInvitedUsers + totalValidEmails)) > 1 ? 's' : ''}
                  ?
                </h2>
                <p className="mb-2">
                  Ao adicionar pelo menos 3 liderados, você desbloqueia a
                  {' '}
                  funcionalidade de ver como o time está e o que fazer para melhorar.
                </p>
                <PersoraButton
                  className="mt-4 mb-3"
                  type="button"
                  onClick={() => setAddUserConfirmSheetOpen(false)}
                  disabled={handleInviteUsersIsLoading}
                >
                  Voltar e convidar mais pessoas
                </PersoraButton>
                <PersoraButton
                  className="mb-4"
                  type="submit"
                  form="inviteUsersForm"
                  disabled={handleInviteUsersIsLoading}
                  estilo="outline"
                >
                  {handleInviteUsersIsLoading ? <Clock tamanho={27} cor={defaultTheme.colors.primary.main} /> : 'Enviar convites'}
                </PersoraButton>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setAddUserConfirmSheetOpen(false)} />
      </Sheet>
    </>
  );
}

AddUserSheet.propTypes = {
  handleInviteUsers: PropTypes.func.isRequired,
  handleInviteUsersIsLoading: PropTypes.bool.isRequired,
  workspaceTeams: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    emoji: PropTypes.string,
  })).isRequired,
};
