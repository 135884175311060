import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import {
  Body,
  Container,
  Header,
} from './styles';

import defaultTheme from '../../assets/styles/themes/default';
import { UserContext } from '../../Context/UserContext';

import SoraNeutra from '../../assets/img/SoraFace/Neutra.svg';
import SoraBustoNerd from '../../assets/img/Sora/Nerd.svg';
import SoraBustoPensativa1 from '../../assets/img/Sora/Pensativa1.svg';
// import SoraBustoPensativa2 from '../../assets/img/Sora/Pensativa2.svg';
import SoraBustoRindoSutilmente from '../../assets/img/Sora/RindoSutilmente.svg';
import SoraBustoTriste from '../../assets/img/Sora/Triste.svg';
// import SoraBustoAnimada from '../../assets/img/Sora/Animada.svg';

// modules styles
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { checkIfPipEndsToday, returnQuestStatus } from '../../utils/dateUtils';
import { Cardiology } from '../DuotoneIcon';
import { Tag } from '../InstrumentComponent/styles';

SwiperCore.use([Pagination]);

export default function HighlightsHomeSlider({ setActiveTapBar }) {
  const { currentUserContextData } = useContext(UserContext);

  const objetivo1 = () => (
    <SwiperSlide className="pb-0">
      <Container
        className="mt-2"
        style={{
          background: defaultTheme.colors.surfaces.lightBlue,
          cursor: 'pointer',
        }}
        onClick={() => { setActiveTapBar('time'); }}
      >
        <Header>
          <h5>Status do meu time</h5>
        </Header>
        <Body>
          <div style={{ width: '130px' }}>
            <CircularProgressbarWithChildren
              strokeWidth={5}
            /* eslint-disable-next-line */
            value={(currentUserContextData.workspaceContext.totalUsersMapped * 100 / currentUserContextData.workspaceContext.totalUsers)}
              styles={buildStyles({
                pathColor: defaultTheme.colors.primary.main,
                trailColor: defaultTheme.colors.black20,
              })}
            >
              <img src={SoraNeutra} alt="Sora Olhos Alegres" width={85} />
            </CircularProgressbarWithChildren>

          </div>
          <div style={{ width: 'calc(100% - 135px)' }}>
            <div className="emoji-paragraph">
              <span style={{ fontSize: '1.2rem' }}>👋</span>
              <div>
                <p className="bold mb-0" style={{ lineHeight: '1.3rem' }}>
                  Convites aceitos
                </p>
                <p className="mb-3 small semibold" style={{ color: defaultTheme.colors.black60 }}>
                  <b className="bold" style={{ color: defaultTheme.colors.primary.main }}>
                    {currentUserContextData.workspaceContext.totalAuthenticatedUsers}
                  </b>
                  /
                  {currentUserContextData.workspaceContext.totalUsers}
                </p>
              </div>
            </div>
            <div className="emoji-paragraph">
              <span style={{ fontSize: '1.2rem' }}>🧭</span>
              <div>
                <p className="bold mb-0" style={{ lineHeight: '1.3rem' }}>
                  Estilos mapeados
                </p>
                <p className="mb-2 small semibold" style={{ color: defaultTheme.colors.black60 }}>
                  <b className="bold" style={{ color: defaultTheme.colors.primary.main }}>
                    {currentUserContextData.workspaceContext.totalUsersMapped}
                  </b>
                  /
                  {currentUserContextData.workspaceContext.totalUsers}
                </p>
              </div>
            </div>
            <div className="d-none">
              Total usuários:
              {' '}
              {currentUserContextData.workspaceContext.totalUsers - 1}
              <br />
              Usuários mapeados:
              {' '}
              {currentUserContextData.workspaceContext.totalUsersMapped}
              <br />
              Usuários convidados:
              {' '}
              {currentUserContextData.workspaceContext.totalInvitedUsers}
              <br />
              Convidados mapeados:
              {' '}
              {currentUserContextData.workspaceContext.invitedUsersMapped}
            </div>
          </div>
        </Body>
      </Container>
    </SwiperSlide>
  );

  /*
    Cards PIP - Só aparecem caso a pip:
    1. Esteja habilitada no workspace;
    2. O usuário seja isLedAt ou isLeaderAt de algum time que tenha a pip habilitada
    Estados possíveis do card:
    1. Agendada
    2. Em andamento
    3. Encerra hoje
    4. Encerrada (com resultado disponível)
  */

  // eslint-disable-next-line
  const pipQuantity = currentUserContextData?.pipContext?.length;
  // eslint-disable-next-line
  const isPipEnabledAtWorkspace = currentUserContextData
    ?.workspaceContext?.toolsSettings.pip?.enabled;
  // eslint-disable-next-line
  const isPipAvaliableAtWorkspace = currentUserContextData.pipContext.length > 0;
  const isLedAtTeamWithPipEnabled = currentUserContextData?.workspaceContext?.teams
    .filter((team) => currentUserContextData.isLedAt.includes(team._id))
    .some((team) => team.toolsSettings.pipEnabled);
  const isLeaderAtTeamWithPipEnabled = currentUserContextData?.workspaceContext?.teams
    .filter((team) => currentUserContextData.isLeaderAt.includes(team._id))
    .some((team) => team.toolsSettings.pipEnabled);
  const [showPipCard, setShowPipCard] = useState(false);
  const [currentPipCard, setCurrentPipCard] = useState(null);
  const [currentPipCardStatus, setCurrentPipCardStatus] = useState(null);

  // total de times que deveriam ter resultado
  const totalDeTimesQueDeveriamTerResultado = currentPipCard?.resultsTeams?.filter(
    (team) => team.emptyResultReason !== 'pipEnabledFalse',
  );
  // total de times que deveriam ter resultado, mas não tiveram
  const totalDeTimesSemResultado = totalDeTimesQueDeveriamTerResultado?.filter(
    (team) => !team.results?.zona,
  );

  useEffect(() => {
    if (
      isPipEnabledAtWorkspace // está ativa no workspace
      && isPipAvaliableAtWorkspace // está disponível (foi criada pelo menos uma no workspace)
      && (isLedAtTeamWithPipEnabled || isLeaderAtTeamWithPipEnabled)) { // é líder ou liderado
      // eslint-disable-next-line
      // console.log('isPipAvaliableAtWorkspace', isPipEnabledAtWorkspace, isPipAvaliableAtWorkspace, pipQuantity, isLedAtTeamWithPipEnabled, isLeaderAtTeamWithPipEnabled);
      setShowPipCard(true);
    }
    if (pipQuantity === 1) {
      setCurrentPipCard(currentUserContextData.pipContext[0]);
      setCurrentPipCardStatus(
        returnQuestStatus({
          startDate: currentUserContextData.pipContext[0].startDate,
          finalDate: currentUserContextData.pipContext[0].finalDate,
        }),
      );
    } else if (pipQuantity > 1) {
      const today = new Date();
      const mostRecentPipStartDate = new Date(currentUserContextData.pipContext[0]?.startDate);
      // Calcula a diferença em milissegundos entre
      // a data atual e a data de início do PIP mais recente
      const timeDifference = mostRecentPipStartDate - today;
      // Converte a diferença de tempo para horas
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      // Se mostRecentPip.startDate for no dia seguinte, currentPipCard receberá mostRecentPip
      if (hoursDifference <= 24) {
        setCurrentPipCard(currentUserContextData.pipContext[0]);
        setCurrentPipCardStatus(
          returnQuestStatus({
            startDate: currentUserContextData.pipContext[0].startDate,
            finalDate: currentUserContextData.pipContext[0].finalDate,
          }),
        );
      } else {
        // Se não, currentPipCard deverá receber leastRecentPip
        setCurrentPipCard(currentUserContextData.pipContext[1]);
        setCurrentPipCardStatus(
          returnQuestStatus({
            startDate: currentUserContextData.pipContext[1].startDate,
            finalDate: currentUserContextData.pipContext[1].finalDate,
          }),
        );
      }
    }
  }, [currentUserContextData]);

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (currentPipCard?.resultsTeams) {
      setDataLoaded(true);
    }
  }, [currentPipCard]);

  const objetivo2 = () => (
    <SwiperSlide className="pb-0">
      <Container
        className="mt-2"
        style={{
          background: defaultTheme.colors.surfaces.lightBlue,
          cursor: 'pointer',
          color: defaultTheme.colors.primary.light,
        }}
        onClick={() => { setActiveTapBar('organizacao'); }}
      >
        {currentPipCardStatus?.status === 'Agendada' && (
          <>
            <Header>
              <h5>PIP</h5>
            </Header>
            <Body className="py-2">
              <div style={{ width: '130px', textAlign: 'center' }}>
                <img src={SoraBustoNerd} alt="Sora Olhos Alegres" width={85} />
              </div>
              <div style={{ width: 'calc(100% - 120px)' }}>
                <h4 className="sourcesans bold mb-1" style={{ color: defaultTheme.colors.primary.main }}>
                  PIP agendada
                </h4>
                <p>
                  Em breve o time poderá responder a PIP, a pesquisa quinzenal que monitora
                  {' '}
                  os desafios do time e oferece suporte à liderança.
                </p>
                <div className="d-none">
                  {currentPipCard?._id}
                  -
                  {currentPipCardStatus?.status}
                </div>
              </div>
            </Body>
          </>
        )}
        {currentPipCardStatus?.status === 'Em andamento' && (
          currentUserContextData.type === 'Líder' ? (
            <>
              <Header>
                <h5>PIP</h5>
              </Header>
              <Body className="py-2">
                <div style={{ width: '130px', textAlign: 'center' }}>
                  <img src={SoraBustoNerd} alt="Sora Olhos Alegres" width={85} />
                </div>
                <div style={{ width: 'calc(100% - 120px)' }}>
                  <h4 className="sourcesans bold mb-1" style={{ color: defaultTheme.colors.primary.main }}>
                    {checkIfPipEndsToday(currentPipCard?.finalDate)
                      ? 'A PIP encerra hoje'
                      : 'PIP em andamento'}
                  </h4>
                  <p>
                    {checkIfPipEndsToday(currentPipCard?.finalDate)
                      ? 'Lembre-se: o resultado só estará disponível com no mínimo 3 respostas efetivas.'
                      : 'Ajude o time a lembrar até que se torne um hábito.'}
                  </p>
                </div>
              </Body>
            </>
          ) : (
            <>
              <Header>
                <h5>PIP</h5>
              </Header>
              <Body className="py-2">
                <div style={{ width: '130px', textAlign: 'center' }}>
                  <img src={SoraBustoNerd} alt="Sora Olhos Alegres" width={85} />
                </div>
                <div style={{ width: 'calc(100% - 120px)' }}>
                  <h4 className="sourcesans bold mb-1" style={{ color: defaultTheme.colors.primary.main }}>
                    PIP em andamento
                  </h4>
                  <p>
                    Você pode ajudar o time ao lembrar todos que
                    {' '}
                    a PIP está disponível para ser respondida.
                  </p>
                </div>
              </Body>
            </>
          )
        )}
        {currentPipCard && currentPipCardStatus?.status === 'Fechada' && (
          <>
            <Header>
              <h5>Resultados da PIP</h5>
            </Header>
            <Body className="py-2" style={{ alignItems: 'start' }}>
              <div style={{ width: '130px', textAlign: 'center' }}>
                {/* {console.log(
                  '=========> totalDeTimesSemResultado',
                  totalDeTimesSemResultado?.length,
                  'totalDeTimesQueDeveriamTerResultado',
                  totalDeTimesQueDeveriamTerResultado?.length,
                  currentPipCard,
                )} */}
                {/* todos os times tiveram resultado => sora fica feliz */}
                {totalDeTimesSemResultado?.length === 0 && (
                  <img src={SoraBustoRindoSutilmente} alt="Sora Rindo Sutilmente" width={95} />
                )}
                {/* todos os times sem resultado => sora triste */}
                {/* eslint-disable-next-line */}
                {totalDeTimesSemResultado?.length === totalDeTimesQueDeveriamTerResultado?.length && (
                  <img src={SoraBustoTriste} alt="Sora Triste" width={85} />
                )}
                {/* alguns times sem resultado => sora pensativa */}
                {/* eslint-disable-next-line */}
                {totalDeTimesSemResultado?.length !== 0 && totalDeTimesSemResultado?.length < totalDeTimesQueDeveriamTerResultado?.length && (
                  <img src={SoraBustoPensativa1} alt="Sora Pensativa" width={85} />
                )}
              </div>
              <div style={{ width: 'calc(100% - 120px)' }} className="hiddenLastHr">
                {/* {console.log(
                  'atrasoooooooo',
                  currentUserContextData.workspaceContext.teams,
                  currentPipCard.resultsTeams,
                )} */}
                {currentPipCard?.resultsTeams?.map((team) => {
                  // Encontra o time correspondente pelo teamId
                  const matchedTeam = currentUserContextData.workspaceContext.teams.find(
                    (t) => t._id === team.teamId,
                  );
                  // se currentUserContextData._idTeam inclui team.teamId
                  if (currentUserContextData._idTeam.includes(team.teamId)) {
                    if (!team.emptyResultReason || team.emptyResultReason === 'lessThanThreeAnswers') {
                      return (
                        <div key={team.teamId} style={{ color: defaultTheme.colors.primary.main }}>
                          <Tag className="mt-1 mb-1" style={{ borderRadius: '10px', color: defaultTheme.colors.primary.main }}>
                            {matchedTeam ? ` ${matchedTeam.emoji ? matchedTeam.emoji : '🤖'} ${matchedTeam.name ? matchedTeam.name : 'Time sem nome'}` : '🤖 Time sem nome'}
                          </Tag>
                          <p className="small semibold mb-2">{team.results?.zona ? 'Resultado disponível' : 'Respostas efetivas insuficientes'}</p>
                          <hr className="my-2" />
                        </div>
                      );
                    }
                  }
                  return '';
                })}
                <p className="">
                  Acesse
                  {' '}
                  <Cardiology tamanho={22} />
                  {' '}
                  <span className="semibold">Indicadores</span>
                  {' '}
                  para saber mais.
                </p>
              </div>

            </Body>
          </>
        )}
      </Container>
    </SwiperSlide>
  );

  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        autoHeight
        style={{ zIndex: 0, marginLeft: '-16px', width: 'calc(100% + 32px)' }}
        pagination={{
          clickable: true,
          el: '.swiper-goals-tracker-pagination',
        }}
        className="swiper-goals-tracker"
      >
        {showPipCard && dataLoaded && objetivo2()}
        {objetivo1()}
      </Swiper>
      <div className="swiper-goals-tracker-pagination" />
    </>
  );
}

HighlightsHomeSlider.propTypes = {
  setActiveTapBar: PropTypes.func.isRequired,
};
