/*
  Grupos
  (D) Dominância - (E) Executor - (A) Ação
  (I) - Influência - (C) Comunicador - (C) Conexão
  (S) - Estabilidade - (P) Planejador - (V) Visão
  (C) - Conformidade - (A) Analista - (O) Organização
*/

import DefaultTheme from '../assets/styles/themes/default';

const dColor = DefaultTheme.colors.disc.d.main;
const iColor = DefaultTheme.colors.disc.i.main;
const sColor = DefaultTheme.colors.disc.s.main;
const cColor = DefaultTheme.colors.disc.c.main;

const dLetterDisc = 'D';
const iLetterDisc = 'I';
const sLetterDisc = 'S';
const cLetterDisc = 'C';

const dLetterSlds = 'E';
const iLetterSlds = 'C';
const sLetterSlds = 'P';
const cLetterSlds = 'A';

const dLetterPersora = 'A';
const iLetterPersora = 'C';
const sLetterPersora = 'V';
const cLetterPersora = 'O';

const dWordDisc = 'Dominância';
const iWordDisc = 'Influência';
const sWordDisc = 'Estabilidade';
const cWordDisc = 'Conformidade';

const dWordSlds = 'Executor';
const iWordSlds = 'Comunicador';
const sWordSlds = 'Planejador';
const cWordSlds = 'Analista';

const dWordPersora = 'Ação';
const iWordPersora = 'Conexão';
const sWordPersora = 'Visão';
const cWordPersora = 'Organização';

// função para trocar as letras DISCdisc por ACVOacvo
export const discLettersToPersoraLetters = (letters) => {
  const replacements = {
    D: 'A',
    I: 'C',
    S: 'V',
    C: 'O',
    d: 'a',
    i: 'c',
    s: 'v',
    c: 'o',
  };
  return letters.replace(/[DISCdisc]/g, (match) => replacements[match]);
};

// função para substituir a palavra você pelo nome recebido
export const replaceVoce = ({ text, name }) => {
  let textoCorrigido = text;
  textoCorrigido = textoCorrigido.replace(/Você/g, name);
  textoCorrigido = textoCorrigido.replace(/você/g, name);
  return textoCorrigido;
};

// função para retornar palavras ou letras DISC
export const discWords = (letras) => {
  switch (letras) {
    case 'D':
      return {
        disc: `<span style='color: ${dColor};'>${dLetterDisc}</span>`,
        slds: `<span style='color: ${dColor};'>${dLetterSlds}</span>`,
        persora: `<span style='color: ${dColor};'>${dLetterPersora}</span>`,
        discWords: `<span style='color: ${dColor};'>${dWordDisc}</span>`,
        sldsWords: `<span style='color: ${dColor};'>${dWordSlds}</span>`,
        persoraWords: `<span style='color: ${dColor};'>${dWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${dColor};'>${dWordPersora}</span>`,
      };
    case 'Di':
      return {
        disc: `<span style='color: ${dColor};'>${dLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterDisc.toLowerCase()}</span>`,
        slds: `<span style='color: ${dColor};'>${dLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterSlds.toLowerCase()}</span>`,
        persora: `<span style='color: ${dColor};'>${dLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterPersora.toLowerCase()}</span>`,
        discWords: `<span style='color: ${dColor};'>${dWordDisc}</span> - <span style='color: ${iColor};'>${iWordDisc}</span>`,
        sldsWords: `<span style='color: ${dColor};'>${dWordSlds}</span> - <span style='color: ${iColor};'>${iWordSlds}</span>`,
        persoraWords: `<span style='color: ${dColor};'>${dWordPersora}</span> - <span style='color: ${iColor};'>${iWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${dColor};'>${dWordPersora}</span> e <span style='color: ${iColor};'>${iWordPersora}</span>`,
      };
    case 'DI':
      return {
        disc: `<span style='color: ${dColor};'>${dLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterDisc}</span>`,
        slds: `<span style='color: ${dColor};'>${dLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterSlds}</span>`,
        persora: `<span style='color: ${dColor};'>${dLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterPersora}</span>`,
        discWords: `<span style='color: ${dColor};'>${dWordDisc}</span> - <span style='color: ${iColor};'>${iWordDisc}</span>`,
        sldsWords: `<span style='color: ${dColor};'>${dWordSlds}</span> - <span style='color: ${iColor};'>${iWordSlds}</span>`,
        persoraWords: `<span style='color: ${dColor};'>${dWordPersora}</span> - <span style='color: ${iColor};'>${iWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${dColor};'>${dWordPersora}</span> e <span style='color: ${iColor};'>${iWordPersora}</span>`,
      };
    case 'ID':
      return {
        disc: `<span style='color: ${iColor};'>${iLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterDisc}</span>`,
        slds: `<span style='color: ${iColor};'>${iLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterSlds}</span>`,
        persora: `<span style='color: ${iColor};'>${iLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterPersora}</span>`,
        discWords: `<span style='color: ${iColor};'>${iWordDisc}</span> - <span style='color: ${dColor};'>${dWordDisc}</span>`,
        sldsWords: `<span style='color: ${iColor};'>${iWordSlds}</span> - <span style='color: ${dColor};'>${dWordSlds}</span>`,
        persoraWords: `<span style='color: ${iColor};'>${iWordPersora}</span> - <span style='color: ${dColor};'>${dWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${iColor};'>${iWordPersora}</span> e <span style='color: ${dColor};'>${dWordPersora}</span>`,
      };
    case 'Id':
      return {
        disc: `<span style='color: ${iColor};'>${iLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterDisc.toLowerCase()}</span>`,
        slds: `<span style='color: ${iColor};'>${iLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterSlds.toLowerCase()}</span>`,
        persora: `<span style='color: ${iColor};'>${iLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterPersora.toLowerCase()}</span>`,
        discWords: `<span style='color: ${iColor};'>${iWordDisc}</span> - <span style='color: ${dColor};'>${dWordDisc}</span>`,
        sldsWords: `<span style='color: ${iColor};'>${iWordSlds}</span> - <span style='color: ${dColor};'>${dWordSlds}</span>`,
        persoraWords: `<span style='color: ${iColor};'>${iWordPersora}</span> - <span style='color: ${dColor};'>${dWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${iColor};'>${iWordPersora}</span> e <span style='color: ${dColor};'>${dWordPersora}</span>`,
      };
    case 'I':
      return {
        disc: `<span style='color: ${iColor};'>${iLetterDisc}</span>`,
        slds: `<span style='color: ${iColor};'>${iLetterSlds}</span>`,
        persora: `<span style='color: ${iColor};'>${iLetterPersora}</span>`,
        discWords: `<span style='color: ${iColor};'>${iWordDisc}</span>`,
        sldsWords: `<span style='color: ${iColor};'>${iWordSlds}</span>`,
        persoraWords: `<span style='color: ${iColor};'>${iWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${iColor};'>${iWordPersora}</span>`,
      };
    case 'Is':
      return {
        disc: `<span style='color: ${iColor};'>${iLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterDisc.toLowerCase()}</span>`,
        slds: `<span style='color: ${iColor};'>${iLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterSlds.toLowerCase()}</span>`,
        persora: `<span style='color: ${iColor};'>${iLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterPersora.toLowerCase()}</span>`,
        discWords: `<span style='color: ${iColor};'>${iWordDisc}</span> - <span style='color: ${sColor};'>${sWordDisc}</span>`,
        sldsWords: `<span style='color: ${iColor};'>${iWordSlds}</span> - <span style='color: ${sColor};'>${sWordSlds}</span>`,
        persoraWords: `<span style='color: ${iColor};'>${iWordPersora}</span> - <span style='color: ${sColor};'>${sWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${iColor};'>${iWordPersora}</span> e <span style='color: ${sColor};'>${sWordPersora}</span>`,
      };
    case 'IS':
      return {
        disc: `<span style='color: ${iColor};'>${iLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterDisc}</span>`,
        slds: `<span style='color: ${iColor};'>${iLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterSlds}</span>`,
        persora: `<span style='color: ${iColor};'>${iLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterPersora}</span>`,
        discWords: `<span style='color: ${iColor};'>${iWordDisc}</span> - <span style='color: ${sColor};'>${sWordDisc}</span>`,
        sldsWords: `<span style='color: ${iColor};'>${iWordSlds}</span> - <span style='color: ${sColor};'>${sWordSlds}</span>`,
        persoraWords: `<span style='color: ${iColor};'>${iWordPersora}</span> - <span style='color: ${sColor};'>${sWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${iColor};'>${iWordPersora}</span> e <span style='color: ${sColor};'>${sWordPersora}</span>`,
      };
    case 'SI':
      return {
        disc: `<span style='color: ${sColor};'>${sLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterDisc}</span>`,
        slds: `<span style='color: ${sColor};'>${sLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterSlds}</span>`,
        persora: `<span style='color: ${sColor};'>${sLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterPersora}</span>`,
        discWords: `<span style='color: ${sColor};'>${sWordDisc}</span> - <span style='color: ${iColor};'>${iWordDisc}</span>`,
        sldsWords: `<span style='color: ${sColor};'>${sWordSlds}</span> - <span style='color: ${iColor};'>${iWordSlds}</span>`,
        persoraWords: `<span style='color: ${sColor};'>${sWordPersora}</span> - <span style='color: ${iColor};'>${iWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${sColor};'>${sWordPersora}</span> e <span style='color: ${iColor};'>${iWordPersora}</span>`,
      };
    case 'Si':
      return {
        disc: `<span style='color: ${sColor};'>${sLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterDisc.toLowerCase()}</span>`,
        slds: `<span style='color: ${sColor};'>${sLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterSlds.toLowerCase()}</span>`,
        persora: `<span style='color: ${sColor};'>${sLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterPersora.toLowerCase()}</span>`,
        discWords: `<span style='color: ${sColor};'>${sWordDisc}</span> - <span style='color: ${iColor};'>${iWordDisc}</span>`,
        sldsWords: `<span style='color: ${sColor};'>${sWordSlds}</span> - <span style='color: ${iColor};'>${iWordSlds}</span>`,
        persoraWords: `<span style='color: ${sColor};'>${sWordPersora}</span> - <span style='color: ${iColor};'>${iWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${sColor};'>${sWordPersora}</span> e <span style='color: ${iColor};'>${iWordPersora}</span>`,
      };
    case 'S':
      return {
        disc: `<span style='color: ${sColor};'>${sLetterDisc}</span>`,
        slds: `<span style='color: ${sColor};'>${sLetterSlds}</span>`,
        persora: `<span style='color: ${sColor};'>${sLetterPersora}</span>`,
        discWords: `<span style='color: ${sColor};'>${sWordDisc}</span>`,
        sldsWords: `<span style='color: ${sColor};'>${sWordSlds}</span>`,
        persoraWords: `<span style='color: ${sColor};'>${sWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${sColor};'>${sWordPersora}</span>`,
      };
    case 'Sc':
      return {
        disc: `<span style='color: ${sColor};'>${sLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterDisc.toLowerCase()}</span>`,
        slds: `<span style='color: ${sColor};'>${sLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterSlds.toLowerCase()}</span>`,
        persora: `<span style='color: ${sColor};'>${sLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterPersora.toLowerCase()}</span>`,
        discWords: `<span style='color: ${sColor};'>${sWordDisc}</span> - <span style='color: ${cColor};'>${cWordDisc}</span>`,
        sldsWords: `<span style='color: ${sColor};'>${sWordSlds}</span> - <span style='color: ${cColor};'>${cWordSlds}</span>`,
        persoraWords: `<span style='color: ${sColor};'>${sWordPersora}</span> - <span style='color: ${cColor};'>${cWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${sColor};'>${sWordPersora}</span> e <span style='color: ${cColor};'>${cWordPersora}</span>`,
      };
    case 'SC':
      return {
        disc: `<span style='color: ${sColor};'>${sLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterDisc}</span>`,
        slds: `<span style='color: ${sColor};'>${sLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterSlds}</span>`,
        persora: `<span style='color: ${sColor};'>${sLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterPersora}</span>`,
        discWords: `<span style='color: ${sColor};'>${sWordDisc}</span> - <span style='color: ${cColor};'>${cWordDisc}</span>`,
        sldsWords: `<span style='color: ${sColor};'>${sWordSlds}</span> - <span style='color: ${cColor};'>${cWordSlds}</span>`,
        persoraWords: `<span style='color: ${sColor};'>${sWordPersora}</span> - <span style='color: ${cColor};'>${cWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${sColor};'>${sWordPersora}</span> e <span style='color: ${cColor};'>${cWordPersora}</span>`,
      };
    case 'CS':
      return {
        disc: `<span style='color: ${cColor};'>${cLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterDisc}</span>`,
        slds: `<span style='color: ${cColor};'>${cLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterSlds}</span>`,
        persora: `<span style='color: ${cColor};'>${cLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterPersora}</span>`,
        discWords: `<span style='color: ${cColor};'>${cWordDisc}</span> - <span style='color: ${sColor};'>${sWordDisc}</span>`,
        sldsWords: `<span style='color: ${cColor};'>${cWordSlds}</span> - <span style='color: ${sColor};'>${sWordSlds}</span>`,
        persoraWords: `<span style='color: ${cColor};'>${cWordPersora}</span> - <span style='color: ${sColor};'>${sWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${cColor};'>${cWordPersora}</span> e <span style='color: ${sColor};'>${sWordPersora}</span>`,
      };
    case 'Cs':
      return {
        disc: `<span style='color: ${cColor};'>${cLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterDisc.toLowerCase()}</span>`,
        slds: `<span style='color: ${cColor};'>${cLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterSlds.toLowerCase()}</span>`,
        persora: `<span style='color: ${cColor};'>${cLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterPersora.toLowerCase()}</span>`,
        discWords: `<span style='color: ${cColor};'>${cWordDisc}</span> - <span style='color: ${sColor};'>${sWordDisc}</span>`,
        sldsWords: `<span style='color: ${cColor};'>${cWordSlds}</span> - <span style='color: ${sColor};'>${sWordSlds}</span>`,
        persoraWords: `<span style='color: ${cColor};'>${cWordPersora}</span> - <span style='color: ${sColor};'>${sWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${cColor};'>${cWordPersora}</span> e <span style='color: ${sColor};'>${sWordPersora}</span>`,
      };
    case 'C':
      return {
        disc: `<span style='color: ${cColor};'>${cLetterDisc}</span>`,
        slds: `<span style='color: ${cColor};'>${cLetterSlds}</span>`,
        persora: `<span style='color: ${cColor};'>${cLetterPersora}</span>`,
        discWords: `<span style='color: ${cColor};'>${cWordDisc}</span>`,
        sldsWords: `<span style='color: ${cColor};'>${cWordSlds}</span>`,
        persoraWords: `<span style='color: ${cColor};'>${cWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${cColor};'>${cWordPersora}</span>`,
      };
    case 'Cd':
      return {
        disc: `<span style='color: ${cColor};'>${cLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterDisc.toLowerCase()}</span>`,
        slds: `<span style='color: ${cColor};'>${cLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterSlds.toLowerCase()}</span>`,
        persora: `<span style='color: ${cColor};'>${cLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterPersora.toLowerCase()}</span>`,
        discWords: `<span style='color: ${cColor};'>${cWordDisc}</span> - <span style='color: ${dColor};'>${dWordDisc}</span>`,
        sldsWords: `<span style='color: ${cColor};'>${cWordSlds}</span> - <span style='color: ${dColor};'>${dWordSlds}</span>`,
        persoraWords: `<span style='color: ${cColor};'>${cWordPersora}</span> - <span style='color: ${dColor};'>${dWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${cColor};'>${cWordPersora}</span> e <span style='color: ${dColor};'>${dWordPersora}</span>`,
      };
    case 'CD':
      return {
        disc: `<span style='color: ${cColor};'>${cLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterDisc}</span>`,
        slds: `<span style='color: ${cColor};'>${cLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterSlds}</span>`,
        persora: `<span style='color: ${cColor};'>${cLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterPersora}</span>`,
        discWords: `<span style='color: ${cColor};'>${cWordDisc}</span> - <span style='color: ${dColor};'>${dWordDisc}</span>`,
        sldsWords: `<span style='color: ${cColor};'>${cWordSlds}</span> - <span style='color: ${dColor};'>${dWordSlds}</span>`,
        persoraWords: `<span style='color: ${cColor};'>${cWordPersora}</span> - <span style='color: ${dColor};'>${dWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${cColor};'>${cWordPersora}</span> e <span style='color: ${dColor};'>${dWordPersora}</span>`,
      };
    case 'DC':
      return {
        disc: `<span style='color: ${dColor};'>${dLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterDisc}</span>`,
        slds: `<span style='color: ${dColor};'>${dLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterSlds}</span>`,
        persora: `<span style='color: ${dColor};'>${dLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterPersora}</span>`,
        discWords: `<span style='color: ${dColor};'>${dWordDisc}</span> - <span style='color: ${cColor};'>${cWordDisc}</span>`,
        sldsWords: `<span style='color: ${dColor};'>${dWordSlds}</span> - <span style='color: ${cColor};'>${cWordSlds}</span>`,
        persoraWords: `<span style='color: ${dColor};'>${dWordPersora}</span> - <span style='color: ${cColor};'>${cWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${dColor};'>${dWordPersora}</span> e <span style='color: ${cColor};'>${cWordPersora}</span>`,
      };
    case 'Dc':
      return {
        disc: `<span style='color: ${dColor};'>${dLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterDisc.toLowerCase()}</span>`,
        slds: `<span style='color: ${dColor};'>${dLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterSlds.toLowerCase()}</span>`,
        persora: `<span style='color: ${dColor};'>${dLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterPersora.toLowerCase()}</span>`,
        discWords: `<span style='color: ${dColor};'>${dWordDisc}</span> - <span style='color: ${cColor};'>${cWordDisc}</span>`,
        sldsWords: `<span style='color: ${dColor};'>${dWordSlds}</span> - <span style='color: ${cColor};'>${cWordSlds}</span>`,
        persoraWords: `<span style='color: ${dColor};'>${dWordPersora}</span> - <span style='color: ${cColor};'>${cWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${dColor};'>${dWordPersora}</span> e <span style='color: ${cColor};'>${cWordPersora}</span>`,
      };
    case 'DS':
      return {
        disc: `<span style='color: ${dColor};'>${dLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterDisc}</span>`,
        slds: `<span style='color: ${dColor};'>${dLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterSlds}</span>`,
        persora: `<span style='color: ${dColor};'>${dLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${sColor};'>${sLetterPersora}</span>`,
        discWords: `<span style='color: ${dColor};'>${dWordDisc}</span> - <span style='color: ${sColor};'>${sWordDisc}</span>`,
        sldsWords: `<span style='color: ${dColor};'>${dWordSlds}</span> - <span style='color: ${sColor};'>${sWordSlds}</span>`,
        persoraWords: `<span style='color: ${dColor};'>${dWordPersora}</span> - <span style='color: ${sColor};'>${sWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${dColor};'>${dWordPersora}</span> e <span style='color: ${sColor};'>${sWordPersora}</span>`,
      };
    case 'SD':
      return {
        disc: `<span style='color: ${sColor};'>${sLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterDisc}</span>`,
        slds: `<span style='color: ${sColor};'>${sLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterSlds}</span>`,
        persora: `<span style='color: ${sColor};'>${sLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${dColor};'>${dLetterPersora}</span>`,
        discWords: `<span style='color: ${sColor};'>${sWordDisc}</span> - <span style='color: ${dColor};'>${dWordDisc}</span>`,
        sldsWords: `<span style='color: ${sColor};'>${sWordSlds}</span> - <span style='color: ${dColor};'>${dWordSlds}</span>`,
        persoraWords: `<span style='color: ${sColor};'>${sWordPersora}</span> - <span style='color: ${dColor};'>${dWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${sColor};'>${sWordPersora}</span> e <span style='color: ${dColor};'>${dWordPersora}</span>`,
      };
    case 'IC':
      return {
        disc: `<span style='color: ${iColor};'>${iLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterDisc}</span>`,
        slds: `<span style='color: ${iColor};'>${iLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterSlds}</span>`,
        persora: `<span style='color: ${iColor};'>${iLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${cColor};'>${cLetterPersora}</span>`,
        discWords: `<span style='color: ${iColor};'>${iWordDisc}</span> - <span style='color: ${cColor};'>${cWordDisc}</span>`,
        sldsWords: `<span style='color: ${iColor};'>${iWordSlds}</span> - <span style='color: ${cColor};'>${cWordSlds}</span>`,
        persoraWords: `<span style='color: ${iColor};'>${iWordPersora}</span> - <span style='color: ${cColor};'>${cWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${iColor};'>${iWordPersora}</span> e <span style='color: ${cColor};'>${cWordPersora}</span>`,
      };
    case 'CI':
      return {
        disc: `<span style='color: ${cColor};'>${cLetterDisc}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterDisc}</span>`,
        slds: `<span style='color: ${cColor};'>${cLetterSlds}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterSlds}</span>`,
        persora: `<span style='color: ${cColor};'>${cLetterPersora}</span><span style='opacity: .7;'> - </span><span style='color: ${iColor};'>${iLetterPersora}</span>`,
        discWords: `<span style='color: ${cColor};'>${cWordDisc}</span> - <span style='color: ${iColor};'>${iWordDisc}</span>`,
        sldsWords: `<span style='color: ${cColor};'>${cWordSlds}</span> - <span style='color: ${iColor};'>${iWordSlds}</span>`,
        persoraWords: `<span style='color: ${cColor};'>${cWordPersora}</span> - <span style='color: ${iColor};'>${iWordPersora}</span>`,
        persoraWordsAnd: `<span style='color: ${cColor};'>${cWordPersora}</span> e <span style='color: ${iColor};'>${iWordPersora}</span>`,
      };
    default:
      return {
        disc: '?',
        slds: '?',
        persora: '?',
        discWords: '?',
        sldsWords: '?',
        persoraWords: '?',
        persoraWordsAnd: '?',
      };
  }
};

// função para converver letras disc para padrão persora
// (dLetterPersora, iLetterPersora, sLetterPersora, cLetterPersora)
export const discLetterToPersoraLetter = (letra) => {
  switch (letra) {
    case 'D':
      return dLetterPersora;
    case 'd':
      return dLetterPersora.toLowerCase();
    case 'I':
      return iLetterPersora;
    case 'i':
      return iLetterPersora.toLowerCase();
    case 'S':
      return sLetterPersora;
    case 's':
      return sLetterPersora.toLowerCase();
    case 'C':
      return cLetterPersora;
    case 'c':
      return cLetterPersora.toLowerCase();
    default:
      return 'Erro';
  }
};

// função para colorir letras do perfil
export const coloredLetters = (letras) => {
  const firstLetter = letras.charAt(0);
  const secondLetter = (letras.charAt(1) ? letras.charAt(1) : '');
  let firstLetterColor = '';
  let secondLetterColor = '';

  // swith firstLetterColor .toLowerCase()

  switch (firstLetter.toLowerCase()) {
    case 'd':
      firstLetterColor = `<span style='color: ${dColor};'>${discLetterToPersoraLetter(firstLetter)}</span>`;
      break;
    case 'i':
      firstLetterColor = `<span style='color: ${iColor};'>${discLetterToPersoraLetter(firstLetter)}</span>`;
      break;
    case 's':
      firstLetterColor = `<span style='color: ${sColor};'>${discLetterToPersoraLetter(firstLetter)}</span>`;
      break;
    case 'c':
      firstLetterColor = `<span style='color: ${cColor};'>${discLetterToPersoraLetter(firstLetter)}</span>`;
      break;
    default:
      firstLetterColor = discLetterToPersoraLetter(firstLetter);
  }

  if (secondLetter) {
    switch (secondLetter.toLowerCase()) {
      case 'd':
        secondLetterColor = `<span style='color: ${dColor};'>${discLetterToPersoraLetter(secondLetter)}</span>`;
        break;
      case 'i':
        secondLetterColor = `<span style='color: ${iColor};'>${discLetterToPersoraLetter(secondLetter)}</span>`;
        break;
      case 's':
        secondLetterColor = `<span style='color: ${sColor};'>${discLetterToPersoraLetter(secondLetter)}</span>`;
        break;
      case 'c':
        secondLetterColor = `<span style='color: ${cColor};'>${discLetterToPersoraLetter(secondLetter)}</span>`;
        break;
      default:
        secondLetterColor = discLetterToPersoraLetter(secondLetter);
    }
  }

  return `${firstLetterColor}${secondLetterColor}`;
};
