import { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import api from '../services/backend';
import { UserContext } from '../Context/UserContext';

function OnesignalCookieWatcher() {
  const { currentUserContextData } = useContext(UserContext);
  const userOnesignalPushIds = currentUserContextData?.idsOneSignal;
  const [hasUpdated, setHasUpdated] = useState(false);

  const updateDeviceId = async (deviceId) => {
    try {
      const body = { onesignal_push_id: deviceId };
      await api.patch('/user/addOneSignalId', body);
      console.log('OnesignalPushId atualizado com sucesso:', deviceId);
      setHasUpdated(true);
    } catch (error) {
      console.error('Erro ao atualizar o OnesignalPushId:', error);
    }
  };

  useEffect(() => {
    if (hasUpdated) return; // Se já foi atualizado, para de vigiar

    const intervalId = setInterval(() => {
      const cookieValue = Cookies.get('onesignal_push_id');

      if (cookieValue && !userOnesignalPushIds.includes(cookieValue)) {
        // O cookie existe e não está no array, então faz a atualização
        updateDeviceId(cookieValue);
      }
    }, 1000); // Vigia a cada 1 segundo

    // Limpa o intervalo quando o componente desmonta
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [hasUpdated, userOnesignalPushIds]);

  return null; // Este componente não precisa renderizar nada
}

export default OnesignalCookieWatcher;
