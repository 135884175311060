import { Sheet } from 'react-modal-sheet';
import { v4 as uuidv4 } from 'uuid';
import { Ratio } from 'react-bootstrap';
import ReactPlayer from 'react-player/youtube';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { useContext, useEffect, useRef } from 'react';
import defaultTheme from '../../assets/styles/themes/default';
import { UserContext } from '../../Context/UserContext';

export default function PersoraManifest() {
  const { currentUserContextData } = useContext(UserContext);
  const manifest = [
    'Os smartphones, a economia global, o conhecimento. A inteligência artificial, os negócios digitais, a saúde mental. O trabalho remoto, a luta por direitos, a hiperconexão.',
    'De tudo que se transformou e transforma nossa vida profissional, nada é mais complexo e desafiador quanto ser líder.',
    'Liderar é olhar pra frente sem esquecer quem está do lado. É ter certeza do que está fazendo e ao mesmo tempo sempre se questionar. É teoria junto com prática. Produtividade e realização. É CPF e CNPJ.',
    'E foi pensando assim que criamos a Persora, uma solução que está transformando a forma de entender e construir a liderança para as empresas.',
    'É uma plataforma digital, e isso é bom: é imparcial e está disponível sempre. Mas a Persora também é humana: é personalizada e torna as pessoas e os negócios melhores.',
    'Concretiza a empatia, o fit cultural, o feedback; coisas que eram abstratas ou difíceis…',
    'A Persora é um acelerador no processo de liderar; É um sistema que entende os times sob diferentes aspectos e direciona o líder para agir com cada um de forma simples e prática.',
    'Porque, se tudo que muda nas nossas vidas transforma a forma de ser líder,',
    'Você pode ter certeza: a gente também pode transformar a forma de liderar.',
  ];
  const youtubeVideoLink = 'https://www.youtube.com/embed/3CJc1UKBPPQ?si=h-O0ue2NjivDFVCs';
  // const sproutVideoDirectFile = 'https://api-files.sproutvideo.com/file/a790d7b31017e3c12e/222ba271986ef34b/1080.mp4';
  // const sproutVideoLink = 'https://videos.sproutvideo.com/embed/a790d7b31017e3c12e/222ba271986ef34b?playerTheme=dark&amp;playerColor=';

  const handleProgress = (state) => {
    const playedPercentage = state.played * 100;
    if (playedPercentage >= 70 && playedPercentage < 71) {
      console.log('A reprodução atingiu 70%');
      if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
        ReactGA.event({
          category: 'Engajamento',
          action: 'video_assistido',
          video_title: 'Manifesto Persora',
        });
        mixpanel.track('video_assistido', {
          category: 'Engajamento',
          company_id: currentUserContextData._idWorkspace[0],
          video_title: 'Manifesto Persora',
        });
      }
    }
  };

  const componenteRef = useRef();
  const timeoutRef = useRef();
  useEffect(() => {
    // Função para fazer o console.log após 45 segundos
    const handleTimeout = () => {
      // if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
      //   ReactGA.event({
      //     category: 'BoasVindas',
      //     action: 'ficar_45s_no_manifesto',
      //   });
      //   mixpanel.track('ficar_45s_no_manifesto', {
      //     category: 'BoasVindas',
      //     company_id: currentUserContextData._idWorkspace[0],
      //   });
      // }
      console.log('Você ficou 45 segundos no manifesto');
    };
    // Inicia o timeout após 45 segundos
    timeoutRef.current = setTimeout(handleTimeout, 45000);
    // Limpa o timeout quando o componente é desmontado
    return () => clearTimeout(timeoutRef.current);
  }, []);
  // Dependência vazia para garantir que o efeito é executado apenas uma vez durante a montagem

  return (
    <Sheet.Container ref={componenteRef}>
      <Sheet.Header />
      <Sheet.Content>
        <Sheet.Scroller className="no-pull-refresh">
          <div
            style={{
              minHeight: 400,
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: 0,
              color: defaultTheme.colors.primary.main,
            }}
          >
            <h3 className="medium mb-3">
              Nosso manifesto
            </h3>
            <Ratio aspectRatio="16x9" style={{ borderRadius: '12px', overflow: 'hidden' }}>
              <ReactPlayer
                url={youtubeVideoLink}
                width="100%"
                height="100%"
                config={{
                  youtube: {
                    playerVars: {
                      controls: 0, // Define para 0 para ocultar os controles padrões do YouTube
                      showinfo: 0,
                      modestbranding: 1,
                    },
                  },
                }}
                onProgress={handleProgress}
              />
              {/* <iframe
                width="560"
                height="315"
                src={youtubeVideoLink}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write;
                encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{ borderRadius: '12px' }}
              /> */}
              {/* <iframe
                className="sproutvideo-player"
                src={sproutVideoLink}
                style={{
                  position: 'absolute', width: '100%',
                  height: '100%', left: 0, top: 0, borderRadius: '12px',
                }}
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                title="Video Player"
              /> */}
            </Ratio>
            <h5 className="semibold mt-4 mb-2">
              Transcrição
            </h5>
            {/* manifest map to put paragraphs in each position of array */}
            {manifest.map((paragraph) => (
              <p key={uuidv4()} style={{ color: defaultTheme.colors.primary.light }}>{paragraph}</p>
            ))}
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}
