import styled from 'styled-components';

export const PageFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  background-color: #FFF;

  /* se for uma tela de computador  */
  /* webapp */
  @media (min-width: 768px) {
    max-width: 600px;
    margin: 20px auto;
    border-radius: 20px;
    overflow: hidden;
    min-height: calc(100dvh - 40px) !important;
    height: calc(100dvh - 40px) !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, .2);
  }
`;

export const MainContainer = styled.main`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  /* justify-content: center; */
  width: 100vw;
  height: 100dvh;
  background-color: #FFF;
`;

export const Content = styled.div`
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding-top: 2rem;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;

  h3{
    margin-top: 20px;
  }
`;
