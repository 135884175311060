import { Sheet } from 'react-modal-sheet';
import { Accordion } from 'react-bootstrap';
import defaultTheme from '../../assets/styles/themes/default';
import Os4Estilos from '../../assets/img/os-4-estilos.svg';
import Disclaimer from '../../components/Disclaimer';
import { Brain } from '../../components/DuotoneIcon';

export default function LearnMoreOsEstilosSheet() {
  return (
    <Sheet.Container>
      <Sheet.Header />
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              minHeight: 400,
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: 0,
              color: defaultTheme.colors.primary.main,
            }}
          >
            <h3 className="medium">
              Os estilos
            </h3>
            <img src={Os4Estilos} alt="Os 4 estilos" className="my-3 mb-4 w-100" />
            <p className="mb-4">
              Os estilos comportamentais são fundamentados na combinação de quatro grupos:
              {' '}
              <b style={{ color: defaultTheme.colors.disc.d.main }}>Ação</b>
              ,
              {' '}
              <b style={{ color: defaultTheme.colors.disc.i.main }}>Conexão</b>
              ,
              {' '}
              <b style={{ color: defaultTheme.colors.disc.s.main }}>Visão</b>
              {' '}
              e
              {' '}
              <b style={{ color: defaultTheme.colors.disc.c.main }}>Organização</b>
              .
              {' '}
              A partir desses grupos, surgem várias combinações que resultam em
              {' '}
              estilos singulares de percepção do mundo.
            </p>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Ação</Accordion.Header>
                <Accordion.Body>
                  <p className="mb-0">
                    São as pessoas do grupo
                    {' '}
                    <b style={{ color: defaultTheme.colors.disc.d.main }}>Ação</b>
                    {' '}
                    que impulsionam as iniciativas do time. Com alto senso de urgência, estão sempre
                    {' '}
                    prontas para superar obstáculos e manter o time em contínuo movimento.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Conexão</Accordion.Header>
                <Accordion.Body>
                  <p className="mb-0">
                    São as pessoas do grupo
                    {' '}
                    <b style={{ color: defaultTheme.colors.disc.i.main }}>Conexão</b>
                    {' '}
                    que asseguram uma comunicação fluida no time. Elas transitam
                    {' '}
                    rapidamente entre diversos assuntos, conectando-se profundamente com todos.
                    {' '}
                    Elas se energizam interagindo com o time.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Visão</Accordion.Header>
                <Accordion.Body>
                  <p className="mb-0">
                    São as pessoas do grupo
                    {' '}
                    <b style={{ color: defaultTheme.colors.disc.s.main }}>Visão</b>
                    {' '}
                    que asseguram estratégias sólidas. Possuem pensamento estruturado
                    {' '}
                    e visão clara do cenário completo, desenvolvendo os melhores planejamentos.
                    {' '}
                    Além disso, são frequentemente as mais empáticas.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Organização</Accordion.Header>
                <Accordion.Body>
                  <p className="mb-0">
                    São as pessoas do grupo
                    {' '}
                    <b style={{ color: defaultTheme.colors.disc.c.main }}>Organização</b>
                    {' '}
                    que asseguram a consistência do time. Elas valorizam regras e procedimentos,
                    {' '}
                    e destacam-se em tarefas que exigem análises profundas. São a base que protege
                    {' '}
                    e fortalece o time.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Disclaimer
              estilo="reference"
              titulo="Referência"
              icone={<Brain cor={defaultTheme.colors.primary.main} />}
              className="mt-4 text-start mb-4"
            >
              <p className="mb-1">
                Fundamentado em instrumentos já validados, como
                {' '}
                <b>DISC</b>
                {' '}
                e
                {' '}
                <b>16personalities</b>
                ,
                {' '}
                desenvolvemos um algoritmo próprio de Estilos Comportamentais da Persora.
              </p>
            </Disclaimer>
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}
