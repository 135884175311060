import { PropTypes } from 'prop-types';
import {
  Container,
  Icon,
  Number,
} from './styles';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { ArrowRight, TrendingDown, TrendingUp } from '../DuotoneIcon';
// import {
//   ChevronSmallRight,
//   CircleChecked,
//   Copy,
// } from '../DuotoneIcon';
import defaultTheme from '../../assets/styles/themes/default';

// /**
//  * @param {string} [props.instrumentTag=''] - Ex.: PIP | CULTURA | SÁUDE MENTAL | etc
//  * @param {string} [props.statusTag=''] - Ex.: Desabilitada | Aberta | Fechada | etc
//  * @param {string} [props.styleTag='default'] - ('default', 'success', 'warning')
//  * @returns {JSX.Element}
//  */
export default function Trends({
  prevValue,
  currentValue,
  iconSize,
  returnIntDiference,
  manteveSeText,
  hiddenText,
  ...props
}) {
  const diference = currentValue - prevValue;
  const diferencePercentage = (diference / prevValue) * 100;
  const isPositive = diference > 0;
  if (diference === 0) {
    return (
      <Container {...props}>
        <Icon>
          <ArrowRight cor={defaultTheme.colors.trends.const.icon} tamanho={iconSize} />
        </Icon>
        {hiddenText ? '' : <Number style={{ color: defaultTheme.colors.trends.const.icon }}>{manteveSeText}</Number> }
      </Container>
    );
  }
  return (
    <Container {...props}>
      <Icon>
        {isPositive
          ? <TrendingUp cor={defaultTheme.colors.trends.up.icon} tamanho={iconSize} />
          : <TrendingDown cor={defaultTheme.colors.trends.down.icon} tamanho={iconSize} /> }
      </Icon>
      <Number style={{ color: `${isPositive ? defaultTheme.colors.trends.up.icon : defaultTheme.colors.trends.down.icon}` }}>
        {returnIntDiference ? Math.abs(diference) : `${Math.abs(diferencePercentage).toFixed(2)}%` }
      </Number>
    </Container>
  );
}

Trends.propTypes = {
  prevValue: PropTypes.number.isRequired,
  currentValue: PropTypes.number.isRequired,
  iconSize: PropTypes.number,
  returnIntDiference: PropTypes.bool,
  manteveSeText: PropTypes.string,
  hiddenText: PropTypes.bool,
};
Trends.defaultProps = {
  iconSize: 20,
  returnIntDiference: false,
  hiddenText: false,
  manteveSeText: 'manteve-se',
};
