import styled from 'styled-components';

export const PageFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  background-color: ${({ theme }) => theme.colors.surfaces.lightOrange};

  /* se for uma tela de computador  */
  /* webapp */
  @media (min-width: 768px) {
    max-width: 600px;
    margin: 20px auto;
    border-radius: 20px;
    overflow: hidden;
    min-height: calc(100dvh - 40px) !important;
    height: calc(100dvh - 40px) !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, .2);
  }
`;

export const MainContainer = styled.main`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;
