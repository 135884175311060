import { Sheet } from 'react-modal-sheet';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { useContext } from 'react';
import defaultTheme from '../../assets/styles/themes/default';
import PersoraButton from '../../components/Button';
import gradientBg1 from '../../assets/img/gradient-bg-1.svg';
import soraPremium3 from '../../assets/img/premium-sora-3.svg';
import { MessageCircleLines, PinpaperCheck, Trophy } from '../../components/DuotoneIcon';
import { UserContext } from '../../Context/UserContext';

const StyledDiv = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.surfaces.white};
  background-color: ${({ theme }) => theme.colors.primary.main};
  background-image: url(${gradientBg1});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: none;
  border: none;
  border-radius: 16px;
  border-color: ${({ theme }) => theme.colors.primary.main};
  font-size: 18px;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
  transition: all 0.2s ease-in-out;
  height: fit-content;
  text-align: left;
  padding: 28px 30px;
  overflow: hidden;
  span {
    background: rgba(119 , 199 , 228, .25);
    min-width: 44px;
    height: 44px;
    text-align: center;
    line-height: 42px;
    border-radius: 44px;
  }
  p {
    font-weight: 600;
  }
`;

export default function PremiumAbout({ setPremiumAboutSheetOpen, setPremiumPricesSheetOpen }) {
  const { currentUserContextData } = useContext(UserContext);
  return (
    <Sheet.Container>
      <Sheet.Header />
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: 0,
              color: defaultTheme.colors.primary.main,
            }}
          >
            <p className="mt-2 mb-3 text-center">
              <img src={soraPremium3} alt="Sora Premium" width="100%" />
            </p>
            <h2 className="premium-text mb-3 sourcesans" style={{ lineHeight: '1.9rem' }}>
              Dar feedback é difícil. Desligar alguém é difícil.
              {' '}
              Construir confiança é difícil. Mas agora você não está sozinho.
            </h2>
            <p className="sourcesans mb-3">
              Ao inserir todo o time, você vai:
            </p>
            <StyledDiv className="mb-2">
              <div className="emoji-paragraph">
                <span><MessageCircleLines cor="#FFFFFF" /></span>
                <p className="pt-2 mb-2">Saber como se comunicar e aproveitar o melhor de cada pessoa do time.</p>
              </div>
              <div className="emoji-paragraph">
                <span><PinpaperCheck cor="#FFFFFF" /></span>
                <p className="pt-2 mb-2">
                  Entender como o time está e saber o que fazer
                  {' '}
                  para melhorar continuamente.
                </p>
              </div>
              <div className="emoji-paragraph">
                <span><Trophy cor="#FFFFFF" /></span>
                <p className="pt-2 mb-2">Manter todos alinhados com o propósito e as regras do jogo.</p>
              </div>
            </StyledDiv>
            <p className="text-center mb-4 d-none">
              Cancele quando quiser
            </p>
            <PersoraButton
              className="mt-3 mb-1"
              onClick={() => {
                if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
                  ReactGA.event({
                    category: 'Premium',
                    action: 'abrir_valores_premium',
                  });
                  mixpanel.track('abrir_valores_premium', {
                    category: 'Premium',
                    company_id: currentUserContextData._idWorkspace[0],
                  });
                }
                setPremiumPricesSheetOpen(true);
              }}
              isPremiumButton
            >
              Quero saber valores
            </PersoraButton>
            <PersoraButton estilo="ghost" className="mb-2" onClick={() => { setPremiumAboutSheetOpen(false); }}>
              <span className="premium-text dmsans medium">Agora não</span>
            </PersoraButton>
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}

PremiumAbout.propTypes = {
  setPremiumAboutSheetOpen: PropTypes.func.isRequired,
  setPremiumPricesSheetOpen: PropTypes.func.isRequired,
};
