import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  &.firstAuthFalse {
    opacity: .8;
    filter: grayscale(100%);
  }
`;

export const CardHeader = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  display: flex;
`;

export const UserIcon = styled.div`
  position: absolute;
  left: 28px;
  top: -12px;
  transform: rotate(30deg);
`;

export const CardButtons = styled.div`
background: ${({ theme }) => theme.colors.fullWhite};
padding: 12px ${({ theme }) => theme.spaces.screenEdge};
display: flex;
justify-content: space-between;
`;
