/* eslint-disable */

import { Sheet } from 'react-modal-sheet';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import defaultTheme from '../../assets/styles/themes/default';

const DocContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const SectionLevel = styled.div`
  margin-top: 20px;
`;
const SectionTitle = styled.h5`
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
`;
const SectionContent = styled.div`
  margin-top: 15px;
`;
const I = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;
`;
const INumber = styled.div`
  font-weight: bold;
  text-align:  right;
  width: 30px;
  font-size: .9rem;
`;
const IContent = styled.div`
  text-align:  left;
  width: calc(100% - 30px - 10px);
`;
const SubI = styled.div`
  width: 100%;
  padding-left: 40px;
`;

function ConteudoDosTermos() {
  return (
    <>
      <h3 className="medium mb-4">
        Termos de Uso
      </h3>
      <p className="mb-1"><b>Olá, seja bem-vindo (a) à Persora!</b></p>
      <p>Reveja cuidadosamente estes Termos e Condições de Uso antes de utilizar os serviços oferecidos pela Persora, com sede registrada no endereço Avenida João Baptista Parra, 673 - Praia do Suá, Vitória - ES, 29.052-123.</p>
      <DocContainer>
        {/* ######## SECTION 1 ######## */}
        <SectionLevel>
          <SectionTitle>1. Termos gerais</SectionTitle>
          <SectionContent>
            <p>
              Estes Termos constituem um acordo legalmente vinculativo entre a
              {' '}
              <b>Persora</b>
              {' '}
              e você, regulando o uso do site localizado em
              {' '}
              <b>https://www.persora.app</b>
              {' '}
              (o “Site”) e os serviços acessíveis através do aplicativo móvel
              {' '}
              <b>Persora</b>
              {' '}
              (“App”) e serviços relacionados, incluindo todas as informações, textos, gráficos, softwares e nossos e-mails (o “Conteúdo”). Para facilitar a leitura destes Termos, o Site, o App, o Conteúdo e nossos serviços são coletivamente chamados de “Serviços”.
            </p>
            <p>
              Por favor, reveja também nossa Política de Privacidade. Os termos da Política de Privacidade e outros termos suplementares, políticas ou documentos que possam ser postados nos Serviços de tempos em tempos são expressamente incorporados aqui por referência.
            </p>
            <p>
              Podemos alterar estes Termos nesta página sempre disponível para você neste aplicativo. Podemos fornecer aviso sobre algumas mudanças críticas, por exemplo, por e-mail ou postando notificações nos Serviços, mas não somos obrigados a fazê-lo em todos os casos. Quaisquer outras alterações serão notificadas a você apenas atualizando a data "Última Atualização" destes Termos, e você renuncia a qualquer direito de receber aviso específico de cada mudança.
            </p>
            <p>
              Se você não concordar com as alterações, deverá parar de usar os Serviços. O uso dos Serviços após quaisquer alterações destes Termos significa que você aceita tais alterações.
              {' '}
              <b>SE VOCÊ NÃO CONCORDAR COM QUALQUER PARTE DESTES TERMOS, OU SE VOCÊ NÃO ESTIVER QUALIFICADO OU AUTORIZADO A ESTAR VINCULADO POR ESTES TERMOS, NÃO FAÇA O DOWNLOAD DO APP OU ACESSE OU USE OS SERVIÇOS DE OUTRA FORMA.</b>
            </p>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 2 ######## */}
        <SectionLevel>
          <SectionTitle>2. Renúncias importantes</SectionTitle>
          <SectionContent>
            <p>
              Não fazemos garantias concernentes ao nível de sucesso que você pode experimentar ao seguir os conselhos e estratégias contidos no app. Os depoimentos e exemplos que possam ser fornecidos nos serviços são resultados excepcionais, que podem não se aplicar a uma pessoa média, e não têm a intenção de representar ou garantir que qualquer um alcançará resultados semelhantes ou iguais.
            </p>
            <p>
              Além de todas as outras limitações e renúncias nestes termos, a empresa isenta-se de qualquer responsabilidade ou perda em conexão com o conteúdo fornecido nos serviços.
            </p>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 3 ######## */}
        <SectionLevel>
          <SectionTitle>3. Objeto</SectionTitle>
          <SectionContent>
            <p>
              O presente Instrumento tem como objeto o seguinte:
            </p>
            <I>
              <INumber>3.1</INumber>
              <IContent>A Licenciante é a proprietária e legítima detentora de todos os direitos sobre o Software Persora. Durante o prazo de vigência deste Termo de Uso, em conformidade com condições estipuladas no item de n° 4, a Licenciante concederá à Licenciada, em caráter não-exclusivo, intransferível, não-sublicenciável, revogável e, quando aplicável, oneroso, uma licença de direito de uso do Software, para que a Licenciada o utilize internamente em suas atividades.</IContent>
            </I>
            <I>
              <INumber>3.2</INumber>
              <IContent>Nos termos e condições fixados no item de n° 4, a Licenciante prestará os serviços de manutenção remota do software e o suporte técnico para a Licenciada.</IContent>
            </I>
            <SubI>
              <I>
                <INumber>i.</INumber>
                <IContent>A Licenciada declara, desde já, ter ciência de que a customização da solução não é um serviço abrangido por este Termo de Uso, sendo ela entregue no estado em que se encontra.</IContent>
              </I>
              <I>
                <INumber>ii.</INumber>
                <IContent>Os serviços que compreendem o objeto deste Termo de Uso serão executados remotamente e serão prestados exclusivamente em favor da unidade Licenciada.</IContent>
              </I>
              <I>
                <INumber>iii.</INumber>
                <IContent>O suporte técnico à Licenciada será realizado mediante a comunicação através do e-mail suporte@persora.com.br. </IContent>
              </I>
              <SubI>
                <I>
                  <INumber>a.</INumber>
                  <IContent>As solicitações e chamados encaminhados para o suporte terão retorno em até 48 (quarenta e oito) horas com orientação ou previsão de solução.</IContent>
                </I>
              </SubI>
            </SubI>
            <I>
              <INumber>3.3</INumber>
              <IContent>A Licenciada reconhece que não possui nenhum direito de propriedade sobre o Software Persora, não se transferindo pelo presente Termo de Uso quaisquer prerrogativas oriundas de tal direito.</IContent>
            </I>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 4 ######## */}
        <SectionLevel>
          <SectionTitle>4. Versão freemium</SectionTitle>
          <SectionContent>
            <I>
              <INumber>4.1</INumber>
              <IContent>A versão Freemium da Persora possibilita que o cliente cadastre sua equipe de até 05 (cinco) pessoas de forma gratuita; ou seja, o líder pode cadastrar até 04 (quatro) pessoas, que entrarão na plataforma como liderados.</IContent>
            </I>
            <SubI>
              <I>
                <INumber>i.</INumber>
                <IContent>Equipes acima de 05 (cinco) pessoas não terão acesso à versão gratuita do Freemium, sendo disponibilizadas outras versões mais adequadas ao modelo do time.</IContent>
              </I>
            </SubI>
            <I>
              <INumber>4.2</INumber>
              <IContent>A versão Freemium tem um prazo de duração de 6 (seis) meses, após esse período serão flexionadas as condições de pagamento que mais se adequam ao perfil da equipe, conforme melhor disposto nos Termos de Subscrição Persora.</IContent>
            </I>
            <I>
              <INumber>4.3</INumber>
              <IContent>Consulte a nossa Política de Privacidade para acessar informações da Persora como definições, fontes de coleta de dados, uso das informações e outros.</IContent>
            </I>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 5 ######## */}
        <SectionLevel>
          <SectionTitle>5. Remuneração e forma de pagamento</SectionTitle>
          <SectionContent>
            <I>
              <INumber>5.1</INumber>
              <IContent>Findo o período de teste gratuito, salvo na hipótese de cancelamento com, pelo menos, 24 (vinte e quatro) horas de antecedência, incidirá a Remuneração pelos Serviços prestados.</IContent>
            </I>
            <I>
              <INumber>5.2</INumber>
              <IContent>As condições comerciais irão variar de acordo com o tipo de plano contratado.</IContent>
            </I>
            <SubI>
              <I>
                <INumber>i.</INumber>
                <IContent>Na tela de assinatura você encontrará as informações sobre o seu plano para aceite.</IContent>
              </I>
            </SubI>
            <I>
              <INumber>5.3</INumber>
              <IContent>Caso necessário, todos os valores estabelecidos na contratação serão atualizados pelo IPCA/IBGE, ou índice que venha a substituí-lo, a cada 12 (doze) meses, contados da data de assinatura.</IContent>
            </I>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 6 ######## */}
        <SectionLevel>
          <SectionTitle>6. Restrições da licença de uso</SectionTitle>
          <SectionContent>
            <I>
              <INumber>6.1</INumber>
              <IContent>A Licenciada deverá abster-se de:</IContent>
            </I>
            <SubI>
              <I>
                <INumber>i.</INumber>
                <IContent>utilizar ou permitir a utilização do Software para outras finalidades que não estejam descritas nos Termos de Uso da plataforma;</IContent>
              </I>
              <I>
                <INumber>ii.</INumber>
                <IContent>usar o Software para qualquer fim ilegal e/ou de qualquer maneira que viole este Termo de Uso;</IContent>
              </I>
              <I>
                <INumber>ii.</INumber>
                <IContent>licenciar, sublicenciar, emprestar, compartilhar, ceder, transferir, arrendar ou alugar seu direito de uso do Software;</IContent>
              </I>
              <I>
                <INumber>iv.</INumber>
                <IContent>realizar cópias não autorizadas ou reproduzir qualquer parte, em qualquer forma, do Software;</IContent>
              </I>
              <I>
                <INumber>v.</INumber>
                <IContent>realizar, direta ou indiretamente, engenharia reversa, decompilar, desmontar, limpar, ou praticar outros atos para reconstruir o Software;</IContent>
              </I>
              <I>
                <INumber>vi.</INumber>
                <IContent>efetuar quaisquer modificações, alterações, melhorias ou aprimoramentos no Software (a não ser que de outra forma permitida pela Licenciante), abstendo-se de praticar qualquer outro ato que viole os Direitos de Propriedade Intelectual da Licenciante;</IContent>
              </I>
              <I>
                <INumber>vii.</INumber>
                <IContent>elaborar obra derivada baseada no Software;</IContent>
              </I>
              <I>
                <INumber>viii.</INumber>
                <IContent>comercializar, vender ou distribuir o Software;</IContent>
              </I>
              <I>
                <INumber>ix.</INumber>
                <IContent>modificar, remover ou redigir avisos sobre direitos autorais, marcas ou outros direitos exclusivos;</IContent>
              </I>
              <I>
                <INumber>x.</INumber>
                <IContent>
                  introduzir qualquer código malicioso,
                  <i>spyware</i>
                  , vírus de computador, cavalo de Tróia,
                  <i>worm</i>
                  , ou outro código semelhante ou componente de hardware projetado para desabilitar, danificar ou interromper a operação, permitir acesso não autorizado ao software, apagar, destruir, impactar o bom funcionamento ou modificar qualquer software, hardware, rede ou outra tecnologia atrelada ao Software; e
                </IContent>
              </I>
              <I>
                <INumber>xi.</INumber>
                <IContent>exercer ou reivindicar quaisquer direitos, propriedade ou título, incluindo os Direitos de Propriedade Intelectual, sobre o Software.</IContent>
              </I>
            </SubI>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 7 ######## */}
        <SectionLevel>
          <SectionTitle>7. Confidencialidade</SectionTitle>
          <SectionContent>
            <I>
              <INumber>7.1</INumber>
              <IContent>As Partes se comprometem a manter a confidencialidade e o sigilo de todas as Informações Confidenciais na melhor forma de direito, bem como todas as informações oriundas do objeto deste Termo de Uso, pelo prazo de vigência do Termo de Uso e até 1 (um) ano após o encerramento deste, sob pena de rescisão imediata deste instrumento, sem prejuízo da cobrança das perdas e danos a que der causa.</IContent>
            </I>
            <I>
              <INumber>7.2</INumber>
              <IContent>Informações Confidenciais. Toda informação (i) escrita, verbal, iconográfica ou apresentada de outro modo, (ii) de natureza comercial, operacional, técnica, contábil, jurídica, financeira, administrativa, de marketing ou comercial, estrutural ou de qualquer outra natureza, (iii) já fornecida ou que venha a ser fornecida às Partes, (iv) em relação direta ou indireta à contratação, às Partes e à operação será tratada como sigilosa e confidencial.</IContent>
            </I>
            <SubI>
              <I>
                <INumber>i.</INumber>
                <IContent>Consideram-se informações confidenciais, para efeitos deste Termo de Uso, todas as informações e documentos, escritos ou verbais, trocados ou fornecidos pelas Partes entre si, exceto se (i) conhecidas publicamente ou de domínio público, sem descumprimento ou violação deste Termo de Uso, (ii) recebidas pela Parte de terceiros ou previamente à celebração deste Termo de Uso, (iii) não estiverem sujeitas a restrição ou obrigação de confidencialidade, ou (iv) sua divulgação se der em razão de medida ou ordem determinada por autoridade competente.</IContent>
              </I>
            </SubI>
            <I>
              <INumber>7.3</INumber>
              <IContent>As Partes poderão revelar Informações Confidenciais para Terceiros quando por requisição de autoridade competente ou com o consentimento da outra Parte.</IContent>
            </I>
            <I>
              <INumber>7.4</INumber>
              <IContent>As Partes, reciprocamente, autorizam uma à outra a fazer referência à presente contratação para fins de divulgação de suas atividades empresariais em seus websites, aplicativos móveis ou material promocional e equivalentes.</IContent>
            </I>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 8 ######## */}
        <SectionLevel>
          <SectionTitle>8. Proteção de dados pessoais</SectionTitle>
          <SectionContent>
            <I>
              <INumber>8.1</INumber>
              <IContent>Para fins do disposto neste instrumento, deverão ser considerados os seguintes conceitos: </IContent>
            </I>
            <SubI>
              <I>
                <INumber>i.</INumber>
                <IContent>Dado pessoal: informação relacionada a pessoa natural identificada ou identificável, que qualquer das Partes venha a ter acesso em razão de divulgação lícita feita pela outra Parte, ou em decorrência da execução do objeto do presente Termo de Uso;</IContent>
              </I>
              <I>
                <INumber>ii.</INumber>
                <IContent>Dado pessoal sensível: dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural, a que uma das Partes venha a ter acesso em razão de divulgação lícita realizada pela outra Parte, ou em decorrência da execução do objeto deste Termo de Uso;</IContent>
              </I>
              <I>
                <INumber>iii.</INumber>
                <IContent>Tratamento: toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração;</IContent>
              </I>
              <I>
                <INumber>iv.</INumber>
                <IContent>Anonimização: utilização de meios técnicos razoáveis e disponíveis no momento do tratamento, por meio dos quais um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo.</IContent>
              </I>
            </SubI>
            <I>
              <INumber>8.2</INumber>
              <IContent>As Partes se comprometem a cumprir todas as leis aplicáveis em matéria de proteção de dados pessoais das partes envolvidas, realizando o tratamento dos dados pessoais compartilhados em razão deste Termo de Uso estritamente para a sua execução.</IContent>
            </I>
            <I>
              <INumber>8.3</INumber>
              <IContent>Os dados da PIP são coletados e direcionados ao líder, mas não serão expostas as respostas de ninguém. </IContent>
            </I>
            <SubI>
              <I>
                <INumber>i.</INumber>
                <IContent>Os resultados somente aparecem a partir de 3 respondentes, sem contar o líder.</IContent>
              </I>
            </SubI>
            <I>
              <INumber>8.4</INumber>
              <IContent>Os dados coletados do usuário são sempre armazenados com criptografia.</IContent>
            </I>
            <I>
              <INumber>8.5</INumber>
              <IContent>A identificação de cada usuário no sistema é baseada em nome, pronome e e-mail.</IContent>
            </I>
            <I>
              <INumber>8.6</INumber>
              <IContent>O acesso aos serviços se dará apenas com códigos aleatórios, como chaves de acesso, enviadas ao e-mail cadastrado, dispensando o uso de senhas.</IContent>
            </I>
            <I>
              <INumber>8.7</INumber>
              <IContent>Caso, por qualquer motivo, um titular dos dados pessoais solicite a anonimização, bloqueio ou eliminação de seus dados a uma das Partes deste Termo de Uso e, consequentemente, isso resultar na impossibilidade ou inviabilidade de continuar a prestação dos serviços, as Partes deverão informar, uma à outra, conforme o caso, acerca de tal situação e negociar as ações a serem aplicadas.</IContent>
            </I>
            <I>
              <INumber>8.8</INumber>
              <IContent>A Persora deverá adotar padrões de segurança da informação incluindo, mas não se limitando, aqueles descritos em normas legais e regulamentares, além de realizar revisões e atualizações periódicas com este fim.</IContent>
            </I>
            <I>
              <INumber>8.9</INumber>
              <IContent>Em caso de ocorrência de um incidente, as Partes deverão comunicar uma à outra, no prazo máximo de 24 (vinte e quatro) horas contados da ciência do evento, provendo informações, relatórios técnicos, e outras formas de suporte solicitados pela Licenciada, para fins de mitigação de danos e prejuízos, e/ou atendimento de autoridades competentes.</IContent>
            </I>
            <I>
              <INumber>8.10</INumber>
              <IContent>Em caso de compartilhamento internacional de dados, a Persora deverá assegurar que o receptor dos dados responda por padrões de segurança de dados compatíveis com os exercidos no Brasil, e atendam à legislação brasileira e demais normas setoriais aplicáveis, sob pena de responsabilidade solidária em caso de danos causados aos titulares dos dados pessoais tratados em razão deste Termo de Uso.</IContent>
            </I>
            <I>
              <INumber>8.11</INumber>
              <IContent>Ao término do tratamento de Dados Pessoais necessários para a prestação dos serviços, a Persora eliminará os Dados Pessoais fornecidos pela Licenciada. Fica autorizada a conservação de Dados Pessoais anonimizados.</IContent>
            </I>
            <I>
              <INumber>8.12</INumber>
              <IContent>A Persora comunicará à Licenciada, no prazo de 48 (quarenta e oito) horas, caso receba uma solicitação de um titular de dados.</IContent>
            </I>
            <I>
              <INumber>8.13</INumber>
              <IContent>Caso qualquer das Partes seja responsabilizada por quaisquer violações, vazamentos, fraudes ou outros incidentes de tratamento de dado pessoais ocasionados em razão da outra Parte, fica garantido o direito de regresso da Parte inocente contra a Parte infratora, que deverá ressarcir a Parte inocente de todos os valores gastos no pagamento de multas e indenizações, bem como despesas com honorários advocatícios, traslados, hospedagem e alimentação destinados à sua defesa.</IContent>
            </I>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 9 ######## */}
        <SectionLevel>
          <SectionTitle>9. Compliance e obrigações anticorrupção</SectionTitle>
          <SectionContent>
            <I>
              <INumber>9.1</INumber>
              <IContent>As Partes se comprometem a garantir que: </IContent>
            </I>
            <SubI>
              <I>
                <INumber>i.</INumber>
                <IContent>seus diretores, funcionários e demais membros desempenham sua atividade em observância à Lei nº 12.846/2013, assim como às demais legislações que tratem de aspectos relativos à prevenção de práticas de corrupção, suborno, lavagem de dinheiro e delitos contra o sistema financeiro, independentemente da jurisdição;</IContent>
              </I>
              <I>
                <INumber>ii.</INumber>
                <IContent>que nenhum dos seus diretores, funcionários e demais membros aceitarão, para si ou em favor de terceiros, seja em nome da outra parte ou não, qualquer ação que leve a ofertas, promessas, pagamentos, oportunidades de negócios, presentes ou entretenimento provenientes de Autoridade do Governo ou de uma pessoa física privada.</IContent>
              </I>
              <SubI>
                <I>
                  <INumber>a.</INumber>
                  <IContent>Para fins deste Termo de Uso, o termo “Autoridade do Governo” inclui: (a) qualquer autoridade ou empregado de qualquer governo, agência governamental ou entidade pública federal, regional ou local, no Brasil ou no exterior; (b) qualquer pessoa atuando em cargo oficial, embora temporariamente ou sem remuneração, em qualquer das entidades identificadas no item (a); (c) qualquer pessoa atuando na empresa prestadora de serviço contratada ou conveniada para a execução de atividade pública típica; e (d) membros de um partido político ou candidato ou indicado a cargo político.</IContent>
                </I>
                <I>
                  <INumber>b.</INumber>
                  <IContent>Em cada um dos casos supramencionados, inclui-se qualquer oferta, ação ou solicitação que vise influenciar decisão de oficial, agente, funcionário ou autoridade pública nos moldes acima descritos, ou ainda, que vise obter, reter ou direcionar qualquer negócio a pessoa ou a empresa.</IContent>
                </I>
              </SubI>
              <I>
                <INumber>iii.</INumber>
                <IContent>As partes reconhecem que qualquer infração às legislações ou disposições trazidas no presente instrumento, ou indícios de infração às mesmas, poderá resultar na rescisão imediata da totalidade ou parte do presente Termo de Uso, bem como de qualquer outro compromisso firmado entre as partes contratantes, e autorizará a reparação por danos materiais e morais em favor da outra parte.</IContent>
              </I>
            </SubI>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 10 ######## */}
        <SectionLevel>
          <SectionTitle>10. Compromisso de não aliciamento</SectionTitle>
          <SectionContent>
            <I>
              <INumber>10.1</INumber>
              <IContent>Uma Parte se compromete a não aliciar, recrutar, contratar e assediar qualquer sócio, diretor, conselheiro, empregado, colaborador, cliente ou negócio de outra Parte, em benefício próprio ou de terceiros, por si ou por intermédio de seus Sócios, Partes Relacionadas ou Pessoas interpostas, nem tampouco encorajar ou permitir que qualquer Pessoa pratique tais atos.</IContent>
            </I>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 11 ######## */}
        <SectionLevel>
          <SectionTitle>11. Obrigações das partes</SectionTitle>
          <SectionContent>
            <I>
              <INumber>11.1</INumber>
              <IContent>Sem prejuízo das obrigações já assumidas neste instrumento, a Persora: </IContent>
            </I>
            <SubI>
              <I>
                <INumber>i.</INumber>
                <IContent>Disponibilizará a Licença de Uso do software conforme previsto neste Termo de Uso;</IContent>
              </I>
              <I>
                <INumber>ii.</INumber>
                <IContent>Prestará o serviço de Manutenção e Suporte conforme previsto neste Termo de Uso;</IContent>
              </I>
              <I>
                <INumber>iii.</INumber>
                <IContent>Não assumirá qualquer responsabilidade por perdas e danos que possam originar das informações prestadas pela Licenciada e que sejam necessárias à prestação dos serviços;</IContent>
              </I>
              <I>
                <INumber>iv.</INumber>
                <IContent>Não assumirá qualquer responsabilidade pela realização ou não de quaisquer negócios jurídicos entre a Licenciada e Terceiros, levadas a termo em razão do uso das informações disponibilizadas na forma deste Termo de Uso, bem como eventuais perdas e danos decorrentes das relações entre a Licenciada e quaisquer Terceiros.</IContent>
              </I>
            </SubI>
            <I>
              <INumber>11.2</INumber>
              <IContent>Sem prejuízo das demais obrigações assumidas por meio deste Termo de Uso, a Licenciada:</IContent>
            </I>
            <SubI>
              <I>
                <INumber>i.</INumber>
                <IContent>Declara ciência às formas e condições dispostas para o uso da Versão Freemium constantes no item de n° 4;</IContent>
              </I>
              <I>
                <INumber>ii.</INumber>
                <IContent>Colocará à disposição da Persora as informações, instalações, documentos e quaisquer outros materiais essenciais à execução deste Termo de Uso;</IContent>
              </I>
              <I>
                <INumber>iii.</INumber>
                <IContent>Não poderá vender, repassar ou estabelecer convênio de repasse de informações obtidas por meio da Persora com outras empresas, especialmente, aquelas que prestam serviços assemelhados, salvo mediante autorização prévia e por escrito;</IContent>
              </I>
              <I>
                <INumber>iv.</INumber>
                <IContent>Se compromete a não utilizar as informações obtidas para constranger ou coagir, de qualquer maneira que seja, qualquer indivíduo ou, ainda, como justificativa para atos que violem ou ameacem interesses de Terceiros;</IContent>
              </I>
              <I>
                <INumber>v.</INumber>
                <IContent>Não utilizará ou acessará a solução Persora com o objetivo de proporcionar a si ou a terceiros meios que facilite o desenvolvimento de serviços concorrentes àqueles objetos deste Termo de Uso;</IContent>
              </I>
              <I>
                <INumber>vi.</INumber>
                <IContent>Se compromete a fazer com que cada um dos usuários autorizados a utilizar a solução Persora: (i) utilize e acesse a solução sempre de acordo com as disposições deste Termo de Uso; (ii) não compartilhe o seu acesso ou os detalhes para seu login com terceiros; e (iii) trate a respectiva senha de acesso e nome de usuário como Informações Confidenciais;</IContent>
              </I>
              <I>
                <INumber>vii.</INumber>
                <IContent>Deverá comunicar à Persora, sempre que entender necessário, qualquer irregularidade ou ocorrência que envolver os funcionários da Persora designados para execução dos Serviços;</IContent>
              </I>
              <I>
                <INumber>viii.</INumber>
                <IContent>Responsabilizar-se-á por todas as informações inseridas na plataforma por seus colaboradores, sócios ou representantes de qualquer natureza.</IContent>
              </I>
            </SubI>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 12 ######## */}
        <SectionLevel>
          <SectionTitle>12. Declarações, garantias e isenções</SectionTitle>
          <SectionContent>
            <I>
              <INumber>12.1</INumber>
              <IContent>A Licenciante garante que o Software funcionará para as atividades descritas na Cláusula 1. A LICENCIANTE NÃO GARANTE QUE AS FUNÇÕES CONTIDAS NO SOFTWARE OPERARÃO DE MANEIRA ININTERRUPTA OU SEM ERROS, OU QUE TODOS OS DEFEITOS SEJAM CORRIGIDOS. EXCETO PELA GARANTIA LIMITADA ESTABELECIDA ACIMA, ESTE SOFTWARE É FORNECIDO NO ESTADO EM QUE SE ENCONTRA, SEM NENHUM TIPO DE GARANTIA. A GARANTIA LIMITADA CONTIDA NESTE TERMO DE USO É APRESENTADA EM SUBSTITUIÇÃO A TODAS AS OUTRAS GARANTIAS, ESTATUTÁRIAS, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, MAS NÃO SE LIMITANDO A, AQUELAS RELATIVAS À COMERCIALIZAÇÃO E ADEQUAÇÃO A UM PROPÓSITO ESPECÍFICO COMO RESULTADO DE USO COMERCIAL, OU POR CURSO DE NEGOCIAÇÃO. A LICENCIANTE NÃO FAZ NENHUMA GARANTIA ADICIONAL E NÃO SERÁ RESPONSÁVEL POR NENHUM DANO MORAL OU MATERIAL DECORRENTE DE: (A) ERROS, INCORREÇÕES OU BUGS DO SOFTWARE; OU (B) CÓDIGO MALICIOSO, SPYWARE, VÍRUS DE COMPUTADOR, CAVALO DE TRÓIA, WORM, OU OUTRO CÓDIGO SEMELHANTE OU COMPONENTE DE HARDWARE PROJETADO PARA DESABILITAR, DANIFICAR OU INTERROMPER A OPERAÇÃO E/OU PERMITIR ACESSO NÃO AUTORIZADO.</IContent>
            </I>
            <I>
              <INumber>12.2</INumber>
              <IContent>Em nenhuma hipótese a Licenciante será responsável por quaisquer danos especiais, incidentais, indiretos, punitivos ou exemplares ou consequenciais, ou danos por perda de negócios, perda de lucros, lucros cessantes, interrupção de negócios ou perda de informações comerciais resultantes do uso ou incapacidade de usar o Software. A responsabilidade total da Licenciante com relação às suas obrigações sob este Termo de Uso ou de outra forma com relação ao Software limita-se, a seu exclusivo critério, à substituição do Software por uma alternativa razoável.</IContent>
            </I>
            <I>
              <INumber>12.3</INumber>
              <IContent>A Licenciada indenizará a Licenciante, suas afiliadas, e os respectivos empregados, agentes, e administradores (em conjunto, as “Partes Indenizadas da Licenciante”) de todo e qualquer dano, perda, responsabilidade, desembolso, obrigação, multas, taxas, penalidades ou outra despesa de qualquer natureza, (inclusive despesas processuais e honorários advocatícios), resultantes, relacionados ou decorrentes de (a) qualquer ação ou omissão da Licenciada, e de seus Representantes; (b) violação pela Licenciada das disposições deste Termo de Uso; (c) descumprimento, omissão, erro, inexatidão ou falsidade de qualquer declaração e garantia prestada neste Termo de Uso; ou (d) demandas judiciais, arbitrais ou administrativas movidas por terceiros e/ou autoridades governamentais contra a Licenciante (“Ações de Terceiros”), com fundamento nos itens (a), (b) ou (c) acima ou em decorrência de atos ou fatos cuja responsabilidade seja imputável à Licenciada.</IContent>
            </I>
            <I>
              <INumber>12.4</INumber>
              <IContent>A Licenciante não terá qualquer responsabilidade perante a Licenciada, nos termos deste Termo de Uso, na medida em que as perdas e danos sofridas pela Licenciada surjam em decorrência do fato de a Licenciada: (i) ter violado este Termo de Uso; (ii) não ter instalado um ambiente de TI seguro e de acordo com as melhores práticas; (iii) usar o Software fora do objeto deste Termo de Uso; (iv) modificar Software sem autorização e, além disso, a modificação se relacionar com a questão da violação; ou (v) combinar o Software com softwares, equipamentos ou sistemas não fornecidos pela Licenciante, na medida em que tal combinação se relacionar com a questão da violação e sem autorização da Licenciante.</IContent>
            </I>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 13 ######## */}
        <SectionLevel>
          <SectionTitle>13. Inexistência de vínculo trabalhista e societário</SectionTitle>
          <SectionContent>
            <I>
              <INumber>13.1</INumber>
              <IContent>As Partes, em razão deste Instrumento, não serão consideradas representantes, agentes, mandatárias, empregadas, sócias ou de qualquer forma associadas umas às outras, de modo que nenhuma das disposições deste Instrumento deverá ser entendida como a criação de relação de trabalho, sociedade ou qualquer tipo de associação entre as Partes, que não tem poderes para representar uma à outra de qualquer forma ou em qualquer momento, salvo acordo posterior celebrado por escrito.</IContent>
            </I>
            <I>
              <INumber>13.2</INumber>
              <IContent>Todos os tributos, inclusive de natureza previdenciária e trabalhista, bem como ônus fiscais de natureza federal, estadual e municipal, decorrentes das obrigações assumidas e oriundas da celebração deste Termo de Uso ou de sua execução, correrão, única e exclusivamente, por conta e risco de cada Parte no que lhes couber, sem prejuízo das obrigações legais de retenção de cada uma das Partes.</IContent>
            </I>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 14 ######## */}
        <SectionLevel>
          <SectionTitle>14. Extinção contratual e penalidades</SectionTitle>
          <SectionContent>
            <I>
              <INumber>14.1</INumber>
              <IContent>No caso de descumprimento de quaisquer cláusulas deste Termo de Uso, a Parte Adimplente deverá notificar a Parte Inadimplente para que seja sanado o inadimplemento, estipulando, para tanto, prazo razoável. </IContent>
            </I>
            <I>
              <INumber>14.2</INumber>
              <IContent>Caso a Parte Inadimplente não apresente justificativa razoável ou não sane o inadimplemento da obrigação sobre a qual fora notificada, o Termo de Uso poderá ser resolvido pela Parte Adimplente, sendo cessados imediatamente os serviços.</IContent>
            </I>
            <I>
              <INumber>14.3</INumber>
              <IContent>As condições de rescisão unilateral pela Contratante encontram-se nas especificações do tipo de plano contidas no Termo de Subscrição.</IContent>
            </I>
            <I>
              <INumber>14.4</INumber>
              <IContent>O Contrato poderá ser extinto unilateralmente pela Persora, a qualquer momento, sem justo motivo, mediante o encaminhamento de notificação, por escrito, com antecedência mínima de 60 (sessenta) dias.</IContent>
            </I>
            <I>
              <INumber>14.5</INumber>
              <IContent>Em qualquer hipótese de extinção contratual, será imediatamente extinta a Licença de Uso da Licenciada, sendo revogado o seu acesso à plataforma e aos Serviços.</IContent>
            </I>
            <I>
              <INumber>14.6</INumber>
              <IContent>As Cláusulas de Confidencialidade e Proteção de Dados Pessoais continuam vigentes mesmo após a extinção deste Termo de Uso, por qualquer motivo, pelo prazo de 1 (um) ano.</IContent>
            </I>
            <I>
              <INumber>14.7</INumber>
              <IContent>Independente do motivo da extinção do Termo de Uso, a Contratante deverá realizar o pagamento de eventual saldo devedor, sendo incontroverso que o valor devido por cada colaborador cadastrado pela Licenciada, mesmo que pago em parcelas, deverá ser integralmente quitado.</IContent>
            </I>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 15 ######## */}
        <SectionLevel>
          <SectionTitle>15. Propriedade intelectual</SectionTitle>
          <SectionContent>
            <I>
              <INumber>15.1</INumber>
              <IContent>A Persora é a detentora exclusiva de todos os direitos, títulos e interesses, invenções não patenteadas, pedidos de registro de patente, patentes, direitos de projeto, direitos autorais, marcas, marcas de serviço, nomes comerciais, direitos sobre nomes de domínio, know-how e outros direitos de segredo comercial, software e todos os outros demais direitos de propriedade intelectual, inclusive obras e documentos derivadas, modificações e respectivos aprimoramentos, e formas de proteção de natureza semelhante em qualquer lugar do mundo (“Direitos de Propriedade Intelectual”), relativos aos sistemas informáticos e serviços, bem como sugestões, ideias, pedidos de melhoria, feedback, recomendações ou outras informações fornecidas pela Contratante ou qualquer outra parte com relação à solução e aos serviços. Este Termo de Uso não transfere à Licenciada nenhum direito sobre ou relativo aos materiais licenciados, aos serviços ou aos Direitos de Propriedade Intelectual da Persora.</IContent>
            </I>
            <SubI>
              <I>
                <INumber>i.</INumber>
                <IContent>Da violação da cláusula 15.1 decorrerá a obrigação do pagamento de perdas e danos.</IContent>
              </I>
              <I>
                <INumber>ii.</INumber>
                <IContent>Se a Licenciada fornecer feedback à Licenciante em relação ao Software ou a qualquer tecnologia relacionada, como sugestões de aprimoramento ou correção de bugs, a Licenciada concede uma licença irrevogável, perpétua, gratuita e não exclusiva à Licenciante para usar esses comentários para desenvolver melhorias e alterações em relação ao Software.</IContent>
              </I>
            </SubI>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 16 ######## */}
        <SectionLevel>
          <SectionTitle>16. Não concorrência</SectionTitle>
          <SectionContent>
            <I>
              <INumber>16.1</INumber>
              <IContent>Durante a vigência deste Termo de Uso, incluindo quaisquer prorrogações, e enquanto utilizar o Software, a Licenciada e suas Afiliadas não poderão promover, oferecer, intermediar, negociar, facilitar, ou desenvolver com o intuito de comercializar com terceiros qualquer atividade de desenvolvimento, de licenciamento ou de distribuição de software que possua funcionalidades idênticas ou semelhantes às do Software objeto deste Termo de Uso e que possam desempenhar competição à Licenciante, salvo na hipótese de desenvolvimento de software para uso próprio e sem a possibilidade de exploração econômica para fins de licenciamento, distribuição ou produção de novos produtos a terceiros.</IContent>
            </I>
          </SectionContent>
        </SectionLevel>
        {/* ######## SECTION 17 ######## */}
        <SectionLevel>
          <SectionTitle>17. Disposições gerais</SectionTitle>
          <SectionContent>
            <I>
              <INumber>17.1</INumber>
              <IContent>A vigência é de acordo com o tipo de plano que será contratado. </IContent>
            </I>
            <SubI>
              <I>
                <INumber>i.</INumber>
                <IContent>ccc</IContent>
              </I>
            </SubI>
            <I>
              <INumber>17.2</INumber>
              <IContent>O presente Instrumento somente poderá ser validamente alterado, modificado ou aditado por manifestação expressa, mediante instrumento escrito devidamente assinado pelas Partes em forma de aditivo contratual.</IContent>
            </I>
            <I>
              <INumber>17.3</INumber>
              <IContent>A Persora poderá utilizar a marca da Licenciada para fins exclusivos de divulgação em portfólios, site, mídias sociais, apresentações e demais meios de comunicação e marketing usuais da Persora, sendo vedado qualquer uso com finalidade diferente.</IContent>
            </I>
            <I>
              <INumber>17.4</INumber>
              <IContent>A Persora, mesmo durante a vigência deste Termo de Uso, poderá celebrar Termo de Usos para a execução de semelhantes ou idênticos serviços para Terceiros, concorrentes ou não da Contratante.</IContent>
            </I>
            <I>
              <INumber>17.5</INumber>
              <IContent>A invalidade parcial deste Instrumento não afetará partes consideradas válidas, desde que as obrigações sejam desmembráveis entre si. Ocorrendo o disposto nesta cláusula, as Partes desde já se comprometem a negociar, no menor prazo possível, em substituição à cláusula invalidada, a inclusão de termos e condições válidos que reflitam os termos e condições da cláusula invalidada, observados a intenção e objetivo das Partes quando da negociação da cláusula invalidada e o contexto em que se insere.</IContent>
            </I>
            <I>
              <INumber>17.6</INumber>
              <IContent>Todas as notificações, consentimentos, solicitações e outras comunicações previstas neste Instrumento serão realizadas por escrito, e deverão ser entregues pessoalmente, por carta, por e-mail ou por notificação push em aplicativo da Persora, em qualquer hipótese, com comprovante de recebimento, nos endereços físicos ou eletrônicos, e para as pessoas indicadas no preâmbulo deste Instrumento, ou para endereços e pessoas diversas especificados por uma Parte à outra, por escrito. Qualquer Parte poderá mudar o endereço para o qual a notificação deverá ser enviada, mediante notificação prévia escrita às demais Partes.</IContent>
            </I>
            <I>
              <INumber>17.7</INumber>
              <IContent>O presente Instrumento vincula as Partes e seus sucessores a qualquer título, em caráter irrevogável e irretratável, ao fiel cumprimento deste Instrumento.</IContent>
            </I>
            <I>
              <INumber>17.8</INumber>
              <IContent>A tolerância de qualquer das Partes com relação à exigência do regular e tempestivo cumprimento das obrigações de outra Parte não constituirá desistência, alteração, modificação, ou novação de quaisquer dos direitos ou obrigações estabelecidas por meio deste Instrumento, constituindo mera liberdade, que não impedirá a Parte tolerante de exigir da outra o fiel e cabal cumprimento deste Instrumento, a qualquer tempo. Nenhuma renúncia a exercício de direito assegurado neste Instrumento será válida, exceto se formalizada por escrito pela Parte renunciante.</IContent>
            </I>
            <I>
              <INumber>17.9</INumber>
              <IContent>Fica expressamente pactuado que uma Parte indenizará a outra se porventura for autuada, notificada, intimada, citada ou condenada em razão do não cumprimento de qualquer obrigação de qualquer espécie atribuível à outra Parte, podendo haver retenções de pagamento até a resolução da disputa.</IContent>
            </I>
            <I>
              <INumber>17.10</INumber>
              <IContent>A relação entre as Partes restringe-se ao objeto do presente, não havendo entre ambas relação associativa, trabalhista, societária, franquia, joint venture ou similar.</IContent>
            </I>
            <I>
              <INumber>17.11</INumber>
              <IContent>As Partes não poderão ceder os direitos ou obrigações decorrentes deste Termo de Uso, sem prévia anuência por escrito da outra Parte.</IContent>
            </I>
            <I>
              <INumber>17.12</INumber>
              <IContent>Sem prejuízo de outros recursos detidos pelas Partes, todas as disposições e obrigações assumidas neste Termo de Uso são passíveis de execução específica, nos termos da legislação vigente, sem prejuízo de eventuais perdas e danos para satisfação adequada do direito das Partes</IContent>
            </I>
            <I>
              <INumber>17.13</INumber>
              <IContent>As Partes se comprometem e obrigam-se, em caráter irretratável e irrevogável, a firmar quaisquer outros documentos e praticar quaisquer outros atos necessários para formalizar o exercício dos direitos previstos neste Instrumento.</IContent>
            </I>
            <I>
              <INumber>17.14</INumber>
              <IContent>O presente instrumento será firmado na finalização do seu cadastro como usuário da Persora. A partir dos aceites nos campos obrigatórios as partes declaram ciência e concordância.</IContent>
            </I>
            <I>
              <INumber>17.15</INumber>
              <IContent>Fica eleito o foro central da comarca de Vitória/ES para as hipóteses em que for necessária a intervenção do Poder Judiciário.</IContent>
            </I>
          </SectionContent>
        </SectionLevel>
      </DocContainer>
    </>
  );
}

export default function TermsOfUse({ isSinglePage }) {
  if (isSinglePage) {
    return (
      <div
        style={{
          minHeight: 400,
          padding: defaultTheme.spaces.screenEdge,
          paddingTop: '20px',
          color: defaultTheme.colors.primary.main,
        }}
      >
        <ConteudoDosTermos />
      </div>
    );
  }
  return (
    <Sheet.Container>
      <Sheet.Header />
      <Sheet.Content>
        <Sheet.Scroller>
          <div
            style={{
              minHeight: 400,
              padding: defaultTheme.spaces.screenEdge,
              paddingTop: 0,
              color: defaultTheme.colors.primary.main,
            }}
          >
            <ConteudoDosTermos />
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
  );
}

TermsOfUse.propTypes = {
  isSinglePage: PropTypes.bool,
};
TermsOfUse.defaultProps = {
  inSinglePage: false,
};
