import styled from 'styled-components';

// ${({ percentual }) => percentual || '0%'};

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.surfaces.lightBlue};
  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px rgba(6, 16, 42, 0.10);
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.surfaces.lightBlue};
  position: relative;
  z-index: 0;
`;

export const CardHeader = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  padding: ${({ theme }) => theme.spaces.screenEdge};
  display: flex;
  align-items: center;
`;

export const CardButtons = styled.div`
background: ${({ theme }) => theme.colors.surfaces.lightBlue};
padding: 12px ${({ theme }) => theme.spaces.screenEdge};
border-top: 1px solid ${({ theme }) => theme.colors.black20};
display: flex;
justify-content: space-between;
  &.firstAuthFalse {
    background: ${({ theme }) => theme.colors.black10};
  }
`;

export const ListItem = styled.div`
  padding-top: 1rem;
  &::before {
    content: '';
    width: calc(100% - (2* ${({ theme }) => theme.spaces.screenEdge}));
    margin-top: calc(-1rem - 2px);
    height: 40px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+3,ffffff+100&1+4,0+100 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 3%,rgba(255,255,255,1) 4%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    position: absolute;
  }
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.primary.light};
  margin-bottom: .2rem !important;
  /* text-wrap: balance; */
  font-weight: 600;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.primary.light};
  margin-bottom: 0.5rem !important;
`;

export const Tag = styled.span`
  color: ${({ theme }) => theme.colors.primary.light};
  background-color: ${({ theme }) => theme.colors.black20};
  padding: 4px 9px;
  border-radius: 8px;
  margin-right: 4px;
  margin-bottom: 1rem;
  display: inline-block;
  font-weight: 600;
  font-size: .9rem;

  &.instrumentTag {
    background-color: ${({ theme }) => theme.colors.alerts.info.background};
    color: ${({ theme }) => theme.colors.alerts.info.textColor};
  }
  &.success {
    background-color: ${({ theme }) => theme.colors.alerts.success.background};
    color: ${({ theme }) => theme.colors.alerts.success.titleColor};
  }
  &.danger {
    background-color: ${({ theme }) => theme.colors.alerts.danger.background};
    color: ${({ theme }) => theme.colors.alerts.danger.titleColor};
  }
  &.warning {
    background-color: ${({ theme }) => theme.colors.alerts.warning.background};
    color: ${({ theme }) => theme.colors.alerts.warning.titleColor};
  }
  &.info {
    background-color: ${({ theme }) => theme.colors.alerts.info.background};
    color: ${({ theme }) => theme.colors.alerts.info.titleColor};
  }

`;
