// import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import { bumperContents } from './bumperContents';
// import { UserContext } from '../../Context/UserContext';
import { Context } from '../../Context/AuthContext';
import api from '../../services/backend';
import SoraBumperCover from '../SoraBumperCover';
import defaultTheme from '../../assets/styles/themes/default';

export function ModalBumpers() {
  // http://localhost:8000/userSpace?activeTapBar=user&triggerBumper=true&bumperId=l-invitesAccepted-b1&customContent1=10&customContent2=20%20usu%C3%A1rios
  const { bumperOptionalProps, setBumperOptionalProps } = useContext(Context);
  // console.log(bumperOptionalProps);

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const location = useLocation(); // Obter a localização atual
  // const history = useHistory(); // Obter o histórico de navegação
  const [triggerBumper, setTriggerBumper] = useState(false);
  const [bumperAvulso, setBumperAvulso] = useState(false);
  const [bumperAvulsoData, setBumperAvulsoData] = useState({});
  const [bumperId, setBumperId] = useState(null);
  const [customContent1prop, setCustomContent1prop] = useState('');
  const [customContent2prop, setCustomContent2prop] = useState('');
  // console.log('==============>', fullscreen, show, triggerBumper, bumperAvulso, bumperId);

  // Função para obter os parâmetros do bumper avulso,
  // caso bumperAvulso seja true
  async function loadBumperAvulsoParams(idBumperAvulso) {
    try {
      // Faz a primeira requisição para obter o messageId
      const { data } = await api.get(`/bumper/getByBumperId?bumperId=${idBumperAvulso}`);
      // Verifica se há dados retornados
      // console.log('bumper/getByBumperId:', data);
      setBumperAvulsoData(data.bumper);
    } catch (error) {
      console.error('Erro ao tentar obter as notificações do usuário', error);
    }
    return null;
  }

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
    if (bumperAvulso) {
      // eslint-disable-next-line
      // console.log('======> bumper avulso visualizado', bumperId, bumperAvulsoData?.pushData?.pushTitle); // DEBUG
    } else {
      // eslint-disable-next-line
      // console.log('======> bumper visualizado', bumperId, bumperContents({ bumperId }).title); // DEBUB
    }
    if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
      // aguardar 2.5s para executar o evento
      setTimeout(() => {
        ReactGA.event({
          category: 'Engajamento',
          action: 'bumper_visualizado',
          bumper_id: bumperId,
          bumper_title: bumperAvulso
            ? bumperAvulsoData?.pushData?.pushTitle
            : bumperContents({ bumperId }).title,
        });
        mixpanel.track('bumper_visualizado', {
          category: 'Engajamento',
          // company_id: currentUserContextData?._idWorkspace[0],
          bumper_id: bumperId,
          bumper_title: bumperAvulso
            ? bumperAvulsoData?.pushData?.pushTitle
            : bumperContents({ bumperId }).title,
        });
      }, 6500);
    }
  }

  function handleClose() {
    if (bumperOptionalProps?.bumperId) {
      setBumperOptionalProps(null);
    }
    // console.log('======> bumper_fechado', bumperId, bumperContents({ bumperId }).title); // DEBUG
    setShow(false);
    setBumperId(null);
    setBumperAvulso(false);
    setCustomContent1prop('');
    setCustomContent2prop('');
    setBumperAvulsoData({});

    // Remover os parâmetros da URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('triggerBumper');
    searchParams.delete('bumperAvulso');
    searchParams.delete('bumperId');
    searchParams.delete('novuTransactionId');
    searchParams.delete('customContent1');
    searchParams.delete('customContent2');

    // Reconstruir a URL sem os parâmetros removidos
    const newSearch = searchParams.toString();
    const newPath = newSearch ? `${location.pathname}?${newSearch}` : location.pathname;

    // Atualizar a URL
    // history.push(newPath);
    window.history.replaceState({}, '', newPath);
    // window.history.pushState({}, '', newPath);
    // replace - by _ on bumperId
    // const bumperIdUnderscore = bumperId.replace(/-/g, '_');
    if (process.env.REACT_APP_TRIGGER_EVENTS === 'true') {
      ReactGA.event({
        category: 'Engajamento',
        action: 'bumper_fechado',
        bumper_id: bumperId,
        bumper_title: bumperAvulso
          ? bumperAvulsoData?.pushData?.pushTitle
          : bumperContents({ bumperId }).title,
      });
      mixpanel.track('bumper_fechado', {
        category: 'Engajamento',
        // company_id: currentUserContextData?._idWorkspace[0],
        bumper_id: bumperId,
        bumper_title: bumperAvulso
          ? bumperAvulsoData?.pushData?.pushTitle
          : bumperContents({ bumperId }).title,
      });
    }
  }

  useEffect(() => {
    // Verificar se a URL contém 'triggerBumper'
    if (bumperOptionalProps?.bumperId) {
      setTriggerBumper(true);
      setBumperId(bumperOptionalProps.bumperId);
    } else {
      const urlParams = new URLSearchParams(location.search);
      if (urlParams.get('triggerBumper')) {
        setTriggerBumper(true);
        setBumperId(urlParams.get('bumperId'));
        if (urlParams.get('bumperAvulso') === 'true') {
          setBumperAvulso(true);
          loadBumperAvulsoParams(urlParams.get('bumperId'));
        } else {
          setBumperAvulso(false);
        }
        setCustomContent1prop(urlParams.get('customContent1'));
        setCustomContent2prop(urlParams.get('customContent2'));
      }
    }
  }, [location.search]);

  useEffect(() => {
    // Verificar se a URL contém 'triggerBumper'
    if (bumperOptionalProps?.bumperId) {
      setTriggerBumper(true);
      setBumperId(bumperOptionalProps.bumperId);
      if (bumperOptionalProps?.bumperAvulso) {
        setBumperAvulso(true);
        loadBumperAvulsoParams(bumperOptionalProps.bumperId);
      }
    }
  }, [bumperOptionalProps]);

  useEffect(() => {
    if (triggerBumper) {
      handleShow(true);
    }
  }, [triggerBumper]);

  if (bumperAvulso) {
    return (
      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={() => {
          handleClose();
          setTriggerBumper(false);
        }}
        animation={false}
        contentClassName="p-0"
        style={{ zIndex: 999999999999 }}
        id="modal-bumpers"
        className="modalFullscreenPage"
      >
        <Modal.Header className="p-4" closeButton>
          <Modal.Title />
        </Modal.Header>
        <Modal.Body className="p-0 pb-4">
          {bumperAvulsoData?.soraCover?.soraBumperComponentParams
            && <SoraBumperCover {...bumperAvulsoData?.soraCover?.soraBumperComponentParams} />}
          <div className="remirror-sendBumper" style={{ padding: `40px ${defaultTheme.spaces.screenEdge}` }}>
            <div dangerouslySetInnerHTML={{ __html: bumperAvulsoData.contentHtml }} />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <Modal
      show={show}
      fullscreen={fullscreen}
      onHide={() => {
        handleClose();
        setTriggerBumper(false);
      }}
      animation={false}
      contentClassName="p-0"
      style={{ zIndex: 999999999999 }}
      id="modal-bumpers"
      className="modalFullscreenPage"
    >
      <Modal.Header className="p-4" closeButton>
        <Modal.Title />
      </Modal.Header>
      <Modal.Body className="p-0 pb-4">
        {bumperContents(
          {
            bumperId,
            customContent1prop: bumperOptionalProps?.customContent1
              ? bumperOptionalProps.customContent1 : customContent1prop,
            customContent2prop: bumperOptionalProps?.customContent2
              ? bumperOptionalProps.customContent2 : customContent2prop,
          },
        ).content}
      </Modal.Body>
    </Modal>
  );
}
