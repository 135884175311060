import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  /* background-color: #f2f2f2; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 25px;
  padding-right: 25px;
  padding-left: 15px;
`;

export const StepDivider = styled.div`
  width: 100%;
  height: 1px;
  /* background-color: ${({ theme }) => theme.colors.black20}; */
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black20};

  /* before with prop stepValue */
  &::before {
    content: '${({ $contentbefore }) => $contentbefore || ''}';
    position: absolute;
    right: 0;
    top: -22px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.black60};
  }
`;

export const LeftBar = styled.div`
  position: relative;
  width: 100%;
  max-width: 180px;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  background-color: ${({ theme }) => theme.colors.secondary.main};
  transition: ease-in-out 0.3s;
  z-index: 2;

  &.worseDimension {
    background-color: #A11212 !important;
  }

  &::before {
    width: fit-content;
    width: 60px;
    padding: 0px 10px;
    content: '${({ $contentbefore }) => $contentbefore || ''}';
    position: absolute;
    left: calc(50% - 28px);
    top: -24px;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.primary.main};
    text-align: center;
    font-weight: 600;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    white-space: nowrap;
  }
`;

export const RightBar = styled.div`
  position: relative;
  width: 100%;
  max-width: 180px;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  background-color: ${({ theme }) => theme.colors.secondary.dark};
  transition: ease-in-out 0.3s;
  z-index: 2;

  &.worseDimension {
    background-color: #A11212 !important;
  }

  &::before {
    width: fit-content;
    width: 60px;
    padding: 0px 10px;
    content: '${({ $contentbefore }) => $contentbefore || ''}';
    position: absolute;
    left: calc(50% - 28px);
    top: -24px;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.primary.main};
    text-align: center;
    font-weight: 600;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    white-space: nowrap;
  }
`;
