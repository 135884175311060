import { useContext, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
// import { Form } from 'react-bootstrap';

import { Sheet } from 'react-modal-sheet';
import defaultTheme from '../../assets/styles/themes/default';
import logo from '../../assets/img/logo-horizontal.svg';

import { Context } from '../../Context/AuthContext';

import {
  Container, Content, FormGroup, TextLink,
} from './styles';
import PersoraButton from '../../components/Button';
import PersoraTextButton from '../../components/TextButton';
import TermsOfUseSheet from '../Sheets/TermsOfUse';
import { Clock } from '../../components/DuotoneIcon';

export default function Signup() {
  const { authenticated, handleSignup, btnLoading } = useContext(Context);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const emailFromUrlParams = urlParams.get('email');
  const [termsOfUseSheetIsOpen, setTermsOfUseSheetOpen] = useState(false);

  /** Se usuário está autenticado, redireciona ele para /userSpace */
  if (authenticated) {
    return <Redirect to="/userSpace" />;
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    // const fullname = event.target.fullname.value;
    const email = event.target.email.value;
    // const pronouns = event.target.pronouns.value;
    handleSignup(email);
  };

  return (
    <Container>
      <Content>
        <img src={logo} alt="Logo" width="160px" />
        <h2 className="mb-3">Vamos criar sua conta 😎</h2>
        <p className="big d-none">
          A gente só vai precisar do seu email...
        </p>
        <form id="signupForm" onSubmit={handleSubmit}>
          {/* <FormGroup>
            <div htmlFor="signup-fullname">Seu nome</div>
            <input type="text" className="form-control"
            id="signup-fullname" name="fullname" required />
          </FormGroup> */}
          <FormGroup>
            <div htmlFor="signup-email">Seu e-mail</div>
            <input type="email" className="form-control" id="signup-email" name="email" defaultValue={emailFromUrlParams} required />
          </FormGroup>
          {/* <Form.Group>
            <Form.Label className="dmsans mt-3" style={{ fontSize: '12px' }}>
              Seus pronomes
            </Form.Label>
            <Form.Select aria-label="Pronomes" name="pronouns" required>
              <option value="">selecione...</option>
              <option value="male">ele/dele</option>
              <option value="female">ela/dela</option>
            </Form.Select>
          </Form.Group> */}
          <PersoraButton className="mt-4" type="submit" form="signupForm" disabled={btnLoading}>
            {btnLoading ? <Clock tamanho={27} cor="#FFFFFF" /> : 'Criar conta'}
          </PersoraButton>
        </form>
        <TextLink className="small">
          Voltar para
          {' '}
          <Link to="/login" style={{ marginLeft: '3px' }}>tela de Login! 👨‍💻</Link>
        </TextLink>
        {/* <Link type="button" onClick={handleNavigate}>Cadastrar</Link> */}
        <p className="small mt-5" style={{ color: defaultTheme.colors.black60 }}>
          Ao continuar, você concordará com nossos
          {' '}
          <PersoraTextButton
            className="bold"
            style={{ display: 'contents' }}
            onClick={() => {
              setTermsOfUseSheetOpen(true);
            }}
          >
            <u>Termos de Uso</u>
          </PersoraTextButton>
          {' '}
          e
          {' '}
          <PersoraTextButton
            className="bold"
            style={{ display: 'contents' }}
            onClick={() => {
              setTermsOfUseSheetOpen(true);
            }}
          >
            <u>Política de Privacidade</u>
          </PersoraTextButton>
          .
        </p>
      </Content>
      <Sheet
        isOpen={termsOfUseSheetIsOpen}
        onClose={() => setTermsOfUseSheetOpen(false)}
        // detent="content-height"
        tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}
      >
        <TermsOfUseSheet />
        <Sheet.Backdrop onTap={() => setTermsOfUseSheetOpen(false)} />
      </Sheet>
    </Container>
  );
}
